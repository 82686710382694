import ModalPanel from '@admin/components/modal_panel'
import Menu from '@admin/components/menu'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Content from './content'
import Design from './design'
import React from 'react'

class Main extends React.Component {

  static propTypes = {
    blocks: PropTypes.array,
    changes: PropTypes.number,
    config: PropTypes.object,
    program_id: PropTypes.number,
    settings: PropTypes.bool,
    status: PropTypes.string,
    onPop: PropTypes.func,
    onPreview: PropTypes.func,
    onPush: PropTypes.func,
    onSave: PropTypes.func,
    onTokens: PropTypes.func,
    onUpdate: PropTypes.func
  }

  _handlePreview = this._handlePreview.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Menu { ...this._getMenu() } />
      </ModalPanel>
    )
  }

  _getMenu() {
    return {
      type: 'pointing',
      items: [
        { label: 't(Blocks)', component: <Content { ...this._getContent() } /> },
        { label: 't(Design)', component: <Design { ...this._getDesign() } /> }
      ]
    }
  }

  _getContent() {
    const { blocks, config, onPop, onPush, onUpdate } = this.props
    return {
      blocks,
      config,
      onPop,
      onPush,
      onUpdate
    }
  }

  _getDesign() {
    const { config, onPop, onPush, onUpdate } = this.props
    return {
      config,
      onPop,
      onPush,
      onUpdate
    }
  }

  _getPanel() {
    const { changes, status } = this.props
    return {
      showHeader: false,
      buttons: [
        {
          label: status === 'ready' ? 'Save' : <Icon icon="circle-o-notch fa-spin" />,
          color: 'blue',
          disabled: changes === 0,
          handler: this._handleSave
        },
        {
          label: 't(Preview)',
          color: 'blue',
          basic: true,
          handler: this._handlePreview
        }
      ]
    }
  }

  _getSettings() {
    const { config, program_id, onTokens, onUpdate } = this.props
    return {
      config: config.settings,
      program_id,
      onTokens,
      onUpdate
    }
  }

  _handlePreview() {
    this.props.onPreview()
  }

  _handleSave() {
    const { config } = this.props
    this.props.onSave(config)
  }

}

export default Main
