import Stack from '@admin/components/stack'
import Downloading from './downloading'
import PropTypes from 'prop-types'
import Browser from './browser'
import Profile from './profile'
import React from 'react'

class Canva extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  stackRef = React.createRef()

  _handleBrowser = this._handleBrowser.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleDownloading = this._handleDownloading.bind(this)

  render() {
    return <Stack { ...this._getStack()} />
  }

  componentDidMount() {
    this._handleProfile()
  }

  _getStack() {
    return {
      display_name: 'canva browser',
      ref: this.stackRef
    }
  }

  _handleDone(social_campaign) {
    this.stackRef.current.pop(1)
    this.props.onNext(social_campaign)
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleBrowser(profile) {
    const { onBack } = this.props
    this._handlePush(Browser, {
      profile,
      onBack,
      onDone: this._handleDownloading
    })
  }

  _handleDownloading({ profile, design }) {
    this._handlePush(Downloading, {
      profile,
      design,
      onNext: this._handleDone
    })
  }

  _handleProfile() {
    const { onBack } = this.props
    this._handlePush(Profile, {
      onBack,
      onDone: this._handleBrowser
    })
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Canva

