import SocialCampaignVariantToken from '@apps/campaigns/admin/tokens/social_campaign_variant'
import MJSONPreview from '@admin/components/mjson_preview'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ social_campaign }, { admin }) => {

  const slides = social_campaign.variants.map((variant, index) => (
    <MJSONPreview entity={ variant } table="campaigns_social_campaign_variants" key={`variant_${index}`} />
  ))

  const details = {
    audits: `campaigns_social_campaigns/${social_campaign.id}`,
    comments: `campaigns_social_campaigns/${social_campaign.id}`,
    header: <Carousel slides={ slides } />,
    sections: [
      {
        title: 't(Campaign Details)',
        items: [
          { label: 't(Team)', content: social_campaign.team.title },
          { label: 't(Title)', content: social_campaign.title },
          { label: 't(Type)', content: social_campaign.type.toUpperCase() },
          ...social_campaign.status === 'scheduled' ? [
            { label: 't(Post At)', content: social_campaign.post_at, format: 'datetime' }
          ] : [],
          { label: 't(Posts)', content: social_campaign.variants.map((social_campaign_variant, index) => (
            <SocialCampaignVariantToken social_campaign_variant={ social_campaign_variant } key={`variant_${index}`} />
          )) },
          ...social_campaign.status === 'posted' ? [
            { label: 't(Posted At)', content: social_campaign.posted_at, format: 'datetime' }
          ] : []
        ]
      }
    ]
  }

  if(social_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social campaign was deleted)' }
  } else if(social_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social campaign is in draft mode)' }
  } else if(social_campaign.status === 'scheduled') {
    details.alert = { color: 'teal', message: 't(This social campaign is scheduled)' }
  } else if(social_campaign.status === 'posting') {
    details.alert = { color: 'yellow', message: 't(This social campaign is in the process of being posted)' }
  } else if(social_campaign.status === 'posted') {
    details.alert = { color: 'green', message: 't(This social campaign was posted)' }
  } else if(social_campaign.status === 'failed') {
    details.alert = { color: 'red', message: 't(Unable to post campaign)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  advisor: PropTypes.object,
  social_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_campaign={ props.social_campaign } />,
  tasks: {
    items: [
      {
        label: 't(Repost Campaign)',
        svg: 'refresh_cw',
        show: props.social_campaign.status === 'failed',
        request: {
          endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/social_campaigns/${props.social_campaign.id}/repost`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully queued reposting of social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to repost social campaign)')
        }
      },
      {
        label: 't(Unschedule Campaign)',
        svg: 'clock',
        show: props.social_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/social_campaigns/${props.social_campaign.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully queued reposting of social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to repost social campaign)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
