import SourceToken from '@admin/tokens/source'
import PropTypes from 'prop-types'
import React from 'react'

const services = {
  facebook: 'Facebook',
  instagram_business: 'Instagram for Business',
  linkedin: 'LinkedIn',
  tiktok: 'TikTok',
  youtube: 'YouTube'
}

const perspectives = {
  all: 'All Perspectives',
  'first-person singular': 'First-Person Singluar',
  'first-person plural': 'First-Person Plural'
}

const languages = {
  all: 'All Languages',
  'american english': 'American English',
  'canadian english': 'Canadian English',
  french: 'French',
  german: 'German',
  italian: 'Italian'
}

const VariantVoiceToken = (variant) => {
  const source_name = variant.service
  const voice = [
    ...perspectives[variant.perspective] ? [perspectives[variant.perspective]] : [],
    ...languages[variant.language] ? [languages[variant.language]] : []
  ].join(', ')
  const name = source_name ? `${services[variant.service]} (${voice})` : voice
  return source_name ? <SourceToken source={{ source_name, name }} /> : (
    <div className="token">
      { voice }
    </div>
  )
}

VariantVoiceToken.propTypes = {
  variant: PropTypes.object
}

export default VariantVoiceToken
