import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import CardToken from '@apps/finance/admin/tokens/card'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Revert extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { programs } = this.context.admin
    const { advisor} = this.props
    const { config } = this.state
    return {
      title: 't(Revert Advisor To Pending)',
      method: 'PATCH',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/revert`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Plan)',
              name: 'plan_id', 
              type: 'dropdown',
              endpoint: '/api/admin/subscriptions/services/3/plans',
              valueKey: 'id',
              textKey: 'title', 
              required: true, 
              defaultValue: 4
            },
            {
              label: 't(Pricing Model)',
              name: 'pricing_model_id',
              type: 'dropdown',
              endpoint: '/api/admin/truevail/admin/pricing_models',
              valueKey: 'id',
              textKey: 'title',
              required: true,
              defaultValue: 1
            },
            { 
              label: 't(Payment Method)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'payment_strategy', 
                  type: 'hidden', 
                  value: 'card' 
                },
                { 
                  name: 'card_strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'previous', text: 't(Use a previous card)' },
                    { value: 'new', text: 't(Use a new card)' }
                  ], 
                  defaultValue: 'previous' 
                },
                { 
                  name: 'payment_method_id', 
                  type: 'dropdown', 
                  show: config.card_strategy === 'previous', 
                  endpoint: '/api/admin/finance/payment_methods', 
                  filter: { contact_id: { $eq: advisor.team.primary_user.provider_contact.id } }, 
                  valueKey: 'id', 
                  textKey: 'description', 
                  format: (payment_method) => (
                    <CardToken payment_method={ payment_method } />
                  ), 
                  required: true, 
                  selectFirst: true 
                },
                { 
                  name: 'payment', 
                  type: StripeCardField, 
                  show: config.card_strategy === 'new', 
                  stripeAccount: programs[0].stripe_id 
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Revert
