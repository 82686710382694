import Requirements from '@admin/components/form/attachmentfield/requirements'
import { getRatios } from '@admin/components/image_editor/ratios'
import ImageEditor from '@admin/components/image_editor'
import Attachments from '@admin/components/attachments'
import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import MediaToken from './token'
import Picker from './picker'
import React from 'react'

class MediaField extends React.Component {

  static contextTypes = {
    form: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    editor: PropTypes.string,
    max_ratio: PropTypes.number,
    min_ratio: PropTypes.number,
    multiple: PropTypes.bool,
    prompt: PropTypes.string,
    ratio: PropTypes.number,
    requirements: PropTypes.array,
    value: PropTypes.array,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    editor: 'modal',
    multiple: false
  }

  state = {
    items: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 150, { leading: true })
  _handleClick = this._handleClick.bind(this)
  _handleEdit = this._handleEdit.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { multiple, requirements } = this.props
    const { items } = this.state
    return (
      <div className="maha-mediafield">
        { multiple ?
          <List { ...this._getList() } /> :
          items.length > 0 ?
            <>
              <div className="maha-mediafield-photo">
                <Button { ...this._getRemove(0) } />
                <MediaToken { ...items[0] } />
              </div>
              <Button { ...this._getEdit(items[0]) } />
            </> : 
            null
        }
        { (items.length === 0 || multiple) &&
          <Button { ...this._getUpload() } />        
        }
        { requirements &&
          <Requirements { ...this._getRequirements() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) return this._handleFetch(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { ratio, value } = this.props
    const { items } = this.state
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
    if(ratio !== prevProps.ratio) {
      this._handleRatio()
    }
    if(!_.isEqual(items, prevState.items)) {
      this._handleChange()
    }
  }

  _getAttachments() {
    const { multiple, requirements, website } = this.props
    return {
      allow: {
        content_types: ['image'],
        types: ['files','photos'],
        dalle: true,
        truevail: true,
        unsplash: true
      },
      ...website ? {
        custom: [
          { icon: 'picture-o', service: 'media', label: 'Website Library', panel: Picker, props: this._getPicker() }
        ]
      } : {},
      multiple,
      requirements,
      onBack: this._handleCancel,
      onDone: this._handleUpdate
    }
  }

  _getDefaultCrop(asset) {
    const { width, height } = asset.metadata
    const ratio = this._getDefaultRatio(asset)
    const viewport = {
      w: (height * ratio) > width ? width : height * ratio,
      h: (height * ratio) > width ? width / ratio : height
    }
    return {
      l: Math.floor((width - viewport.w) / 2),
      t: Math.floor((height - viewport.h) / 2),
      w: Math.floor(viewport.w),
      h: Math.floor(viewport.h)
    }
  }

  _getDefaultRatio(asset) {
    const { min_ratio, max_ratio, ratio } = this.props
    if(ratio) return ratio
    const { width, height } = asset.metadata
    const original_ratio = width / height
    const allowed_ratios = getRatios(original_ratio, min_ratio, max_ratio)
    return allowed_ratios.reduce((closest, current) => {
      return Math.abs(current - original_ratio) < Math.abs(closest - original_ratio) ? current : closest
    })
  }

  _getEdit(item) {
    return {
      label: 't(Edit Photo)',
      className: 'link',
      handler: this._handleEdit.bind(this, item, 0)
    }
  }

  _getEditor(item, index) {
    const { max_ratio, min_ratio, ratio } = this.props
    const { items } = this.state
    return {
      asset: item.asset,
      max_ratio,
      min_ratio,
      transforms: item.transforms,
      ratio,
      onBack: this._handleBack,
      onDone: (transforms) => {
        this.setState({
          items: items.map((media, i) => ({
            ...media,
            ...i === index ? { transforms } : {}
          }))
        })
        this._handleBack()
      }
    }
  }

  _getList() {
    const { multiple } = this.props
    const { items } = this.state
    return {
      actions: (item, index) => [
        {
          svg: 'pencil',
          handler: this._handleEdit.bind(this, item, index)
        },
        {
          svg: 'x',
          confirm: 't(Are you sure you want to remove this photo?)',
          handler: this._handleRemove.bind(this, index)  
        }
      ],
      className: 'bordered',
      maxHeight: 'calc((62px + 0.8rem) * 5)',
      format: (media, index) => (
        <div className="maha-mediafield-preview">
          <div className="maha-mediafield-preview-image">
            <MediaToken { ...media } />
          </div>
          <div className="maha-mediafield-preview-details" />
        </div>  
      ),
      items,
      empty: <Token text="t(You have not yet added any photos)" />,
      onMove: multiple ? this._handleMove : null
    }
  }

  _getNormalized(items) {
    return items.map(item => ({
      ...item,
      transforms: this._getNormalizedTransforms(item.asset, item.transforms)
    }))
  }

  _getNormalizedTransforms(asset, transforms) {
    if(!asset) return null
    const { adjustments, crop, rotation, zoom } = transforms
    const normalized = {
      ...adjustments && Object.keys(adjustments).length > 0 ? { adjustments } : {},
      ...crop ? { crop } : {},
      ...rotation && (rotation.x != 0 || rotation.y != 0 || rotation.z != 0) ? { rotation } : {},
      ...zoom ? { zoom } : {}
    }
    return Object.keys(normalized).length > 0 ? normalized : null
  }

  _getPicker() {
    const { website } = this.props
    return {
      website,
      onDone: this._handleUpdate
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: 'maha-mediafield-remove',
      confirm: 't(Are you sure you want to remove this photo?)',
      handler: this._handleRemove.bind(this, index)  
    }
  }

  _getRequirements() {
    const { requirements } = this.props
    return { requirements }
  }

  _getUpload() {
    const { multiple, prompt } = this.props
    return {
      label: prompt || (multiple ? '+ t(add photo(s))' : 't(add photo)'),
      ...multiple ? {
        className: 'link'
      } : {
        fluid: false
      },
      handler: this._handleClick
    }
  }

  _handleBack() {
    const { editor } = this.props
    if(editor === 'inline') return this.context.form.pop()
    this.context.modal.close()
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleClick() {
    this.context.form.push(Attachments, this._getAttachments.bind(this))
  }

  _handleChange() {
    const { multiple } = this.props
    const { items } = this.state
    const normalized = this._getNormalized(items)
    const value = (multiple ? normalized : normalized[0]) || null
    this.props.onChange(value)
  }

  _handleEdit(item, index) {
    const { form, modal } = this.context
    const { editor } = this.props
    if(editor === 'inline') return form.push(<ImageEditor { ...this._getEditor(item, index) } />)
    modal.open(<ImageEditor { ...this._getEditor(item, index) } />)
  }

  _handleFetch(items) {
    items = _.castArray(items)
    this.context.network.request({
      endpoint: '/api/admin/assets',
      method: 'GET',
      filter: {
        id: { $in: items.map(item => item.asset_id) }
      },
      onFailure: () => {},
      onSuccess: ({ data }) => {
        this.setState({
          items: items.map(item => ({
            asset_id: item.asset_id,
            asset: data.find(asset => asset.id === item.asset_id),
            transforms: item.transforms
          }))
        }, this.props.onReady)
      }
    })
  }

  _handleMove(from, to) {
    const { items } = this.state
    this.setState({
      items: (from < to) ? [
        ...items.slice(0, from),
        ...items.slice(from + 1, to + 1),
        items[from],
        ...items.slice(to + 1)
      ] : [
        ...items.slice(0, to),
        items[from],
        ...items.slice(to, from),
        ...items.slice(from + 1)
      ]
    })
  }

  _handleRatio() {
    const { items } = this.state
    if(!items) return
    this.setState({
      items: items.map(item => ({
        ...item,
        transforms: {
          ...item.transforms,
          crop: this._getDefaultCrop(item.asset),
          rotation: { x: 0, y: 0, z: 0 }
        }
      }))
    })
  }

  _handleRemove(index) {
    const { items } = this.state
    this.setState({
      items: items.filter((item, i) => {
        return i !== index
      })
    })
  }

  _handleSet(items) {
    this.setState({
      items: items ? _.castArray(items).map(item => ({
        transforms: {},
        ...item
      })) : []
    })
  }

  _handleUpdate(assets) {
    const { items } = this.state
    this.setState({
      items: [
        ...items,
        ...assets.map(asset => ({
          asset_id: asset.id,
          asset: {
            id: asset.id,
            path: asset.path,
            metadata: asset.metadata
          },
          transforms: {
            adjustments: {},
            crop: this._getDefaultCrop(asset),
            rotation: { x: 0, y: 0, z: 0 }
          }
        }))
      ]
    })
    this.context.form.pop()
  }

}

export default MediaField
