import SmsCampaignToken from '@apps/campaigns/admin/tokens/campaign/sms'
import ProgramForm from '@apps/crm/admin/components/programform'
import Panel from '@admin/components/panel'
import New from '../new_outbound'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Outbound SMS Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } },
  collection: {
    endpoint: '/api/admin/campaigns/sms/outbound',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (sms_campaign) => (
        <SmsCampaignToken sms_campaign={ sms_campaign } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    criteria: {
      fields: [
        { label: 't(SMS Campaign)', fields: [
          { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Sessions)', key: 'sessions_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'message_square',
      title: 't(No Outbound SMS Campaigns)',
      text: 't(You have not yet created any outbound SMS campaigns)',
      buttons: [
        { 
          label: 't(Create SMS Campaign)',
          access: { rights: { $oneOf: ['campaigns:manage_sms_campaigns'] } },
          modal: <ProgramForm requires={['phone_number']} form={ New } /> 
        }
      ]
    },
    entity: 'sms campaign',
    onClick: (record) => context.router.push(`/campaigns/sms/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create SMS Campaign)',
    access: { rights: { $oneOf: ['campaigns:manage_sms_campaigns'] } },    
    modal: <ProgramForm fields={ props.fields } requires={['phone_number']} form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
