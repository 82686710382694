import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    return {
      title: 't(Create Variants)',
      action: `/api/admin/truevail/agency/social_campaigns/${social_campaign.id}/variants`,
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Source)',
              name: 'source', 
              type: 'dropdown', 
              entity: 'source variant',
              valueKey: 'value',
              textKey: 'service',
              options: social_campaign.variants.reduce((options, variant) => [
                ...options,
                ...!options.find(option => option.language === variant.language && option.perspective === variant.perspective) ? [
                  {
                    value: { 
                      language: variant.language,
                      perspective: variant.perspective
                    },
                    language: variant.language,
                    perspective: variant.perspective
                  }
                ] : []
              ],[]),
              format: VariantVoiceToken,
              selectFirst: true,
              required: true
            },
            { 
              label: 't(Voices)',
              name: 'voices',
              type: 'checkboxgroup',
              options: ['all','first-person plural','first-person singular'].reduce((perspectives, perspective) => [
                ...perspectives,
                ...['all','american english','canadian english','french','german','italian'].reduce((languages, language) => [
                  ...languages,
                  ...!social_campaign.variants.find(variant => variant.perspective === perspective && variant.language === language) ? [
                    {
                      value: { perspective, language },
                      perspective,
                      language
                    }
                  ] : []
                ], [])
              ], []),
              deselectable: true,
              groupKey: 'perspective',
              valueKey: 'value',
              textKey: 'service',
              format: VariantVoiceToken,
              groupFormat: ({ value }) => (
                <VariantVoiceToken perspective={ value} />
              ),
              required: true
            }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { features } = this.context.admin
    const { type } = this.props.social_campaign
    return [
      ..._.includes(['photo','video','story','reel'], type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      ..._.includes(['photo','video','link','story','reel'], type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ...features.linkedin === true && _.includes(['photo','video','link'], type) ? [
        { name: 'LinkedIn', source_name: 'linkedin' }
      ] : [],
      ...features.youtube === true && _.includes(['reel','video'], type) ? [
        { name: 'Youtube', source_name: 'youtube' }
      ] : [],
      ...features.tiktok === true && _.includes(['reel'], type) ? [
        { name: 'TikTok', source_name: 'tiktok' }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default New
