
import { createLogger } from '@core/utils/console'
import LocalStorage from './local_storage'
import localforage from 'localforage'
import PropTypes from 'prop-types'
import React from 'react'

export { LocalStorageContext, useLocalStorageContext } from './local_storage'

const logAction = createLogger('pink')

const LocalStorageContainer = (props) => {

  const name = props.name || 'local'
  const storeName = props.storeName || 'cache'

  const client = localforage.createInstance({
    name,
    storeName
  })

  const get = ({ key, onSuccess, onFailure }) => {

    client.getItem(key, (err, value) => {

      if(err) {
        logAction('local_storage/GET_FAILURE', { key, err })
        return onFailure(err)
      }

      logAction('local_storage/GET_SUCCESS', { key, value })
      onSuccess(value)

    })

  }

  const remove = ({ key, onSuccess, onFailure }) => {

    client.removeItem(key, (err, value) => {

      if(err) {
        logAction('local_storage/REMOVE_FAILURE', { key, err })
        return onFailure(err)
      }

      logAction('local_storage/REMOVE_SUCCESS', { key })
      onSuccess(value)

    })

  }

  const set = ({ key, value, onSuccess, onFailure }) => {

    client.setItem(key, value, (err, value) => {

      if(err) {
        logAction('local_storage/SET_FAILURE', { key, value, err })
        return onFailure(err)
      }

      logAction('local_storage/SET_SUCCESS', { key, value })
      onSuccess(value)

    })

  }

  const mergedProps = {
    ...props,
    get,
    remove,
    set
  }

  return <LocalStorage { ...mergedProps }/>

}

LocalStorageContainer.propTypes = {
  name: PropTypes.string,
  storeName: PropTypes.string
}

export default LocalStorageContainer