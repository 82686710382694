import LibraryAdd from '@apps/truevail/admin/components/library_add'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import MJSONPreview from '@admin/components/mjson_preview'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ design }) => {

  const remix = {
    label: 't(remix)',
    className: 'link',
    link: design.remix_url
  }

  const view = {
    label: 't(view)',
    className: 'link',
    link: design.view_url
  }

  const details = {
    audits: `truevail_designs/${design.id}`,
    comments: `truevail_designs/${design.id}`,
    header: <MJSONPreview entity={ design} table="truevail_designs" />,


    sections: [
      {
        title: 't(Design Details)',
        items: [
          { label: 't(Title)', content: design.title },
          { label: 't(Links)', content: <><Button { ...remix } /> | <Button { ...view } /></> }
        ]
      },
      {
        title: 't(Library Details)',
        items: [
          { label: 't(Folders)', content: design.folders.length > 0 ? design.folders.map(folder => folder.title).join(', ') : null },
          { label: 't(Tags)', content: design.tags.length > 0 ? <TagsToken tags={ design.tags } /> : null }
        ]
      }
    ]
  }

  if(design.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This design was deleted)' }
  } else if(design.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This design is not published to the library)' }
  } else if(design.status === 'published') {
    details.alert = { color: 'green', message: 't(This design is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  design: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview design={ props.design } />,
  tasks: {
    items: [
      {
        label: 't(Edit Design)',
        svg: 'square_pen',
        modal: <Edit design={ props.design } />
      },
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: props.design.status === 'draft',
        modal: <LibraryAdd scope="admin" entity="designs" record={ props.design } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: props.design.status === 'published',
        confirm: 't(Are you sure you want to unpublish this design?)',
        request: {
          endpoint: `/api/admin/truevail/admin/designs/${props.design.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished this design)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish this design)')
        }
      },
      {
        label: 't(Delete Design)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this design?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/designs/${props.design.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted design)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete design)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
