import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'
import _ from 'lodash'

const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

class DatesField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    dates: [],
    offset: 0
  }

  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { dates } = this.state
    const current = this._getCurrent()
    return (
      <div className="truevail-datesfield">
        <table>
          <thead>
            <tr>
              <td><Button { ...this._getPrev() } /></td>
              <td colSpan="5">{ current.format('MMMM YYYY') }</td>
              <td><Button { ...this._getNext() } /></td>
            </tr>
            <tr>
              { Array(7).fill(0).map((a,i) => (
                <td key={`day_${i}`}>
                  { days[i] }
                </td>
              )) }
            </tr>
          </thead>
          <tbody>
            { Array(6).fill(0).map((a,i) => (
              <tr key={`row_${i}`}>
                { Array(7).fill(0).map((b,j) => (
                  <td key={`col_${i}_${j}`} { ...this._getDay((7 * i) + j) }>
                    <span>{ this._getNumber((7 * i) + j) }</span>
                  </td>
                )) }
              </tr>
            )) }
          </tbody>
        </table>
        <div className="truevail-datesfield-summary">
          { pluralize('selected day', dates.length, true )}
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { dates } = this.state
    if(!_.isEqual(dates, prevState.dates)) {
      this._handleChange()
    }
  }

  _getClass(index) {
    const current = this._getCurrent(index)
    const date = this._getDate(index)
    const today = moment()
    const { dates } = this.state 
    const classes = []
    const valid =  date.startOf('day').diff(today.startOf('day'), 'day') >= 0
    classes.push(valid ? 'valid' : 'invalid')
    if(date.startOf('day').diff(today.startOf('day'), 'day') === 0) classes.push('today')
    if(current.diff(date.clone().startOf('month'), 'month') != 0) classes.push('noncurrent')
    if(_.includes(dates, date.format('YYYY-MM-DD'))) classes.push('selected')
    return classes.join(' ')
  }

  _getCurrent() {
    const { offset } = this.state
    return moment().startOf('month').add(offset, 'months')
  }

  _getDay(index) {
    return {
      className: this._getClass(index),
      onClick: this._handleUpdate.bind(this, index)
    }
  }

  _getDate(index) {
    const current = this._getCurrent()
    const offset = index - current.weekday()
    return current.clone().add(offset, 'days')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getNumber(index) {
    const date = this._getDate(index)
    return date.format('D')
  }

  _getPrev() {
    return {
      svg: 'chevron_left',
      className: 'truevail-datesfield-action',
      handler: this._handleOffset.bind(this, -1)
    }
  }

  _getNext() {
    return {
      svg: 'chevron_right',
      className: 'truevail-datesfield-action',
      handler: this._handleOffset.bind(this, 1)
    }
  }

  _handleChange() {
    const { dates } = this.state
    this.props.onChange(dates)
  }

  _handleOffset(add) {
    const { offset } = this.state
    this.setState({
      offset: add === 0 ? 0 : offset + add
    })
  }

  _handleSet(dates) {
    this.setState(dates || [])
  }

  _handleUpdate(index) {
    const date = this._getDate(index)
    const today = moment()
    const valid =  date.startOf('day').diff(today.startOf('day'), 'day') >= 0
    if(!valid) return
    this.setState({
      dates: _.xor(this.state.dates, [date.format('YYYY-MM-DD')]).sort((a,b) => a > b ? 1 : -1 )
    })
  }

  _handleValidate() {
    const { dates } = this.state
    const { required, onValid } = this.props
    if(required && dates.length === 0) {
      return onValid(null, ['You must choose at least 1 date'])
    }
    onValid(dates)
  }

}

export default DatesField
