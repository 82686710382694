import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Profile from './profile'
import React from 'react'

class Brand extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Brand)',
      cancelSvg: 'chevron_left',
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      showSteps: false,
      onCancel: this._handleCancel,
      onNext: this._handleNext,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { configuration } = this.props
    return configuration
  }

  _getSteps(formdata) {
    return [
      { label: 't(Profile)', component: Profile },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleNext(data) {
    this.props.onNext(data)
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Brand
