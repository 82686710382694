import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const enrollment = {
  false: 't(Contacts can be enrolled in this workflow multiple times)',
  true: 't(Contacts can only be enrolled in this workflow once)'
}

const Overview = ({ workflow }, { admin }) => {

  const program = {
    label: workflow.program.title,
    className: 'link',
    route: `/team/programs/${workflow.program.id}`
  }

  const details = {
    audits: `automation_workflows/${workflow.id}`,
    comments: `automation_workflows/${workflow.id}`,
    sections: [{
      title: 't(Workflow Details)',
      items: [
        { label: 't(Title)', content: workflow.title, show: workflow.trigger.code === 'automation:enrollment_created' },
        { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
        { label: 't(Trigger)', content: <T text={ workflow.trigger.text } /> }
      ]
    }]
  }

  if(workflow.trigger.code === 'automation:enrollment_created') {
    details.sections[0].items.push({ label: 't(Purpose)', content: workflow.purpose })
  }

  if(workflow.event) {
    const event = {
      label: workflow.event.title,
      className: 'link',
      route: `/events/events/${workflow.event.id}`
    }
    details.sections[0].items.push({ label: 't(Event)', content: <Button { ...event } /> })
  }

  if(workflow.email) {
    const email = {
      label: workflow.email.title,
      className: 'link',
      route: `/automation/emails/${workflow.email.id}`
    }
    details.sections[0].items.push({ label: 't(Email)', content: <Button { ...email } /> })
  }

  if(workflow.email_campaign) {
    const email_campaign = {
      label: workflow.email_campaign.title,
      className: 'link',
      route: `/campaigns/email/${workflow.email_campaign.id}`
    }
    details.sections[0].items.push({ label: 't(Email Campaign)', content: <Button { ...email_campaign } /> })
  }

  if(workflow.field) {
    const field = {
      label: workflow.field.name.value,
      className: 'link',
      route: `/team/programs/${workflow.program.id}/properties`
    }
    details.sections[0].items.push({ label: 't(Property)', content: <Button { ...field } /> })
  }

  if(workflow.form) {
    const form = {
      label: workflow.form.title,
      className: 'link',
      route: `/forms/forms/${workflow.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(workflow.list) {
    const list = {
      label: workflow.list.title,
      className: 'link',
      route: `/crm/lists/${workflow.list.id}`
    }
    details.sections[0].items.push({ label: 't(List)', content: <Button { ...list } /> })
  }

  if(workflow.tag) {
    const tag = {
      label: workflow.tag.text,
      className: 'link',
      route: `/team/tags/${workflow.tag.id}`
    }
    details.sections[0].items.push({ label: 't(Tag)', content: <Button { ...tag } /> })
  }

  if(workflow.pipeline) {
    const pipeline = {
      label: workflow.pipeline.title,
      className: 'link',
      route: `/sales/pipelines/${workflow.pipeline.id}`
    }
    details.sections[0].items.push({ label: 't(Pipeline)', content: <Button { ...pipeline } /> })
  }

  if(workflow.from_stage) {
    const from_stage = {
      label: workflow.from_stage.title,
      className: 'link',
      route: `/sales/pipelines/${workflow.pipeline.id}/stages`
    }
    details.sections[0].items.push({ label: 't(From Stage)', content: <Button { ...from_stage } /> })
  }

  if(workflow.to_stage) {
    const to_stage = {
      label: workflow.to_stage.title,
      className: 'link',
      route: `/sales/pipelines/${workflow.pipeline.id}/stages`
    }
    details.sections[0].items.push({ label: 't(To Stage)', content: <Button { ...to_stage } /> })
  }

  if(workflow.stage) {
    const stage = {
      label: workflow.stage.title,
      className: 'link',
      route: `/sales/pipelines/${workflow.pipeline.id}/stages`
    }
    details.sections[0].items.push({ label: 't(Stage)', content: <Button { ...stage } /> })
  }

  if(workflow.truevail_entity_type) {
    details.sections[0].items.push({ label: 't(Truevail Entity Type)', content: workflow.truevail_entity_type })
  }

  if(workflow.team_status) {
    details.sections[0].items.push({ label: 't(Status)', content: workflow.team_status })
  }

  if(workflow.from_team_status) {
    details.sections[0].items.push({ label: 't(From Status)', content: workflow.from_team_status })
  }

  if(workflow.to_team_status) {
    details.sections[0].items.push({ label: 't(To Status)', content: workflow.to_team_status })
  }

  if(workflow.duration) {
    const unit = workflow.duration % 168 === 0 ? 'week' : workflow.duration % 24 === 0 ? 'day' : 'hour'
    const value = unit === 'week' ? workflow.duration / 168 : unit === 'day' ? workflow.duration / 24 : workflow.duration
    details.sections[0].items.push({ label: 't(Duration)', content: pluralize(unit, value, true) })
  }

  if(workflow.service) {
    const service = {
      label: workflow.service.title,
      className: 'link',
      route: `/subscriptions/services/${workflow.service.id}`
    }
    details.sections[0].items.push({ label: 't(Service)', content: <Button { ...service } /> })
  }

  if(workflow.store) {
    const store = {
      label: workflow.store.title,
      className: 'link',
      route: `/stores/stores/${workflow.store.id}`
    }
    details.sections[0].items.push({ label: 't(Store)', content: <Button { ...store } /> })
  }

  if(workflow.signup_page) {
    const signup_page = {
      label: workflow.signup_page.title,
      className: 'link',
      route: `/truevail/signup_pages/${workflow.signup_page.id}`
    }
    details.sections[0].items.push({ label: 't(Signup Page)', content: <Button { ...signup_page } /> })
  }

  if(workflow.webinar) {
    const webinar = {
      label: workflow.webinar.title,
      className: 'link',
      route: `/truevail/admin/webinars/${workflow.webinar.id}`
    }
    details.sections[0].items.push({ label: 't(Webinar)', content: <Button { ...webinar } /> })
  }

  details.sections[0].items.push({ label: 't(Enrollment)', content: <T text={ enrollment[workflow.is_unique] } /> })

  if(workflow.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This workflow was deleted)' }
  } else if(workflow.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This workflow is in draft mode)' }
  } else if(workflow.status === 'active') {
    details.alert = { color: 'green', message: 't(This workflow is active)' }
  } else if(workflow.status === 'inactive') {
    details.alert = { color: 'red', message: 't(This workflow is inactive)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  workflow: PropTypes.object
}

const getTasks = ({ workflow }, context) => {
  const items = []
  if(!workflow.deleted_at) {
    items.push({ 
      label: 't(Edit Workflow)', 
      svg: 'square_pen',
      access: {
        programs: { $canEdit: workflow.program.id },
        rights: { $oneOf: ['automation:manage_workflows'] }
      },
      modal: <Edit workflow={ workflow } /> 
    })
    items.push({ 
      label: 't(Duplicate Workflow)', 
      svg: 'copy',
      access: {
        programs: { $canEdit: workflow.program.id },
        rights: { $oneOf: ['automation:manage_workflows'] }
      },
      modal: <Clone workflow={ workflow } /> 
    })
    items.push({
      label: 't(Activate Workflow)',
      svg: 'check',
      access: {
        programs: { $canEdit: workflow.program.id },
        rights: { $oneOf: ['automation:manage_workflows'] }
      },
      show: workflow.status !== 'active',
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/automation/workflows/${workflow.id}/activate`,
        body: { is_active: true },
        success: () => {},
        failure: () => {}
      }
    })
    items.push({
      label: 't(Dectivate Workflow)',
      svg: 'x',
      access: {
        programs: { $canEdit: workflow.program.id },
        rights: { $oneOf: ['automation:manage_workflows'] }
      },
      show: workflow.status === 'active',
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/automation/workflows/${workflow.id}/activate`,
        body: { is_active: false },
        success: () => {},
        failure: () => {}
      }
    })
    items.push({
      label: 't(Delete Workflow)',
      svg: 'trash',
      deletable: true,
      access: {
        programs: { $canEdit: workflow.program.id },
        rights: { $oneOf: ['automation:manage_workflows'] }
      },
      confirm: 't(Are you sure you want to delete this workflow? You will also delete all of the associated emails, and performance data)',
      request: {
        endpoint: `/api/admin/automation/workflows/${workflow.id}`,
        method: 'DELETE',
        onFailure: () => context.flash.set('error', 't(Unable to delete this workflow)'),
        onSuccess: () => {
          context.flash.set('success', 't(The workflow was successfully deleted)')
          context.router.goBack()
        }
      }
    })
  }
  return { items }
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview workflow={ props.workflow } />,
  tasks: getTasks(props, context)
})

export default Panel(null, mapPropsToPanel)
