import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    host: PropTypes.object
  }

  static propTypes = {
    job_id: PropTypes.string,
    canva_url: PropTypes.string,
    onDone: PropTypes.func
  }
  
  _handleOpen = this._handleOpen.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Message { ...this._getMessage() } />
      </ModalPanel>
    )
  }

  _getMessage() {
    return  {
      poster: '/imagecache/assets/7c3f407712674a3b6b90e3434754cafb/image.jpeg',
      title: 't(Edit in Canva)',
      text: 't(You can now edit your assets in Canva. Make sure you click the "Return to Truevail" button when youre done so we can be notified to download your new assets)',
      buttons: [
        { 
          className: 'maha-canva-editor-button',
          children: (
            <>
              <span />
              <T text="Open in Canva" />
            </>
          ),
          handler: this._handleOpen
        }
      ]
    }
  }

  _getPanel() {
    return {
      title: 't(Edit in Canva)'
    }
  }

  _handleOpen() {
    const { job_id, canva_url } = this.props
    this.context.host.window.open(`${canva_url}/edit?correlation_state=${job_id}`)
  }

}

export default Edit

