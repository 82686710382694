import ContactToken from '@apps/crm/admin/tokens/contact'
import ModalPanel from '@admin/components/modal_panel'
import Infinite from '@admin/components/infinite'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Results from './results'
import React from 'react'

class Picker extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    instructions: PropTypes.any,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    strategy: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    contact_ids: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="tofield-picker">
          <div className="tofield-picker-filter">
            <Search { ...this._getContacts() } />
          </div>
          <div className="tofield-picker-main">
            <Infinite { ...this._getInfinite() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(!defaultValue) return
    const { contact_ids } = defaultValue
    this.setState({ contact_ids })
  }

  _getContacts() {
    const { channel, endpoint, program_id, purpose } = this.props
    const { contact_ids } = this.state
    return {
      endpoint,
      defaultQuery: {
        program_id,
        purpose,
        channel
      },
      excludeIds: contact_ids,
      prompt: 't(Find a contact)',
      valueKey: 'id',
      textKey: 'display_name',
      format: (recipient) => <ContactToken { ...recipient.contact } />,
      onChange: this._handleChoose
    }
  }

  _getInfinite() {
    const { channel, endpoint, program_id, purpose } = this.props
    const { contact_ids } = this.state
    return {
      empty: {
        svg: 'user_round',
        title: 't(Add Contacts)',
        text: 't(Add contacts to your list)'
      },
      endpoint,
      query: {
        strategy: 'contacts',
        program_id,
        purpose,
        channel,
        contact_ids
      },
      ...contact_ids.length === 0 ? {
        filter: null
      } : {},
      footer: ({ all, total }) => `Matching ${total} of ${pluralize('contact', all, true)}`,
      layout: Results,
      props: {
        onRemove: this._handleRemove
      }
    }
  }

  _getPanel() {
    const { instructions } = this.props
    const { contact_ids } = this.state
    return {
      title: 't(Choose Contacts)',
      instructions,
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: contact_ids ? [
        { label: 't(Done)', handler: this._handleDone }
      ] : null
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChoose(contact_id) {
    this.setState({
      contact_ids: [
        ...this.state.contact_ids || [],
        contact_id
      ]
    })
  }

  _handleDone() {
    const { contact_ids } = this.state
    this.props.onDone({ contact_ids })
    this.context.form.pop()
  }

  _handleRemove(id) {
    this.setState({
      contact_ids: _.without(this.state.contact_ids, id)
    })
  }

}

export default Picker
