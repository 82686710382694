import ChannelTypeToken from '@apps/truevail/admin/tokens/channel_type'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object,
    channel: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agency, channel } = this.props
    return {
      title: 't(Edit Channel)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/channels/${channel.id}/edit`,
      i: `/api/admin/truevail/admin/agencies/${agency.id}/channels/${channel.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea', required: true },
            { label: 't(Type)', name: 'type', type: 'radiogroup', options: ['public','private'], required: true, defaultValue: 'public', format: ChannelTypeToken }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(post) {
    this.context.modal.close()
  }

}

export default Edit
