import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import Feed from '@admin/components/feed'
import AppToken from '@admin/tokens/app'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Activities)',
  collection: {
    endpoint: '/api/admin/truevail/agency/activities',
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 'User', key: 'user_id', type: 'select', multiple: true, endpoint: '/api/admin/team/users', valueKey: 'id', textKey: 'full_name', sort: { key: 'last_name', order: 'asc' }, format: UserToken },
          { name: 'App', key: 'app_id', type: 'select', multiple: true, endpoint: '/api/admin/team/apps', valueKey: 'id', textKey: 'title', sort: { key: 'code', order: 'asc' }, format: AppToken },
          { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] }
        ] }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'activity',
      title: 't(No Activities)',
      text: 't(We have not yet recorded any activities in your content library)'
    },
    entity: 'activity',
    layout: (props) => <Feed { ...props } onClick={ (activity) => context.router.push(activity.url) } />
  }
})

export default Panel(null, mapPropsToPanel)
