import Stack from '@admin/components/stack'
import Downloading from './downloading'
import Uploading from './uploading'
import PropTypes from 'prop-types'
import Complete from './complete'
import Profile from './profile'
import Edit from './edit'
import React from 'react'

class CanvaEditor extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    canva_url: PropTypes.string,
    job_id: PropTypes.string,
    photos: PropTypes.array,
    profiles: PropTypes.array,
    title: PropTypes.string,
    onDone: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    canva_url: null,
    job_id: null
  }

  stackRef = React.createRef()

  _handleComplete = this._handleComplete.bind(this)
  _handleCreateDesign = this._handleCreateDesign.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleDownloading = this._handleDownloading.bind(this)
  _handleEdit = this._handleEdit.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleProfile = this._handleProfile.bind(this)
  _handleStatus = this._handleStatus.bind(this)

  render() {
    return (
      <div className="maha-canva-editor">
        <Stack { ...this._getStack()} />
      </div>
    )
  }

  componentDidMount() {
    this._handleProfile()
  }

  _getStack() {
    return {
      display_name: 'canva_editor',
      ref: this.stackRef
    }
  }

  _handleComplete(assets) {
    this._handleLeave()
    this._handlePush(Complete, {
      onDone: this._handleDone.bind(this, assets)
    })
  }

  _handleCreateDesign(profile) {
    const { photos, title } = this.props
    this._handlePush(Uploading)
    this.context.network.request({
      endpoint: `/api/admin/profiles/${profile.id}/canva`,
      method: 'POST',
      body: {
        title,
        photos: photos.map(({ photo }) => ({
          path: photo.asset.path,
          transforms: photo.transforms
        }))
      },
      onSuccess: ({ data }) => {
        this._handleEdit({
          canva_url: data.canva_url,
          job_id: data.job_id
        }, false)
      }
    })
  }

  _handleDone(assets) {
    this.props.onDone(assets)
  }

  _handleDownloading() {
    this._handlePush(Downloading) 
  }

  _handleEdit({ canva_url, job_id }) {
    this.setState({
      canva_url,
      job_id
    }, () => {
      this.props.onUpdate({ canva_url, job_id })
      this._handleJoin()
      this._handlePush(Edit, {
        canva_url,
        job_id,
        onDone: this._handleDownloading
      })  
    })
  }

  _handleJoin() {
    const { admin } = this.context
    const { job_id } = this.state
    const { user } = admin
    this.context.network.subscribe({ 
      channel: `/users/${user.id}/admin/canva/${job_id}`,
      action: 'status',
      handler: this._handleStatus
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { job_id } = this.state
    const { user } = admin
    this.context.network.unsubscribe({ 
      channel: `/users/${user.id}/admin/canva/${job_id}`,
      action: 'status',
      handler: this._handleStatus
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleProfile() {
    this._handlePush(Profile, {
      onDone: this._handleCreateDesign
    })
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleStatus(data) {
    const { status } = data
    if(status === 'downloading') this._handleDownloading()
    if(status === 'downloaded') this._handleComplete(data.assets)
  }

}

export default CanvaEditor

