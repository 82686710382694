import { CSSTransition } from 'react-transition-group'
import Infinite from '@admin/components/infinite'
import Button from '@admin/components/button'
import TableLayout from './layouts/table'
import CardLayout from './layouts/card'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Results from './results'
import Filter from './filter'
import Header from './header'
import Batch from './batch'
import React from 'react'
import qs from 'qs'

class Collection extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    autoRefresh: PropTypes.number,
    batch: PropTypes.object,
    buttons: PropTypes.any,
    cacheKey: PropTypes.string,
    card: PropTypes.func,
    chart: PropTypes.object,
    collectionActions: PropTypes.array,
    context: PropTypes.object,
    criteria: PropTypes.object,
    data: PropTypes.array,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    defaultSort: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    empty: PropTypes.object,
    export: PropTypes.array,
    failure: PropTypes.any,
    filter: PropTypes.object,
    filtering: PropTypes.bool,
    filters: PropTypes.array,
    footer: PropTypes.bool,
    handler: PropTypes.func,
    layout: PropTypes.func,
    loading: PropTypes.any,
    notFound: PropTypes.object,
    open: PropTypes.bool,
    panel: PropTypes.any,
    q: PropTypes.string,
    query: PropTypes.object,
    records: PropTypes.array,
    recordTasks: PropTypes.func,
    route: PropTypes.object,
    rowClass: PropTypes.func,
    search: PropTypes.bool,
    selected: PropTypes.object,
    selectable: PropTypes.bool,
    selectValue: PropTypes.string,
    sort: PropTypes.object,
    sortable: PropTypes.bool,
    table: PropTypes.array,
    tasks: PropTypes.object,
    token: PropTypes.string,
    onAddPanel: PropTypes.func,
    onClearPanel: PropTypes.func,
    onClick: PropTypes.func,
    onFetch: PropTypes.func,
    onRemovePanel: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSetFilter: PropTypes.func,
    onChooseFilter: PropTypes.func,
    onSetParams: PropTypes.func,
    onSetQuery: PropTypes.func,
    onSetSelected: PropTypes.func,
    onSetRecords: PropTypes.func,
    onSort: PropTypes.func,
    onToggleFilter: PropTypes.func
  }

  static defaultProps = {
    cacheKey: null,
    defaultFilter: {},
    entity: 'record',
    footer: true,
    sortable: true,
    search: true,
    selectable: false,
    selectValue: 'id'
  }

  infinite = null
  
  state = {
    cacheKey: _.random(100000, 999999).toString(36),
    layout: null
  }

  _handleDeselect = this._handleDeselect.bind(this)
  _handleLoadLayout = this._handleLoadLayout.bind(this)
  _handleLayout = this._handleLayout.bind(this)
  _handleRefresh = this._handleRefresh.bind(this)

  render() {
    const { layout } = this.state
    if(!layout) return null
    const { batch, buttons, criteria, endpoint, records, selected } = this.props
    return (
      <div className={ this._getClass() }>
        <Header { ...this._getHeader() } />
        <div className="maha-collection-main">
          <div className="maha-collection-body">
            { records && <Results { ...this._getResults() } /> }
            { endpoint && <Infinite { ...this._getInfinite() } /> }
          </div>
          <div className="maha-collection-sidebar">
            { criteria && <Filter { ...this._getFilter() } /> }
          </div>
        </div>
        { (buttons || batch) &&
          <CSSTransition in={ selected.total > 0 } classNames="expanded" timeout={ 100 } mountOnEnter={ true } unmountOnExit={ true }>
            <div className="maha-collection-batch">
              <div className="maha-collection-batch-buttons">
                <Button { ...this._getBatch(selected.total) } />
              </div>
              <Button { ...this._getDeselectAll()} />
            </div>
          </CSSTransition>
        }
      </div>
    )
  }

  componentDidMount() {
    const { data, onSetRecords } = this.props
    if(data) onSetRecords(data)
    this._handleLoadLayout()
    this._handleParseUrl()
  }

  componentDidUpdate(prevProps, prevState) {
    const { cacheKey, filter, sort } = this.props
    const { layout } = this.state
    if(cacheKey !== prevProps.cacheKey) {
      this._handleRefresh()
    }
    if(!_.isEqual(filter, prevProps.filter)) {
      this._handleChangeUrl()
    }
    if(!_.isEqual(sort, prevProps.sort)) {
      this._handleChangeUrl()
    }
    if(layout !== prevState.layout) {
      this._handleSaveLayout()
    }
  }

  _getBatch(total) {
    const { batch, buttons, selected } = this.props
    const tasks =  [
      ...buttons ? buttons(selected, this._handleDeselect) : [],
      ...batch ? batch.tasks.map(task => ({
        label: task.label,
        svg: task.svg,
        confirm: task.confirm,
        deletable: task.deletable,
        modal: <Batch batch_action={ task.action } batch_type={ batch.batch_type } batch_config={ task.config } title={ task.label } filter={ selected.filter } component={ task.component } request={ task.request } onDone={ this._handleDeselect } />
      })) : []
    ]
    tasks.width = 300
    return {
      label: `With ${total} Selected`,
      basic: true,
      color: 'blue',
      tasks
    }
  }

  _getClass() {
    const { filtering } = this.props
    const classes = ['maha-collection']
    if(filtering) classes.push('filtering')
    return classes.join(' ')
  }

  _getCode() {
    const { admin } = this.context
    return window.location.pathname.substr(1).replace(/\//g,'-').replace(admin.team.subdomain, 'admin')
  }

  _getDeselectAll() {
    return {
      svg: 'x',
      className: 'maha-collection-batch-close',
      handler: this._handleDeselect
    }
  }

  _getFilter() {
    const { criteria, entity, filter, onChooseFilter, onSetFilter, onToggleFilter } = this.props
    return {
      code: this._getCode(),
      entity,
      defaultValue: filter && Object.keys(filter).length > 0 ? filter : null,
      fields: criteria.fields,
      system: criteria.system,
      onChoose: document.body.clientWidth > 900 ? onSetFilter : onChooseFilter,
      onChange: onSetFilter,
      onClose: onToggleFilter
    }
  }

  _getHeader() {
    const { criteria, collectionActions, defaultFilter, defaultQuery, defaultSort, endpoint, entity, filter, filters, filtering, query, search, sort, table, tasks, onSetQuery, onToggleFilter } = this.props
    const { layout } = this.state
    return {
      columns: this.props.export || table,
      collectionActions,
      criteria,
      defaultFilter, 
      defaultQuery, 
      defaultSort, 
      endpoint,
      entity,
      filter,
      filters,
      filtering,
      layouts: this._getLayouts(),
      layout,
      query,
      search,
      sort,
      tasks,
      onSetQuery,
      onLayout: this._handleLayout,
      onToggleFilter,
      onRefresh: this._handleRefresh
    }
  }

  _getInfinite() {
    const { autoRefresh, card, chart, defaultFilter, defaultQuery, empty, endpoint, entity, filter, failure, handler, loading, notFound, q, query, recordTasks, rowClass, selectable, selectValue, sort, sortable, table, onSetSelected, onClick, onSort } = this.props
    const { cacheKey } = this.state
    const layout = this._getLayout()
    return {
      autoRefresh,
      reference: node => this.infinite = node,
      cacheKey,
      endpoint,
      defaultFilter,
      defaultQuery: {
        ...defaultQuery || {},
        ...query || {}
      },
      filter: filter && Object.keys(filter).length > 0 || q.length > 0 ? {
        ...this.props.filter,
        ...q.length > 0 ? { q } : {}
      } : null,
      loading,
      empty,
      failure,
      footer: ({ all, total }) => total !== null ? `Showing ${total} of ${pluralize(entity, all, true)}` : 'Loading...',
      layout: Results,
      notFound,
      scrollpane: layout.value !== 'table',
      selectValue,
      props: {
        card,
        code: this._getCode(),
        chart,
        handler,
        layout: layout.component,
        recordTasks,
        rowClass,
        selectable,
        sort,
        sortable,
        table,
        onClick,
        onSort
      },
      sort,
      onUpdateSelected: onSetSelected
    }
  }

  _getLayout() {
    const layouts = this._getLayouts()
    return layouts.find(layout => {
      return layout.value === this.state.layout
    })
  }

  _getLayouts() {
    const { card, layout, table } = this.props
    return [
      ...layout ? [{ label: 't(Custom)', value: 'custom', svg: 'layout_grid', component: layout }] : [],
      ...table ? [{ label: 't(Table)', value: 'table', svg: 'table_properties', component: TableLayout }] : [],
      ...card ? [{ label: 't(Card)', value: 'card', svg: 'layout_grid', component: CardLayout }] : []
    ]
  }

  _getSanitizedFilter(filter) {
    if(Object.keys(filter)[0] === '$and') return filter
    return _.isArray(filter) ? { $and: filter} : { $and: [filter] }
  }

  _getSanitizedSort(sort) {
    if(!_.isString(sort)) return sort
    return {
      key: sort.replace(/^-/, ''),
      order: sort[0] === '-' ? 'desc' : 'asc'
    }
  }

  _handleChangeUrl() {
    const { filter, route, query, sort } = this.props
    const { pathname } = route
    const { key, order } = sort
    const $filter = filter
    const $sort = order === 'desc' ? `-${key}` : key
    const querystring = qs.stringify({ 
      ...query || {},
      $filter,
      $sort
    }, { encode: false, skipNulls: true })
    this.context.router.replace(`${pathname}?${querystring}`)
  }

  _handleClearSelected() {
    this.props.onSetSelected({
      filter: {},
      values: [],
      mode: '$in',
      total: 0
    })
  }

  _handleDeselect() {
    console.log('collection')
    this.infinite.clearSelection()
  }

  _handleLayout() {
    const layouts = this._getLayouts()
    const currentIndex = layouts.findIndex(layout => {
      return layout.value === this.state.layout
    })
    const nextIndex = (currentIndex + 1) % layouts.length
    this.setState({ 
      layout: layouts[nextIndex].value
    })
  }

  _handleLoadLayout() {
    const layouts = this._getLayouts()
    const code = this._getCode()
    if(layouts.length === 1) {
      return this.setState({
        layout: layouts[0].value
      })
    }
    this.props.context.local_storage.get({
      key: `collection-${code}`,
      onSuccess: (value) => {
        const layout = value || layouts[0].value
        this.setState({ layout })
      },
      onFailure: () => {}
    })
  }

  _handleParseUrl() {
    const { search } = this.props.route
    const { defaultSort, onSetParams } = this.props
    const decoder = (str) => str.match(/^\d$/) !== null ? parseInt(str) : str
    const parsed = search.length > 0 ? qs.parse(search.slice(1), { decoder }) : {}
    const query = _.omit(parsed, ['$filter','$sort'])
    const filter = parsed.$filter ? this._getSanitizedFilter(parsed.$filter) : null
    const sort = this._getSanitizedSort(parsed.$sort || defaultSort || { key: 'created_at', order: 'desc' })
    onSetParams(filter, query, sort)
  }

  _handleRefresh() {
    this.setState({
      cacheKey: _.random(100000, 999999).toString(36)
    })
  }

  _handleSaveLayout() {
    const layouts = this._getLayouts()
    if(layouts.length === 1) return
    const code = this._getCode()
    const { layout } = this.state
    this.props.context.local_storage.set({
      key: `collection-${code}`,
      value: layout,
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

}

export default Collection
