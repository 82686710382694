import ContactToken from '@apps/domains/admin/tokens/contact'
import InputTokens from '@admin/components/input_tokens'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Contact from './contact'
import React from 'react'

class ContactField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    contact: null
  }

  _handleBegin = this._handleBegin.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleContact = this._handleContact.bind(this)

  render() {
    const { contact } = this.state
    return (
      <div className="maha-input">
        <div className="maha-input-field" onClick={ this._handleBegin }>
          { contact ?
            <InputTokens { ...this._getInputTokens() } /> :
            <div className="maha-input-placeholder">
              Enter contact
            </div>
          }
        </div>
        { contact &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contact } = this.state
    if(!_.isEqual(contact, prevState.contact)) {
      this._handleChange()
    }
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getContact() {
    const { contact } = this.state
    return {
      contact,
      onDone: this._handleContact
    }
  }

  _getInputTokens() {
    const { contact } = this.state
    return {
      tokens: [contact],
      format: (contact) => (
        <ContactToken contact={ contact } />
      )
    }
  }

  _handleBegin() {
    this.context.form.push(Contact, this._getContact.bind(this))
  }

  _handleChange() {
    const { contact } = this.state
    this.props.onChange(contact)
  }

  _handleClear() {
    this.setState({
      name: null
    })
  }

  _handleContact(contact) {
    this.setState({ contact })
    this.context.form.pop()
  }

}

export default ContactField
