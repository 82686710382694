import FlowchartDesigner from '@admin/components/flowchart_designer'
import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

class VoiceDesigner extends React.PureComponent {

  static propTypes = {
    voice_campaign: PropTypes.object,
    programfields: PropTypes.array
  }

  render() {
    return <FlowchartDesigner { ...this._getFlowchartDesigner() } />
  }

  _getFlowchartDesigner() {
    const { voice_campaign } = this.props
    const { steps, status } = voice_campaign
    return {
      campaign: voice_campaign,
      editable: _.includes(['active','draft','inactive'], voice_campaign.status),
      endpoint: `/campaigns_voice_campaigns/${voice_campaign.id}/config`,
      fields: this._getFields(),
      program: voice_campaign.program,
      properties: this._getProperties(),
      tokens: this._getTokens(),
      blocks: [
        this._getTrigger(),
        { action: 'play' },
        { action: 'say' },
        ...voice_campaign.direction === 'inbound' ? [
          { action: 'dial' },
          { action: 'dialbyextension' },
          { action: 'dialbyname' }
        ] : [],
        { action: 'dialmenu' },
        ...voice_campaign.direction === 'inbound' ? [
          { action: 'voicemail' }
        ] : [],
        { action: 'redirect' },
        { action: 'ifthen' },
        { action: 'wait' },
        { action: 'goal' },
        { action: 'set' },
        { action: 'property' },
        { action: 'consent' },
        { action: 'list' },
        { action: 'workflow' },
        { action: 'email' },
        { action: 'sms' },
        { action: 'internal_email' },
        { action: 'internal_sms' },
        { action: 'timeofday' },
        {
          svg: 'phone',
          label: 'Hangup',
          type: 'ending',
          action: 'ending'
        }
      ],
      defaultValue: steps,
      status
    }
  }

  _getFields() {
    const { programfields, voice_campaign } = this.props
    if(!programfields) return []
    return [
      ...programfields.length > 0 ? [
        { 
          label: voice_campaign.program.title, 
          fields: programfields.map(field => ({
            name: field.name.value,
            key: `contact.values.${field.code}`,
            type: field.type
          }))
        }
      ] : []
    ]
  }

  _getProperties() {
    const { programfields, voice_campaign } = this.props
    if(!programfields) return []
    return [
      ...programfields.length > 0 ? [
        { 
          label: voice_campaign.program.title, 
          fields: programfields.map(field => ({
            ...field,
            name: `values.${field.code}`
          }))
        }
      ] : []
    ]
  }

  _getTokens() {
    const { programfields, voice_campaign } = this.props
    if(!programfields) return []
    return [
      ...programfields.length > 0 ? [
        { 
          title: voice_campaign.program.title, 
          tokens: programfields.map(field => ({
            name:   field.label,
            token: `program.${field.name.token}`
          }))
        }
      ] : [],
      { title: 't(Call)', tokens: [
        { name: 'From Number', token: 'call.from_number' },
        { name: 'From Number (spoken)', token: 'call.from_number_spoken' },
        { name: 'To Number', token: 'call.to_number' },
        { name: ',To Number (spoken)', token: 'call.to_number_spoken' }
      ] },
      ...voice_campaign.store ? this._getOrderTokens(voice_campaign.store) : [],
      ...voice_campaign.event ? this._getRegistrationTokens(voice_campaign.event) : []
    ]
  }

  _getTrigger() {
    const { voice_campaign } = this.props
    if(voice_campaign.direction === 'inbound') {
      return {
        svg: 'phone',
        label: 'Incoming Call',
        type: 'trigger',
        token: () => voice_campaign.phone_number.formatted
      }
    } else {
      return {
        svg: 'phone',
        label: 'Outgoing Call',
        type: 'trigger',
        token: () => voice_campaign.phone_number.formatted
      }
    }
  }

  _getPaymentTokens(model) {
    return {
      title: 't(Payment Tokens)',
      tokens: [
        { name: 'Method', token: `${model}.payment_method` },
        { name: 'Amount', token: `${model}.payment_amount` },
        { name: 'Card', token: `${model}.payment_card` },
        { name: 'Summary', token: `${model}.payment_summary` }
      ]
    }
  }

  _getRegistrationTokens(event) {
    return [
      {
        title: 't(Registration Tokens)',
        tokens: [
          { name: 'First Name', token: 'registration.first_name' },
          { name: 'Last Name', token: 'registration.last_name' },
          { name: 'Email', token: 'registration.email' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `registration.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('registration')
    ]
  }

  _getOrderTokens(store) {
    return [
      {
        title: 't(Order Tokens)',
        tokens: [
          { name: 'Order ID', token: 'order.id' },
          { name: 'First Name', token: 'order.first_name' },
          { name: 'Last Name', token: 'order.last_name' },
          { name: 'Email', token: 'order.email' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `order.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('order')
    ]
  }

}

const mapResources = (props, context) => ({
  programfields: `/api/admin/team/programs/${props.voice_campaign.program.id}/fields`
})

export default Container(mapResources)(VoiceDesigner)
