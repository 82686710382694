import Token from './token'
import Form from './form'

export default {
  svg: 'check',
  label: 'Create a Task',
  type: 'contact',
  action: 'task',
  form: Form,
  token: Token
}
