import React, { useState, useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'

const Scrollbar = forwardRef((props, ref) => {

  const horizonal = props.horizontal === true
  const vertical = props.vertical !== false
  const hideScroll = props.hideScroll === true
  const { maxHeight } = props

  const [isScrolling, setIsScrolling] = useState(false)
  const timeoutRef = useRef(null)
  
  const handleScroll = (e) => {
    if(props.onScroll) props.onScroll(e)
    setIsScrolling(true)
    if(timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setIsScrolling(false)
    }, 1000)
  }
  
  useEffect(() => {
    return () => {
      if(timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  const classes = [props.className,'scrollable']
  if(horizonal) classes.push('horizontal')
  if(vertical) classes.push('vertical')
  if(!hideScroll || isScrolling) classes.push('scrolling')

  const mergedProps = {
    ..._.omit(props, ['horizontal', 'vertical', 'maxHeight']),
    ...maxHeight ? { style: { maxHeight } } : {},
    className: classes.join(' '),
    onScroll: handleScroll,
    ...props.onTouchStart ? { onTouchStart: props.onTouchStart } : {},
    ...props.onTouchEnd ? { onTouchEnd: props.onTouchEnd } : {},
    ref
  }

  return (
    <div { ...mergedProps }>
      { props.children }
    </div>
  )
})

Scrollbar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hideScroll: PropTypes.bool,
  horizontal: PropTypes.bool,
  maxHeight: PropTypes.string,
  vertical: PropTypes.bool,
  onScroll: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func
}

export default Scrollbar