import Container from '@admin/components/container'
import Button from '@admin/components/button'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Task extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    pipelines: PropTypes.array,
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this.props.config || {}
      }
    })
  }

  _getForm() {
    const { admin } = this.context
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Create a Task)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Task)', type: 'segment', fields: [
              { name: 'title', type: 'textfield', required: true, placeholder: 'Enter Title', defaultValue: config.title },
              { name: 'description', type: 'textarea', placeholder: 'Enter Description', defaultValue: config.description }
            ], after: <Button { ...this._getTokens() } /> },
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', required: true, defaultValue: config.owner_id || admin.user.id, format: UserToken },
            { label: 't(Assigned To)', name: 'assignee_ids', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', multiple: true, required: true, defaultValue: config.assignee_ids || [admin.user.id], format: UserToken },
            { label: 't(Due)', type: 'segment', fields: [
              { name: 'is_due', type: 'radiogroup', options: [
                { value: false, text: 't(No due date)' },
                { value: true, text: 't(This task has a due date)' }
              ], defaultValue: config.is_due || false },
              { type: 'fields', show: config.is_due, fields: [
                { name: 'due_date', type: 'datefield', defaultValue: config.due_date || moment() },
                { name: 'due_time', type: 'timefield', defaultValue: config.due_time || '09:00' }
              ] }
            ] }
          ]
        }
      ]
    }
  }

  _getTokens() {
    const { onTokens } = this.props
    return {
      label: 't(you can use these tokens in the title)',
      className: 'link',
      handler: onTokens
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    const { pipelines } = this.props
    const pipeline = _.find(pipelines, { id: config.pipeline_id })
    this.props.onDone({
      ...config,
      pipeline: pipeline ? {
        id: pipeline.id,
        title: pipeline.title
      } : null
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}
const mapResources = (props, context) => ({
  pipelines: '/api/admin/sales/pipelines'
})

export default Container(mapResources)(Task)
