import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Convert from '../convert'
import React from 'react'

const Overview = ({ demo }) => {

  const contact = {
    label: demo.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${demo.contact.id}`
  }

  const details = {
    audits: `truevail_demos/${demo.id}`,
    comments: `truevail_demos/${demo.id}`,
    sections: [
      {
        title: 't(Demo)',
        items: [
          { label: 't(Contact)', content: <Button { ...contact } /> },
          { label: 't(Start Time)', content: demo.started_at, format: 'datetime' },
          { label: 't(End Time)', content: demo.ended_at, format: 'datetime' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  demo: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview demo={ props.demo } />,
  tasks: {
    items: [
      {
        label: 't(Mark as Completed)',
        svg: 'check',
        show: ['scheduled','rescheduled'].includes(props.demo.status),
        request: {
          endpoint: `/api/admin/truevail/admin/demos/${props.demo.id}/complete`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully completed this demo)'),
          onFailure: () => context.flash.set('error', 't(Unable to completed this demo)')
        }
      },
      {
        label: 't(Mark as No Show)',
        svg: 'ban',
        show: ['scheduled','rescheduled'].includes(props.demo.status),
        request: {
          endpoint: `/api/admin/truevail/admin/demos/${props.demo.id}/noshow`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully noshowed this demo)'),
          onFailure: () => context.flash.set('error', 't(Unable to noshowed this demo)')
        }
      },
      {
        label: 't(Mark as Converted)',
        svg: 'flag',
        show: props.demo.signup === null,
        modal: <Convert demo={ props.demo } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
