import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const getScreenshot = (email_campaign) => ({
  engine: email_campaign.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `campaigns_email_campaigns/${email_campaign.code}`,
  width: 100,
  height: 75,
  screenshoted_at: email_campaign.screenshoted_at
})

const EmailCampaignCard = ({ email_campaign }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <MJSONScreenshot { ...getScreenshot(email_campaign) } />
    </div>
    <div className="maha-card-layout-card-details">
      <div className="token-stats">
        <span className={ email_campaign.status }>{ email_campaign.status }</span>
        { email_campaign.status === 'scheduled' &&
          <span><Date datetime={ email_campaign.send_at } /></span>
        }
        { email_campaign.status === 'sending' &&
          <>
            { email_campaign.sent === email_campaign.total ?
              <span>{ email_campaign.sent } sent</span> :
              <span>{ email_campaign.sent } / { email_campaign.total } sent ({ 
                numeral(email_campaign.sent/email_campaign.total).format('0.0%') 
              })</span>
            }
          </>
        }
        { email_campaign.status === 'sent' &&
          <span><Date datetime={ email_campaign.sent_at } /></span>
        }
      </div>
      { email_campaign.status === 'sent' &&
        <div className="token-stats">
          <span>{ email_campaign.sent } sent</span>
        </div>
      }
    </div>
  </div>
)

EmailCampaignCard.propTypes = {
  email_campaign: PropTypes.object
}

export default EmailCampaignCard
