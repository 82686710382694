import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ email_campaign }, { admin }) => {

  const details = {
    sections: [
      {
        title: 't(Email Campaign)',
        items: [
          { label: 't(Title)', content: email_campaign.title }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  email_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email_campaign={ props.email_campaign } />
})

export default Panel(null, mapPropsToPanel)
