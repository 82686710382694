import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class WebRange extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    nullValues: PropTypes.array,
    of: PropTypes.any,
    tabIndex: PropTypes.number,
    units: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    nullValues: ['auto'],
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    numeric: '',
    value: null
  }

  _handleChange = _.throttle(this._handleChange.bind(this), 250)
  _handleDecrease = this._handleDecrease.bind(this)
  _handleIncrease = this._handleIncrease.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleType = this._handleType.bind(this)
  _handleNumeric = _.debounce(this._handleNumeric.bind(this), 500)
  _handleRange = this._handleRange.bind(this)

  render() {
    const { value } = this.state
    const { of } = this.props
    if(!value) return null
    return (
      <div className={ this._getRangeClass() }>
        <div className="maha-range-slider">
          <input { ...this._getRange() } />
        </div>
        <div className="maha-range-label">
          <div className={ this._getNumericClass() }>
            <div className="maha-input-field">
              <Input { ...this._getNumeric() } />
              { of !== undefined &&
                <span> / {of}</span>
              }
            </div>
            <div className="maha-numericfield-controls">
              <Button { ...this._getIncrease() } />
              <Button { ...this._getClear() } />
              <Button { ...this._getDecrease() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(this.state.value, prevState.value)) {
      this._handleChange()
    }
    if(!_.isEqual(this.props.value, prevProps.value)) {
      this._handleSet(this.props.value)
    }
  }

  _getDecrease() {
    return {
      icon: 'caret-down',
      className: 'maha-numericfield-decrease',
      handler: this._handleDecrease
    }
  }

  _getDefaultUnits() {
    const { units } = this.props
    return units[0].name
  }

  _getDefaultValue() {
    const { nullValues, defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : nullValues[0]
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-numericfield-clear',
      handler: this._handleClear
    }
  }

  _getIncrease() {
    return {
      icon: 'caret-up',
      className: 'maha-numericfield-increase',
      handler: this._handleIncrease
    }
  }

  _getNullValue() {
    const { nullValues } = this.props
    const first = nullValues[0]
    const matches = first.match(/^([-\d.]+)\s?([A-Za-z%]+)$/)
    return {
      quantity: matches ? matches[1] : null,
      text: first,
      units: matches ? matches[2] : null
    }
  }

  _getNumeric() {
    const { disabled, id, tabIndex } = this.props
    const { numeric } = this.state
    return {
      disabled,
      type: 'textfield',
      id,
      tabIndex,
      value: numeric,
      onChange: this._handleType
    }
  }

  _getNumericClass() {
    const { disabled } = this.props
    const classes = ['maha-input','maha-numericfield']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getParsed(raw) {
    const { value } = this.state
    const trimmed = raw ? raw.trim() : ''
    if(trimmed.length === 0) return this._getNullValue()
    const matches = trimmed.match(/^([-\d.]+)\s?([A-Za-z%]*)$/)
    if(!matches || matches[1].length === 0) return this._getNullValue()
    const quantity = Number(matches[1])
    const units = matches[2].length > 0 ? this._getSanitizedUnits(matches[2]) : value ? value.units : this.props.units[0].name
    const text = `${quantity}${units ? units : ''}`
    return { text, units, quantity }
  }

  _getRange() {
    const { disabled, tabIndex } = this.props
    const { value } = this.state
    const { min, max, step } = this._getUnits(value.units)
    const quantity = _.isNil(value.quantity) ? max : value.quantity
    return {
      disabled,
      type: 'range',
      min: 0,
      max: (max - min) / step,
      tabIndex,
      value: (quantity - min) / step,
      onChange: this._handleRange
    }
  }

  _getRangeClass() {
    const { disabled } = this.props
    const classes = ['maha-input','maha-range']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getSanitizedUnits(name) {
    const units = this._getUnits(name)
    return units.name || this._getDefaultUnits()
  }

  _getUnits(name) {
    const { units } = this.props
    return units.find(u => {
      return u.name === name
    }) || {
      min: 0,
      max: 100,
      step: 1,
      name: null
    }
  }

  _handleClear() {
    this._handleNumeric('')
  }

  _handleChange() {
    const { value } = this.state
    const { nullValues } = this.props
    const output = (value === null || _.includes(nullValues, value.text)) ? null : value.text
    this.props.onChange(output)
  }

  _handleDecrease() {
    const { value } = this.state
    const { name, min, max, step } = this._getUnits(value.units)
    const units = name || this._getDefaultUnits()
    const quantity = value.quantity || max
    const newQuantity = quantity - step
    const decreased = !_.isNil(min) ? Math.max(min, newQuantity) : newQuantity
    this._handleUpdate(decreased, units)
  }

  _handleIncrease() {
    const { value } = this.state
    const { name, min, max, step } = this._getUnits(value.units)
    const units = name || this._getDefaultUnits()
    const quantity = value.quantity || min
    const newQuantity = quantity + step
    const increased = !_.isNil(max) ? Math.min(max, newQuantity) : newQuantity
    this._handleUpdate(increased, units)
  }

  _handleNumeric(numeric) {
    this._handleSet(numeric)
  }

  _handleRange(e) {
    const { value } = this.state
    const { name, min, step } = this._getUnits(value.units)
    const units = name || this._getDefaultUnits()
    const val = parseInt(e.target.value)
    const quantity = min + (step * val)
    this._handleUpdate(quantity, units)
  }

  _handleSet(text) {
    const parsed = this._getParsed(text)
    this._handleUpdate(parsed.quantity, parsed.units)
  }

  _handleType(numeric) {
    this.setState({ numeric })
    this._handleNumeric(numeric)
  }

  _handleUpdate(newQuantity, newUnits) {
    const { nullValues} = this.props
    const { min, max, name } = this._getUnits(newUnits)
    const quantity = newQuantity !== null ? Math.min(Math.max(newQuantity, min), max) : null
    const numeric = quantity !== null ? `${quantity}${name || ''}` : nullValues[0]
    this.setState({
      numeric,
      value: {
        text: numeric,
        units: name,
        quantity
      }
    })
  }

}

export default WebRange
