import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    allowedPricing: PropTypes.array,
    entity: PropTypes.string,
    manageInventory: PropTypes.bool,
    products: PropTypes.array,
    onDone: PropTypes.func
  }

  static defaultProps = {
    allowedPricing: ['free', 'fixed', 'custom']
  }

  state = {
    code: getCode(10),
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity } = this.props
    const { code } = this.state
    return {
      title: `New ${entity}`,
      cancelSvg: 'chevron_left',
      saveText: 'Done',
      onSubmit: this._handleSuccess,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      sections: [
        {
          fields: [
            { name: 'code', type: 'hidden', value: code },
            { label: 't(Description)', name: 'description', required: true, type: 'textfield' },
            ...this._getPricing(),
            { label: 't(Value)', name: 'value', type: 'textfield', placeholder: 't(Enter a value for exports)' },
            ...this._getInventory()
          ]
        }
      ]
    }
  }

  _getInventory() {
    const { manageInventory } = this.props
    return manageInventory ? [
      { name: 'is_sold_out', type: 'checkbox', prompt: 'This item is sold out' }
    ] : []
  }

  _getPricing() {
    const { allowedPricing } = this.props
    const { config } = this.state
    const options = this._getPricingOptions()
    return !_.isEqual(allowedPricing, ['fixed']) ? [
      { label: 't(Pricing)', type: 'segment', required: true, fields: [
        { type: 'radiogroup', name: 'pricing', options, defaultValue: 'free' },
        ...this._getPricingType(config.pricing)
      ] }
    ] : this._getPricingType('fixed')
  }

  _getPricingOptions() {
    const { allowedPricing } = this.props
    const options = []
    if(_.includes(allowedPricing, 'free')) options.push({ value: 'free', text: 't(Free)' })
    if(_.includes(allowedPricing, 'fixed')) options.push({ value: 'fixed', text: 't(Fixed Price)' })
    if(_.includes(allowedPricing, 'custom')) options.push({ value: 'custom', text: 't(Custom Amount)'  })
    return options
  }

  _getPricingType(pricing) {
    return pricing !== 'free' ? [
      { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
      { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken },
      ...pricing === 'fixed' ? [{ label: 'Fixed Price', name: 'price', required: true, type: 'moneyfield', placeholder: '0.00' }] : [],
      { label: 't(Tax Rate)', name: 'tax_rate', required: true, type: 'ratefield' }
    ] : []
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(product) {
    console.log({ product})
    this.props.onDone(product)
  }

}

export default New
