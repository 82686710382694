import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import qs from 'qs'

class Terms extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    account_id: PropTypes.number,
    program_id: PropTypes.number,
    service: PropTypes.object,
    showHeader: PropTypes.bool,
    team_id: PropTypes.number,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  termsRef = React.createRef()

  state = {
    reachedBottom: false
  }

  _handleAgree = this._handleAgree.bind(this)
  _handleScroll = this._handleScroll.bind(this)

  render() {
    const { service } = this.props
    const text = this._getText(service.type)
    return (
      <ModalPanel { ...this._getPanel() }>
        <div { ...this._getTerms() }>
          <p><T text={ text.intro } /></p>
          { ['use','protect'].map((section, i) => (
            <Fragment key={`section_${i}`}>
              <h2><T text={ text[section].title } /></h2>
              <ol>
                { text[section].text.map((phrase, j) => (
                  <li key={`phrase_${j}`}>
                    <T text={ phrase } />
                  </li>
                ))}
              </ol>
            </Fragment>
          )) }
          <p><T text={ text.conclusion } /></p>
          <Button { ...this._getAgree() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleScroll()
  }

  _getText(type) {
    const { provider } = this.context
    return {
      intro: `t(By connecting this account, you are giving ${provider.title} permission to this access this service, its features, and your data on your behalf.)`,
      use: {
        title: `t(How does ${provider.title} use my data?)`,
        text: [
          ...(type === 'contacts') ? [
            `t(When you browse your contacts from this account, ${provider.title} downloads contact data (first name, last name, organization, position, birthday, email addresses, mailing addresses, and phone numbers) to display through the interface, but does not store a permanent copy in its database. While browsing contacts, ${provider.title} does not download or store the this data.)`,
            `t(If you import contacts from this service, ${provider.title} will download and store contact data in its database.)`
          ] : [],
          ...(type === 'posts') ? [
            `t(When you connect a social profile to this account, ${provider.title} will be able to post content on your behalf.)`,
            `t(When you post to a social network, ${provider.title} will collect engagment metrics on each post for up to two weeks after posting.)`
          ] : [],
          ...(type === 'emails') ? [
            `t(In order to quickly list and search your emails, ${provider.title} downloads and stores your email metadata (from, to, cc, bcc, subject, snippet, sent at, priority, has attachments, was flagged, and is read). ${provider.title} does not store a copy of the email body or any attachments.)`,
            `t(When you view an email, ${provider.title} downloads the body and attachments to make them available through the interface, but does not store a permanent copy in its database.)`,
            `t(If you import an email thread, ${provider.title} will download the entire email thread, including body and attachments, and save the content in its database. Furthermore, it will listen for new emails in the conversation and import them as they are received.)`,
            `t(When you send, forward, or reply to an email within ${provider.title}, it will be sent using the service API, as if it were sent directly through the service.)`,
            't(Only you will be able to send, forward, or reply to emails from your account. No other user will have access to your email account.)'
          ] : [],
          ...(_.includes(['files','photos'], type)) ? [
            `t(When you browse your files from this account, ${provider.title} downloads  file metadata (name, content type, filesize, and a thumbnail) to display through the interface, but does not store a permanent copy in its database. While browsing files, ${provider.title} does not download or store the file contents.)`,
            `t(If you import a file, ${provider.title} will download the file and store the content and metadata in its database.)`
          ] : [],
          ...(type === 'signatures') ? [
            `t(When you create an agreement, ${provider.title} sends the agreement to signers using the service API. Before sending the agreement, ${provider.title} saves a copy of the unsigned agreement in its database.)`,
            `t(When all signers have signed the agreement, the signed agreement is downloaded to ${provider.title} and saved in its database.)`
          ] : []
        ]
      },
      protect: {
        title: `t(How does ${provider.title} protect my data?)`,
        text: [
          't(The tokens and credentials used to connect to your account are encrypted and stored securely in our database.)',
          `t(When you disconnect your account, ${provider.title} deletes all access tokens and stored data. The only exception is data that you have explicitly imported into ${provider.title}. This data can be also be removed manually.)`
        ]
      },
      conclusion: 't(If you accept the terms outlined above, please click the button below in order to proceed.)'
    }
  }

  _getAgree() {
    const { reachedBottom } = this.state
    return { 
      label: 'I Agree', 
      disabled: !reachedBottom, 
      color: 'blue', 
      fluid: false,
      handler: this._handleAgree 
    }
  }

  _getPanel() {
    const { showHeader, onBack } = this.props
    return {
      title: 't(Terms and Conditions)',
      showHeader,
      leftItems: [
        { svg: 'chevron_left', handler: onBack }
      ]
    }
  }

  _getTerms() {
    return {
      className: 'maha-profile-manager-terms',
      ref: this.termsRef,
      onScroll: this._handleScroll
    }
  }

  _handleAgree() {
    const { admin, host, provider } = this.context
    const { account_id, service, program_id, team_id } = this.props
    const { source, type } = service
    const { token } = admin
    const query = qs.stringify({
      ...account_id ? { account_id } : {},
      ...program_id ? { program_id } : {},
      ...team_id ? { team_id } : {},
      type,
      timestamp: moment().format('x'),
      token
    })
    host.window.signin(`${provider.admin_host}/admin/oauth/${source}/authorize?${query}`)
    this.props.onDone()
  }

  _handleScroll() {
    const { scrollHeight, scrollTop, clientHeight } = this.termsRef.current
    const { reachedBottom } = this.state
    this.setState({
      reachedBottom: reachedBottom || scrollHeight - scrollTop === clientHeight
    })
  }

}

export default Terms
