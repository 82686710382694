import TruevailSignature from './truevail_signature'
import AddressBook from './address_book'
import Unsubscribe from './unsubscribe'
import Countdown from './countdown'
import Forward from './forward'
import Divider from './divider'
import Address from './address'
import WebView from './webview'
import Follow from './follow'
import Button from './button'
import Share from './share'
import Image from './image'
import Video from './video'
import Quote from './quote'
import Text from './text'
import Icon from './icon'
import Map from './map'

const blocks = [
  { type: 'address', label: 't(Address)', form: Address, svg: 'map_pin_house' },
  { type: 'address_book', label: 't(Address Book)', form: AddressBook, svg: 'notebook_tabs' },
  { type: 'button', label: 't(Button)', form: Button, svg: 'mouse_pointer' },
  { type: 'countdown', label: 't(Countdown)', form: Countdown, svg: 'clock' },
  { type: 'divider', label: 't(Divider)', form: Divider, svg: 'minus' },
  { type: 'forward', label: 't(Forward)', form: Forward, svg: 'arrow_big_right' },
  { type: 'icon', label: 't(Icon)', form: Icon, svg: 'smile' },
  { type: 'image', label: 't(Image)', form: Image, svg: 'image' },
  { type: 'map', label: 't(Map)', form: Map, svg: 'map' },
  { type: 'follow', label: 't(Social Follow)', form: Follow, svg: 'facebook' },
  { type: 'share', label: 't(Social Share)', form: Share, svg: 'share2' },
  { type: 'text', label: 't(Text)', form: Text, svg: 'case_upper' },
  { type: 'truevail_signature', label: 't(Signature)', form: TruevailSignature, svg: 'signature', access: {
    'team.id': { $eq: 41 }
  } },
  { type: 'unsubscribe', label: 't(Unsubscribe)', form: Unsubscribe, svg: 'ban' },
  { type: 'video', label: 't(Video)', form: Video, svg: 'sqaure_play' },
  { type: 'webview', label: 't(Web View)', form: WebView, svg: 'globe' }

]

export default blocks
