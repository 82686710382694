import ModalPanel from '@admin/components/modal_panel'
import Menu from '@admin/components/menu'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Sections from './sections'
import React from 'react'

class Main extends React.Component {

  static propTypes = {
    action: PropTypes.string,
    after: PropTypes.string,
    before: PropTypes.any,
    busy: PropTypes.array,
    buttons: PropTypes.array,
    defaults: PropTypes.object,
    cancel: PropTypes.object,
    cancelSvg: PropTypes.string,
    cancelText: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.object,
    errors: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.any,
    fields: PropTypes.array,
    filtered: PropTypes.object,
    highlights: PropTypes.array,
    inline: PropTypes.bool,
    instructions: PropTypes.any,
    isConfiguring: PropTypes.bool,
    isReady: PropTypes.bool,
    isBusy: PropTypes.bool,
    isValid: PropTypes.bool,
    message: PropTypes.string,
    method: PropTypes.string,
    ready: PropTypes.array,
    saveIcon: PropTypes.string,
    saveText: PropTypes.string,
    showHeader: PropTypes.bool,
    sections: PropTypes.array,
    status: PropTypes.string,
    tabs: PropTypes.array,
    title: PropTypes.string,
    validated: PropTypes.array,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onChangeField: PropTypes.func,
    onFailure: PropTypes.func,
    onFetch: PropTypes.func,
    onSetBusy: PropTypes.func,
    onSetData: PropTypes.func,
    onSetReady: PropTypes.func,
    onSetStatus: PropTypes.func,
    onSetValid: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitForm: PropTypes.func,
    onSuccess: PropTypes.func,
    onUpdateData: PropTypes.func,
    onValidate: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)

  render() {
    const { after, before, inline, isConfiguring, message, sections, tabs } = this.props
    const content = (
      <div className={ this._getClass() }>
        { before &&
          <div className="maha-form-header">
            <div className="maha-form-before">
              { _.isString(before) ?
                <T text={`${before}`} /> :
                <>{ before }</> 
              }
            </div>
          </div>
        }
        { message &&
          <div className="maha-form-message">
            { message }
          </div>      
        }
        { !isConfiguring && sections &&
          <Sections { ...this._getSections(sections, 0) } />
        }
        { !isConfiguring && tabs &&
          <Menu { ...this._getTabs() } />
        }
        { after &&
          <div className="maha-form-footer">
            <div className="maha-form-after">{ after }</div>
          </div>
        }
      </div>
    )
    if(inline) return content
    return (
      <ModalPanel { ...this._getPanel() }>
        { content }
      </ModalPanel>
    )
  }

  _getButtonClasses() {
    const { isBusy } = this.props
    const classes = ['maha-modal-panel-header-navigation']
    if(isBusy) classes.push('disabled')
    return classes.join(' ')
  }

  _getCancel() {
    const { cancel, cancelSvg, cancelText } = this.props
    if(cancel) return [cancel]
    const handler = this._handleCancel
    if(cancelSvg) return [{ svg: cancelSvg, handler }]
    if(cancelText) return [{ label: cancelText, handler }]
    return null
  }

  _getClass() {
    const { isConfiguring, isReady, status } = this.props
    const classes = ['ui','form']
    if(isConfiguring || !isReady || status === 'submitting') classes.push('loading')
    return classes.join(' ')
  }

  _getTab(tab, tabIndex) {
    const highlight = this.props.highlights.reduce((highlight, error) => {
      return highlight || error.split('-')[0] === `${tabIndex}` 
    }, false)
    return {
      label: tab.label,
      className: highlight ? 'error' : '',
      component: <Sections { ...this._getSections(tab.sections, tabIndex) } />
    }
  }

  _getTabs() {
    const { tabs } = this.props
    return {
      type: 'pointing',
      items: tabs.map((tab, tabIndex) => {
        return this._getTab(tab, tabIndex)
      })
    }
  }

  _getPanel() {
    const { buttons, color, instructions, inline, showHeader, title } = this.props
    return {
      buttons,
      color,
      instructions,
      showHeader: showHeader && !inline,
      title,
      leftItems: this._getCancel(),
      rightItems: this._getSave()
    }
  }

  _getSections(sections, tabIndex) {
    const { data, errors, fields, highlights, inline, status, onSetBusy, onSetReady, onSetValid, onUpdateData, onValidate } = this.props
    return {
      data,
      errors,
      fields,
      highlights,
      inline,
      sections,
      status,
      tabIndex,
      onBusy: onSetBusy,
      onReady: onSetReady,
      onSubmit: onValidate,
      onUpdateData,
      onValid: onSetValid
    }
  }

  _getSave() {
    const { isBusy, saveIcon, saveText, onValidate } = this.props
    if(isBusy) return null
    const handler = onValidate
    if(saveIcon) return [{ icon: saveIcon, handler }]
    if(saveText) return [{ label: saveText, handler }]
    return null
  }

  _handleCancel() {
    this.props.onCancel()
  }

}

export default Main
