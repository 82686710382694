import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_campaign.title,
  manager: {
    path: `/admin/truevail/admin/advisors/${props.advisor_id}/social_campaigns/${resources.social_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } social_campaign={ resources.social_campaign } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}`,
  social_campaign: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/social_campaigns/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
