import SignatureField from '../../fields/signaturefield'
import AddressField from '../../fields/addressfield'
import CountryField from '../../fields/countryfield'
import HiddenField from '../../fields/hiddenfield'
import NumberField from '../../fields/numberfield'
import MoneyField from '../../fields/moneyfield'
import CheckBoxes from '../../fields/checkboxes'
import RadioGroup from '../../fields/radiogroup'
import PhoneField from '../../fields/phonefield'
import DateField from '../../fields/datefield'
import FileField from '../../fields/filefield'
import TextField from '../../fields/textfield'
import TimeField from '../../fields/timefield'
import TextArea from '../../fields/textfield'
import Checkbox from '../../fields/checkbox'
import DropDown from '../../fields/dropdown'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import Text from '../../fields/text'
import PropTypes from 'prop-types'
import React from 'react'

class Custom extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    fields: PropTypes.array,
    form: PropTypes.object
  }

  render() {
    const fields = this._getFields()
    return (
      <div className="flowchart-designer-blocks">
        <p><T text="t(These fields belong to the form and will not update the contact properties when the form is submitted)" /></p>
        { fields.map((field, index) => (
          <div className="flowchart-designer-block" key={`field_${index}`} { ...this._getField(field) }>
            <div className="flowchart-designer-block-icon action">
              <Icon svg={ field.svg } />
            </div>
            <div className="flowchart-designer-block-label">
              <T text={ field.label } />
            </div>
          </div>
        )) }
      </div>
    )
  }

  _getFields() {
    const { features } = this.context.admin
    return [
      { label: 't(Address Field)', svg: 'map_pin', type: 'addressfield', component: AddressField },
      { label: 't(Checkbox)', svg: 'square_check', type: 'checkbox', component: Checkbox },
      { label: 't(Checkboxes)', svg: 'square_check', type: 'checkboxgroup', component: CheckBoxes },
      { label: 't(Country Field)', svg: 'flag', type: 'countryfield', component: CountryField },
      { label: 't(Date Field)', svg: 'calendar_days', type: 'datefield', component: DateField },
      { label: 't(Drop Down)', svg: 'chevron_down', type: 'dropdown', component: DropDown },
      { label: 't(File Upload)', svg: 'cloud_upload', type: 'filefield', component: FileField },
      { label: 't(Hidden Field)', svg: 'eye_closed', type: 'hiddenfield', component: HiddenField },
      { label: 't(Instructions)', svg: 'info', type: 'text', component: Text },
      { label: 't(Money Field)', svg: 'dollar_sign', type: 'moneyfield', component: MoneyField },
      { label: 't(Number Field)', svg: 'hash', type: 'numberfield', component: NumberField },
      { label: 't(Phone Field)', svg: 'phone', type: 'phonefield', component: PhoneField },
      { label: 't(Radio Group)', svg: 'circle_dot', type: 'radiogroup', component: RadioGroup },
      ...features.agreements ? [{ label: 't(Signature Field)', svg: 'signature', type: 'signaturefield', component: SignatureField }] : [],
      { label: 't(Text Area)', svg: 'case_sensitive', type: 'textarea', component: TextArea },
      { label: 't(Text Field)', svg: 'case_sensitive', type: 'textfield', component: TextField },
      { label: 't(Time Field)', svg: 'clock', type: 'timefield', component: TimeField }
    ]
  }

  _getField(field) {
    return {
      draggable: true,
      onDragStart: this._handleDragStart.bind(this, field)
    }
  }

  _handleDragStart(field, e) {
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'all'
    e.dataTransfer.setData('field', JSON.stringify({
      type: field.type
    }))
  }

}

export default Custom
