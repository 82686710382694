import SocialDesigner from './social_designer'
import PropTypes from 'prop-types'
import React from 'react'

class SocialDesignerContainer extends React.PureComponent {

  static contextTypes = {
    versions: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.object,
    editable: PropTypes.bool,
    title: PropTypes.string,
    version: PropTypes.object,
    versions: PropTypes.array,
    onChange: PropTypes.func
  }

  static defaultProps = {
    editable: true,
    versionable: false,
    onChange: () => {}
  }

  state = {
    cacheKey: 'new',
    config: null
  }

  _handleSave = this._handleSave.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { config } = this.state
    if(!config) return null
    return (
      <div className="maha-social-designer">
        <div className="maha-social-designer-body">
          <SocialDesigner { ...this._getSocialDesigner() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue, version } = this.props
    this._handleSet(version?.record?.id || 'new', defaultValue?.entity || version?.current?.entity || {})
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    const { version } = this.props
    if(!_.isEqual(version, prevProps.version)) {
      this._handleSet(version?.record?.id, version?.current?.entity )
    }
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getNormalized(config) {
    return {
      ...config,
      content: {
        ...config.content.profile ? {
          profile: config.content.profile
        } : {},
        ...config.content.type === 'link' ? {
          link: config.content.link && config.content.link.link ? {
            link_id: config.content.link.link.id
          } : null
        } : config.content.type === 'photo' ? {
          photos: config.content.photos && config.content.photos.length > 0 ? config.content.photos.map(photo => ({
            photo: {
              asset_id: photo.photo.asset_id,
              transforms: photo.photo.transforms
            }
          })) : null  
        } : config.content.type === 'reel' ? {
          reel: config.content.reel && config.content.reel.asset ? {
            asset_id: config.content.reel.asset.id
          } : null
        } : config.content.type === 'story' ? {
          story_type: config.content.story_type,
          ...config.content.story_type === 'photo' && config.content.story_photo && config.content.story_photo.asset ? {
            story_photo: {
              asset_id: config.content.story_photo.asset_id,
              transforms: config.content.story_photo.transforms  
            }
          } : config.content.story_type === 'video' && config.content.story_video && config.content.story_video.asset ? {
            story_video: {
              asset_id: config.content.story_video.asset.id
            }
          } : {}
        } : config.content.type === 'video' ? {
          video: config.content.video && config.content.video.asset ? {
            asset_id: config.content.video.asset.id
          } : null
        } : {},
        text: config.content.text,
        type: config.content.type,
        add_location: config.content.add_location || false,
        location: config.content.location || null,
        add_first_comment: config.content.add_first_comment || false,
        first_comment: config.content.first_comment || null
      }
    }
  }

  _getSocialDesigner() {
    const { editable, title, version, versions } = this.props
    const { cacheKey, config } = this.state
    return {
      cacheKey,
      config,
      editable,
      title,
      version,
      versions,
      onChange: this._handleUpdate,
      onSave: this._handleSave
    }
  }

  _handleChange() {
    const entity = this._getNormalized(this.state.config)
    this.props.onChange({ entity })
  }

  _handleSave() {
    const entity = this._getNormalized(this.state.config)
    this.context.versions.save({ entity }, true)
  }

  _handleSet(cacheKey, config) {
    this.setState({ cacheKey, config })
  }

  _handleUpdate(config, save = false) {
    this.setState({ 
      config 
    }, ...save ? [this._handleSave] : [])
  }

}

export default SocialDesignerContainer
