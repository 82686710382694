import PropTypes from 'prop-types'
import React from 'react'

const FormToken = ({ form }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-label">
      <div className="token-link">
        { form.title }        
      </div>
      { form.agency ?
        <div className="token-stats">
          <span>Agency: { form.agency.title }</span>
        </div> :
        form.team ?
          <div className="token-stats">
            <span>Team: { form.team.title }</span>
          </div> :
          <div className="token-stats">
            <span>Provider: Truevail</span>
          </div>    
      }
    </div>
  </div>
)

FormToken.propTypes = {
  form: PropTypes.object
}

export default FormToken
