import LogoStrategyToken from './logo_strategy'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          disabled: true,
          handler: this._handleBack 
        },
        { 
          label: ['brand','design'].includes(config.logo_strategy) ? 't(Next)' : 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Logo)',
              instructions: 't(Your logo will be used across various services for consistent branding)',
              type: 'segment',
              fields: [
                { 
                  name: 'logo_strategy',
                  type: 'radiogroup',
                  options: ['upload','design','brand','text'],
                  format: (props) => (
                    <LogoStrategyToken { ...props } pricing={ formdata.pricing } />
                  ),
                  defaultValue: config.logo_strategy || 'upload' 
                },
                { 
                  name: 'logo_image_id',
                  show: config.logo_strategy === 'upload',
                  type: 'attachmentfield',
                  prompt: 't(Upload Logo)',
                  allow: { content_types: ['images'] },
                  multiple: false,
                  required: true,
                  defaultValue: config.logo_image_id 
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext, onSave } = this.props
    const action = ['brand','design'].includes(config.logo_strategy) ? onNext : onSave
    action({
      ...formdata,
      ...config
    })
  }

}

export default Details
