import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import Campaign from './campaign'
import pluralize from 'pluralize'
import React from 'react'

class Assigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    onMove: PropTypes.func,
    onRemove: PropTypes.func
  }

  render() {
    const { assigned } = this.props
    return (
      <div className="truevail-social-batch-campaigns-assigned">
        { assigned.length > 0 ?
          <div className="truevail-social-batch-campaigns-assigned-campaigns">
            <div className="truevail-social-batch-campaigns-assigned-campaigns-body">
              { assigned.map((social_campaign, index) => (
                <Campaign { ...this._getCampaign(social_campaign, index) } key={`assigned_${social_campaign.id}`} />
              ))}
            </div>
            <div className="truevail-social-batch-campaigns-assigned-campaigns-footer">
              { pluralize('assigned campaign', assigned.length, true) }
            </div>
          </div> : 
          <Message { ...this._getEmpty() } />
        }
      </div>
    )
  }

  _getEmpty() {
    return {
      svg: 'message_square',
      title: 't(Social Campaigns)',
      text: 't(You have not yet assigned any social campaigns to this batch)'
    }
  }

  _getCampaign(social_campaign, index) {
    const { onMove, onRemove } = this.props
    return {
      social_campaign,
      index,
      onMove,
      onRemove
    }
  }

}

export default Assigned
