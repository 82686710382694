import PropTypes from 'prop-types'
import Pasteur from 'pasteur'
import React from 'react'

class Canvas extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object
  }

  canvasRef = React.createRef()

  _handleRender = this._handleRender.bind(this)

  render() {
    return (
      <div className="maha-social-designer-canvas">
        <iframe { ...this._getIFrame() } />
      </div>
    )
  }

  componentDidMount() {
    this.pasteur = new Pasteur({
      window,
      target: this.canvasRef.current.contentWindow,
      name: 'mDesigner',
      targetName: 'mRenderer'
    })
    this.pasteur.on('ready', this._handleRender)
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props
    if(!_.isEqual(config, prevProps.config)) {
      this._handleRender()
    }
  }

  componentWillUnmount() {
    this.pasteur.close()
  }

  _getIFrame() {
    return {
      ref: this.canvasRef,
      src: '/renderers/mjson/social'
    }
  }

  _handleRender() {
    const { provider } = this.context
    const { config } = this.props
    this.pasteur.send('update', {
      domain: provider,
      editable: true,
      config: {
        entity: config
      }
    })
  }

}

export default Canvas
