import New from '@apps/platform/admin/views/domains/new'
import DomainToken from '@apps/crm/admin/tokens/domain'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Domains)',
  collection: {
    endpoint: '/api/admin/platform/domains',
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: DomainToken },
      { label: 't(Nameserver)', key: 'nameserver_status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'globe',
      title: 't(No Domains)',
      text: 't(You have not yet created any domains for this domain)',
      buttons: [
        { label: 't(Create Domain)', modal: New }
      ]
    },
    entity: 'domain',
    onClick: (record) => context.router.push(`/platform/domains/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Delete Domain)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this domain?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/platform/domains/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete domain)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted domain)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Domain)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
