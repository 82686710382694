import { CSSTransition } from 'react-transition-group'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

export const FlashContext = React.createContext()
FlashContext.displayName = 'FlashContext'

export const useFlashContext = () => React.useContext(FlashContext)

class Flash extends React.Component{

  static childContextTypes = {
    flash: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  state = {
    show: false,
    message: null,
    style: null
  }

  _handleClear = this._handleClear.bind(this)
  _handleSet = this._handleSet.bind(this)

  render() {
    const { message, show, style } = this.state
    return (
      <FlashContext.Provider value={ this.getChildContext() }>
        { this.props.children }
        <CSSTransition key="maha-flash" in={ show } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className={`maha-flash-popup ${style || ''}`}>
            <div className="maha-flash-popup-panel">
              <div className="maha-flash-popup-icon">
                <Icon icon={  this._getIcon(style) } />
              </div>
              <div className="maha-flash-popup-message">
                <p><T text={ message } /></p>
              </div>
              <Button { ...this._getClear() } />
            </div>
          </div>
        </CSSTransition>
      </FlashContext.Provider>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { show } = this.state
    if(prevState.show !== show && show) {
      setTimeout(this._handleClear, 2000)
    }
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-flash-popup-clear',
      handler: this._handleClear
    }
  }

  _getIcon(style) {
    if(style == 'success') return 'check-circle'
    if(style == 'warning') return 'warning'
    if(style == 'error') return 'times-circle'
    if(style == 'info') return 'info-circle'
  }

  getChildContext() {
    return {
      flash: {
        clear: this._handleClear,
        set: this._handleSet
      }
    }
  }

  _handleClear() {
    this.setState({
      show: false
    })
    setTimeout(() => this._handleSet(null, null, false), 250)
  }

  _handleSet(style, message, show = true) {
    this.setState({
      style,
      message,
      show
    })
  }

}

export default Flash
