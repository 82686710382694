import New from '@apps/finance/admin/views/scholarships/new'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Scholarships)',
  collection: {
    endpoint: '/api/admin/finance/scholarships',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (scholarship) => <ProgramToken { ...scholarship.program } /> },
      { label: 't(Balance)', key: 'balance',  primary: true, align: 'right', format: (scholarship) => (
        <>{scholarship.balance} / {scholarship.amount}</>
      ) }
    ],
    empty: {
      title: 't(No Scholarships)',
      text: 't(There are no scholarships for this contact)',
      svg: 'dollar_sign',
      buttons: [{
        label: 't(Create Scholarship)',
        modal: <New contact={ props.contact } />
      }]
    },
    entity: 'payments',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/scholarships/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <New contact={ props.contact } />
  }
})

export default Panel(null, mapPropsToPanel)
