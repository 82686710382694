import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Password extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  state = {
    password: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Change Password)',
      method: 'PATCH',
      action: '/api/admin/account/security/password',
      cancelSvg: 'chevron_left',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'New Password', name: 'password', type: 'passwordfield', placeholder: 'New Password', required: true },
            { label: 'Confirm Password', name: 'confirmation', type: 'passwordfield', placeholder: 'Confirm Password', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleSuccess(user) {
    this.context.modal.pop()
  }

}

export default Password
