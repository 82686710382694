import { Activity, AppWindow, ArrowBigRight, ArrowRightLeft } from 'lucide-react'
import { BadgePlus, Ban, Bell, BookMinus, BookOpen, BookPlus } from 'lucide-react'
import { Calendar, CalendarDays, CaseSensitive, CaseUpper, ChartLine, ChartNetwork, Check, CheckSquare, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Circle, CircleAlert, CircleCheck, CircleDollarSign, CircleDot, CircleHelp, CirclePause, CirclePlus, CirclePower, CircleX, Clock, CloudDownload, CloudUpload, ClipboardList, Code, Columns2, Columns3, Columns4, Cog, Contact, Copy } from 'lucide-react'
import { Dashboard, Database, Download, DollarSign } from 'lucide-react'
import { Ellipsis, EllipsisVertical, Eye, EyeClosed } from 'lucide-react'
import { Facebook, FileStack, FileText, Files, Filter, Flag, Folder, FolderPen } from 'lucide-react'
import { Ghost, Globe, Grip } from 'lucide-react'
import { Hammer, HandCoins, HardDrive, HardDriveDownload, HardDriveUpload, Hash, HeartHandshake } from 'lucide-react'
import { IdCard, Image, Images, Info } from 'lucide-react'
import { LayoutDashboard, LayoutGrid, LifeBuoy, Link, List, Lock, LockOpen } from 'lucide-react'
import { Mail, MailOpen, Map, MapPin, MapPinHouse, Megaphone, Merge, MessageSquare, MessageSquarePlus, MessagesSquare, Minimize2, Minus, MonitorPlay, MonitorSmartphone, Moon, MousePointer, Move, MoveLeft } from 'lucide-react'
import { Network, Newspaper, NotebookTabs } from 'lucide-react'
import { PencilRuler, Presentation } from 'lucide-react'
import { Palette, Pencil, Phone, Plane, Plus, Power, Puzzle } from 'lucide-react'
import { Quote } from 'lucide-react'
import { Repeat, RefreshCcw, RefreshCw, RotateCw, RotateCcw, Rss } from 'lucide-react'
import { ScanFace, Search, Settings, Shapes, Share2, Shield, ShoppingCart, Signature, Smartphone, Smile, Square,SquareArrowLeft, SquareCheck, SquareMinus, SquareMenu, SquarePen, SquarePlay, SquarePlus, Stamp, Star, Sun } from 'lucide-react'
import { TableProperties, Tag, Tags, ThumbsDown, ThumbsUp, Trash2, TriangleAlert } from 'lucide-react'
import { Undo2, Unlink, Unplug, UserCircle2, UserRound, UsersRound } from 'lucide-react'
import { WandSparkles, Workflow } from 'lucide-react'
import { X } from 'lucide-react'

export default {
  activity: Activity,
  app_window: AppWindow,
  arrow_big_right: ArrowBigRight,
  arrow_right_left: ArrowRightLeft,
  badge_plus: BadgePlus,
  ban: Ban,
  bell: Bell,
  book_minus: BookMinus,
  book_open: BookOpen,
  book_plus: BookPlus,
  calendar: Calendar,
  calendar_days: CalendarDays,
  case_sensitive: CaseSensitive,
  case_upper: CaseUpper,
  chart_line: ChartLine,
  chart_network: ChartNetwork,
  check: Check,
  check_square: CheckSquare,
  chevron_down: ChevronDown,
  chevron_left: ChevronLeft,
  chevron_right: ChevronRight,
  chevron_up: ChevronUp,
  circle: Circle,
  circle_check: CircleCheck,
  circle_dollar_sign: CircleDollarSign,
  circle_dot: CircleDot,
  circle_help: CircleHelp,
  circle_info: CircleAlert,
  circle_pause: CirclePause,
  circle_plus: CirclePlus,
  circle_power: CirclePower,
  circle_x: CircleX,
  clipboard_list: ClipboardList,
  clock: Clock,
  cloud_download: CloudDownload,
  cloud_upload: CloudUpload,
  code: Code,
  columns_2: Columns2,
  columns_3: Columns3,
  columns_4: Columns4,
  cog: Cog,
  contact: Contact,
  copy: Copy,
  dashboard: Dashboard,
  database: Database,
  dollar_sign: DollarSign,
  download: Download,
  ellipsis: Ellipsis,
  ellipsis_vertical: EllipsisVertical,
  eye_closed: EyeClosed,
  eye: Eye,
  facebook: Facebook,
  file_stack: FileStack,
  file_text: FileText,
  files: Files,
  filter: Filter,
  flag: Flag,
  folder: Folder,
  folder_pen: FolderPen,
  ghost: Ghost,
  globe: Globe,
  grip: Grip,
  hammer: Hammer,
  hand_coins: HandCoins,
  hard_drive: HardDrive,
  hard_drive_download: HardDriveDownload,
  hard_drive_upload: HardDriveUpload,
  hash: Hash,
  heart_handshake: HeartHandshake,
  id_card: IdCard,
  image: Image,
  images: Images,
  info: Info,
  layout_dashboard: LayoutDashboard,
  layout_grid: LayoutGrid,
  life_buoy: LifeBuoy,
  link: Link,
  list: List,
  lock: Lock,
  lock_open: LockOpen,
  map_pin_house: MapPinHouse,
  map: Map,
  map_pin: MapPin,
  mail: Mail,
  mail_open: MailOpen,
  megaphone: Megaphone,
  merge: Merge,
  message_square: MessageSquare,
  message_square_plus: MessageSquarePlus,
  messages_square: MessagesSquare,
  minimize_2: Minimize2,
  minus: Minus,
  monitor_play: MonitorPlay,
  monitor_smartphone: MonitorSmartphone,
  moon: Moon,
  mouse_pointer: MousePointer,  
  move: Move,
  move_left: MoveLeft,
  network: Network,
  newspaper: Newspaper,
  notebook_tabs: NotebookTabs,
  palette: Palette,
  pencil: Pencil,
  pencil_ruler: PencilRuler,
  phone: Phone,
  plane: Plane,
  plus: Plus,
  power: Power,
  presentation: Presentation,
  puzzle: Puzzle,
  quote: Quote,
  refresh_cw: RefreshCw,
  refresh_ccw: RefreshCcw,
  repeat: Repeat,
  rotate_cw: RotateCw,
  rotate_ccw: RotateCcw,
  rss: Rss,
  scan_face: ScanFace,
  search: Search,
  settings: Settings,
  share2: Share2,
  shapes: Shapes,
  shield: Shield,
  shopping_cart: ShoppingCart,
  signature: Signature,
  smartphone: Smartphone,
  smile: Smile,
  square: Square,
  square_arrow_left: SquareArrowLeft,
  square_check: SquareCheck,
  square_menu: SquareMenu,
  square_minus: SquareMinus,
  square_pen: SquarePen,
  sqaure_play: SquarePlay,
  square_plus: SquarePlus,
  stamp: Stamp,
  star: Star,
  sun: Sun,
  table_properties: TableProperties,
  tag: Tag,
  tags: Tags,
  thumbs_down: ThumbsDown, 
  thumbs_up: ThumbsUp, 
  trash: Trash2,
  triangle_alert: TriangleAlert,
  undo2: Undo2,
  unlink: Unlink,
  unplug: Unplug,
  user_round: UserRound,
  user_circle_2: UserCircle2,
  users_round: UsersRound,
  wand_sparkles: WandSparkles,
  workflow: Workflow,
  x: X
}