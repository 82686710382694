import VoiceToken from '@apps/truevail/admin/tokens/voice'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Transition from '../transition'
import PropTypes from 'prop-types'
import Change from '../change'
import Revert from '../revert'
import Edit from '../edit'
import moment from 'moment'
import React from 'react'

const Overview = ({ advisor }) => {

  const agency = {
    className: 'link',
    label: advisor.agency.team.title,
    route: `/admin/truevail/admin/agencies/${advisor.agency.id}`
  }

  const primary_user = {
    className: 'link',
    label: advisor.team.primary_user.provider_contact.display_name,
    route: `/admin/crm/contacts/${advisor.team.primary_user.provider_contact.id}`
  }

  const details = {
    audits: `truevail_advisors/${advisor.id}`,
    comments: `truevail_advisors/${advisor.id}`,
    sections: [
      {
        title: 't(Advisor Details)',
        items: [
          { label: 't(Name)', content: advisor.name },
          { label: 't(Organization)', content: advisor.organization },
          { label: 't(Public Domain)', content: advisor.team.public_domain },
          { label: 't(Agency)', content: <Button { ...agency } /> },
          { label: 't(Voice)', content: <VoiceToken perspective={ advisor.perspective } language={ advisor.language } /> },
          { label: 't(Timezone)', content: advisor.team.timezone.zone },
          { label: 't(Primary User)', content: <Button { ...primary_user } /> }
        ]
      }
    ]
  }

  const unschedule = {
    label: 't(Unschedule Transition)',
    className: 'link',
    confirm: 't(Are you sure you want to unschedule this transition?)',
    request: {
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/transition`,
      method: 'PATCH',
      body: {
        action: 'unschedule'
      }
    }
  }

  if(advisor.team.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This advisor is pending configuration)' }
  } else if(advisor.team.deliverability_status === 'suspended') {
    details.alert = { color: 'red', message: 't(This advisor\'s ability to send email is suspended)' }
  } else if(advisor.team.status === 'active') {
    details.alert = { color: 'green', message: 't(This advisor is active)' }
  } else if(advisor.team.status === 'configured') {
    details.alert = { color: 'blue', message: 't(This advisor has configured their account)' }
  } else if(advisor.team.status === 'activating') {
    details.alert = { color: 'teal', message: (
      <>This advisor will be activated on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'pausing') {
    details.alert = { color: 'teal', message: (
      <>This advisor will be paused on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'reactivating') {
    details.alert = { color: 'teal', message: (
      <>This advisor will reactivate on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'resuming') {
    details.alert = { color: 'teal', message: (
      <>This advisor will resume on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'canceling') {
    details.alert = { color: 'teal', message: (
      <>This advisor will cancel on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'unconfigured') {
    details.alert = { color: 'grey', message: 't(This advisor did not complete their configuration within 30 days)' }
  } else if(advisor.team.status === 'paused') {
    details.alert = { color: 'yellow', message: 't(This advisor is paused)' }
  } else if(advisor.team.status === 'inactive') {
    details.alert = { color: 'grey', message: 't(This advisor is inactive)' }
  } else if(advisor.team.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This advisor has been canceled)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } />,
  tasks: {
    items: [
      {
        label: 't(Edit Advisor)',
        svg: 'square_pen',
        modal: <Edit advisor={ props.advisor } />
      },
      {
        label: 't(Change Agency)',
        svg: 'arrow_right_left',
        modal: <Change advisor={ props.advisor } />
      },
      {
        label: 't(Activate Advisor)',
        svg: 'check',
        show: props.advisor.team.status === 'configured',
        modal: <Transition advisor={ props.advisor } action="activate" />
      },
      {
        label: 't(Pause Advisor)',
        svg: 'circle_pause',
        show: props.advisor.team.status === 'active',
        modal: <Transition advisor={ props.advisor } action="pause" />
      },
      {
        label: 't(Cancel Advisor)',
        svg: 'ban',
        show: ['pending','configured','unconfigured','paused','active'].includes(props.advisor.team.status),
        modal: <Transition advisor={ props.advisor } action="cancel" />
      },
      {
        label: 't(Resume Advisor)',
        svg: 'circle_pause',
        show: props.advisor.team.status === 'paused',
        modal: <Transition advisor={ props.advisor } action="resume" />
      },
      {
        label: 't(Reactivate Advisor)',
        svg: 'check',
        show: props.advisor.team.status === 'canceled',
        modal: <Transition advisor={ props.advisor } action="reactivate" />
      },
      {
        label: 't(Revert to Pending)',
        svg: 'move_left',
        show: props.advisor.team.status === 'inactive',
        modal: <Revert advisor={ props.advisor } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
