import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleUpdate,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { 
              label: 't(Landing Page)', 
              instructions: 't(Landing pages let you customize the look and feel of your form. If you dont add a landing page, you can still offer a basic view of the form.)',        
              type: 'segment', 
              fields: [
                { 
                  name: 'landing_page.strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'none', text: 't(I dont need a landing page)' },
                    { value: 'create', text: 't(Create a new landing page)' }
                  ], defaultValue: 'none'
                },
                { 
                  label: 't(Theme)', 
                  name: 'landing_page.theme_id', 
                  type: 'dropdown', 
                  endpoint: '/api/admin/team/themes', 
                  valueKey: 'id', 
                  textKey: 'title', 
                  required: true,
                  selectFirst: true,
                  show: config.landing_page?.strategy === 'create'
                },
                { 
                  label: 't(Title)', 
                  name: 'landing_page.title', 
                  type: 'textfield', 
                  required: true, 
                  show: config.landing_page?.strategy === 'create',
                  defaultValue: 'Landing Page'
                },
                { 
                  label: 't(URL)', 
                  name: 'landing_page.permalink', 
                  type: 'permalinkfield', 
                  prefix: '/pages', 
                  placeholder: '/path/to/landingpage', 
                  show: config.landing_page?.strategy === 'create'
                }  
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(store) {
    this.props.onNext(store)
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Details
