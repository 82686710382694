import Attachments from '@admin/components/attachments'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import { getCode } from '@core/utils/codes'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Root from './root'
import React from 'react'
import List from './list'
import Grid from './grid'

class Folder extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.bool,
    folder: PropTypes.object,
    info: PropTypes.bool,
    preview: PropTypes.object,
    records: PropTypes.array,
    selected: PropTypes.array,
    view: PropTypes.string,
    onAddSelected: PropTypes.func,
    onClearSelected: PropTypes.func,
    onChangeFolder: PropTypes.func,
    onCreateFile: PropTypes.func,
    onMove: PropTypes.func,
    onDrive: PropTypes.func,
    onPreview: PropTypes.func,
    onReplaceSelected: PropTypes.func,
    onShowDetails: PropTypes.func,
    onStarred: PropTypes.func,
    onTasks: PropTypes.func,
    onTrash: PropTypes.func,
    onUp: PropTypes.func,
    onUpdateFile: PropTypes.func
  }

  state = {
    cacheKey: null,
    sort: {
      key: 'label',
      order: 'asc'
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handlePreview = this._handlePreview.bind(this)
  _handleRefreshFolder = this._handleRefreshFolder.bind(this)
  _handleShowDetails = this._handleShowDetails.bind(this)
  _handleTasks = this._handleTasks.bind(this)
  _handleUp = this._handleUp.bind(this)

  render() {
    const { folder, view } = this.props
    const { sort } = this.state
    return (
      <div { ...this._getContainer() }>
        <div className="drive-header">
          <div className="drive-header-breadcrumb" onClick={ this._handleUp }>
            <div className="drive-header-back">
              { folder.code === 'root' ?
                <Icon icon="home" /> :
                <Icon icon="chevron-left" />
              }
            </div>
            <div className="drive-header-label">
              { folder.label }
            </div>
          </div>
          { folder.code !== 'root' ?
            <div className="drive-header-icon" onClick={ this._handleTasks }>
              <Icon svg="ellipsis_vertical" />
            </div> : <div className="drive-header-icon" />
          }
        </div>
        <div className="drive-results" { ...this._getResults() }>
          { view === 'list' && folder.code !== 'root' &&
            <div className="drive-results-header">
              <div className="drive-results-header-item drive-name" onClick={ this._handleSort.bind(this, 'label') }>
                Name
                { sort.key === 'label' &&
                  <Icon icon={`chevron-${sort.order === 'asc' ? 'up' : 'down'}`} />
                }
              </div>
              <div className="drive-results-header-item drive-owner" onClick={ this._handleSort.bind(this, 'owned_by') }>
                Owner
                { sort.key === 'owned_by' &&
                  <Icon icon={`chevron-${sort.order === 'asc' ? 'up' : 'down'}`} />
                }
              </div>
              <div className="drive-results-header-item drive-updated" onClick={ this._handleSort.bind(this, 'updated_at') }>
                Updated
                { sort.key === 'updated_at' &&
                  <Icon icon={`chevron-${sort.order === 'asc' ? 'up' : 'down'}`} />
                }
              </div>
              <div className="drive-results-header-item drive-size" onClick={ this._handleSort.bind(this, 'file_size') }>
                Size
                { sort.key === 'file_size' &&
                  <Icon icon={`chevron-${sort.order === 'asc' ? 'up' : 'down'}`} />
                }
              </div>
              <div className="drive-results-header-item drive-action" />
              <div className="drive-results-header-item drive-action" />
            </div>
          }
          <div className="drive-results-body">
            { folder.code === 'root' ?
              <Root { ...this._getRoot() } /> :
              <Infinite { ...this._getInfinite() } />
            }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleFocus()
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props
    if(active !== prevProps.active && active) {
      this._handleFocus()
    }
  }

  _getAttachments() {
    return {
      prompt: 'Upload File(s)',
      networks: ['device','web','google','dropbox','box','microsoft'],
      onChooseAssets: this._handleCreate
    }
  }

  _getContainer() {
    const special = ['drive','starred','trash']
    const { folder } = this.props
    return {
      className: 'drive-folder',
      'data-code': _.includes(special, folder.code) ? null : folder.code
    }
  }

  _getEmptyFolder() {
    return  {
      icon: 'folder-open-o',
      title: 't(Empty Folder)',
      text: 't(There are no items in this folder)',
      button: {
        label: 'Upload File(s)',
        modal: <Attachments { ...this._getAttachments() } />
      }
    }
  }

  _getEmptyStarred() {
    return {
      icon: 'star',
      title: 't(No Starred Items)',
      text: 't(You have not starred any items)'
    }
  }

  _getEmptyTrash() {
    return {
      icon: 'trash',
      title: 't(Empty Trash)',
      text: 't(The trash is empty)'
    }
  }

  _getFolder(folder) {
    const { team } = this.context.admin
    const { cacheKey, sort } = this.state
    const { view } = this.props
    return {
      cacheKey,
      endpoint: `/api/admin/drive/items/folders/${folder.code}`,
      refresh: `/teams/${team.id}/admin/drive/folders/${folder.code}`,
      sort,
      empty: <Message { ...this._getEmptyFolder() } />,
      notFound: <Message { ...this._getEmptyFolder() } />,
      layout: view === 'list' ? List : Grid,
      props: this._getItems.bind(this)
    }
  }

  _getInfinite() {
    const { folder } = this.props
    if(folder.code === 'starred') return this._getStarred()
    if(folder.code === 'trash') return this._getTrash()
    return this._getFolder(folder)
  }

  _getItems() {
    const { folder, info, preview, selected, onAddSelected, onChangeFolder, onClearSelected, onCreateFile, onMove, onPreview, onReplaceSelected, onTasks, onUpdateFile } = this.props
    return {
      folder,
      info,
      preview,
      selected,
      onAddSelected,
      onChangeFolder,
      onClearSelected,
      onCreateFile,
      onMove,
      onPreview,
      onReplaceSelected,
      onTasks,
      onUpdateFile
    }
  }

  _getPath(code) {
    if(code === 'root') return '/admin/drive'
    if(code === 'drive') return '/admin/drive/items'
    if(code === 'starred') return '/admin/drive/starred'
    if(code === 'trash') return '/admin/drive/trash'
    return `/admin/drive/folders/${code}`
  }

  _getResults() {
    return {
      onClick: this._handlePreview,
      onContextMenu: this._handleTasks
    }
  }

  _getRoot() {
    const { onDrive, onStarred, onTrash } = this.props
    return {
      onDrive,
      onStarred,
      onTrash
    }
  }

  _getStarred() {
    const { cacheKey, sort } = this.state
    const { view } = this.props
    return {
      cacheKey,
      endpoint: '/api/admin/drive/starred',
      sort,
      empty: <Message { ...this._getEmptyStarred() } />,
      notFound: <Message { ...this._getEmptyStarred() } />,
      layout: view === 'list' ? List : Grid,
      props: this._getItems()
    }
  }

  _getTrash() {
    const { cacheKey, sort } = this.state
    const { view } = this.props
    return {
      cacheKey,
      endpoint: '/api/admin/drive/trash',
      sort,
      empty: <Message { ...this._getEmptyTrash() } />,
      notFound: <Message { ...this._getEmptyTrash() } />,
      layout: view === 'list' ? List : Grid,
      props: this._getItems()
    }
  }

  _getView() {
    const { view } = this.props
    return view === 'list' ? 'list' : 'th'
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleCreate(assets) {
    const { folder, onCreateFile } = this.props
    assets.map(asset => {
      onCreateFile(folder.item_id, asset.id)
    })
  }

  _handleFocus() {
    const { folder } = this.props
    const path = this._getPath(folder.code)
    this.context.router.replace(path)
  }

  _handlePreview() {
    const { folder, onPreview, onClearSelected } = this.props
    onClearSelected()
    onPreview(folder)
  }

  _handleRefreshFolder() {
    this.setState({
      cacheKey: getCode(6)
    })
  }

  _handleShowDetails(e) {
    e.stopPropagation()
    this.props.onShowDetails()
  }

  _handleSort(key) {
    const { sort } = this.state
    this.setState({
      sort: {
        key,
        order: key === sort.key && sort.order === 'asc' ? 'desc' : 'asc'
      }
    })
  }

  _handleTasks(e) {
    const { folder } = this.props
    e.stopPropagation()
    e.preventDefault()
    this.props.onTasks([folder], e)
  }

  _handleUp() {
    const { folder } = this.props
    if(folder.code === 'root') return
    this.props.onUp()
  }

}

export default Folder
