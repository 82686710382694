import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  link: 'link',
  photo: 'image',
  reel: 'sqaure_play',
  story: 'book_open',
  video: 'sqaure_play'
}

const SocialCampaignType = ({ social_campaign }) => (
  <div className="token-stats">
    <Icon svg={ types[social_campaign.type] }/>{ social_campaign.type.toUpperCase() }<br />
  </div>
)

SocialCampaignType.propTypes = {
  social_campaign: PropTypes.object
}

export default SocialCampaignType
