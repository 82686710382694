import Scrollbar from '@admin/components/scrollbar'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class VersionHistory extends React.Component {

  static contextTypes = {
    network: PropTypes.object,
    tasks: PropTypes.object,
    versions: PropTypes.object
  }

  static propTypes = {
    publish_rollback: PropTypes.bool,
    version: PropTypes.object,
    versions: PropTypes.array
  }

  static defaultProps = {
    publish_rollback: false
  }

  _handleClick = this._handleClick.bind(this)
  _handleTasks = this._handleTasks.bind(this)
  render() {
    const { versions } = this.props
    return (
      <Scrollbar className="maha-version-history">
        { versions.map((version, index) => (
          <div { ...this._getVersion(version, index) } key={`version_${index}`}>
            <div className="maha-version-history-active">
              <Icon svg={ this._getIcon(version.record.id) } />
            </div>
            <div className="maha-version-history-details">
              <div className="maha-version-history-title">
                { moment(version.record.updated_at).format('MMM DD, YYYY [@] h:mm A') }
              </div>
              { version.record.is_published &&
                <span className="maha-version-history-status">
                  <T text="Published Version" />
                </span>
              }
              { version.record.user &&
                <div className="maha-version-history-user">
                  { version.record.user.full_name }
                </div>              
              }
              <Button { ...this._getEdit(version, index) }/>
            </div>
          </div>
        ))}
      </Scrollbar>
    )
  }

  _getClass(id) {
    const { version } = this.props
    const classes = ['maha-version-history-version']
    if(id === version.record.id) classes.push('active')
    return classes.join(' ')
  }

  _getEdit(version, index) {
    return {
      svg: 'ellipsis_vertical',
      className: 'maha-version-history-tasks',
      tasks: this._getTasks(version, index)
    }
  }

  _getIcon(id) {
    const { version } = this.props
    return id === version.record.id ? 'circle_check' : 'circle'
  }

  _getTasks(version, index) {
    return [
      ...index === 0 && !version.record.is_published ? [
        { 
          label: 't(Publish Version)', 
          svg: 'check',
          handler: this._handlePublish.bind(this, version) 
        }
      ] : [
        {
          label: 't(Revert to Version)', 
          svg: 'undo2',
          handler: this._handleRollback.bind(this, version) 
        }
      ]
    ]
  }

  _getVersion(version, index) {
    return {
      className: this._getClass(version.record.id),
      onContextMenu: this._handleTasks.bind(this, version, index),
      onClick: this._handleClick.bind(this, version)
    }
  }

  _handleClick(version) {
    this.context.versions.setVersion(version.record.id)
  }

  _handlePublish(version) {
    this.context.versions.publish(version)
  }

  _handleRollback(version) {
    const { publish_rollback } = this.props
    this.context.versions.rollback(version, publish_rollback)
  }

  _handleTasks(version, index, e) {
    e.stopPropagation()
    e.preventDefault()
    const y = e.clientY
    const x = e.clientX
    const tasks = this._getTasks(version, index)
    this.context.tasks.open({
      position: {
        ...y < window.innerHeight - 200 ? {
          top: y
        } : {
          bottom: y
        },
        ...x > window.innerWidth - 200 ? {
          right: window.innerWidth - x
        } : {
          left: x
        }
      }, 
      items: tasks
    })
  }

}

export default VersionHistory
