import EmailReader from '@admin/components/email_reader'
import Panel from '@admin/components/panel'
import React from 'react'

const getEmailReader = (sender) => ({
  endpoint: '/api/admin/platform/incoming_emails',
  perspective: 'incoming',
  show_interactions: false
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Incoming Email)',
  component: <EmailReader { ...getEmailReader(props.sender) } />
})

export default Panel(null, mapPropsToPanel)
