import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Logo from '@admin/components/logo'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import React from 'react'

const format = (rate) => {
  return rate > 0 ? numeral(rate).format('0.00%') : '0%'
}

const getScreenshot = (table, entity) => ({
  engine: 'mjson',
  entity: `${table}/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const PublishingToken = ({ publishing }) => (
  <div className="campaign-token">
    { publishing.agency_email_campaign &&
      <div className="campaign-token-preview">
        <div className="campaign-token-preview-inner">
          <MJSONScreenshot { ...getScreenshot('truevail_email_campaign_variants', publishing.agency_email_campaign.variants[0]) } />
        </div>
      </div>
    }
    { publishing.agency_social_campaign &&
      <div className="campaign-token-preview">
        <div className="campaign-token-preview-inner">
          <MJSONScreenshot { ...getScreenshot('truevail_social_campaign_variants', publishing.agency_social_campaign.variants[0]) } />
        </div>
      </div>
    }
    { publishing.advisor_email_campaign &&
      <div className="campaign-token-preview">
        <div className="campaign-token-preview-inner">
          <MJSONScreenshot { ...getScreenshot('campaigns_email_campaigns', publishing.advisor_email_campaign) } />
        </div>
      </div>
    }
    { publishing.advisor_social_campaign &&
      <div className="campaign-token-preview">
        <div className="campaign-token-preview-inner">
          <MJSONScreenshot { ...getScreenshot('campaigns_social_campaign_variants', publishing.advisor_social_campaign.variants[0]) } />
        </div>
      </div>
    }
    { publishing.advisor_form &&
      <div className="team-token-logo">
        <Logo team={ publishing.advisor.team } />
      </div>
    }
    { publishing.advisor_workflow &&
      <div className="team-token-logo">
        <Logo team={ publishing.advisor.team } />
      </div>
    }
    { publishing.social_batch &&
      <div className="team-token-logo">
        <Logo team={ publishing.subscription.advisor.team } />
      </div>
    }
    <div className="campaign-token-label">
      <div className="token-link">
        { publishing.advisor_email_campaign &&
          <>{ publishing.advisor_email_campaign.title }</>
        }
        { publishing.advisor_social_campaign &&
          <>{ publishing.advisor_social_campaign.type.toUpperCase() }: { publishing.advisor_social_campaign.title }</>
        }
        { publishing.agency_email_campaign &&
          <>{ publishing.agency_email_campaign.title }</>
        }
        { publishing.agency_social_batch &&
          <>{ publishing.agency_social_batch.title }</>
        }
        { publishing.agency_social_campaign &&
          <>{ publishing.agency_social_campaign.type.toUpperCase() }: { publishing.agency_social_campaign.title }</>
        }
        { publishing.social_batch &&
          <>{ publishing.subscription.advisor.team.title }</>
        }
        { publishing.advisor_form &&
          <>{ publishing.advisor.team.title }</>
        }
        { publishing.advisor_workflow &&
          <>{ publishing.advisor.team.title }</>
        }
      </div>
      <div className="token-stats">
        { publishing.advisor_email_campaign &&
          <>
            { publishing.subscription.advisor.team.title }<br />
            <span className={ publishing.advisor_email_campaign.status }>{ publishing.advisor_email_campaign.status }</span>
            { _.includes(['scheduled','sent'], publishing.advisor_email_campaign.status) &&
              <span><Date datetime={ publishing.advisor_email_campaign.send_at } timezone={ publishing.subscription.advisor.team.timezone } /></span>
            }
            { _.includes(['sending','sent'], publishing.advisor_email_campaign.status) &&
              <>
                { publishing.advisor_email_campaign.sent === publishing.advisor_email_campaign.total ?
                  <span>{ publishing.advisor_email_campaign.sent } sent</span> :
                  <span>{ publishing.advisor_email_campaign.sent } / { publishing.advisor_email_campaign.total } sent ({ 
                    numeral(publishing.advisor_email_campaign.sent/publishing.advisor_email_campaign.total).format('0.0%') 
                  })</span>
                }
              </>
            }
            { publishing.advisor_email_campaign.status === 'sent' &&
              <>
                <span>{ format(publishing.advisor_email_campaign.open_rate) } open rate</span>
                <span>{ format(publishing.advisor_email_campaign.click_rate) } click rate</span>
                <span>{ format(publishing.advisor_email_campaign.bounce_rate) } bounce rate</span>
              </>
            }          
          </>
        }
        { publishing.advisor_social_campaign &&
          <>
            { publishing.subscription.advisor.team.title }<br />
            <span className={ publishing.advisor_social_campaign.status }>{ publishing.advisor_social_campaign.status }</span>
            { _.includes(['scheduled','failed'], publishing.advisor_social_campaign.status) &&
              <span><Date datetime={ publishing.advisor_social_campaign.post_at } timezone={ publishing.subscription.advisor.team.timezone } /></span>
            }
            { publishing.advisor_social_campaign.status === 'posted' &&
              <span><Date datetime={ publishing.advisor_social_campaign.posted_at } timezone={ publishing.subscription.advisor.team.timezone } /></span>
            }
          </>
        }
        { publishing.agency_email_campaign &&
          <>{ publishing.subscription.agency.team.title }<br /></>
        }
        { publishing.agency_social_batch &&
          <>{ publishing.subscription.agency.team.title }<br /></>
        }
        { publishing.agency_social_campaign &&
          <>{ publishing.subscription.agency.team.title }<br /></>
        }
        { publishing.social_batch &&
          <div className="token-stats">
            <span>{ pluralize('Social Campaign', publishing.social_campaigns_count, true) } </span>
          </div>
        }
        { publishing.advisor_form &&
          <>{ pluralize('response', publishing.advisor_form.responses_count, true) }</>
        }
        { publishing.advisor_workflow &&
          <>
            <span className={ publishing.advisor_workflow.status }>{ publishing.advisor_workflow.status }</span>
            <span>{ publishing.advisor_workflow.enrolled_count || 0 } enrolled</span>
            <span>{ publishing.advisor_workflow.active_count || 0 } active</span>
            <span>{ publishing.advisor_workflow.lost_count || 0 } lost</span>
            <span>{ publishing.advisor_workflow.converted_count || 0 } converted</span>
            <span>{ publishing.advisor_workflow.completed_count || 0 } completed</span>
          </>
        }
      </div>
    </div>
  </div>
)

PublishingToken.propTypes = {
  publishing: PropTypes.object
}

export default PublishingToken
