import Collection from '@admin/components/collection'
import { canAccess } from '@core/utils/access'
import Error from '@admin/components/error'
import Tabs from '@admin/components/tabs'
import PropTypes from 'prop-types'
import React from 'react'

class Panel extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    manager: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.object,
    cacheKey: PropTypes.string,
    collection: PropTypes.object,
    component: PropTypes.any,
    panel: PropTypes.any,
    props: PropTypes.object,
    rightItems: PropTypes.array,
    showHeader: PropTypes.bool,
    tabs: PropTypes.object,
    task: PropTypes.object,
    tasks: PropTypes.object,
    title: PropTypes.string
  }

  _handleMenu = this._handleMenu.bind(this)

  render() {
    const { access, component, collection, panel, tabs } = this.props
    if(!this._getAccess(access)) return null
    return (
      <Error { ...this._getError() }>
        { collection && <Collection { ...this._getCollection() } /> }
        { tabs && <Tabs { ...tabs } /> }
        { component ?
          <>
            { component }
          </> : panel &&
          <div className="maha-manager-container">
            <div className="maha-manager-panel">
              { panel }
            </div>
          </div>
        }
      </Error>
    )
  }

  componentDidMount() {
    this._handleSetRightItems()
  }

  componentWillUnmount() {
    this.context.manager.setRightItems(null)
  }

  _getAccess(access) {
    const { admin } = this.context
    return access ? canAccess(access, admin) : true
  }

  _getCollection() {
    const { cacheKey, collection } = this.props
    return {
      cacheKey,
      ...collection
    }
  }

  _getError() {
    return {}
  }

  _getTask() {
    const { admin } = this.context
    const { task } = this.props
    if(!task) return null
    if(task.show === false) return null
    if(!canAccess(task.access, admin)) return null
    return  {
      svg: 'plus',
      ...task
    }
  }

  _getTasks() {
    const { admin } = this.context
    const { tasks } = this.props
    if(!tasks) return null
    if(tasks.show === false) return null
    if(!canAccess(tasks.access, admin)) return null
    const items = tasks.items.filter(item => {
      return canAccess(item.access, admin)
    })
    if(items.length === 0) return null
    return {
      ...tasks.icon ? { 
        icon: tasks.icon 
      } : tasks.svg ? { 
        svg: tasks.svg 
      } : { 
        svg: 'ellipsis_vertical' 
      },
      ...tasks.access ? { access: tasks.access } : {},
      ...tasks.show ? { access: tasks.show } : {},
      tasks: tasks.items
    }
  }

  _handleMenu() {
    this.context.manager.toggle()
  }
  
  _handleSetRightItems() {
    const { manager } = this.context
    const { rightItems } = this.props
    const tasks = this._getTasks()
    const task = this._getTask()
    manager.setRightItems([
      ...task ? [task] : [],
      ...tasks ? [tasks] : [],
      ...rightItems ? rightItems : []
    ])
  }

}

export default Panel
