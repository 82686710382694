import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

const Overview = ({ agency }) => {

  const details = {
    sections: [
      {
        title: 't(Agency Details)',
        items: [
          { label: 't(Title)', content: agency.team.title },
          { label: 't(Perspectives)', content: agency.perspectives?.join(', ') || null },
          { label: 't(Languages)', content: agency.languages?.join(', ') || null },
          { label: 't(Admins)', content: agency.admins.length > 0 ? agency.admins.map((admin,index) => (
            <div key={`admin_${index}`}>{ admin.full_name }</div>
          )) : null }

        ]
      },
      {
        title: 't(Translation Restrictions)',
        items: [
          { label: 't(Phrases)', content: agency.config.translation_restrictions?.length > 0 ? agency.config.translation_restrictions.map((restriction,index) => (
            <div key={`restriction_${index}`}>"{ restriction.phrase }"</div>
          )) : null }
        ]
      },
      {
        title: 't(Preview Messages)',
        items: [
          { label: 't(Email)', content: (
            <div className="truevail-preview-message" dangerouslySetInnerHTML={{ __html: agency.config.email_preview_message }} />
          ) },
          { label: 't(Social Media)', content: (
            <div className="truevail-preview-message" dangerouslySetInnerHTML={{ __html: agency.config.social_preview_message }} />
          ) }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  agency: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agency={ props.agency } />,
  tasks: {
    items: [
      {
        label: 't(Edit Agency)',
        svg: 'square_pen',
        modal: <Edit agency={ props.agency } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
