import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { form, type, workflow } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'type', type: 'hidden', value: type },
            ...form ? [
              { name: 'form_id', type: 'hidden', value: form.id }
            ] : [],
            ...workflow ? [
              { name: 'workflow_id', type: 'hidden', value: workflow.id }
            ] : [],
            { name: 'type', type: 'hidden', value: type },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...['automation_email','email_introduction'].includes(type) ? [
              { label: 't(Email Details)', type: 'segment', fields: [
                { label: 't(Subject)', name: 'subject', type: 'textfield', required: true },
                { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' }
              ] }
            ] : [],
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'all', text: 't(All Perspectives)' },
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: 'all', required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'all', text: 't(All Languages)' },
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'all', required: true }
            ], required: true }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(template) {
    this.props.onNext(template)
  }

}

export default Details
