import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(DMARC Reports)',
  collection: {
    endpoint: `/api/admin/truevail/admin/deliverability/domains/${props.domain.id}/dmarc_reports`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Organization)', key: 'organization.name', sort: 'organization' },
      { label: 't(Dates)', key: 'start_date', primary: true, wrap: false, collapsing: true, format: (record) => (
        <div className="token">
          <Date date={ record.start_date } /> - <Date date={ record.end_date } />
        </div>
      ) }
    ],
    defaultSort: { key: 'start_date', order: 'desc' },
    empty: {
      icon: 'file',
      title: 't(No DMARC Reports)',
      text: 't(There are no DMARC reports for this domain)'
    },
    entity: 'DMARC report',
    onClick: (record) => context.router.push(`/admin/truevail/admin/dmarc_reports/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
