import TextField from '@admin/components/form/textfield'
import Dropdown from '@admin/components/form/dropdown'
import { DragSource, DropTarget } from 'react-dnd'
import Handle from '@admin/components/handle'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Stage extends React.Component {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    stage: PropTypes.object,
    tabIndex: PropTypes.number,
    onMove: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, stage } = this.props
    return connectDropTarget(connectDragPreview(
      <div className={`stagesfield-stage ${stage.entity}`}>
        { connectDragSource(
          <div className="stagesfield-stage-handle">
            <Handle />
          </div>
        ) }
        <div className="stagesfield-stage-label">
          <TextField { ...this._getTextField() } />
        </div>
        <div className="stagesfield-stage-probability">
          <Dropdown { ...this._getDropdown() } />
        </div>
        { stage.id ?
          <div className="stagesfield-stage-action" /> :
          <Button { ...this._getRemove() } />
        }
      </div>
    ))
  }

  _getDropdown() {
    const { stage } = this.props
    return {
      options: [
        { value: 10, text: '10%' },
        { value: 20, text: '20%' },
        { value: 30, text: '30%' },
        { value: 40, text: '40%' },
        { value: 50, text: '50%' },
        { value: 60, text: '60%' },
        { value: 70, text: '70%' },
        { value: 80, text: '80%' },
        { value: 90, text: '90%' }
      ],
      placeholder: 'Probability',
      value: stage.probability,
      onChange: this._handleUpdate.bind(this, 'probability')
    }
  }

  _getTextField() {
    const { stage, tabIndex } = this.props
    return {
      placeholder: 't(Enter Title)',
      tabIndex,
      type: 'textfield',
      value:  stage.title,
      onChange: this._handleUpdate.bind(this, 'title')
    }
  }

  _getRemove() {
    return {
      svg: 'x',
      className: 'stagesfield-stage-action',
      confirm: 't(Are you sure you want to remove this stage?)',
      handler: this.props.onRemove
    }
  }

  _handleUpdate(key, value) {
    this.props.onUpdate(key, value)
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const item = monitor.getItem()
    const dragIndex = item.index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Stage = DragSource('ITEM', source, sourceCollector)(Stage)
Stage = DropTarget('ITEM', target, targetCollector)(Stage)

export default Stage
