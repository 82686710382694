import AdminDeliverabilityEmailCampaign from './admin/deliverability/email_campaigns/show'
import AgencySocialBatchPublishing from './agency/social_batches/show/publishings/show'
import AgencyAdvisorSocialCampaign from './agency/advisors/show/social_campaigns/show'
import AdminAdvisorSocialCampaign from './admin/advisors/show/social_campaigns/show'
import AgencyAdvisorSubscription from './agency/advisors/show/subscriptions/show'
import AdminAdvisorSubscription from './admin/advisors/show/subscriptions/show'
import AdminAgencySubscription from './admin/agencies/show/subscriptions/show'
import AdminDomainSegment from './admin/deliverability/domain_segments/show'
import AdminVerification from './admin/deliverability/verifications/show'
import AdminDmarcReport from './admin/deliverability/dmarc_reports/show'
import AdminAdvisorBranding from './admin/advisors/show/branding/show'
import AgencySocialPerformance from './agency/social_performance/show'
import AdminParticipant from './admin/webinars/show/participants/show'
import AgencyEmailPerformance from './agency/email_performance/show'
import AdvisorSubscription from './advisor/show/subscriptions/show'
import AdminAestheticProfile from './admin/aesthetic_profiles/show'
import AgencySocialCampaign from './agency/social_campaigns/show'
import AgencyEmailCampaign from './agency/email_campaigns/show'
import AdminEmailCampaign from './admin/email_campaigns/show'
import AdminDomain from './admin/deliverability/domains/show'
import AgencySocialBatch from './agency/social_batches/show'
import AdminSentShow from './admin/deliverability/sent/show'
import AdminSenderDomain from './admin/sender_domains/show'
import AdminPricingModel from './admin/pricing_models/show'
import AdminSocialBatch from './admin/social_batches/show'
import AdminSocial from './admin/social_campaigns/show'
import AdminSignupPage from './admin/signup_pages/show'
import AgencyWorkflow from './agency/workflows/show'
import AgencyTemplate from './agency/templates/show'
import AgencyAdvisor from './agency/advisors/show'
import AdminTemplate from './admin/templates/show'
import AdminWorkflow from './admin/workflows/show'
import AdminWebinar from './admin/webinars/show'
import AdminAdvisor from './admin/advisors/show'
import AdminAgency from './admin/agencies/show'
import AdminNotice from './admin/notices/show'
import AdminDesign from './admin/designs/show'
import AgencyForm from './agency/forms/show'
import AdminForm from './admin/forms/show'
import AdminDemo from './admin/demos/show'
import AdminTld from './admin/tlds/show'
import Advisor from './advisor/show'
import Library from './library'
import Agency from './agency'
import Admin from './admin'

const routes = [
  { path: '/admin', component: Admin },
  { path: '/admin/:view', component: Admin },
  { path: '/admin/agencies/:id/:view', component: AdminAgency },
  { path: '/admin/agencies/:agency_id/subscriptions/:id/:view', component: AdminAgencySubscription },
  { path: '/admin/advisors/:advisor_id/branding/:view', component: AdminAdvisorBranding },
  { path: '/admin/advisors/:advisor_id/social_campaigns/:id/:view', component: AdminAdvisorSocialCampaign },
  { path: '/admin/advisors/:advisor_id/subscriptions/:id/:view', component: AdminAdvisorSubscription },
  { path: '/admin/advisors/:id/:view', component: AdminAdvisor },
  { path: '/admin/aesthetic_profiles/:id/:view', component: AdminAestheticProfile },
  { path: '/admin/deliverability/email_campaigns/:id/:view', component: AdminDeliverabilityEmailCampaign },
  { path: '/admin/designs/:id/:view', component: AdminDesign },
  { path: '/admin/dmarc_reports/:id/:view', component: AdminDmarcReport },
  { path: '/admin/demos/:id/:view', component: AdminDemo },
  { path: '/admin/domains/:id/:view', component: AdminDomain },
  { path: '/admin/domain_segments/:id/:view', component: AdminDomainSegment },
  { path: '/admin/email_campaigns/:id/:view', component: AdminEmailCampaign },
  { path: '/admin/forms/:id/:view', component: AdminForm },
  { path: '/admin/notices/:id/:view', component: AdminNotice },
  { path: '/admin/pricing_models/:id/:view', component: AdminPricingModel },
  { path: '/admin/sender_domains/:id/:view', component: AdminSenderDomain },
  { path: '/admin/sent/domain_segments/:domain_segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/domains/:domain_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/segments/:segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/signup_pages/:id/:view', component: AdminSignupPage },
  { path: '/admin/social_batches/:id/:view', component: AdminSocialBatch },
  { path: '/admin/social_campaigns/:id/:view', component: AdminSocial },
  { path: '/admin/templates/:id/:view', component: AdminTemplate },
  { path: '/admin/tlds/:id/:view', component: AdminTld },
  { path: '/admin/verifications/:id', component: AdminVerification },
  { path: '/admin/webinars/:webinar_id/participants/:id/:view', component: AdminParticipant },
  { path: '/admin/webinars/:id/:view', component: AdminWebinar },
  { path: '/admin/workflows/:id/:view', component: AdminWorkflow },
  { path: '/advisor', component: Advisor },
  { path: '/advisor/subscriptions/:id/:view', component: AdvisorSubscription },
  { path: '/advisor/:view', component: Advisor },
  { path: '/agency', component: Agency },
  { path: '/agency/:view', component: Agency },
  { path: '/agency/advisors/:advisor_id/social_campaigns/:id/:view', component: AgencyAdvisorSocialCampaign },
  { path: '/agency/advisors/:advisor_id/subscriptions/:id/:view', component: AgencyAdvisorSubscription },
  { path: '/agency/advisors/:id/:view', component: AgencyAdvisor },
  { path: '/agency/email_campaigns/:id/:view', component: AgencyEmailCampaign },
  { path: '/agency/email_performance/:id/:view', component: AgencyEmailPerformance },
  { path: '/agency/forms/:id/:view', component: AgencyForm },
  { path: '/agency/social_batches/:social_batch_id/publishings/:id/:view', component: AgencySocialBatchPublishing },
  { path: '/agency/social_batches/:id/:view', component: AgencySocialBatch },
  { path: '/agency/social_campaigns/:id/:view', component: AgencySocialCampaign },
  { path: '/agency/social_performance/:id/:view', component: AgencySocialPerformance },
  { path: '/agency/templates/:id/:view', component: AgencyTemplate },
  { path: '/agency/workflows/:id/:view', component: AgencyWorkflow },
  { path: '/library/:view', component: Library }
]

export default routes
