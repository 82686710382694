import PipelineToken from '@apps/sales/admin/tokens/pipeline'
import New from '@apps/sales/admin/views/deals/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Deals)',
  collection: {
    endpoint: '/api/admin/sales/deals',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Pipeline)', key: 'title', primary: true, format: (deal) => {
        return <PipelineToken { ...deal.pipeline } />
      } },
      { label: 't(Stage)', key: 'stage.title' }
    ],
    empty: {
      title: 't(No Deals)',
      text: 't(There are no deals for this contact)',
      svg: 'heart_handshake',
      buttons: [{
        label: 't(Create Deal)',
        modal: <New contact={ props.contact } />
      }]
    },
    entity: 'deal',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/sales/deals/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <New contact={ props.contact } />
  }
})

export default Panel(null, mapPropsToPanel)
