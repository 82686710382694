import SocialCampaignToken from '@apps/campaigns/admin/tokens/campaign/social'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/social_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'advisor.title', sort: 'advisor', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) },
      { label: 't(Preview)', key: 'publishing.preview_status', collapsing: true, align: 'center', format: (social_campaign) => {
        return social_campaign.publishing?.social_campaign_id  ? <StatusToken status={ social_campaign.publishing.preview_status } /> : null
      } }
    ],
    defaultSort: { key: 'post_at', order: 'desc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Social Campaigns)',
      text: 't(There are no published social campaigns for this advisor)'
    },
    entity: 'social campaign',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        show: ['draft','scheduled'].includes(record.status) && record.publishing?.social_campaign_id ,
        modal: <Preview publishing={ record.publishing } scope="admin" type="social" />
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${props.advisor.id}/social_campaigns/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
