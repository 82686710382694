import CompactRevenueTypeToken from '@apps/finance/admin/tokens/revenue_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Program Revenue)',
  access: { rights: { $oneOf: ['finance:access_revenue_reports'] } },
  collection: {
    endpoint: '/api/admin/dashboards/crm/program_revenue',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'customer.display_name', sort: 'customer', primary: true, format: ({ customer }) => {
        return <ContactToken { ...customer } />
      } },
      { label: 't(Description)', key: 'line_item.description', visible: true },
      { label: 't(Program)', key: 'program.title', sort: 'program' },
      { label: 't(Project)', key: 'project.title', sort: 'project', format: CompactProjectToken },
      { label: 't(Revenue Type)', key: 'revenue_type.title', sort: 'revenue_type', format: CompactRevenueTypeToken },
      { label: 't(Date)', key: 'payment.date', sort: 'date', format: 'date' },
      { label: 't(Amount)', key: 'amount', visible: true, primary: true, format: 'currency' },
      { label: 't(Fee)', key: 'fee', visible: true, format: 'currency' },
      { label: 't(Total)', key: 'total', visible: true, format: 'currency' }
    ],
    defaultSort: { key: 'date', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/payments/${record.payment.id}`),
    empty: {
      svg: 'dollar_sign',
      title: 't(No Results)',
      text: 't(There is no data for this report)'
    },
    entity: 'payment'
  }
})

export default Page(null, mapPropsToPage)
