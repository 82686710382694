import SocialCampaigns from './social_campaigns'
import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_batch.title,
  manager: {
    path: `/admin/truevail/agency/social_batches/${resources.social_batch.id}/publishings/${resources.publishing.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview publishing={ resources.publishing } social_batch={ resources.social_batch } />
      },
      {
        label: 't(Social Campaigns)',
        path: '/social_campaigns',
        panel: <SocialCampaigns publishing={ resources.publishing } social_batch={ resources.social_batch } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  publishing: `/api/admin/truevail/agency/social_batches/${props.params.social_batch_id}/publishings/${props.params.id}`,
  social_batch: `/api/admin/truevail/agency/social_batches/${props.params.social_batch_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
