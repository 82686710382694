import RecordValueToken from '@apps/domains/admin/tokens/record_value'
import RecordNameToken from '@apps/domains/admin/tokens/record_name'
import Panel from '@admin/components/panel'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Records)',
  collection: {
    endpoint: `/api/admin/domains/domains/${props.domain.id}/records`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Host)', key: 'name', collapsing: true, format: (record) => (
        <RecordNameToken record={ record } domain={ props.domain } />
      ), primary: true },
      { label: 't(Type)', key: 'type', collapsing: true, primary: true },
      { label: 't(Value)', key: 'values', sort: 'value', format: (record) => (
        <RecordValueToken record={ record } />
      ) },
      { label: 't(TTL)', key: 'ttl', collapsing: true }
    ],
    defaultSort: { key: 'fqdn', order: 'asc' },
    empty: {
      svg: 'database',
      title: 't(No Records)',
      text: 't(There are no records)',
      buttons: [
        { 
          label: 't(Create Record)', 
          access: { rights: { $oneOf: ['domains:manage_domains'] } },
          modal: <New domain={ props.domain } /> 
        }
      ]
    },
    entity: 'record',
    ...!props.domain.deleted_at ? {
      recordTasks: (record) => [
        { 
          label: 't(Edit Record)', 
          svg: 'square_pen',
          access: { rights: { $oneOf: ['domains:manage_domains'] } },
          modal: <Edit domain={ props.domain } record={ record } /> 
        },
        {
          label: 't(Delete Record)',
          svg: 'trash',
          access: { rights: { $oneOf: ['domains:manage_domains'] } },
          confirm: 't(Are you sure you want to delete this record?)',
          deletable: true,
          request: {
            endpoint: `/api/admin/domains/domains/${props.domain.id}/records/${record.id}`,
            method: 'DELETE',
            body: { record },
            onFailure: () => this.context.flash.set('error', 't(Unable to delete record)'),
            onSuccess: () => {}
          }
        }
      ]
    } : {}
  },
  ...!props.domain.deleted_at ? {
    task: {
      svg: 'plus',
      access: { rights: { $oneOf: ['domains:manage_domains'] } },
      modal: <New domain={ props.domain } />
    }
  } : {}
})

export default Panel(null, mapPropsToPanel)
