import SmsCampaignToken from '@apps/campaigns/admin/tokens/campaign/sms'
import New from '@apps/campaigns/admin/views/sms/new_outbound'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(SMS Campaigns)',
  collection: {
    endpoint: '/api/admin/campaigns/sms/outbound',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (sms_campaign) => (
        <SmsCampaignToken sms_campaign={ sms_campaign } compact={ true } />
      ) }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 'Sessions', key: 'sessions_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultQuery: { event_id: props.event.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'message_square',
      title: 't(No SMS Campaigns)',
      text: 't(You have not yet created any sms campaigns for this event)',
      buttons: props.event.deleted_at === null ? [
        { 
          label: 't(Create SMS Campaign)', 
          modal: <New event={ props.event } /> 
        }
      ] : null
    },
    entity: 'sms campaign',
    onClick: (record) => context.router.push(`/campaigns/sms/${record.id}`)
  },
  task: props.event.deleted_at === null ? {
    svg: 'plus',
    modal: <New event={ props.event } />
  } : null
})

export default Panel(null, mapPropsToPanel)
