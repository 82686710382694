import CriteriaBuilder from '@admin/components/criteria_builder'
import criteria from '@apps/crm/admin/views/contacts/criteria'
import TextField from '@admin/components/form/textfield'
import ModalPanel from '@admin/components/modal_panel'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'


class Branch extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.string
  }

  static propTypes = {
    branch: PropTypes.object,
    fields: PropTypes.array,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    branch: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    if(!this.state.branch) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="crm-workflow-branchesfield-form">
          <div className="crm-workflow-branchesfield-form-header">
            <TextField {...this._getTextField() } />
          </div>
          <div className="crm-workflow-branchesfield-form-body">
            <CriteriaBuilder {...this._getCriteriaBuilder() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this.setState({
      branch: {
        ...this._getDefault(),
        ...this.props.branch || {}
      }
    })
  }

  _getDefault() {
    return {
      code: getCode(10),
      name: '',
      criteria: null
    }
  }

  _getCriteriaBuilder() {
    const { branch } = this.state
    const { fields } = this.props
    return {
      defaultValue: branch.criteria,
      fields: criteria({
        sections: fields, 
        context: this.context
      }),
      standalone: false,
      onChange: this._handleChange.bind(this, 'criteria')
    }
  }

  _getPanel() {
    return {
      title: 't(Branch)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ],
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Save)', color: 'blue', handler: this._handleDone }
      ]
    }
  }

  _getTextField() {
    const { branch } = this.state
    return {
      defaultValue: branch.name,
      placeholder: 'Enter a name for this branch',
      onChange: this._handleChange.bind(this, 'name')
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(key, value) {
    const { branch } = this.state
    this.setState({
      branch: {
        ...branch,
        [key]: value
      }
    })
  }

  _handleDone() {
    const { branch } = this.state
    this.props.onDone(branch)
  }

}

export default Branch
