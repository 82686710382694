import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    workflow: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { workflow } = this.props
    return {
      title: 't(Edit Workflow)',
      method: 'PATCH',
      endpoint: `/api/admin/automation/workflows/${workflow.id}/edit`,
      action: `/api/admin/automation/workflows/${workflow.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...workflow.trigger.code === 'automation:enrollment_created' ? [
              { 
                label: 't(Title)', 
                name: 'title', 
                type: 'textfield', 
                required: true 
              },
              { 
                label: 't(Purpose)', 
                name: 'purpose', 
                type: 'radiogroup', 
                options: ['marketing','transactional'], 
                required: true, 
                format: PurposeToken, 
                defaultValue: 'marketing' 
              }
            ] : workflow.trigger.code === 'maha:team_status_transitioned' ? [
              { 
                label: 't(Truevail Entity Type)', 
                name: 'truevail_entity_type', 
                type: 'dropdown',
                options: [
                  { value: null, text: 't(Any)' },
                  { value: 'agency', text: 't(Agency)' },
                  { value: 'advisor', text: 't(Advisor)' },
                  { value: 'supplier', text: 't(Supplier)' }
                ],
                required: true,
                defaultValue: null
              },
              { 
                label: 't(From Status)', 
                name: 'from_team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled']
              },
              { 
                label: 't(To Status)', 
                name: 'to_team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled']
              }
            ] : workflow.trigger.code === 'maha:team_status_duration' ? [
              { 
                label: 't(Truevail Entity Type)', 
                name: 'truevail_entity_type', 
                type: 'dropdown',
                options: [
                  { value: null, text: 't(Any)' },
                  { value: 'agency', text: 't(Agency)' },
                  { value: 'advisor', text: 't(Advisor)' },
                  { value: 'supplier', text: 't(Supplier)' }
                ],
                required: true,
                defaultValue: null
              },
              { 
                label: 't(Status)', 
                name: 'team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled'],
                required: true 
              },
              { 
                label: 't(Duration)',
                name: 'duration', 
                type: 'durationfield',
                required: true 
              }
            ] : workflow.trigger.code === 'sales:deal_transitioned' ? [
              { 
                label: 't(From Stage)', 
                name: 'from_stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${workflow.pipeline.id}/stages`, 
                valueKey: 'id',
                textKey: 'title' 
              },
              { 
                label: 't(To Stage)', 
                name: 'to_stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${workflow.pipeline.id}/stages`, 
                valueKey: 'id', 
                textKey: 'title' 
              }
            ] : workflow.trigger.code === 'sales:deal_stage_duration' ? [
              { 
                label: 't(Stage)', 
                name: 'stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${workflow.pipeline.id}/stages`, 
                valueKey: 'id',
                textKey: 'title' 
              },
              { 
                label: 't(Duration)',
                name: 'duration', 
                type: 'durationfield',
                required: true 
              }
            ] : [],
            { 
              label: 't(Enrollment)', 
              name: 'is_unique', 
              type: 'radiogroup', 
              options: [
                { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
                { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
              ], 
              defaultValue: false, 
              required: true
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
