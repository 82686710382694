import MJSONScreenshot from '@admin/components/mjson_screenshot'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (template) => ({
  engine: 'mjson',
  entity: `truevail_template_variants/${template.variants[0].code}`,
  width: 100,
  height: 75,
  screenshoted_at: template.variants[0].screenshoted_at
})

const TemplateCard = ({ template }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <MJSONScreenshot { ...getScreenshot(template) } />
    </div>
    <div className="maha-card-layout-card-details">
      <div className="token-stats">
        <LibraryToken value={ template.library_status } />
      </div>
    </div>
  </div>
)

TemplateCard.propTypes = {
  template: PropTypes.object
}

export default TemplateCard
