import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Title from './title'
import Edit from './edit'
import React from 'react'

class Card extends React.Component {  

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    confirm: PropTypes.object,
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    card: PropTypes.object,
    daterange: PropTypes.object,
    filter: PropTypes.object,
    index: PropTypes.number,
    panel: PropTypes.object,
    isExpanded: PropTypes.bool,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool,
    onReorder: PropTypes.func,
    onToggle: PropTypes.func
  }

  _handleExpand = this._handleExpand.bind(this)

  render() {
    const { panel } = this.props
    const { admin } = this.context
    const Component = this._getComponent()
    return (
      <div className={ this._getClass() }>
        <div className="maha-dashboard-card-actions">
          <Button { ...this._getExpand() } />
          { (admin.user.type === 'support' || panel.owner.id === admin.user.id) &&
            <Button { ...this._getEdit() } />
          }
        </div>
        <Component { ...this._getCard() } />
      </div>
    )
  }

  _getCard() {
    const { card, isExpanded, filter, showChart, showTable } = this.props
    const daterange = card.config.daterange_source === 'custom' ? card.config.daterange : this.props.daterange
    return {
      config: card.config,
      daterange,
      key: JSON.stringify({
        ...card.config,
        daterange
      }),
      isExpanded,
      filter,
      showChart,
      showTable
    }
  }

  _getClass() {
    const { card } = this.props
    const classes = ['maha-dashboard-card', `card-${card.id}`]
    return classes.join(' ')
  }

  _getComponent() {
    const { card } = this.props
    const { maha } = this.context
    const found = _.find(maha.dashboardCards, {
      code: card.type.code
    })
    if(!found) return null
    return found.card
  }

  _getEdit() {
    const { card, panel } = this.props
    return {
      svg: 'ellipsis_vertical',
      className: 'maha-dashboard-card-action',
      tasks: [
        { 
          label: 't(Edit Card)',
          svg: 'square_pen',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: <Edit panel={ panel } card={ card } /> 
        },
        { 
          label: 't(Change Title)',
          svg: 'square_pen',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: <Title panel={ panel } card={ card } /> 
        },
        {
          label: 't(Duplicate Card)',
          svg: 'copy',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          request: {
            endpoint: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}/clone`,
            method: 'POST',
            onFailure: () => this.context.flash.set('error','t(Unable to duplicate this card)')
          }
        },
        {
          label: 't(Remove Card)',
          svg: 'trash',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          request: {
            endpoint: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}`,
            method: 'DELETE'
          }
        }
      ]
    }
  }

  _getExpand() {
    const { isExpanded } = this.props
    return {
      svg: isExpanded ? 'square_minus' : 'square_plus',
      className: 'maha-dashboard-card-action',
      handler: this.props.onToggle
    }
  }

  _handleExpand(e) {
    const { isExpanded } = this.props
    e.stopPropagation()
    if(!isExpanded) this.props.onToggle()
  }

}

export default Card
