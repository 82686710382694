import AssetThumbnail from '@admin/components/asset_thumbnail'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Review extends React.Component {

  static propTypes = {
    files: PropTypes.array,
    review: PropTypes.bool,
    onRemove: PropTypes.func,
    onToggleReview: PropTypes.func
  }

  _handleToggleReview = this._handleToggleReview.bind(this)

  render() {
    const { files, review } = this.props
    return (
      <div className={ this._getClass() }>
        <div className="maha-attachments-review-header" onClick={ this._handleToggleReview }>
          <div className="maha-attachments-review-header-label">
            { files.length } files selected
          </div>
          <div className="maha-attachments-review-header-icon">
            <Icon svg="chevron_up" />
          </div>
        </div>
        { review &&
          <div className="maha-attachments-review-body">
            { files.length > 0 ?
              <div className="maha-attachments-list-items">
                { files.map((file, index) => (
                  <div className="maha-attachments-list-item" key={`file_${index}`}>
                    <div className="maha-attachments-list-item-icon">
                      <AssetThumbnail { ...file } />
                      { file?.service?.source &&
                        <div className="maha-attachments-list-item-service">
                          <Img src={ `/images/services/${file.service.source}.png` } />
                        </div>                    
                      }
                    </div>
                    <div className="maha-attachments-list-item-name">
                      { file.name }
                    </div>
                    <Button { ...this._getRemove(index) } />
                  </div>
                )) }
              </div> :
              <Message { ...this._getEmpty() } />
            }
          </div>
        }
      </div>
    )
  }

  _getClass() {
    const { review } = this.props
    const classes = ['maha-attachments-review']
    if(review) classes.push('reviewing')
    return classes.join(' ')
  }

  _getEmpty() {
    return {
      icon: 'file-o',
      title: 't(No Files)',
      text: 't(You have not selected any files)'
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: 'maha-attachments-list-item-remove',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

  _handleToggleReview() {
    this.props.onToggleReview()
  }

}

export default Review
