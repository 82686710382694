import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Result from './result'
import Prompt from './prompt'
import React from 'react'

class Dalle extends React.Component {

  static propTypes = {
    onAdd: PropTypes.func,
    onBack: PropTypes.func
  }

  stackRef = React.createRef()

  state = {
    prompt: ''
  }

  _handleDone = this._handleDone.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePrompt = this._handlePrompt.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Prompt, this._getPrompt.bind(this))
  }

  _getPrompt() {
    const { onBack } = this.props
    return {
      onBack,
      onNext: this._handlePrompt
    }
  }

  _getResult(prompt) {
    return {
      prompt,
      onBack: this._handlePop,
      onDone: this._handleDone
    }
  }

  _getStack() {
    return {
      display_name: 'attachments_dalle',
      ref: this.stackRef
    }
  }

  _handleDone(url) {
    this.props.onAdd({
      id: url,
      create: {
        endpoint: '/api/admin/assets/url',
        body: { url }
      },
      source_id: 'dalle',
      name: 'generated.jpg',
      service: {
        source: 'dalle'
      },
      content_type: 'image/jpeg',
      thumbnail: url,
      status: 'pending'
    })
    this.props.onBack()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePrompt(prompt) {
    this._handlePush(Result, this._getResult.bind(this, prompt))
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Dalle
