import SocialCampaignCard from '@apps/truevail/admin/cards/library_social_campaign'
import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/library/social_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'advisor.title', sort: 'advisor', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ true } />
      ) }
    ],
    card: (social_campaign) => (
      <SocialCampaignCard social_campaign={ social_campaign } />
    ),
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Social Campaigns)',
      text: 't(There are no published social campaigns)'
    },
    entity: 'social campaign',
    onClick: (record) => context.router.push(`/admin/truevail/admin/social_campaigns/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
