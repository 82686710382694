import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class URLField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    service: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    placeholder: 'http://example.com',
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    value: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { disabled, service } = this.props
    const { value } = this.state
    return (
      <div className={ this._getClass() }>
        { service &&
          <div className={ this._getServiceClass() }>
            <Img src={`/images/services/${service}.png`} />
          </div>
        }
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
        </div>
        { value && value.length > 0 && !disabled &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-urlfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      autoComplete: 'off',
      disabled,
      id,
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      tabIndex,
      type: 'text',
      value,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _getServiceClass() {
    const { value } = this.state
    const classes = ['maha-urlfield-service']
    if(value.length === 0) classes.push('disabled')
    return classes.join(' ')
  }

  _handleBlured() {
    const { onBlur } = this.props
    const { value } = this.state
    this.setState({
      focused: false,
      value: value.length > 0 ? value.replace(/\/$/, '') : ''
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value.length > 0 ? value : null)
  }

  _handleClear() {
    this._handleSet('')
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleSet(value) {
    this.setState({ 
      value: value || ''
    })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

  _handleValidate() {
    const { value } = this.state
    const { required, onValid } = this.props
    if(!required && !value) return onValid(null)
    if(required && !value) return onValid(null, ['This field is required'])
    if(!/^(http|https|tel|mailto):/.test(value)) return onValid(null, ['URL must begin with http, https, tel, or mailto'])
    onValid(value)
  }

}

export default URLField
