import CompactRevenueTypeToken from '@apps/finance/admin/tokens/revenue_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import ProjectToken from '@apps/finance/admin/tokens/project'
import ProgramToken from '@apps/crm/admin/tokens/program'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Revenue Report)',
  access: { rights: { $oneOf: ['finance:access_revenue_reports'] } },
  collection: {
    endpoint: '/api/admin/finance/reports/revenue',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'customer.display_name', sort: 'customer', primary: true, format: ({ customer }) => {
        return <ContactToken { ...customer } />
      } },
      { label: 't(Description)', key: 'line_item.description', visible: true },
      { label: 't(Program)', key: 'program.title', sort: 'program' },
      { label: 't(Project)', key: 'project.title', sort: 'project', format: CompactProjectToken },
      { label: 't(Revenue Type)', key: 'revenue_type.title', sort: 'revenue_type', format: CompactRevenueTypeToken },
      { label: 't(Date)', key: 'payment.date', sort: 'date', format: 'date' },
      { label: 't(Amount)', key: 'amount', visible: true, primary: true, format: 'currency' },
      { label: 't(Fee)', key: 'fee', visible: true, format: 'currency' },
      { label: 't(Total)', key: 'total', visible: true, format: 'currency' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 'Contact', key: 'contact_id', type: 'select', endpoint: '/api/admin/crm/contacts', multiple: true, valueKey: 'id', textKey: 'full_name', format: ContactToken },
            { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', multiple: true, valueKey: 'id', textKey: 'title', format: ProgramToken },
            { name: 'Project', key: 'project_id', type: 'select', endpoint: '/api/admin/finance/projects', multiple: true, valueKey: 'id', textKey: 'display', format: ProjectToken },
            { name: 'Method', key: 'method', type: 'select', multiple: true, options: [
              { value: 'card', text: 't(Credit Card)' },
              { value: 'googlepay', text: 't(Google Pay)' },
              { value: 'applepay', text: 't(Apple Pay)' },
              { value: 'paypal', text: 't(PayPal)' },
              { value: 'check' ,text: 't(Check)' },
              { value: 'ebt', text: 't(Electronic Benefit Transfer (EBT))' },
              { value: 'cash', text: 't(Cash)' },
              { value: 'credit', text: 't(Customer Credit)' },
              { value: 'scholarship', text: 't(Scholarship)' }
            ] },
            { name: 'Card Type', key: 'card_type', type: 'select', multiple: true, options: [
              { value: 'visa', text: 't(Visa)' },
              { value: 'mastercard', text: 't(Master Card)' },
              { value: 'amex', text: 't(American Express)' },
              { value: 'discover', text: 't(Discover)' },
              { value: 'jcb', text: 't(JCB)' }
            ] },
            { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] }
          ]
        }
      ]
    },
    defaultSort: { key: 'date', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/payments/${record.payment.id}`),
    empty: {
      svg: 'dollar_sign',
      title: 't(No Results)',
      text: 't(There is no data for this report)'
    },
    entity: 'payment'
  }
})

export default Panel(null, mapPropsToPanel)
