import EmailActivities from '@admin/components/email_activities'
import Scrollbar from '@admin/components/scrollbar'
import Comments from '@admin/components/comments'
import Button from '@admin/components/button'
import Email from '@admin/components/email'
import PropTypes from 'prop-types'
import React from 'react'

class EmailViewer extends React.Component {

  static propTypes = {
    email: PropTypes.object,
    show_interactions: PropTypes.bool
  }

  render() {
    const { email, show_interactions } = this.props
    return (
      <div className="email-viewer-frame">
        <div className="email-viewer-header">
          { email.registration &&
            <>
              <strong>Event:</strong> { this._getEvent() }<br />
              <strong>Registration:</strong> { this._getRegistration() }<br />
            </>
          }
          { email.response &&
            <>
              <strong>Form:</strong> { this._getForm() }<br />
              <strong>Response:</strong> { this._getResponse() }<br />
            </>
          }
          { email.order &&
            <>
              <strong>Store:</strong> { this._getStore() }<br />
              <strong>Order:</strong> { this._getOrder() }<br />
            </>
          }
          { email.deal &&
            <>
              <strong>Pipeline:</strong> { this._getPipeline() }<br />
              <strong>Deal:</strong> { this._getDeal() }<br />
            </>
          }
          { email.subscription &&
            <>
              <strong>Service:</strong> { this._getService() }<br />
              <strong>Subscription:</strong> { this._getSubscription() }<br />
            </>
          }
          { email.email_campaign &&
            <><strong>Email Campaign:</strong> { this._getEmailCampaign() }<br /></>
          }
          { email.sms_campaign &&
            <><strong>Sms Campaign:</strong> { this._getSMSCampaign() }<br /></>
          }
          { email.voice_campaign &&
            <><strong>Voice Campaign:</strong> { this._getVoiceCampaign() }<br /></>
          }
          { email.workflow &&
            <><strong>Workflow:</strong> { this._getWorkflow() }<br /></>
          }
          { email.program &&
            <><strong>Program:</strong> { this._getProgram() }<br /></>
          }
          { email.contact &&
            <><strong>Contact:</strong> { this._getContact() }<br /></>
          }
          { email.user &&
            <><strong>User:</strong> { this._getUser() }<br /></>
          }
        </div>
        <Scrollbar className="email-viewer">
          <div className="email-viewer-email">
            <div className="email-viewer-email-body">
              <Email email={ email } expandable={ false } show_interactions={ show_interactions } />
            </div>
          </div>
          { email.activities &&
            <EmailActivities email={ email } />        
          }
          { show_interactions &&
            <div className="email-viewer-footer">
              <Comments entity={`maha_emails/${email.id}`} />
            </div>
          }
        </Scrollbar>
      </div>
    )
  }

  _getContact() {
    const { email } = this.props
    const button = {
      label: email.contact.display_name,
      className: 'link',
      route: `/admin/crm/contacts/${email.contact.id}`
    }
    return <Button { ...button } />
  }

  _getEmailCampaign() {
    const { email } = this.props
    const button = {
      label: email.email_campaign.title,
      className: 'link',
      route: `/admin/campaigns/email/${email.email_campaign.id}`
    }
    return <Button { ...button } />
  }

  _getFrom() {
    const { email } = this.props
    if(!email.program) return email.from
    const button = {
      label: email.from,
      className: 'link',
      route: `/admin/team/programs/${email.program.id}`
    }
    return <Button { ...button } />
  }

  _getProgram() {
    const { email } = this.props
    const button = {
      label: email.program.title,
      className: 'link',
      route: `/admin/team/programs/${email.program.id}`
    }
    return <Button { ...button } />
  }

  _getSmsCampaign() {
    const { email } = this.props
    const button = {
      label: email.sms_campaign.title,
      className: 'link',
      route: `/admin/campaigns/sms/${email.sms_campaign.id}`
    }
    return <Button { ...button } />
  }

  _getTo() {
    const { email } = this.props
    if(!email.contact) return email.to
    const button = {
      label: email.to.name,
      className: 'link',
      route: `/admin/crm/contacts/${email.contact.id}`
    }
    return <Button { ...button } />
  }

  _getVoiceCampaign() {
    const { email } = this.props
    const button = {
      label: email.voice_campaign.title,
      className: 'link',
      route: `/admin/campaigns/voice/${email.voice_campaign.id}`
    }
    return <Button { ...button } />
  }

  _getRegistration() {
    const { email } = this.props
    const button = {
      label: `Registration #${email.registration.id}`,
      className: 'link',
      route: `/admin/events/events/${email.registration.event.id}/registrations/${email.registration.id}`
    }
    return <Button { ...button } />
  }

  _getEvent() {
    const { email } = this.props
    const button = {
      label: email.registration.event.title,
      className: 'link',
      route: `/admin/events/events/${email.registration.event.id}`
    }
    return <Button { ...button } />
  }

  _getResponse() {
    const { email } = this.props
    const button = {
      label: `Response #${email.response.id}`,
      className: 'link',
      route: `/admin/forms/forms/${email.response.form.id}/responses/${email.response.id}`
    }
    return <Button { ...button } />
  }

  _getForm() {
    const { email } = this.props
    const button = {
      label: email.response.form.title,
      className: 'link',
      route: `/admin/forms/forms/${email.response.form.id}`
    }
    return <Button { ...button } />
  }

  _getOrder() {
    const { email } = this.props
    const button = {
      label: `Order #${email.order.id}`,
      className: 'link',
      route: `/admin/stores/stores/${email.order.store.id}/orders/${email.order.id}`
    }
    return <Button { ...button } />
  }

  _getStore() {
    const { email } = this.props
    const button = {
      label: email.order.store.title,
      className: 'link',
      route: `/admin/stores/stores/${email.order.store.id}`
    }
    return <Button { ...button } />
  }

  _getDeal() {
    const { email } = this.props
    const button = {
      label: email.deal.title,
      className: 'link',
      route: `/admin/sales/deals/${email.deal.id}`
    }
    return <Button { ...button } />
  }

  _getPipeline() {
    const { email } = this.props
    const button = {
      label: email.deal.pipeline.title,
      className: 'link',
      route: `/admin/sales/pipelines/${email.deal.pipeline.id}`
    }
    return <Button { ...button } />
  }

  _getSubscription() {
    const { email } = this.props
    const button = {
      label: `Subscription #${email.subscription.id}`,
      className: 'link',
      route: `/admin/subscriptions/services/${email.subscription.service.id}/subscriptions/${email.subscription.id}`
    }
    return <Button { ...button } />
  }

  _getService() {
    const { email } = this.props
    const button = {
      label: email.subscription.service.title,
      className: 'link',
      route: `/admin/subscriptions/services/${email.subscription.service.id}`
    }
    return <Button { ...button } />
  }

  _getWorkflow() {
    const { email } = this.props
    const button = {
      label: email.workflow.title,
      className: 'link',
      route: `/admin/automation/workflows/${email.workflow.id}`
    }
    return <Button { ...button } />
  }

  _getUser() {
    const { email } = this.props
    const button = {
      label: email.user.full_name,
      className: 'link',
      route: `/admin/team/users/${email.user.id}`
    }
    return <Button { ...button } />
  }

}

export default EmailViewer
