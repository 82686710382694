import PhoneNumber from '@admin/components/phone_number'
import AssetToken from '@admin/tokens/asset'
import Image from '@admin/components/image'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import Payment from './payment'
import React from 'react'

const Content = (props) => {
  const { data, field } = props
  const value = props.value === undefined ? data[field.code] : props.value
  const type = props.type === undefined ? (field.contactfield ? field.contactfield.type : field.type) : props.type
  if(_.isNil(value)) return null
  if(_.includes(['hiddenpaymentfield','optionsfield','paymentfield','productfield'], type)) {
    return <Payment line_items={ value.line_items } />
  } else if(type === 'addressfield') {
    return <span>{ value.description }</span>
  } else if(type === 'checkbox') {
    return value ? 'checked' : 'not checked'
  } else if(_.includes(['checkboxes','checkboxgroup'], type)) {
    return value.length > 0 ? <span>{ `${value.join(', ')}` }</span> : null
  } else if(type === 'signaturefield') {
    return <AssetToken { ...value.signed } route={`/admin/crm/agreements/${value.id}`} />
  } else if(type === 'colorfield') {
    return <div style={{backgroundColor: value }}>{ value }</div>
  } else if(type === 'videofield') {
    return <div>{ value.src }</div>
  } else if(type === 'datefield') {
    return <Date date={ value } />
  } else if(type === 'phonefield') {
    return <PhoneNumber number={ value } />
  } else if(type === 'filefield') {
    return (
      <div className="form-content-token-filefields">
        { _.castArray(value).map((asset, index) => (
          <AssetToken { ...asset } key={`asset_${index}`} />
        ))}
      </div>
    )
  } else if(type === 'imagefield') {
    return (
      <div>
        { value.asset_id }
        { false &&
          <Image width={100} height={100} src={value.asset.path} transform={value.transform} />
        }
      </div>
    )
  }
  return <span>{ value }</span>
}

Content.propTypes = {
  data: PropTypes.object,
  empty: PropTypes.bool,
  field: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.any
}

Content.defaultProps = {
  empty: true
}

export default Content
