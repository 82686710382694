import SocialDesigner from '@admin/components/social_designer'
import Dropdown from '@admin/components/form/dropdown'
import Versions from '@admin/components/versions'
import PropTypes from 'prop-types'
import React from 'react'

class SocialVariantDesigner extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.string,
    format: PropTypes.any,
    social_campaign: PropTypes.object
  }

  state = {
    variant_id: null
  }
  
  _handleVariant = this._handleVariant.bind(this)

  render() {
    const { variant_id } = this.state
    return (
      <div className="maha-variant-designer">
        <div className="maha-variant-designer-variants">
          <Dropdown { ...this._getVariants() } />
        </div>
        <div className="maha-variant-designer-variant">
          { variant_id &&
            <Versions { ...this._getVersions() }>
              <SocialDesigner { ...this._getSocialDesigner() } />          
            </Versions>
          }
        </div>
      </div>
    )
  }

  _getSocialDesigner() {
    const { variant_id } = this.state
    const { social_campaign } = this.props
    return {
      key: variant_id,
      title: social_campaign.title
    }
  }

  _getVariants() {
    const { format, social_campaign } = this.props
    return {
      options: social_campaign.variants.map(variant => ({ 
        id: variant.id,
        service: variant.service,
        perspective: variant.perspective,
        profile: variant.profile,
        language: variant.language
      })),
      valueKey: 'id',
      textKey: 'name',
      format,
      defaultValue: social_campaign.variants[0].id,
      deselectable: false,
      selectFirst: true,
      onChange: this._handleVariant
    }
  }

  _getVersions() {
    const { variant_id } = this.state
    const { entity } = this.props
    return {
      key: variant_id,
      endpoint: `/${entity}/${variant_id}/config`
    }
  }

  _handleVariant(variant_id) {
    this.setState({ 
      variant_id,
      config: null
    })
  }
  
}

export default SocialVariantDesigner
