import Button from '@admin/components/button'
import ExportDialog from './export'
import PropTypes from 'prop-types'
import React from 'react'

class Export extends React.Component {

  static propTypes = {
    columns: PropTypes.array,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    defaultSort: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    filter: PropTypes.object,
    query: PropTypes.object,
    sort: PropTypes.string,
    token: PropTypes.string
  }

  render() {
    return <Button { ...this._getButton() } />
  }

  _getButton() {
    return {
      svg: 'download',
      className: 'maha-collection-header-action',
      modal: <ExportDialog { ...this._getExport() } />,
      tooltip: {
        title: 't(Export Records)',
        position: 'bottom right'
      }
    }
  }

  _getExport() {
    const { columns, defaultFilter, defaultQuery, defaultSort, endpoint, entity, filter, query, sort } = this.props
    return {
      columns,
      defaultFilter,
      defaultQuery,
      defaultSort,
      endpoint,
      entity,
      filter,
      query,
      sort
    }
  }

}

export default Export
