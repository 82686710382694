import SubscriptionToken from '@apps/truevail/admin/tokens/subscription'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/subscriptions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Subscription)', key: 'type', primary: true, format: SubscriptionToken },
      { label: 't(Status)', key: 'team.status', sort: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'repeat',
      title: 't(No Subscriptions)',
      text: 't(You have not yet configured any subscriptions for this advsior)',
      buttons: [
        { 
          label: 't(Create Subscription)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${props.advisor.id}/subscriptions/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Subscription)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
