import TruevailSignup from './truevail_signup'
import Accordian from './accordian'
import Countdown from './countdown'
import Contact from './contact'
import Gallery from './gallery'
import Buttons from './buttons'
import Number from './number'
import Social from './social'
import Button from './button'
import Image from './image'
import Video from './video'
import Quote from './quote'
import Card from './card'
import Code from './code'
import Form from './form'
import Text from './text'
import Icon from './icon'
import Map from './map'

const blocks = [
  { type: 'accordian', label: 't(Accordian)', form: Accordian, svg: 'square_plus' },
  // { type: 'blog', label: 't(Blog)', form: Blog, svg: 'rss' },
  { type: 'button', label: 't(Button)', form: Button, svg: 'circle_power' },
  { type: 'buttons', label: 't(Buttons)', form: Buttons, svg: 'circle_power' },
  { type: 'form', label: 't(Form)', form: Form, svg: 'square_check' },
  { type: 'card', label: 't(Hover Card)', form: Card, svg: 'image' },
  { type: 'code', label: 't(Code)', form: Code, svg: 'code' },
  { type: 'contact', label: 't(Contact)', form: Contact, svg: 'user_round' },
  { type: 'countdown', label: 't(Countdown)', form: Countdown, svg: 'clock' },
  { type: 'gallery', label: 't(Gallery)', form: Gallery, svg: 'images' },
  { type: 'image', label: 't(Image)', form: Image, svg: 'image' },
  { type: 'icon', label: 't(Icon)', form: Icon, svg: 'smile' },
  { type: 'map', label: 't(Map)', form: Map, svg: 'map' },
  { type: 'number', label: 't(Number)', form: Number, svg: 'hash' },
  { type: 'quote', label: 't(Quote)', form: Quote, svg: 'quote' },
  { type: 'social', label: 't(Social)', form: Social, svg: 'facebook' },
  { type: 'text', label: 't(Text)', form: Text, svg: 'case_upper' },
  { type: 'truevail_signup', label: 't(Truevail Signup)', form: TruevailSignup, svg: 'square_check', access: {
    'team.id': { $eq: 41 }
  } },
  { type: 'video', label: 't(Video)', form: Video, svg: 'sqaure_play' }
]

export default blocks
