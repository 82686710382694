import RecipientToken from '@apps/campaigns/admin/tokens/recipient'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    records: PropTypes.array,
    onRemove: PropTypes.func
  }

  render() {
    const { records, onRemove } = this.props
    return (
      <div className="tofield-picker-results">
        { records.map((record, index) => (
          <div className="tofield-picker-result" key={`result_${record.id || index}`}>
            <div className="tofield-picker-result-token">
              <RecipientToken recipient={ record } />
            </div>
            { onRemove &&
              <Button { ...this._getRemove(record) } />
            }
          </div>
        )) }
      </div>
    )
  }

  _getRemove(record) {
    return {
      svg: 'x',
      className: 'tofield-picker-result-remove',
      handler: this._handleRemove.bind(this, record)
    }
  }

  _handleRemove(record) {
    this.props.onRemove(record.id)
  }

}

export default Results
