import { useLocaleContext } from '@admin/components/locale'
import IconToken from '@admin/tokens/icon'
import Icon from '@admin/components/icon'
import React, { useState } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

const DragToken = (props) => {

  const locale = useLocaleContext()?.locale || {}
  const [is_dragging, setIsDragging] = useState(false)

  const { action, noun_project_id, entity, icon, label, social, svg, type } = props

  const handleDragStart = (e) => {
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.setData(entity, entity)
    e.dataTransfer.setData('action', action)
    e.dataTransfer.setData('entity', entity)
    e.dataTransfer.setData('label', locale.t(label))
    e.dataTransfer.setData('type', type)
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const dragToken = {
    className: `maha-drag-token ${is_dragging ? 'dragging' : ''}`,
    draggable: true,
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd
  }

  const classes = ['maha-drag-token-icon']
  if(type) classes.push(type)
  if(entity) classes.push(entity)

  return (
    <div { ...dragToken }>
      <div className={ classes.join(' ')}>
        { noun_project_id &&
          <IconToken icon={{noun_project_id}} fg='FFFFFF' />        
        }
        { social &&
          <IconToken icon={{social}} fg='FFFFFF' />        
        }
        { icon &&
          <Icon icon={ icon } />        
        }
        { svg &&
          <Icon svg={ svg } />        
        }
      </div>
      <div className="maha-drag-token-label">
        <T text={ label } />
      </div>
    </div>
  )
}

DragToken.propTypes = {
  action: PropTypes.string,
  entity: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  noun_project_id: PropTypes.number,
  social: PropTypes.string,
  svg: PropTypes.string,
  type: PropTypes.string
}

export default DragToken