import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Publishings)',
  collection: {
    endpoint: '/api/admin/truevail/agency/email_publishings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Publishing)', key: 'advisor.title', sort: 'advisor', primary: true, format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Sent)', key: 'advisor_email_campaign.sent', sort: 'sent', collapsing: true, visible: false },
      { label: 't(Delivery Rate)', key: 'advisor_email_campaign.delivery_rate', sort: 'delivery_rate', collapsing: true, visible: false, format: 'rate'  },
      { label: 't(Open Rate)', key: 'advisor_email_campaign.open_rate', sort: 'open_rate', collapsing: true, visible: false, format: 'rate' },
      { label: 't(Click Rate)', key: 'advisor_email_campaign.click_rate', sort: 'click_rate', collapsing: true, visible: false, format: 'rate' },
      { label: 't(Bounce Rate)', key: 'advisor_email_campaign.bounce_rate', sort: 'bounce_rate', collapsing: true, visible: false, format: 'rate' },
      { label: 't(Complain Rate)', key: 'advisor_email_campaign.complain_rate', sort: 'complain_rate', collapsing: true, visible: false, format: 'rate' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Date Range)', key: 'send_at', type: 'daterange', include: ['day','this','last','next'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['scheduled','sending','queuing','warming','sent'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Sending Today)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'send_at', operator: '$dr', parent: null, value: 'today' }
          ]
        } },
        { id: 1, title: 't(Sent Yesterday)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'send_at', operator: '$dr', parent: null, value: 'yesterday' }
          ]
        } },
        { id: 2, title: 't(Sent)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'sent' }
          ]
        } },
        { id: 3, title: 't(Sending)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'sending' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'send_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Publishings)',
      text: 't(There are no published email campaigns)'
    },
    entity: 'publishing',
    recordTasks: (record) => [
      {
        label: 't(Unschedule Email Campaign)',
        svg: 'clock',
        show: record.advisor_email_campaign.status === 'scheduled',
        confirm: 't(Are you sure you want to unschedule this email campaign?)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_publishings/${record.advisor_email_campaign.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unschedule email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unschedule email campaign)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
