import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Methods from './methods'
import Email from './email'
import React from 'react'
import Link from './link'

class Share extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object
  }

  state = {
    active: null,
    methods: []
  }

  _handleChooseMethod = this._handleChooseMethod.bind(this)

  render() {
    return (
      <div className="drive-share">
        <Stack cards={ this._getCards() } />
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      methods: [
        { svg: 'mail', label: 't(Share via Email)', component: Email },
        { icon: 'link', label: 't(Share via Link)', component: Link }
      ]
    })
  }

  _getCards() {
    const { active, methods } = this.state
    const method = (active !== null) ? methods[active] : null
    const cards = [
      { component: () => <Methods { ...this._getMethods() } /> }
    ]
    if(method) cards.push({ component: () => <method.component { ...this._getMethod() } /> })
    return cards
  }

  _getMethods() {
    const { methods } = this.state
    const { item } = this.props
    return {
      item,
      methods,
      onChooseMethod: this._handleChooseMethod
    }
  }

  _getMethod() {
    const { item } = this.props
    return {
      item,
      onBack: this._handleChooseMethod
    }
  }

  _handleChooseMethod(active) {
    this.setState({ active })
  }

}

export default Share
