import SpeakField from '@apps/phone/admin/components/speakfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Say extends React.PureComponent {

  static propTypes = {
    config: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefaults(),
        ...this.props.config || {}
      }
    })
  }

  _getDefaults() {
    return {
      say: {
        voice: 'Salli',
        text: ''
      }
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Speak Text)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'red', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Message)', type: 'segment', fields: [
              { name: 'say', type: SpeakField, placeholder: 't(Enter a message)', required: true, defaultValue: config.say }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone() {
    const { config } = this.state
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Say
