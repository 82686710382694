import Comments from '@admin/components/comments'
import Buttons from '@admin/components/buttons'
import Audit from '@admin/components/audit'
import Steps from '@admin/components/steps'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Section from './section'
import React from 'react'

class Details extends React.Component {

  static propTypes = {
    alert: PropTypes.object,
    audits: PropTypes.string,
    before: PropTypes.any,
    buttons: PropTypes.array,
    className: PropTypes.string,
    comments: PropTypes.string,
    error: PropTypes.object,
    empty: PropTypes.object,
    footer: PropTypes.any,
    header: PropTypes.any,
    items: PropTypes.array,
    notice: PropTypes.any,
    sections: PropTypes.array,
    steps: PropTypes.object
  }

  render() {
    const { audits, alert, before, buttons, comments, empty, error, footer, header, items, notice, steps } = this.props
    const sections = this._getSections()
    return (
      <>
        { error &&
          <div className="maha-details-message error">
            <Icon svg="triangle_alert" /> { error }
          </div>
        }
        { notice &&
          <div className="maha-details-message notice">
            <Icon svg="info" /> { notice }
          </div>
        }
        { alert &&
          <div className={`maha-details-alert ${alert.color}`}>
            <div className="maha-details-alert-icon">
              <Icon svg="info" stroke_width={3} />
            </div>
            <div className="maha-details-alert-details">
              <T text={ alert.message } />
            </div>
          </div>
        }
        { steps && 
          <div className="maha-details-steps">
            <Steps { ...steps } /> 
          </div>
        }
        { before && 
          <div className="maha-details-before">
            { before}
          </div>
        }
        <div className={ this._getClasses() }>
          { header &&
            <div className="maha-details-header">
              { this._getComponent(header) }
            </div>
          }
          { sections &&
            sections.map((section, index) => (
              <Section { ...section } key={`list_section_${index}`} />
            ))
          }
          { items &&
            <Section items={ items } empty={ empty } />
          }
          <div className="maha-details-section">
            { audits &&
              <Audit entity={ audits } />
            }
          </div>
          <div className="maha-details-section">
            { comments &&
              <Comments entity={ comments } />
            }
          </div>
          { footer &&
            <div className="maha-details-footer">
                <>{ this._getComponent(footer) }</>
            </div>
          }
          { buttons &&
            <div className="maha-details-buttons">
              <Buttons buttons={ buttons } />
            </div>
          }
        </div>
      </>
    )
  }

  _getClasses() {
    const { className } = this.props
    const classes = ['maha-details']
    if(className) classes.push(className)
    return classes.join(' ')
  }

  _getComponent(component) {
    return _.isFunction(component) || !!component.WrappedComponent ? React.createElement(component) : component
  }

  _getSections() {
    const { sections } = this.props
    return sections ? sections.filter(section => {
      return section.show !== false
    }) : null
  }

}

export default Details
