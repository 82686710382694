import ProjectToken from '@apps/finance/admin/tokens/project'
import Panel from '@admin/components/panel'
import Merge from '../projects/merge'
import Edit from '../projects/edit'
import New from '../projects/new'
import React from 'react'

const _getIntegrationColumns = (integration) => {
  if(_.includes(['accpac','accumatica'], integration)) {
    return [
      { label: 'Main Project Code', key: 'integration.main_project_code', collapsing: true, visible: false  },
      { label: 'Project Code', key: 'integration.project_code', collapsing: true, visible: false  },
      { label: 'Program Code', key: 'integration.program_code', collapsing: true, visible: false  },
      { label: 'Source Code', key: 'integration.source_code', collapsing: true, visible: false },
      { label: 'Match', key: 'integration.match', collapsing: true, visible: false  }
    ]
  }
  return []
}

const _getIntegrationExports = (integration) => {
  if(_.includes(['accpac','accumatica'], integration)) {
    return [
      { label: 'Main Project Code', key: 'integration.main_project_code' },
      { label: 'Project Code', key: 'integration.project_code' },
      { label: 'Program Code', key: 'integration.program_code' },
      { label: 'Source Code', key: 'integration.source_code' },
      { label: 'Match', key: 'integration.match' }
    ]
  }
  return []
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Projects)',
  access: { rights: { $oneOf: ['finance:view_projects','finance:manage_projects'] } },
  collection: {
    endpoint: '/api/admin/finance/projects',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'project_code', sort: 'project_code', primary: true, format: ProjectToken },
      ..._getIntegrationColumns(props.app.settings.integration)
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Active)', key: 'is_active' },
      ..._getIntegrationExports(props.app.settings.integration)
    ],
    onClick: (record) => context.router.push(`/finance/projects/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Edit Project)',
        svg: 'square_pen',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: record.is_active,
        modal: <Edit project={ record } integration={ props.app.settings.integration } />
      }, 
      {
        label: 't(Merge Project)',
        svg: 'merge',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: record.is_active,
        modal: <Merge id={ record.id } />
      }, 
      {
        label: 't(Disable Project)',
        svg: 'x',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: record.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/finance/projects/${record.id}/disable`,
          onFailure: (result) => context.flash.set('error', 't(Unable to disable this project)')
        }
      }, 
      {
        label: 't(Enable Project)',
        svg: 'check',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: !record.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/finance/projects/${record.id}/enable`,
          onFailure: (result) => context.flash.set('error', 't(Unable to enable this project)')
        }
      }
    ],
    defaultSort: { key: 'project_code', order: 'asc' },
    empty: {
      icon: 'folder',
      title: 't(No Projects)',
      text: 't(You have not yet created any projects)',
      buttons: [
        { 
          label: 't(Create Project)',
          access: { rights: { $oneOf: ['finance:manage_projects'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'project'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Project)',
    access: { rights: { $oneOf: ['finance:manage_projects'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
