import Icon from '@admin/components/icon'
import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    requires: PropTypes.array,
    onChoose: PropTypes.func
  }

  render() {
    const { records, requires } = this.props
    return (
      <div className="crm-program-chooser-list">
        { records.map((program, index) => (
          <div className={ this._getClass(program) } key={`record_${index}`} onClick={ this._handleChoose.bind(this, program) }>
            <div className="crm-program-chooser-list-item-token">
              <div className="program-token">
                <div className="program-token-logo">
                  <Logo team={ program } width="24" />
                </div>
                <div className="program-token-label">
                  { program.title }
                  { _.includes(requires, 'bank') &&
                    <span> { program.bank ? program.bank.title : '(no bank account)' }</span>
                  }
                </div>
              </div>
            </div>
            <div className="crm-program-chooser-list-item-proceed">
              <Icon svg="chevron_right" />
            </div>
          </div>
        )) }
      </div>
    )
  }

  _getClass(program) {
    const { requires } = this.props
    const classes = ['crm-program-chooser-list-item']
    if(_.includes(requires, 'bank') && !program.bank) classes.push('disabled')
    return classes.join(' ')
  }

  _handleChoose(program) {
    const { requires } = this.props
    if(_.includes(requires, 'bank') && !program.bank) return
    this.props.onChoose(program)
  }

}

export default Results
