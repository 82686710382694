import Container from '@admin/components/container'
import Email from '@admin/components/email'
import PropTypes from 'prop-types'
import React from 'react'

class Show extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    email: PropTypes.object,
    id: PropTypes.number,
    profile_id: PropTypes.number,
    onFlag: PropTypes.func,
    onForward: PropTypes.func,
    onMark: PropTypes.func,
    onMove: PropTypes.func,
    onReply: PropTypes.func
  }

  _handleForward = this._handleForward.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    return (
      <div className="email-client-show">
        <Email { ...this._getEmail() } />
      </div>
    )
  }

  componentDidMount() {
    const { email } = this.props
    if(!email.is_read) this._handleMark(true)
  }

  _getEmail() {
    const { email } = this.props
    const { to, cc } = email
    return {
      actions: [
        { icon: 'reply', label: 'Reply', handler: this._handleReply.bind(this, false) },
        ...(to.length + cc.length) > 1 ? [
          { icon: 'reply-all',label: 'Reply All',  handler: this._handleReply.bind(this, true) }
        ] : [],
        { icon: 'share', label: 'Forward', handler: this._handleForward },
        { svg: 'ellipsis_vertical', label: 'More', handler: this._handleTasks }
      ],
      email,
      expandable: false
    }
  }

  _handleFlag(is_flagged) {
    const { id, profile_id } = this.props
    this.props.onFlag([{ id, profile_id }], is_flagged)
  }

  _handleForward() {
    const { email } = this.props
    this.props.onForward({ email })
  }

  _handleMove(folder) {
    const { email, id, profile_id } = this.props
    this.props.onMove([{ id, profile_id }], email.folder, folder)
  }

  _handleMark(is_read) {
    const { id, profile_id } = this.props
    this.props.onMark([{ id, profile_id }], is_read)
  }

  _handleReply(all) {
    const { email } = this.props
    this.props.onReply({ email, all })
  }

  _handleTasks() {
    const { email } = this.props
    this.context.tasks.open({
      items: [
        { label: 'Unflag', show: email.is_flagged, handler: this._handleFlag.bind(this, false) },
        { label: 'Flag', show: !email.is_flagged, handler: this._handleFlag.bind(this, true) },
        { label: 'Mark as Unread', show: email.is_read, handler: this._handleMark.bind(this, false) },
        { label: 'Mark as Read', show: !email.is_read, handler: this._handleMark.bind(this, true) },
        { label: 'Move to Inbox', show: email.folder === 'trash', handler: this._handleMove.bind(this, 'inbox') },
        { label: 'Move to Trash', show: email.folder !== 'trash', handler: this._handleMove.bind(this, 'trash') }
      ]
    })
  }

}

const mapResources = (props, context) => ({
  email: {
    endpoint: `/api/admin/email/${props.profile_id}/${props.id}/view`,
    refresh: `/users/${context.admin.user.id}/admin/email/${props.id}`
  }
})

export default Container(mapResources)(Show)
