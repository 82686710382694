import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = (props) => {

  const _import = props.import

  const details = {
    audits: `maha_imports/${_import.id}`,
    comments: `maha_imports/${_import.id}`,
    sections: [
      {
        title: 't(Import Details)',
        items: [
          { label: 't(Created)', content: _import.created_at, format: 'datetime' },
          { label: 't(User)', content: _import.user.full_name },
          { label: 't(Source)', content: _import.service },
          { label: 't(Contacts)', content: (
            <>{_import.created_count} new, {_import.merged_count} merged</>
          ) },
          { label: 't(Verifications)', content: (
            <>{_import.pending_count} pending, {_import.failed_count} failed, {_import.success_count} succeeded</>
          ) }
        ]
      }
    ]
  }

  if(_import.status === 'reverting') {
    details.alert = { color: 'orange', message: 't(This import is being reverted)' }
  } else if(_import.status === 'reverted') {
    details.alert = { color: 'red', message: 't(This import was reverted)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  import: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview import={ props.import } />,
  tasks: {
    items: [
      {
        label: 't(Revert Import)',
        show: props.import.status === 'completed',
        confirm: 't(Are you sure you want to revert this import? This will delete all NEW contacts created by this import)',
        request: {
          endpoint: `/api/admin/crm_contacts/imports/${props.import.id}/revert`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Reverting import)'),
          onFailure: () => context.flash.set('error', 't(Unable to revert import)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
