import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/email_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'advisor.title', sort: 'advisor', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      { label: 't(Preview)', key: 'publishing.preview_status', collapsing: true, align: 'center', format: (email_campaign) => {
        return email_campaign.publishing ? <StatusToken status={ email_campaign.publishing.preview_status } /> : null
      } }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Date Range)', key: 'send_at', type: 'daterange', include: ['day','this','last','next'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['scheduled','sending','sended'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Sending Today)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'send_at', operator: '$dr', parent: null, value: 'today' }
          ]
        } },
        { id: 1, title: 't(Sent Yesterday)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'send_at', operator: '$dr', parent: null, value: 'yesterday' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'send_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(There are no published email campaigns for this advisor)'
    },
    entity: 'email campaign',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        show: ['draft','scheduled'].includes(record.status),
        modal: <Preview publishing={ record.publishing } scope="agency" type="email" />
      }
    ],
    onClick: (record) => context.host.window.open(`/${record.team.subdomain}/campaigns/email/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
