import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Posts extends React.PureComponent {

  static contextTypes = {}

  static propTypes = {
    endpoint: PropTypes.string,
    selected: PropTypes.number,
    onChoose: PropTypes.func
  }

  static defaultProps = {}

  state = {
    q: ''
  }

  _handleType = this._handleType.bind(this)

  render() {
    return (
      <div className="campaigns-social-conversations-sidebar">
        <Searchbox { ...this._getSearchbox() } />
        <Infinite { ...this._getInfinite() } />
      </div>
    )
  }

  _getInfinite() {
    const { endpoint, selected, onChoose } = this.props
    const { q } = this.state
    return {
      endpoint,
      filter: {
        ...q.length > 0 ? { q } : {}
      },
      layout: Results,
      props: {
        selected,
        onChoose
      }
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Search conversations',
      onChange: this._handleType
    }
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default Posts
