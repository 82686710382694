import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    format: PropTypes.any,
    chosen: PropTypes.any,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    search: PropTypes.bool,
    showToggles: PropTypes.bool,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onToggleRecord: PropTypes.func
  }

  render() {
    const { format, multiple, showToggles, textKey } = this.props
    const records = this._getRecords()
    return (
      <div className="maha-search-results">
        { records.map((record, index) => (
          <div key={`record_${index}`} className={ this._getClass(record) } onClick={ this._handleToggleRecord.bind(this, record) }>
            { showToggles && multiple &&
              <div className="maha-search-item-icon">
                <Icon icon={ this._getIcon(record) } />
              </div>
            }
            <div className="maha-search-item-label">
              <Format format={ format } { ...record } value={ _.get(record, textKey) } />
            </div>
            { showToggles && !multiple &&
              <div className="maha-search-item-icon">
                { this._getChecked(record) &&
                  <Icon svg="check" />
                }
              </div>
            }
          </div>
        )) }
      </div>
    )
  }

  _getClass(record) {
    const classes = ['maha-search-item']
    if(this._getChecked(record)) classes.push('checked')
    return classes.join(' ')
  }

  _getChecked(record) {
    const { chosen, valueKey } = this.props
    const key = valueKey || 'id'
    return _.find(chosen, { [key]: _.get(record, key) })
  }

  _getIcon(record) {
    const checked = this._getChecked(record)
    if(checked) return 'check-circle'
    return 'circle-o'
  }

  _getRecords() {
    const { chosen, multiple, records, showToggles, valueKey } = this.props
    return (!multiple || showToggles) ? records : records.filter(record => {
      return chosen.find(item => {
        if(valueKey) return _.get(item, valueKey) === _.get(record, valueKey)
        return _.isEqual(item, record)
      }) === undefined
    })
  }

  _handleToggleRecord(record) {
    this.props.onToggleRecord(record)
  }

}

export default Results
