import Panel from '@admin/components/panel'
import Feed from '@admin/components/feed'
import AppToken from '@admin/tokens/app'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Activities)',
  rights: ['team:manage_team'],
  collection: {
    endpoint: '/api/admin/team/activities',
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 'App', key: 'app_id', type: 'select', multiple: true, endpoint: '/api/admin/team/apps', valueKey: 'id', textKey: 'title', sort: { key: 'code', order: 'asc' }, format: AppToken },
          { name: 'Date Range', key: 'created_at', type: 'daterange', include: ['this','last'] }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Date)', key: 'created_at' },
      { label: 't(User)', key: 'user.full_name' },
      { label: 't(App)', key: 'app.title' },
      { label: 't(Description)', key: 'description' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    defaultFilter: {
      user_id: {
        $eq: props.user.id
      }
    },
    empty: {
      svg: 'activity',
      title: 't(No Activities)',
      text: 't(We have not yet recorded any activities for this user)'
    },
    entity: 'activity',
    layout: (props) => <Feed { ...props } onClick={ (activity) => context.router.push(activity.url) } />
  }
})

export default Panel(null, mapPropsToPanel)
