import ModalPanel from '@admin/components/modal_panel'
import ProfileToken from '@admin/tokens/profile'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Profiles extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    profiles: PropTypes.array,
    types: PropTypes.array,
    onChoose: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleNew = this._handleNew.bind(this)

  render() {
    const { profiles } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="profilefield-picker-profiles">
          { profiles.map((profile, index) => (
            <div className="profilefield-picker-profile" key={`source_${index}`} onClick={ this._handleChoose.bind(this, index) }>
              <ProfileToken profile={ profile } />
            </div>
          ))}
          <div className="profilefield-picker-profile-add" onClick={ this._handleNew }>
            <div className="profilefield-picker-profile-add-icon">
              <Icon icon="plus-circle" />
            </div>
            <div className="profilefield-picker-profile-add-label">
              Add Profile
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getNew() {
    const { types } = this.props
    return {
      types
    }
  }

  _getPanel() {
    return {
      title: 't(Profiles)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChoose(index) {
    this.props.onChoose(index)
    this.context.form.pop()
  }

  _handleNew() {
    this.context.form.push(New, this._getNew.bind(this))
  }
}

export default Profiles
