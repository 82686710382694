import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at,
  placeholder: entity.status === 'pending'
})

const SocialCampaignCard = ({ social_campaign }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
    </div>
  </div>
)

SocialCampaignCard.propTypes = {
  social_campaign: PropTypes.object
}

export default SocialCampaignCard
