import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: `/api/admin/crm/imports/${props.import.id}/contacts`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'display_name', sort: 'last_name', primary: true, format: ContactToken },
      { label: 't(Email)', key: 'email', sort: 'primary_email' },
      { label: 't(Phone)', key: 'phone', sort: 'primary_phone', format: 'phone' }
    ],
    defaultFilter: props.type === 'new' ? {
      is_valid: { $eq: true }, is_duplicate: { $eq: false }, is_nonunique: { $eq: false } 
    } : props.type === 'merged' ? {
      is_duplicate: { $eq: true }
    } : {},
    defaultSort: { key: 'last_name', order: 'desc' },
    empty: {
      svg: 'user_round',
      title: 't(No Contacts)',
      text: 't(No contacts)'
    },
    entity: 'contact',
    onClick: (record) => context.router.push(`/crm/contacts/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
