import SendingRates from './sending_rates'
import Page from '@admin/components/page'
import Engagements from './engagements'
import Overview from './overview'
import Queued from './queued'
import React from 'react'
import Sent from './sent'

const mapPropsToPage = (props, context, resources, page) => ({
  title: `${resources.domain_segment.domain.name} (${resources.domain_segment.segment.text})`,
  manager: {
    path: `/admin/truevail/admin/domain_segments/${resources.domain_segment.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview domain_segment={ resources.domain_segment } />
      },
      {
        label: 't(Engagement)',
        path: '/engagements',
        panel: <Engagements domain_segment={ resources.domain_segment } />
      },
      {
        label: 't(Sending Rates)',
        path: '/sending_rates',
        panel: <SendingRates domain_segment={ resources.domain_segment } />
      },
      {
        label: 't(Queued)',
        path: '/queued',
        panel: <Queued domain_segment={ resources.domain_segment } />
      },
      {
        label: 't(Sent)',
        path: '/sent',
        panel: <Sent domain_segment={ resources.domain_segment } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  domain_segment: `/api/admin/truevail/admin/deliverability/domain_segments/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
