import EmailActivityToken from '@apps/maha/admin/tokens/email_activity'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context)=> ({
  title: 't(Email Activities)',
  access: {},
  collection: {
    endpoint: `/api/admin/automation/emails/${props.email.id}/activities`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Activity)', key: 'description', sort: 'created_at', format: EmailActivityToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'activity',
      title: 't(No Activities)',
      text: 't(This email campaign has not yet been sent)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/emails/${record.email.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
