import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Desktop from './desktop'
import Mobile from './mobile'
import Status from './status'
import Sounds from './sounds'
import InApp from './inapp'
import Email from './email'
import Mute from './mute'
import React from 'react'

class Preferences extends React.Component {

  static contextTypes = {
    provider: PropTypes.object,
    host: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { provider } = this.context
    const host = this.context.host.device.getType()
    return {
      title: 't(Preferences)',
      method: 'PATCH',
      endpoint: '/api/admin/account/notifications',
      action: '/api/admin/account/notifications/preferences',
      cancelSvg: 'chevron_left',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Notification Status', name: 'notifications_enabled', instructions: 't(You can temporarily disable all notifications at any time)', type: Status },
            { label: 'In-App Notifications', name: 'in_app_notifications_enabled', instructions: `t(If you have ${provider.title} open, but are not looking at the appropriate page, we can display an in-app notification to the top of the screen)`, type: InApp },
            { label: 'Notification Sound', name: 'sounds', instructions: `t(If you have ${provider.title} closed or this window is inactive, we can play a sound)`, type: Sounds },
            ...host === 'mobile' ? [
              { label: 'Push Notifications', name: 'push_notifications_enabled', instructions: 't(If you do not have the app open, we can send push notifications to your mobile device)', type: Mobile }
            ] : [
              { label: 'Desktop Notifications', name: 'push_notifications_enabled', instructions: `t(If you do not have ${provider.title} open or the window is not active, we can push desktop notifications to your laptop or computer)`, type: Desktop }
            ],
            { label: 'Email Notifications', name: 'email_notifications_method', instructions: 't(If we are unable to notify you any other way, we can send you notifications by email. Please indicate how often you would like to receive them)', type: Email },
            { label: 'Quiet Time', name: 'mute', instructions: 'We support your work/life balance! If you enable desktop, mobile push, or sound notifications, we can mute them during any hours you would not like to receive notifications. You will still receive notifications via email', type: Mute }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleSuccess() {
    this.context.modal.pop()
  }

}

export default Preferences
