import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_campaign } = this.props
    return {
      title: 't(Duplicate Email Campaign)',
      action: `/api/admin/truevail/admin/email_campaigns/${email_campaign.id}/clone`,
      method: 'POST',
      saveText: 't(Clone)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 't(Enter the title)', required: true, defaultValue: `Copy of ${email_campaign.title}` },
            { label: 't(Design URL)', name: 'design_url', type: 'urlfield', defaultValue: email_campaign.design_url },
            { 
              label: 't(Library Details)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Folders)', 
                  entity: 'folder',
                  name: 'folder_ids', 
                  type: 'checkboxgroup', 
                  endpoint: '/api/admin/truevail/admin/folders', 
                  valueKey: 'id', 
                  textKey: 'title', 
                  defaultValue: email_campaign.folders.map(folder => folder.id)
                },
                { 
                  label: 't(Tags)', 
                  name: 'tags', 
                  type: 'tagfield', 
                  endpoint: '/api/admin/tags', 
                  defaultValue: email_campaign.tags.map(tag => tag.text)
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(email_campaign) {
    this.context.router.push(`/truevail/admin/email_campaigns/${email_campaign.id}`)
    this.context.modal.close()
  }

}

export default Clone
