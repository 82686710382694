import ProgramToken from '@apps/crm/admin/tokens/program'
import Assignment from '@admin/components/assignment'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    bank: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Assignment { ...this._getAssignment() } />
  }

  _getAssignment() {
    const { bank } = this.props
    return {
      title: 't(Manage Programs)',
      action: `/api/admin/finance/banks/${bank.id}/programs`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        program_ids: assigned.map((assignee) => assignee.id) 
      }),
      assigneeFormat: (program) => <ProgramToken { ...program } />,
      assignedEndpoint: `/api/admin/finance/banks/${bank.id}/programs`,
      empty: {
        svg: 'network',
        title: 't(Add programs)',
        text: 't(Please assign programs to this bank)'
      },
      unassignedEndpoint: '/api/admin/programs',
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.title.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          return assignment.id === assignee.id
        }) === undefined
      },
      onCancel: this._handleCancel,
      onDone: this._handleDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Manage
