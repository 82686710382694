import SocialCampaignToken from '@apps/campaigns/admin/tokens/campaign/social'
import Infinite from '@admin/components/infinite'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class List extends React.PureComponent {

  static contextTypes = {
    alert: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    onEdit: PropTypes.func,
    onSchedule: PropTypes.func
  }

  render() {
    return (
      <div className="campaigns-social-schedule-list">
        <Infinite { ...this._getInfinite() } />
      </div>
    )
  }

  _getInfinite() {
    const { endpoint } = this.props
    return {
      endpoint,
      filter: { 
        status: { $neq: 'draft' } 
      },
      empty: {
        svg: 'messages_square',
        title: 't(No Scheduled Campaigns)',
        text: 't(There are no scheduled campaigns for the selected month)'
      },
      entity: 'social campaign',
      layout: ({ records }) => (
        <div className="campaigns-social-schedule-list-items">
          { records.map((social_campaign, index) => (
            <div { ...this._getSocialCampaign(social_campaign) } key={`social_campaign_${index}`}>
              <div className="campaigns-social-schedule-list-item-token">
                <SocialCampaignToken social_campaign={ social_campaign } />
              </div>
              <div className="campaigns-social-schedule-list-item-action">
                <Button { ...this._getEdit(social_campaign) }/>
              </div>
              <div className="campaigns-social-schedule-list-item-proceed">
                <Icon svg="chevron_right" />
              </div>
            </div>
          )) }
        </div>
      )
    }
  }

  _getEdit(social_campaign) {
    return {
      svg: 'ellipsis',
      className: 'maha-table-layout-icon',
      handler: this._handleEdit.bind(this, social_campaign)
    }
  }

  _getSocialCampaign(social_campaign) {
    return {
      className: 'campaigns-social-schedule-list-item',
      title: social_campaign.title,
      onClick: this._handleClick.bind(this, social_campaign)
    }
  }

  _handleClick(social_campaign) {
    this.context.router.push(`/campaigns/social/${social_campaign.id}`)
  }

  _handleEdit(social_campaign) {
    this.props.onEdit(social_campaign)
  }

}

export default List


