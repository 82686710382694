import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    provider: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    pricing_model: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { pricing_model } = this.props
    return {
      title: 't(Edit Pricing Modal)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/pricing_models/${pricing_model.id}/edit`,
      action: `/api/admin/truevail/admin/pricing_models/${pricing_model.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo Design)', type: 'segment', fields: [
              { label: 't(Price)', name: 'pricing.logo.price', type: 'moneyfield', required: true },
              { label: 't(Discounted Price)', name: 'pricing.logo.discount_price', type: 'moneyfield' }
            ] },
            { label: 't(Brand Design)', type: 'segment', fields: [
              { label: 't(Price)', name: 'pricing.brand.price', type: 'moneyfield', required: true },
              { label: 't(Discounted Price)', name: 'pricing.brand.discount_price', type: 'moneyfield' }
            ] },
            { label: 't(Single Page Website)', type: 'segment', fields: [
              { label: 't(Price)', name: 'pricing.singlepage.price', type: 'moneyfield', required: true },
              { label: 't(Discounted Price)', name: 'pricing.singlepage.discount_price', type: 'moneyfield' }
            ] },
            { label: 't(Seven Page Website)', type: 'segment', fields: [
              { label: 't(Price)', name: 'pricing.sevenpage.price', type: 'moneyfield', required: true },
              { label: 't(Discounted Price)', name: 'pricing.sevenpage.discount_price', type: 'moneyfield' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
