import Page from '@admin/components/page'
import Items from './items'
import React from 'react'

const getTabs = ({ cart, products }) => ({
  items: [
    { label: 't(Items)', component: <Items items={ cart.items } /> }
  ]
})

const getTasks = ({ cart, store }, { flash, router }) => ({
  items: [
    {
      label: 't(Delete Cart)',
      svg: 'trash',
      show: cart.status !== 'ordered',
      confirm: 't(Are you sure you want to delete this cart?)',
      deletable: true,
      request: {
        endpoint: `/api/admin/stores/stores/${store.id}/carts/${cart.id}`,
        method: 'DELETE',
        onFailure: () => flash.set('error', 't(Unable to delete cart)'),
        onSuccess: () => {
          flash.set('success', 't(Successfully deleted cart)'),
          router.goBack()
        }
      }
    }
  ]
})

const mapResourcesToPage = (props, context) => ({
  cart: `/api/admin/stores/stores/${props.params.store_id}/carts/${props.params.id}`,
  store: `/api/admin/stores/stores/${props.params.store_id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Cart)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
