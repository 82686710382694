import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Translation extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          instructions: 't(When you create new variants of your content, we will automatically translate them into the voices you have selected. Sometimes there are phrases that you do not want to be translated. You can whitelist a set of phrases that should not be translated by adding them below.)',
          fields: [
            { 
              label: 't(Translation Restrictions)',
              name: 'config.translation_restrictions',
              type: 'collectionfield',
              fields: [
                { 
                  label: 't(Phrase)',
                  name: 'phrase',
                  type: 'textfield' 
                }
              ],
              entity: 'phrase',
              cloneable: false,
              orderable: false,
              token: ({ value }) => (
                <div className="token">
                  { value.phrase }
                </div>
              ),
              defaultValue: formdata.config?.translation_restrictions || []
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(agency) {
    const { formdata } = this.props
    this.props.onNext({
      config: {
        ...formdata.config,
        ...agency.config
      }
    })
  }

}

export default Translation
