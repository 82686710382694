import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const CompactTypeToken = ({ value }) => (
  <div className="compact-type-token">
    <div className={`type-token-badge ${value}`}>
      { value === 'advance' && <Icon icon="arrow-right" /> }
      { value === 'check' && <Icon svg="check" /> }
      { value === 'expense' && <Icon icon="credit-card" /> }
      { value === 'reimbursement' && <Icon icon="dollar" /> }
      { value === 'trip' && <Icon icon="car" /> }
      { value === 'import' && <Icon icon="table" /> }
    </div>
  </div>
)

CompactTypeToken.propTypes = {
  value: PropTypes.string
}

export default CompactTypeToken
