import EmailAddressToken from './email_address'
import Button from '@admin/components/button'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    actions: PropTypes.array,
    email: PropTypes.object
  }

  render() {
    const { actions, email } = this.props
    return (
      <div className="maha-email-header">
        { actions &&
          <div className="maha-email-header-actions">
            { actions.map((action, index) => (
              <Button { ...this._getAction(action) } key={`action_${index}`} />
            )) }
          </div>
        }
        { email.sent_at ?
          <>{ this._getTimestamp() }</> :
          <><span className="alert">SENDING...</span></>
        }<br />
        { email.sender ? 
          <>
            From: <>
              <div className="maha-email-header-tokens">
                <EmailAddressToken { ...email.sender } />
              </div>on behalf of <div className="maha-email-header-tokens">
                <EmailAddressToken { ...email.from } />
              </div>
            </><br />
          </> :
          <>
            From: <div className="maha-email-header-tokens">
              <EmailAddressToken { ...email.from } />
            </div><br />
          </>        
        }
        { email.reply_to?.length > 0 &&
          <>
            Reply-To: <div className="maha-email-header-tokens">
              { email.reply_to.map((reply_to, index) => (
                <EmailAddressToken { ...reply_to } key={`reply_to_${index}`} />
              )) }
            </div><br />
          </>
        }
        { email.to.length > 0 &&
          <>
            To: <div className="maha-email-header-tokens">
              { email.to.map((to, index) => (
                <EmailAddressToken { ...to } key={`to_${index}`} />
              )) }
            </div><br />
          </>
        }
        { email.cc?.length > 0 &&
          <>
            Cc: <div className="maha-email-header-tokens">
              { email.cc.map((cc, index) => (
                <EmailAddressToken { ...cc } key={`cc_${index}`} />
              )) }
            </div><br />
          </>
        }
        { email.bcc?.length > 0 &&
          <>
            Bcc: <div className="maha-email-header-tokens">
              { email.bcc.map((bcc, index) => (
                <EmailAddressToken { ...bcc } key={`bcc_${index}`} />
              )) }
            </div><br />
          </>
        }
        { email.subject }
      </div>
    )
  }

  _getAction(action) {
    return {
      ...action,
      label: null,
      className: 'maha-email-header-action'
    }
  }

  _getTimestamp() {
    const { account } = this.context.admin
    const { email } = this.props
    const today = moment().tz(account.timezone).startOf('day')
    const yesterday = moment().tz(account.timezone).subtract(1, 'day').startOf('day')
    const sent_at = moment(email.sent_at).tz(account.timezone)
    if(sent_at.isAfter(today)) return sent_at.format('[Today at] h:mm A z')
    if(sent_at.isBetween(yesterday, today)) return sent_at.format('[Yesterday at] h:mm A z')
    return sent_at.format('dddd, MMMM D, YYYY @ h:mm A z')
  }

}

export default Header
