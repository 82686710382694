import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { list_id, tag_id, trigger } = formdata
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Title)', 
              name: 'title', 
              type: 'textfield',
              required: true 
            },
            ...trigger === 'automation:enrollment_created' ? [
              { 
                label: 't(Purpose)', 
                name: 'purpose', 
                type: 'radiogroup', 
                options: ['marketing','transactional'], 
                required: true, 
                format: PurposeToken, 
                defaultValue: 'marketing' 
              }
            ] : [],
            ..._.includes(['crm:subscription_created','crm:subscription_deleted'], trigger) && !list_id ? [
              { 
                label: 't(List)', 
                type: 'textfield',
                editable: false,
                value: 'You will choose the list when publishing this workflow'
              }
            ] : [],
            ..._.includes(['crm:tag_added','crm:tag_removed'], trigger) && !tag_id ? [
              { 
                label: 't(Tag)', 
                name: 'tag', 
                type: 'textfield'
              }
            ] : [],
            { 
              label: 't(Enrollment)', 
              name: 'is_unique', 
              type: 'radiogroup', 
              options: [
                { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
                { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
              ], 
              defaultValue: false 
            }
          ]
        }
      ]
    }
  }

  _getEmailCampaignId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_campaign_id || config.email_campaign_id || null
  }

  _getAutomationEmailId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_id || config.email_id || null
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(workflow) {
    this.props.onNext(workflow)
  }

}

export default Details
