import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class LibraryAddBatch extends React.Component {

  static propTypes = {
    entity: PropTypes.string,
    type: PropTypes.string,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity, type } = this.props
    return {
      title: 't(Add to Library)',
      saveText: 'Next',
      onCancel: this._handleCancel,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'entity', type: 'hidden', value: entity },
            { name: 'type', type: 'hidden', value: type },
            { 
              label: 't(Library Details)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Folders)', 
                  entity: 'folder',
                  name: 'folder_ids', 
                  type: 'checkboxgroup', 
                  endpoint: `/api/admin/truevail/${entity}/folders`, 
                  valueKey: 'id', 
                  textKey: 'title' 
                },
                { 
                  label: 't(Tags)', 
                  name: 'tags', 
                  type: 'tagfield', 
                  endpoint: '/api/admin/tags' 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

export default LibraryAddBatch
