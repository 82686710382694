import EmailInteractionsToken from '@admin/tokens/email_interactions'
import EmailBounceToken from '@admin/tokens/email_bounce'
import Attachments from './attachments'
import PropTypes from 'prop-types'
import Priority from './priority'
import Header from './header'
import Import from './import'
import Body from './body'
import React from 'react'

class Email extends React.Component {

  static propTypes = {
    actions: PropTypes.array,
    email: PropTypes.object,
    expandable: PropTypes.bool,
    show_interactions: PropTypes.bool
  }

  render() {
    const { email, show_interactions } = this.props
    return (
      <div className="maha-email">
        { (email.profile_id && !email.is_draft) &&
          <Import { ...this._getImport() } />
        }
        <Header { ...this._getHeader() } />
        { email.priority && email.priority !== 'normal' &&
          <Priority { ...this._getPriority() } />
        }
        { email.attachments?.length > 0 &&
          <Attachments { ...this._getAttachments() } />
        }
        { email.was_bounced ?
          <EmailBounceToken email={ email } /> :
          show_interactions ? 
            <div className={`maha-email-interactions ${email.was_bounced ? 'bounced' : ''}`}>
              <EmailInteractionsToken email={ email } />
            </div> :
            null
        }
        <Body { ...this._getBody() } />
      </div>
    )
  }

  _getAttachments() {
    const { email } = this.props
    return {
      email
    }
  }

  _getBody() {
    const { actions, email, expandable } = this.props
    return {
      actions,
      email,
      expandable
    }
  }

  _getHeader() {
    const { actions, email } = this.props
    return {
      actions,
      email
    }
  }

  _getImport() {
    const { email } = this.props
    return {
      email
    }
  }

  _getPriority() {
    const { email } = this.props
    return {
      email
    }
  }

}

export default Email
