import EmailToken from '@admin/tokens/email'
import React, { Fragment } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

class Results extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    perspective: PropTypes.string,
    records: PropTypes.array,
    show_interactions: PropTypes.bool,
    selected: PropTypes.object,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose

  render() {
    const grouped = this._getGrouped()
    return Object.keys(grouped).map((group, i) => (
      <Fragment key={`group_${i}`}>
        { grouped[group].length > 0 ?
          <>
            <div className="email-client-list-result-header">
              { group.replace('_', ' ') }
            </div>
            <div className="email-client-list-result-body">
              { grouped[group].map((email, j) => (
                <div className={ this._getClass(email) } key={`email_${email.id}`} onClick={ this._handleChoose.bind(this, email) }>
                  <EmailToken { ...this._getEmail(email) } />
                </div>
              ))}
            </div>
          </> :
          null
        }
      </Fragment>
    ))
  }

  componentDidMount() {
    const { records } = this.props
    if(records.length === 0) return
    if(document.body.clientWidth <= 1024) return
    const first = this._getFirst()
    if(first) this._handleChoose(first)
  }

  _getClass(email) {
    const { selected } = this.props
    const classes = ['email-reader-list-result']
    if(selected && email.code && email.code === selected.code) classes.push('selected')
    if(selected && email.uuid && email.uuid === selected.uuid) classes.push('selected')
    return classes.join(' ')
  }

  _getEmail(email) {
    const { perspective, show_interactions } = this.props
    return {
      email,
      perspective,
      show_interactions
    }
  }

  _getFirst() {
    const grouped = this._getGrouped()
    return Object.values(grouped).reduce((first, group) => {
      return first || group[0]
    }, null)
  }

  _getGrouped() {
    const { account } = this.context.admin
    const { records } = this.props
    const tz = account.timezone
    const today = moment().tz(tz).startOf('day')
    const yesterday = moment().tz(tz).subtract(1, 'day').startOf('day')
    const this_week = moment().tz(tz).startOf('week').startOf('day')
    const last_week = moment().tz(tz).startOf('week').subtract(1, 'week').startOf('day')
    return records.reduce((grouped, email) => ({
      ...grouped,
      ...!email.sent_at ? {
        unsent: [
          ...grouped.unsent,
          email
        ]
      } : {
        ...moment(email.sent_at).tz(tz).isAfter(today) ? {
          today: [
            ...grouped.today,
            email
          ]
        } : {
          ...moment(email.sent_at).tz(tz).isBetween(yesterday, today) ? {
            yesterday: [
              ...grouped.yesterday,
              email
            ]
          } : {
            ...moment(email.sent_at).tz(tz).isBetween(this_week, yesterday) ? {
              this_week: [
                ...grouped.this_week,
                email
              ]
            } : {
              ...moment(email.sent_at).tz(tz).isBetween(last_week, this_week) ? {
                last_week: [
                  ...grouped.last_week,
                  email
                ]
              } : {
                ...moment(email.sent_at).tz(tz).isBefore(last_week) ? {
                  older: [
                    ...grouped.older,
                    email
                  ]
                } : {}
              }
            }
          }
        }
      }
    }), { unsent: [], today: [], yesterday: [], this_week: [], last_week: [], older: [] })
  }

  _handleChoose(email) {
    this.props.onChoose(email)
  }

}

export default Results
