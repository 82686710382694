import HtmlField from '@admin/components/form/htmlfield'
import { DragSource, DropTarget } from 'react-dnd'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Image extends React.PureComponent {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    image: PropTypes.object,
    index: PropTypes.number,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func
  }

  _handleRemove = this._handleRemove.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, image } = this.props
    return connectDragSource(connectDropTarget(connectDragPreview(
      <div className="mediafield-image" >
        <div className="mediafield-image-handle">
          <Icon icon="bars" />
        </div>
        <div className="mediafield-image-image">
          <Img src={`/imagecache/fit=cover&w=100&h=100${image.asset.path}`} />
        </div>
        <div className="mediafield-image-details">
          <HtmlField { ...this._getCaption() } />
        </div>
        <Button { ...this._getRemove() } />
      </div>
    )))
  }

  _getCaption() {
    const { image } = this.props
    return {
      placeholder: 't(Enter a caption)',
      defaultValue: image.caption,
      onChange: this._handleUpdate
    }
  }

  _getRemove() {
    return {
      svg: 'x',
      className: 'mediafield-image-details-action',
      confirm: 't(Are you sure you want to remove this image?)',
      handler: this._handleRemove
    }
  }

  _handleRemove() {
    const { index } = this.props
    this.props.onRemove(index)
  }

  _handleUpdate(caption) {
    const { image, index } = this.props
    this.props.onUpdate(index, {
      ...image,
      caption
    })
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    delta: props.image.delta,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  }
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Image = DragSource('ITEM', source, sourceCollector)(Image)
Image = DropTarget('ITEM', target, targetCollector)(Image)

export default Image
