import MJSONPreview from '@admin/components/mjson_preview'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ theme }, { admin }) => {

  const program = {
    label: theme.program.title,
    className: 'link',
    route: `/admin/team/programs/${theme.program.id}`
  }

  const details = {
    audits: `maha_themes/${theme.id}`,
    comments: `maha_themes/${theme.id}`,
    header:  <MJSONPreview entity={ theme } table="maha_themes" />,
    sections: [
      {
        title: 't(Theme Details)',
        items: [
          { label: 't(Title)', content: theme.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
        ]
      }
    ]
  }

  if(theme.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This theme was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  theme: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview theme={ props.theme } />,
  tasks: !props.theme.deleted_at ? {
    items: [
      { 
        label: 't(Edit Theme)', 
        svg: 'square_pen',
        access: { 
          programs: { $canEdit: props.theme.program.id },
          rights: { $oneOf: ['team:manage_templates'] } 
        },
        modal: <Edit theme={ props.theme } /> 
      },
      {
        label: 't(Delete Theme)',
        svg: 'trash',
        access: { 
          programs: { $canEdit: props.theme.program.id },
          rights: { $oneOf: ['team:manage_templates'] } 
        },
        confirm: 't(Are you sure you want to delete this theme?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/team/themes/${props.theme.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this theme)'),
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted this theme)')
            context.router.goBack()
          }
        }
      }
    ]
  } : null
})

export default Panel(null, mapPropsToPanel)
