import Message from '@admin/components/message'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class EmailReport extends React.Component {

  static propTypes = {
    spamassassin: PropTypes.object
  }

  render() {
    const { spamassassin } = this.props
    return (
      <div className="maha-dns-table">
        { !spamassassin ?
          <Message { ...this._getUnscanned() } /> :
          spamassassin.score === 0 ?
            <Message { ...this._getNoScore() } /> :
            <>
              <p><T text="t(We scanned your email and found the following issues with the content)" /></p>
              <table className="ui unstackable compact celled table">
                <thead>
                  <tr>
                    <th>Issue</th>
                  </tr>
                </thead>
                <tbody>
                  { spamassassin.rules.map((rule, index) => (
                    <tr key={`rule_${index}`}>
                      <td>{ rule.description }</td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </>
        }
      </div>
    )
  }

  _getNoScore() {
    return {
      svg: 'mail',
      title: 't(Undetected)',
      text: 't(There are no issues with this email)'
    }
  }

  _getUnscanned() {
    return {
      svg: 'mail',
      title: 't(Unscanned)',
      text: 't(We have not yet scanned this email\'s content)'
    }
  }

}

export default EmailReport
