import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import MJSONDesigner from '@admin/components/mjson_designer'
import Dropdown from '@admin/components/form/dropdown'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class TemplateVariantDesigner extends React.Component {

  static propTypes = {
    template: PropTypes.object
  }

  state = {
    variant_id: null
  }

  _handleVariant = this._handleVariant.bind(this)

  render() {
    const { variant_id } = this.state
    return (
      <div className="campaigns-variant-designer">
        <div className="campaigns-variant-designer-header">
          <Dropdown { ...this._getVariants() } />
        </div>
        <div className="campaigns-variant-designer-body">
          { variant_id &&
            <MJSONDesigner { ...this._getMJSONDesigner() } />          
          }
        </div>
      </div>
    )
  }

  _getMJSONDesigner() {
    const { template } = this.props
    const { variant_id } = this.state
    const variant = template.variants.find(variant => {
      return variant.id === variant_id
    })
    return {
      endpoint: `/truevail_template_variants/${variant_id}`,
      root_route: `/truevail/admin/templates/${template.id}/design`,
      ...['automation_email','email_article','email_introduction','email_layout','template','email_module','email_template'].includes(template.type) ? {
        type: 'email'
      } : {
        type: 'page'
      },
      language: variant.language,
      perspective: variant.perspective,
      versionable: true,
      key: variant_id
    }
  }

  _getVariants() {
    const { template } = this.props
    return {
      options: template.variants.map(variant => ({ 
        id: variant.id,
        perspective: variant.perspective,
        language: variant.language
      })),
      valueKey: 'id',
      textKey: 'name',
      format: VariantVoiceToken,
      deselectable: false,
      selectFirst: true,
      onChange: this._handleVariant
    }
  }

  _handleVariant(variant_id) {
    this.setState({ 
      variant_id
    })
  }
  
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <TemplateVariantDesigner template={ props.template } />
})

export default Panel(null, mapPropsToPanel)
