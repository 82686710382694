import Dropdown from '@admin/components/form/dropdown'
import Button from '@admin/components/button'
import EditPanel from '../../../panels/edit'
import NewCard from '../../../cards/new'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Toolbar extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    cards: PropTypes.array,
    daterange: PropTypes.object,
    isExpanded: PropTypes.bool,
    panel: PropTypes.object,
    reflowable: PropTypes.bool,
    onDateRange: PropTypes.func,
    onReflow: PropTypes.func
  }

  render() {
    const { panel, reflowable } = this.props
    const { admin } = this.context
    return (
      <div className="maha-dashboard-panel-grid-toolbar">
        { reflowable ?
          <div className="maha-dashboard-panel-grid-toolbar-section">
            <div className="maha-dashboard-panel-grid-toolbar-instructions">
              <T text="t(Drag and drop cards to reorganize the layout of the dashboard)" />
            </div>
          </div> :
          <div className="maha-dashboard-panel-grid-toolbar-section">
            <div className="maha-dashboard-panel-grid-toolbar-field">
              <Dropdown { ...this._getType()} />
            </div>
            <div className="maha-dashboard-panel-grid-toolbar-field">
              <Dropdown { ...this._getStep()} />
            </div>
          </div>
        }
        { (admin.user.type === 'support' || panel.owner.id === admin.user.id) &&
          <div className="maha-dashboard-panel-grid-toolbar-actions">
            { reflowable ?
              <Button { ...this._getDone() } /> :
              <>
                <Button { ...this._getAdd() } />
                <Button { ...this._getReflow() } />
                <Button { ...this._getEdit() } />
                <Button { ...this._getDelete() } />
              </>
            }
          </div>   
        }     
      </div>
    )
  }

  _getAdd() {
    const { card_types, isExpanded, panel, reflowable } = this.props
    return {
      svg: 'plus',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      disabled: isExpanded || reflowable,
      tooltip: 't(Add a Card)',
      modal: <NewCard card_types={ card_types } panel={ panel } />
    }
  }

  _getDelete() {
    const { isExpanded, panel, reflowable } = this.props
    return {
      svg: 'trash',
      tooltip: 't(Delete Panel)',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      disabled: isExpanded || reflowable,
      confirm: 't(Are you sure you want to delete this panel?)',
      request: {
        method: 'DELETE',
        endpoint: `/api/admin/dashboards/panels/${panel.id}`
      }
    }
  }

  _getReflow() {
    const { isExpanded, reflowable } = this.props
    const classes = ['maha-dashboard-panel-grid-toolbar-action']
    if(reflowable) classes.push('selected')
    return {
      svg: 'move',
      tooltip: 't(Adjust Layout)',
      className: classes.join(' '),
      disabled: isExpanded,
      handler: this.props.onReflow
    }
  }

  _getDone() {
    return {
      label: 't(Done)',
      className: 'maha-dashboard-panel-grid-toolbar-action selected',
      handler: this.props.onReflow
    }

  }

  _getEdit() {
    const { isExpanded, panel, reflowable } = this.props
    return {
      svg: 'pencil',
      tooltip: 't(Edit Panel)',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      disabled: isExpanded || reflowable,
      modal: <EditPanel panel={ panel } />
    }
  }

  _getStep() {
    const { daterange } = this.props
    return {
      options: [
        { value: 'day', text: 't(Group by day)' },
        { value: 'week', text: 't(Group by week)' },
        { value: 'quarter', text: 't(Group by quarter)' },
        { value: 'month', text: 't(Group by month)' },
        { value: 'year', text: 't(Group by year)' }
      ],
      deselectable: false,
      value: daterange.step,
      onChange: this._handleDateRange.bind(this, 'step')
    }
  }

  _getType() {
    const { daterange } = this.props
    return {
      options: [
        { value: '30_days', text: 't(Last 30 days)' },
        { value: '60_days', text: 't(Last 60 days)' },
        { value: '90_days', text: 't(Last 90 days)' },
        { value: 'this_week', text: 't(This Week)' },
        { value: 'last_week', text: 't(Last Week)' },
        { value: 'this_month', text: 't(This Month)' },
        { value: 'last_month', text: 't(Last Month)' },
        { value: 'this_quarter', text: 't(This Quarter)' },
        { value: 'last_quarter', text: 't(Last Quarter)' },
        { value: 'this_year', text: 't(This Year)' },
        { value: 'last_year', text: 't(Last Year)' },
        { value: 'custom', text: 't(Custom)' }
      ],
      deselectable: false,
      value: daterange.type,
      onChange: this._handleDateRange.bind(this, 'type')
    }
  }

  _handleDateRange(key, value) {
    const { daterange } = this.props
    this.props.onDateRange({
      ...daterange,
      [key]: value
    })
  }

}

export default Toolbar
