import Message from '@admin/components/message'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    host: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    design: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    editing: false,
    canva_id: null
  }
  
  _handleLookup = this._handleLookup.bind(this)
  _handleOpen = this._handleOpen.bind(this)
  
  render() {
    return <Message { ...this._getMessage() } />
  }

  _getMessage() {
    const { canva_thumbnail } = this.props.design
    const { editing } = this.state
    return  {
      poster: `/imagecache/h=250&&dpi=2${canva_thumbnail.path}`,
      title: 't(Edit in Canva)',
      text: 't(You can now edit your assets in Canva. Make sure you click the "Return to Truevail" button when youre done so we can be notified to download your new assets)',
      buttons: [
        { 
          className: 'maha-canva-editor-button',
          children: (
            <>
              <span />
              { editing ? <T text="I'm done editing and ready to import" /> : <T text="Open in Canva" /> }
            </>
          ),
          handler: editing ? this._handleLookup : this._handleOpen
        }
      ]
    }
  }

  _getPanel() {
    return {
      title: 't(Edit in Canva)'
    }
  }

  _handleLookup() {
    const { design } = this.props
    this.context.network.request({
      endpoint: `/api/admin/profiles/${design.profile.id}/files`,
      method: 'GET',
      query: {
        $filter: {
          q: design.canva_title
        }
      },
      onSuccess: ({ data }) => {
        const found = data.sort((a, b) => {
          return moment(b.created_at).diff(moment(a.created_at))
        }).find((i,j) => j === 0)
        this.props.onDone({
          ...design,
          canva_id: found.id
        })
      }
    })
  }

  _handleOpen() {
    const { design } = this.props
    this.context.host.window.open(`${design.remix_url}`)
    this.setState({ editing: true })
  }

}

export default Edit

