import Search from '@admin/components/search'
import TeamToken from '@admin/tokens/team'
import PropTypes from 'prop-types'
import React from 'react'

class Advisor extends React.PureComponent {

  static propTypes = {
    entity: PropTypes.string,
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    const { entity } = this.props.props
    return {
      endpoint: `/api/admin/truevail/${entity}/advisors`,
      entity: 'advisor',
      compareKey: 'id',
      valueKey: null,
      textKey: 'title',
      multiple: false,
      format: (advisor) => (
        <TeamToken { ..._.omit(advisor.team, 'is_active') } />
      ),
      onChoose: this._handleDone
    }
  }

  _handleDone(advisor) {
    this.props.onNext({ advisor })
  }

}

export default Advisor
