import ModalPanel from '@admin/components/modal_panel'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

class Mapping extends React.PureComponent {

  static propTypes = {
    mappings: PropTypes.array,
    mapping: PropTypes.object,
    fields: PropTypes.array,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)

  render() {
    const { fields } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-search-options">
          <div className="maha-search-results">
            { fields.map((item, index) => (
              <Fragment key={`item_${index}`}>
                { item.fields ?
                  <>{ this.renderSegment(item) }</> :
                  <>{ this.renderField(item) }</>
                }
              </Fragment>
            )) }
          </div>
        </div>
      </ModalPanel>
    )
  }

  renderSegment(segment) {
    return (
      <div className="maha-search-results">
        <div className="maha-search-results-header">
          <T text={ segment.label } />
        </div>
        { segment.fields.map((field, index) => (
          <Fragment key={`field_${index}`}>
            { this.renderField(field) }
          </Fragment>
        )) }
      </div>
    )
  }

  renderField(field) {
    return (
      <div { ...this._getField(field) }>
        <div className="maha-search-item-label padded">
          <T text={ field.label } />
        </div>
        <div className="maha-search-item-icon">
          { this._getChecked(field.name) &&
            <Icon svg="check" />
          }
        </div>
      </div>
    )
  }

  _getClass(field) {
    const { mapping, mappings } = this.props
    const mapped = _.find(mappings, { field })
    const classes = ['maha-search-item']
    if(mapped && mapped.header !== mapping.header) classes.push('disabled')
    return classes.join(' ')
  }

  _getChecked(field) {
    const { mapping } = this.props
    return mapping.field === field
  }

  _getField(field) {
    return {
      className: this._getClass(field.name),
      onClick: this._handleChoose.bind(this, field.name)
    }
  }

  _getPanel() {
    return {
      title: 't(Choose Field)',
      leftItems: [
        { icon : 'chevron-left', handler: this._handleCancel }
      ],
      rightItems: [
        { label : 'Done', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleChoose(field) {
    const { mapping } = this.props
    this.props.onDone({
      ...mapping,
      field
    })
  }

}

export default Mapping
