import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

const DesignCard = ({ design }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <Image src={ design.thumbnail.path } />
    </div>
  </div>
)

DesignCard.propTypes = {
  design: PropTypes.object
}

export default DesignCard
