import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static propTypes = {
    ticket_type: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    ticket_type: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.ticket_type) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { ticket_type } = this.props
    this.setState({ ticket_type })
  }

  _getForm() {
    const { ticket_type } = this.state
    return {
      title: 't(Edit Ticket Type)',
      cancelSvg: 'chevron_left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'is_active', type: 'hidden', defaultValue: ticket_type.is_active },
            { label: 't(Name)', name: 'name', type: 'textfield', required: true, defaultValue: ticket_type.name },
            { label: 't(Description)', name: 'description', type: 'textarea', defaultValue: ticket_type.description },
            { label: 't(Pricing)', type: 'segment', required: true, fields: [
              { name: 'price_type', type: 'radiogroup', options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'sliding_scale', text: 't(Sliding Scale)' },
                { value: 'free', text: 't(Free)'}
              ], required: true, defaultValue: ticket_type.price_type },
              ...this._getPriceType()
            ] },
            { label: 't(Availability)', type: 'segment', fields: [
              { type: 'fields', fields: [
                { label: 't(Total Tickets)', name: 'total_tickets', type: 'numberfield', defaultValue: ticket_type.total_tickets },
                { label: 't(Maximum Per Order)', name: 'max_per_order', type: 'numberfield', defaultValue: ticket_type.max_per_order }
              ] },
              { type: 'fields', fields: [
                { label: 't(Sales Open)', name: 'sales_open_at', type: 'datetimefield', defaultValue: ticket_type.sales_open_at },
                { label: 't(Sales Close)', name: 'sales_close_at', type: 'datetimefield', defaultValue: ticket_type.sales_close_at }
              ] }
            ] }
          ]
        }
      ]
    }
  }

  _getPriceType() {
    const { ticket_type } = this.state
    if(ticket_type.price_type === 'fixed') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, defaultValue: ticket_type.project_id },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: ticket_type.revenue_type_id }
        ] },
        { label: 't(Fixed Price)', name: 'fixed_price', type: 'moneyfield', required: true, defaultValue: ticket_type.fixed_price },
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: ticket_type.tax_rate  },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)', defaultValue: ticket_type.is_tax_deductible  }
      ]
    }
    if(ticket_type.price_type === 'sliding_scale') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, defaultValue: ticket_type.project_id },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: ticket_type.revenue_type_id }
        ] },
        { type: 'fields', fields: [
          { label: 't(Low Price)', name: 'low_price', type: 'moneyfield', required: true, defaultValue: ticket_type.low_price },
          { label: 't(High Price)', name: 'high_price', type: 'moneyfield', required: true, defaultValue: ticket_type.high_price }
        ] },
        { label: 't(Overage Strategy)', name: 'overage_strategy', type: 'dropdown', options: [
          { value: 'income', text: 't(Treat any amount over the low price as additional income)' },
          { value: 'donation', text: 't(Treat any amount over the low price as a donation)' }
        ], required: true, defaultValue: ticket_type.overage_strategy },
        ...this._getOverageStrategy(),
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: ticket_type.tax_rate  },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)', defaultValue: ticket_type.is_tax_deductible  }
      ]
    }
    return []
  }

  _getOverageStrategy() {
    const { ticket_type } = this.state
    if(ticket_type.overage_strategy === 'donation') {
      return [
        { label: 't(Donation Revenue Type)', name: 'donation_revenue_type_id', type: 'lookup',  endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: ticket_type.donation_revenue_type_id }
      ]
    }
    return []
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(ticket_type) {
    this.setState({ ticket_type })
  }

  _handleSuccess(ticket_type) {
    this.props.onDone({
      id: this.props.ticket_type.id,
      ...ticket_type
    })
  }

}

export default Edit
