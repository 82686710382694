import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_batch: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_batch } = this.props
    return {
      title: 't(Edit Social Batch)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/agency/social_batches/${social_batch.id}/edit`,
      action: `/api/admin/truevail/agency/social_batches/${social_batch.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { 
              label: 't(Library Details)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Folders)', 
                  entity: 'folder',
                  name: 'folder_ids', 
                  type: 'checkboxgroup', 
                  endpoint: '/api/admin/truevail/agency/folders', 
                  valueKey: 'id', 
                  textKey: 'title' 
                },
                { 
                  label: 't(Tags)', 
                  name: 'tags', 
                  type: 'tagfield', 
                  endpoint: '/api/admin/tags' 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
