import Finder from '@admin/components/finder'
import Logo from '@admin/components/logo'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Apps extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    analytics: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    items: PropTypes.array,
    state: PropTypes.string,
    title: PropTypes.string,
    onBack: PropTypes.func,
    onDone: PropTypes.func,
    onForward: PropTypes.func,
    onSetMode: PropTypes.func
  }

  _handleToggleMode = this._handleToggleMode.bind(this)

  render() {
    const { teams, team } = this.context.admin
    return (
      <div className="maha-navigation-panel apps">
        <div className="maha-navigation-header" onClick={ this._handleToggleMode }>
          <div className="maha-navigation-header-back">
            <Logo team={ team } width="32" />
          </div>
          <div className="maha-navigation-header-team">
            <div className="maha-navigation-header-team-title">
              <T text={ team.title } />
            </div>
          </div>
          { teams.length > 1 &&
            <div className="maha-navigation-header-next">
              <Icon svg="chevron_up" />
            </div>
          }
        </div>
        <div className="maha-navigation-body">
          <Finder { ...this._getFinder() } />
        </div>
      </div>
    )
  }

  _getFinder() {
    const { items } = this.props
    return {
      items: this._getItems(items)
    }
  }

  _getItems(items) {
    return items.sort((a,b) => {
      return a.label < b.label ? -1 : 1
    }).map(item => ({
      ...item.svg ? { svg: item.svg } : item.icon ? { icon: item.icon } : {},
      label: item.label,
      ...item.items ? {
        children: this._getItems(item.items)
      } : {
        onClick: this._handleClick.bind(this, item)
      }
    }))
  }

  _handleClick(item) {
    this.context.analytics.track(`clicked '${item.label}' in apps navigation`)
    this.context.router.push(item.route)
    this.props.onDone()
  }

  _handleToggleMode() {
    const { teams } = this.context.admin
    if(teams.length === 1) return
    this.props.onSetMode('teams')
  }

}

export default Apps
