import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Badge extends React.Component {

  static contextTypes = {
    portal: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.bool,
    channel: PropTypes.string,
    count: PropTypes.number,
    icon: PropTypes.string,
    sound: PropTypes.string,
    svg: PropTypes.string
  }

  render() {
    const { count, icon, svg } = this.props
    return (
      <div className="maha-badge">
        { count > 0 &&
          <div className="maha-badge-label" title={`${count} unread`}>{ count > 99 ? '99+' : count }</div>
        }
        { svg ? <Icon svg={ svg } /> : icon ? <Icon icon={ icon } /> : null }
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { count } = this.props
    if(count !== prevProps.count) {
      this.context.portal.updateUnseen(count - prevProps.count)
    }
  }

}

export default Badge
