import Token from './token'
import Form from './form'

export default {
  svg: 'thumbs_up',
  label: 'Update Consent',
  type: 'contact',
  action: 'consent',
  form: Form,
  token: Token
}
