import FormPublish from '@apps/truevail/admin/components/form_publish'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forms)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/forms`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Form)', key: 'title' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' }, 
    empty: {
      svg: 'square_check',
      title: 't(No Forms)',
      text: 't(You have not yet created any forms for this advisor)',
      buttons: [
        { 
          label: 't(Publish Advisor Form)',
          modal: <FormPublish advisor={ props.advisor } entity="agency" />
        }
      ]
    },
    entity: 'form'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Advisor  Form)',
    modal: <FormPublish advisor={ props.advisor } entity="agency" />
  }
})

export default Panel(null, mapPropsToPanel)
