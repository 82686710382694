import RoleToken from '@apps/team/admin/tokens/role'
import Panel from '@admin/components/panel'
import New from '../../roles/new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Roles)',
  access: { rights: { $oneOf: ['team:view_roles','team:manage_roles'] } },
  collection: {
    endpoint: '/api/admin/team/roles',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, RoleToken },
      { label: 't(Users)', key: 'users_count', collapsing: true, primary: true, align: 'right' }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      svg: 'network',
      title: 't(No Roles)',
      text: 't(You have not yet created any roles)',
      buttons: [
        { 
          label: 't(Create Role)', 
          access: { rights: { $oneOf: ['team:manage_roles'] } },
          modal: New 
        }
      ]
    },
    entity: 'role', 
    onClick: (record) => context.router.push(`/team/roles/${record.id}`)
  },
  task: {
    access: { rights: { $oneOf: ['team:manage_roles'] } },
    tooltip: 't(New Role)',
    svg: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
