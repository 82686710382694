import Notifications from '@admin/components/notifications'
import Dashboard from '@apps/dashboards/admin/components/dashboard'
import StackRouter from '@admin/components/stack_router'
import { CSSTransition } from 'react-transition-group'
import Navigation from '@admin/components/navigation'
import Timezone from '@admin/components/timezone'
import Greeting from '@admin/components/greeting'
import Account from '@admin/components/account'
import Verify from '@admin/components/verify'
import Prompt from '@admin/components/prompt'
import Button from '@admin/components/button'
import Ribbon from '@admin/components/ribbon'
import Tasks from '@admin/components/tasks'
import Modal from '@admin/components/modal'
import Push from '@admin/components/push'
import PropTypes from 'prop-types'
import Sidebar from './sidebar'
import React from 'react'

export const PortalContext = React.createContext(null)
PortalContext.displayName = 'PortalContext'

class Portal extends React.Component {

  static childContextTypes = {
    portal: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object,
    maha: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.number,
    mode: PropTypes.string,
    unseen: PropTypes.number,
    onChoose: PropTypes.func,
    onSetMode: PropTypes.func,
    onUpdateUnseen: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)
  _handleCloseSidebar = this._handleCloseSidebar.bind(this)
  _handleSetMode = this._handleSetMode.bind(this)
  _handleUpdateUnseen = this._handleUpdateUnseen.bind(this)

  render() {
    const { admin, maha } = this.context
    const { active, mode } = this.props
    const { team, user } = admin
    if(!team || !user) return null
    return (
      <PortalContext.Provider value={ this.getChildContext() }>
        <div className={ this._getClass() }>
          <Notifications>
            <Push>
              <Modal>
                <Prompt>
                  <Verify>
                    <Modal>
                      <Tasks>
                        <Timezone>
                          <div className="maha-portal-frame">
                            <div className="maha-portal-ribbon">
                              <Ribbon { ...this._getRibbon() } />
                            </div>
                            <div className="maha-portal-main">
                              <Sidebar sidebar={ maha.badges[active]?.sidebar } />
                              <div className="maha-portal-body">
                                { admin.hasRights({ allOf: ['dashboards:access_app'] }) ?
                                  <Dashboard /> :
                                  <Greeting { ...this._getGreeting() }/>
                                }
                                <StackRouter />
                              </div>
                              <CSSTransition in={ mode !== null } classNames="fade-in-half" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
                                <Button { ...this._getOverlay() } />
                              </CSSTransition>
                              <div className="maha-portal-account">
                                <Account { ...this._getAccount() } />
                              </div>
                              <div className="maha-portal-navigation">
                                <Navigation { ...this._getNavigation() } />
                              </div>
                            </div>
                          </div>
                        </Timezone>
                      </Tasks>
                    </Modal>
                  </Verify>
                </Prompt>
              </Modal>
            </Push>
          </Notifications>
        </div>
      </PortalContext.Provider>
    )
  }

  componentDidMount() {
    this.context.host.device.setCount(0)
  }

  componentDidUpdate(prevProps) {
    const { unseen } = this.props
    if(unseen !== prevProps.unseen) {
      this.context.host.device.setCount(unseen)
    }
  }

  getChildContext() {
    return {
      portal: {
        closeSidebar: this._handleCloseSidebar,
        updateUnseen: this._handleUpdateUnseen
      }
    }
  }

  _getAccount() {
    return {
      onDone: this._handleSetMode.bind(this, null)
    }
  }

  _getClass() {
    const { active, mode } = this.props
    const classes = ['maha-portal']
    if(active !== null) classes.push('sidebar')
    if(mode) classes.push(mode)
    return classes.join(' ')
  }

  _getGreeting() {
    const { user } = this.context.admin
    return { user }
  }

  _getNavigation() {
    return {
      onDone: this._handleSetMode.bind(this, null)
    }
  }

  _getOverlay() {
    return {
      label: '',
      className: 'maha-portal-overlay',
      event: 'closed navigation',
      handler: this._handleSetMode.bind(this, null)
    }
  }

  _getRibbon() {
    const { active } = this.props
    return {
      active,
      onChoose: this._handleChoose,
      onHelp: this._handleHelp,
      onSetMode: this._handleSetMode
    }
  }

  _handleChoose(index) {
    const { badges } = this.context.maha
    this._handleSetMode(null)
    if(document.body.clientWidth > 1024) return this.props.onChoose(index)
    const badge = badges[index]
    if(badge.route) this.context.router.push(badge.route)
  }

  _handleSetMode(mode) {
    this.props.onSetMode(mode)
  }

  _handleCloseSidebar() {
    this.props.onChoose(null)
  }

  _handleUpdateUnseen(unseen) {
    this.props.onUpdateUnseen(unseen)
  }

}

export default Portal
