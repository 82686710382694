import Logo from '@admin/components/logo'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Activities extends React.PureComponent {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    activity: PropTypes.object,
    contact: PropTypes.object
  }

  state = {
    show: false
  }

  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { activity, contact } = this.props
    const { show } = this.state
    const type = this.context.maha.activityCards[activity.type]
    return (
      <div className={ this._getClass() }>
        <div className="crm-timeline-card-header" onClick={ this._handleToggle }>
          <div className="crm-timeline-card-header-toggle">
            <Icon svg="chevron_right" />
          </div>
          <div className="crm-timeline-card-header-details">
            <div className="crm-timeline-card-header-description">
              { activity.user ?
                <strong>{ activity.user.full_name }</strong> :
                <strong>{ contact.display_name }</strong>
              } { activity.story }
            </div>
            { activity.program &&
              <div className="crm-timeline-card-header-program">
                <Logo team={ activity.program } width="24" />
                { activity.program.title }
              </div>
            }
            <div className="crm-timeline-card-header-timestamp">
              <Date datetime={activity.created_at} />
            </div>
          </div>
          { false &&
            <div className="crm-timeline-card-header-tasks">
              <Icon svg="ellipsis" />
            </div>
          }
        </div>
        { show &&
          <type.component activity={ activity } />
        }
      </div>
    )
  }

  _getClass() {
    const { show } = this.state
    const classes = ['crm-timeline-card']
    if(show) classes.push('expanded')
    return classes.join(' ')
  }

  _getToggle() {
    const { show } = this.state
    return {
      label: show ? 'hide details' : 'show details',
      className: 'link',
      handler: this._handleToggle
    }
  }

  _handleToggle() {
    const { show } = this.state
    this.setState({
      show: !show
    })
  }

}

export default Activities
