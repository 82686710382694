import MJSONPreview from '@admin/components/mjson_preview'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ module }) => {

  const details = {
    audits: `maha_modules/${module.id}`,
    comments: `maha_modules/${module.id}`,
    header:  <MJSONPreview entity={ module } table="maha_modules" />,
    sections: [
      {
        title: 't(Module Overview)',
        items: [
          { label: 'Title ', content: module.title },
          { label: 'Category', content: module.category.title }
        ]
      }
    ]
  }


  if(module.deleted_at) {
    details.alert = { color: 'red', message: 't(This module was deleted)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  module: PropTypes.notice
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview module={ props.module } />,
  ...props.module.deleted_at === null ? {
    tasks: {
      items: [
        {
          label: 't(Edit Module)',
          svg: 'square_pen',
          modal: <Edit module={ props.module } />
        },
        {
          label: 't(Delete Module)',
          svg: 'trash',
          confirm: 't(Are you sure you want to delete this module?)',
          deletable: true,
          request: {
            endpoint: `/api/admin/platform/modules/${props.module.id}`,
            method: 'DELETE',
            onSuccess: () => {
              context.router.goBack()
              context.flash.set('success', 't(The module was successfully deleted)')
            }
          }
        }
      ]  
    }
  } : {}
})

export default Panel(null, mapPropsToPanel)
