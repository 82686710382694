import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import VoiceToken from './voice'
import PhotoToken from './photo'
import React from 'react'

class Personalization extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { advisor } = this.props
    const { config } = this.state
    const { team } = advisor
    return {
      ref: this.formRef,
      title: 't(Personalization)',
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          disabled: true 
        },
        { 
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Photo)',
              instructions: 't(Upload a professional or travel photo of yourself, which may be used for personalized marketing materials)',
              type: 'segment',
              fields: [
                { 
                  name: 'personalization_photo_strategy',
                  type: 'radiogroup',
                  options: ['upload','optout'],
                  defaultValue: config.personalization_photo_strategy || 'upload',
                  format: PhotoToken 
                },
                { 
                  name: 'personalization_photo_id',
                  show: config.personalization_photo_strategy === 'upload',
                  type: 'attachmentfield',
                  prompt: 'Upload Photo',
                  allow: { content_types: ['images'] },
                  multiple: false,
                  required: true,
                  defaultValue: config.personalization_photo_id 
                }                
              ],
              require: true 
            },
            { 
              label: 't(Voice)',
              instructions: 't(Select the style of voice of your content)',
              name: 'personalization_voice',
              type: 'radiogroup',
              options: ['individual_american','agency_american','individual_canadian','agency_canadian'],
              defaultValue: config.personalization_voice || 'individual_american',
              format: VoiceToken,
              required: true 
            },
            { 
              label: 't(Timezone)',
              instructions: 't(Select the timezone you prefer your content to be scheduled in)',
              name: 'personalization_timezone_id',
              type: 'lookup',
              endpoint: '/api/admin/timezones',
              valueKey: 'id',
              textKey: 'zone',
              defaultValue: config.personalization_timezone_id,
              required: true 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Personalization
