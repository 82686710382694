import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Enrollments)',
  access: {},
  collection: {
    endpoint: `/api/admin/automation/workflows/${props.workflow.id}/enrollments`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (enrollment) => <ContactToken { ...enrollment.contact } /> },
      { label: 't(Enrolled At)', key: 'created_at', format: 'datetime' },
      { label: 't(Converted)', key: 'was_converted', collapsing: true, format: 'check' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    empty: {
      svg: 'user_round',
      title: 't(No Enrollments)',
      text: 't(There are no contacts enrolled in this workflow)',
      buttons: [
        { 
          label: 't(Enroll Contact)', 
          access: {
            programs: { $canEdit: props.workflow.program.id },
            rights: { $oneOf: ['automation:manage_workflows'] }
          },
          modal: <New workflow={ props.workflow } /> 
        }
      ]
    },
    defaultSort: { key: '-created_at', order: 'asc' },
    entity: 'enrollment',
    onClick: (record) => context.router.push(`/automation/workflows/${props.workflow.id}/enrollments/${record.id}`)
  },
  tasks: {
    svg: 'plus',
    items: [
      {
        label: 't(Enroll Contact)', 
        access: {
          programs: { $canEdit: props.workflow.program.id },
          rights: { $oneOf: ['automation:manage_workflows'] }
        },
        modal: <New workflow={ props.workflow } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
