import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Layout from './layout'
import React from 'react'
import qs from 'qs'

class Unsplash extends React.Component {

  static propTypes = {
    doneText: PropTypes.any,
    files: PropTypes.array,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    const { doneText, files } = this.props
    return {
      title: 'Unsplash Images',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: files.length > 0 ? [
        { label: doneText, handler: this._handleNext }
      ] : []
    }
  }

  _getSearch() {
    const { files } = this.props
    return {
      endpoint: '/api/admin/unsplash',
      layout: Layout,
      label: 'photo',
      onChange: this._handleChoose,
      valueKey: null,
      props: {
        files
      }
    }
  }

  _handleChoose(photo) {
    const url = new URL(photo.urls.regular)
    const query = qs.parse(url.search.substr(1))
    query.w = Math.max(1080, photo.width)
    this.props.onAdd({
      id: photo.id,
      create: {
        endpoint: '/api/admin/assets/url',
        body: {
          file_name: `${url.pathname}.jpg`,
          url: `${url.origin}${url.pathname}?${qs.stringify(query)}`
        }
      },
      source_id: 'unsplash',
      name: photo.id,
      service: 'unsplash',
      content_type: 'image/jpeg',
      thumbnail: photo.urls.thumb,
      status: 'pending'
    })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Unsplash
