import VoiceCampaignToken from '@apps/campaigns/admin/tokens/campaign/voice'
import ProgramForm from '@apps/crm/admin/components/programform'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Voice Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } },
  collection: {
    endpoint: '/api/admin/campaigns/voice',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (voice_campaign) => (
        <VoiceCampaignToken voice_campaign={ voice_campaign } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    criteria: {
      fields: [
        { label: 't(Voice Campaign)', fields: [
          { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Calls)', key: 'calls_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'phone',
      title: 't(No Voice Campaigns)',
      text: 't(You have not yet created any voice campaigns)',
      buttons: [
        { 
          label: 't(Create Voice Campaign)', 
          access: { rights: { $oneOf: ['campaigns:manage_voice_campaigns'] } },
          modal: <ProgramForm requires={['phone_number']} form={ New } /> 
        }
      ]
    },
    entity: 'voice campaign',
    onClick: (record) => context.router.push(`/campaigns/voice/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Voice Campaign)', 
    access: { rights: { $oneOf: ['campaigns:manage_voice_campaigns'] } },
    modal: <ProgramForm requires={['phone_number']}  form={ New } />
  }
})

export default Page(null, mapPropsToPage)
