import ModalPanel from '@admin/components/modal_panel'
import ToggleList from '@admin/components/toggle_list'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Chooser extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    chosen: PropTypes.array,
    entity: PropTypes.string,
    defaultQuery: PropTypes.object,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    form: PropTypes.object,
    format: PropTypes.any,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    oneAtATime: PropTypes.bool,
    options: PropTypes.array,
    search: PropTypes.bool,
    showToggles: PropTypes.bool,
    sort: PropTypes.object,
    textKey: PropTypes.string,
    token: PropTypes.any,
    valueKey: PropTypes.string,
    onSetChosen: PropTypes.func
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { form } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <ToggleList { ...this._getToggleList() } />
        { form &&
          <div className="maha-lookup-panel-add">
            <Button { ...this._getAdd() } />
          </div>
        }
      </ModalPanel>
    )
  }

  _getAdd() {
    const { entity, label } = this.props
    return {
      label: `t(Add) ${label || entity}`,
      color: 'blue',
      handler: this._handleAdd
    }
  }

  _getNew() {
    const { form } = this.props
    return {
      form,
      onSuccess: this._handleSuccess
    }
  }

  _getPanel() {
    const { entity, label, multiple } = this.props
    return {
      title: `t(Choose) ${label || entity}`,
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ],
      rightItems: multiple ? [
        { label: 't(Done)', handler: this._handleCancel }
      ] : null
    }
  }

  _getToggleList() {
    const { chosen, defaultQuery, endpoint, filter, format, multiple, options, search, showToggles, sort, textKey, token } = this.props
    return {
      defaultValue: chosen,
      defaultQuery,
      endpoint,
      filter,
      options,
      format,
      full: true,
      multiple,
      search,
      showToggles,
      sort,
      textKey,
      token,
      valueKey: null,
      onChange: this._handleChoose
    }
  }

  _handleAdd() {
    this.context.form.push(New, this._getNew.bind(this))
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChoose(items) {
    const { multiple, oneAtATime, chosen } = this.props    
    this.props.onSetChosen(items)
    if(!_.isEqual(items, chosen) && (oneAtATime || !multiple )) this.context.form.pop()
  }

  _handleSuccess(created) {
    const { multiple, chosen } = this.props    
    this.props.onSetChosen([...chosen, created])
    this.context.form.pop(multiple ? -1 : -2)
  }

}

export default Chooser
