import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const actions = {
  add: 't(Add to)',
  remove: 't(Remove from)'
}

const Token = ({ action, list }) => (
  <><T text={actions[action]} /> <T text={ list?.title || 't(unspecified list)' } /></>
)

Token.propTypes = {
  action: PropTypes.string,
  list: PropTypes.object
}

export default Token