import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    medium: PropTypes.string
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { programs, team } = this.context.admin
    const { config } = this.state
    return {
      title: 't(New Signup)',
      method: 'POST',
      action: '/api/admin/truevail/admin/signups',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Signup Details)',
              type: 'segment',
              fields: [
                { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
                { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
                { label: 't(Organization)', name: 'organization', type: 'textfield', required: true },
                { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
                { label: 't(Timezone)', name: 'timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true, defaultValue: 172 }
              ]
            },
            {
              label: 't(Entity)',
              type: 'segment',
              fields: [
                {
                  name: 'entity_type',
                  type: 'dropdown',
                  options: [
                    { value: 'advisor', text: 't(Advisor)' },
                    { value: 'agency', text: 't(Agency)' },
                    { value: 'supplier', text: 't(Supplier)' }
                  ],
                  defaultValue: 'advisor'
                },
                { 
                  label: 't(Agency)', 
                  name: 'agency_id', 
                  type: 'dropdown',
                  endpoint: '/api/admin/truevail/admin/agencies', 
                  valueKey: 'id', 
                  textKey: 'team.title', 
                  required: true,
                  show: config.entity_type === 'advisor',
                  defaultValue: 3
                },
                { 
                  label: 't(Public Domain)', 
                  name: 'public_domain', 
                  type: 'domainfield',
                  provider_id: team.provider.id, 
                  required: true
                }
              ]
            },
            {
              label: 't(Billing Subscription)',
              type: 'segment',
              fields: [
                {
                  name: 'billing_strategy',
                  type: 'radiogroup',
                  options: [
                    { value: 'subscription', text: 't(Set up a billing subscription)' },
                    { value: 'free', text: 't(Do not set up a billing subscription)' }
                  ],
                  required: true,
                  defaultValue: 'subscription'
                },
                {
                  label: 't(Plan)',
                  name: 'plan_id',
                  type: 'dropdown',
                  endpoint: '/api/admin/subscriptions/services/3/plans',
                  valueKey: 'id',
                  textKey: 'title',
                  required: true,
                  show: config.billing_strategy === 'subscription',
                  defaultValue: 4
                },
                {
                  label: 't(Pricing Model)',
                  name: 'pricing_model_id',
                  type: 'dropdown',
                  endpoint: '/api/admin/truevail/admin/pricing_models',
                  valueKey: 'id',
                  textKey: 'title',
                  required: true,
                  show: config.billing_strategy === 'subscription',
                  defaultValue: 1
                },
                { 
                  label: 't(Credit Card)', 
                  name: 'payment', 
                  type: StripeCardField, 
                  stripeAccount: programs[0].stripe_id,
                  show: config.billing_strategy === 'subscription'
                }              
              ]
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
