import EmailClient from '@apps/email/admin/components/email_client'
import ProfileManager from '@admin/components/profile_manager'
import New from '@apps/email/admin/views/emails/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  component: <EmailClient profile={ props.profile } folder={ props.folder } mode="full" />,
  tasks: {
    visible: ['mobile'],
    items: [
      { 
        label: 't(Compose Email)',
        svg: 'mail',
        modal: <New /> 
      },
      {
        label: 't(Sync Email)',
        svg: 'refresh_cw',
        request: {
          endpoint: '/api/admin/email/sync',
          method: 'GET'
        }
      },
      { 
        label: 't(Manage Accounts)', 
        svg: 'users_round',
        modal: <ProfileManager types={['emails']} /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
