import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Profiles extends React.Component {

  static propTypes = {
    empty: PropTypes.object,
    profiles: PropTypes.array,
    showHeader: PropTypes.bool,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func,
    onConnect: PropTypes.func
  }

  _handleConnect = this._handleConnect.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { profiles } = this.props
    const grouped = this._getGrouped()
    return (
      <ModalPanel { ...this._getPanel() }>
        { profiles.length > 0 ?
          <div className="maha-profile-manager-profiles-container">
            <div className="maha-profile-manager-profiles">
              { Object.keys(grouped).map((group, i) => (
                <div key={`group_${i}`}>
                  { group !== 'default' &&
                    <div className="maha-profile-manager-header">
                      <T text={ group } />
                    </div>
                  }
                  { grouped[group].map((profile, index) => (
                    <div { ...this._getProfile(profile)} key={`profile_${index}`}>
                      <div className="maha-profile-manager-profile-label">
                        <ProfileToken profile={ profile } />
                      </div>
                      <div className="maha-profile-manager-profile-proceed">
                        <Icon svg="chevron_right" />
                      </div>
                    </div>
                  )) }
                </div>
              )) }
            </div>
            <div className="maha-attachments-connect">
              <Button { ...this._getConnect()} />
            </div>
          </div> :
          <Message { ...this._getEmpty() } />
        }
      </ModalPanel>
    )
  }

  _getConnect() {
    return {
      label: '+ t(Connect another account)',
      className: 'link',
      handler: this._handleConnect
    }
  }

  _getEmpty() {
    const { empty } = this.props
    return {
      ...empty,
      buttons: [
        { label: 'Connect an account', color: 'blue', handler: this._handleConnect }
      ]
    }
  }

  _getGrouped() {
    const { profiles, types } = this.props
    return profiles.reduce((grouped, profile) => ({
      ...grouped,
      ...!types && profile.group ? {
        [profile.group]: [
          ...grouped[profile.group] || [],
          profile
        ]
      } : {
        default: [
          ...grouped.default || [],
          profile
        ]
      }
    }), {})
  }

  _getPanel() {
    const { showHeader } = this.props
    return {
      title: 't(Choose account)',
      showHeader
    }
  }

  _getProfile(profile) {
    return {
      className: 'maha-profile-manager-profile',
      onClick: this._handleChoose.bind(this, profile)
    }
  }

  _handleChoose(profile) {
    this.props.onChoose(profile)
  }

  _handleConnect() {
    this.props.onConnect()
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: props.program_id ? `/api/admin/programs/${props.program_id}/profiles` : '/api/admin/profiles',
    refresh: props.program_id ? `/teams/${context.admin.team.id}/admin/profiles` : `/accounts/${context.admin.account.id}/admin/profiles`,
    ...(props.sources || props.types) ? {
      filter: {
        $and: [
          ...props.sources ? [{
            source: { $in: props.sources }
          }] : [],
          ...props.types ? [{
            type: { $in: props.types }
          }] : []   
        ]
      }
    } : {}
  }
})

export default Container(mapResources)(Profiles)
