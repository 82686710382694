import { CSSTransition } from 'react-transition-group'
import Scrollbar from '@admin/components/scrollbar'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class Scrollpane extends React.Component {

  static propTypes = {
    children: PropTypes.any,
    notificationPercent: PropTypes.number,
    records: PropTypes.array,
    onPullDown: PropTypes.func,
    onReachBottom: PropTypes.func
  }

  static defaultProps = {
    notificationPercent: 30,
    onPullDown: () => {},
    onReachBottom: () => {}
  }

  pane = null
  panelRef = React.createRef()

  state = {
    notified: false,
    refresh: false,
    scrollTop: 0,
    signpost: false
  }

  _handleScroll = this._handleScroll.bind(this) //_.throttle(this._handleScroll.bind(this), 100)
  _handleScrollToTop = this._handleScrollToTop.bind(this)

  render() {
    const { refresh, scrollTop, signpost } = this.state
    const { children } = this.props
    return (
      <div className="maha-scrollpane" ref={ (node) => this.pane = node }>
        { refresh &&
          <div className="maha-scrollpane-loader">
            <Icon icon="circle-o-notch fa-spin" />
          </div>
        }
        <Scrollbar { ...this._getPanel() }>
          { _.castArray(children).map((child, index) => (
            <Fragment key={`child_${index}`}>
              { React.cloneElement(child, {
                isOverscrolled: scrollTop < 0
              }) }
            </Fragment>
          )) }
        </Scrollbar>
        <CSSTransition in={ signpost } classNames="popin" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-scrollpane-signpost" onClick={ this._handleScrollToTop }>
            <Icon icon="chevron-up" />
          </div>
        </CSSTransition>
      </div>
    )
  }

  componentDidMount() {
    this._handleScroll()
  }

  componentDidUpdate(prevProps) {
    const { records } = this.props
    if(!_.isEqual(records, prevProps.records)) {
      this.setState({
        notified: false,
        refresh: false
      })
    }
  }

  _getPanel() {
    return {
      className: 'maha-scrollpane-inner',
      ref: this.panelRef,
      onScroll: this._handleScroll,
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd
    }
  }

  _handleScroll(e) {
    const { notificationPercent } = this.props
    const { notified, refresh, signpost } = this.state
    const { offsetHeight, scrollHeight, scrollTop } = this.panelRef.current
    if(scrollTop < 0) e.stopPropagation()
    const bottomPosition = scrollHeight - (scrollTop + offsetHeight)
    const percentRemaining = (bottomPosition / scrollHeight) * 100
    const showSignpost = scrollTop > 100
    if(!refresh && scrollTop < -50) {
      this.setState({
        refresh: true,
        scrollTop
      }, () => {
        this.props.onPullDown()
      })
    }
    if(refresh && scrollTop >= 0) {
      this.setState({
        refresh: false,
        scrollTop
      })
    }
    if(!notified && percentRemaining <= notificationPercent) {
      this.setState({
        notified: true,
        scrollTop
      }, () => {
        this.props.onReachBottom()
      })
    }
    if(signpost !== showSignpost) {
      this.setState({
        signpost: showSignpost
      })
    }
  }

  _handleScrollToTop() {
    this.panelRef.current.scrollTop = 0
  }

}

export default Scrollpane
