import Html from '@admin/components/html'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Control from './control'
import Segment from './segment'
import Fields from './fields'
import React from 'react'

class Field extends React.Component {

  static propTypes = {
    after: PropTypes.string,
    collapsed: PropTypes.bool,
    collapsing: PropTypes.bool,
    data: PropTypes.object,
    errors: PropTypes.object,
    field: PropTypes.object,
    fields: PropTypes.array,
    status: PropTypes.string,
    onBusy: PropTypes.func,
    onReady: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdateData: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    collapsing: false,
    onReady: () => {}
  }

  _handleToggle = this._handleToggle.bind(this)

  constructor(props) {
    super(props)
    const collapsed = (props.field.collapsed !== null) ? props.field.collapsed : props.field.collapsing
    this.state = {
      htmlFor: null,
      collapsed
    }
  }

  _handleBusy = this._handleBusy.bind(this)
  _handleReady = this._handleReady.bind(this)

  render() {
    const { after, collapsing, include, instructions, label, type } = this.props.field
    const { htmlFor } = this.state
    const error = this._getError()
    if(include === false) return null
    return (
      <div className={ this._getClass() }>
        { label &&
          <div className="maha-form-field-label">
            <label htmlFor={ htmlFor } onClick={ this._handleToggle }>
              { collapsing &&
                <div className="maha-form-field-label-icon">
                  <Icon svg="chevron_right" />
                </div>
              } <T text={ label } />
            </label>
          </div>
        }
        <div className="maha-form-field-control">
          { instructions &&
            <div className="maha-form-field-instructions">
              { this._getInstructions(instructions) }
            </div>
          }
          { type === 'fields' && <Fields { ...this._getFields() } /> }
          { type === 'segment' && <Segment { ...this._getFields() } /> }
          { !_.includes(['fields','segment'], type) && <Control { ...this._getControl() } /> }
          { error &&
            <div className="error-message">
              <T text={ error } />
            </div>
          }
          { after }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      htmlFor: _.random(100000000, 999999999).toString(36)
    })
  }

  _getClass() {
    const { field } = this.props
    const { collapsing, type, required } = field
    const { collapsed } = this.state
    const error = this._getError()
    const classes = ['maha-form-field']
    classes.push(field.type === 'fields' ? 'fields' : 'field')
    if(_.includes(['hidden'], type)) classes.push('hidden')
    if(required) classes.push('required')
    if(field.show === false) classes.push('hidden')
    if(error) classes.push('error')
    if(collapsing) classes.push('collapsing')
    if(collapsing) classes.push(collapsed ? 'collapsed' : 'expanded')
    return classes.join(' ')
  }

  _getControl() {
    const { data, field, status, onSubmit } = this.props
    const tabIndex = this._getTabIndex(field.name)
    const { htmlFor } = this.state
    const { name } = field
    return {
      field,
      id: htmlFor,
      status,
      tabIndex,
      value: field.value !== undefined ? field.value : _.get(data, name),
      onBusy: this._handleBusy.bind(this, name),
      onChange: this._handleUpdateData.bind(this, name),
      onReady: this._handleReady.bind(this, name),
      onSubmit,
      onValid: this._handleValid.bind(this, name)
    }
  }

  _getError() {
    const { errors, field } = this.props
    const { name } = field
    return (errors && errors[name]) ? errors[name][0] : null
  }

  _getFields() {
    const { data, errors, field, fields, status, tabIndex, onBusy, onReady, onSubmit, onUpdateData, onValid } = this.props
    return {
      data,
      errors,
      field,
      fields,
      show: field.show,
      status,
      tabIndex,
      onBusy,
      onReady,
      onSubmit,
      onUpdateData,
      onValid
    }
  }

  _getInstructions(instructions) {
    return _.isString(instructions) ? <Html html={ instructions } /> : instructions
  }
  
  _getTabIndex(name) {
    const { fields } = this.props
    const field = fields.find(field => {
      return field.name === name
    })
    return field ? field.tabIndex : null

  }

  _handleBusy(name, value) {
    if(name) this.props.onBusy(name, value)
  }

  _handleReady(name) {
    if(name) this.props.onReady(name)
  }

  _handleToggle() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  _handleUpdateData(name, value) {
    if(!name) return
    this.props.onUpdateData(name, value)
  }

  _handleValid(name, value, errors) {
    if(name) this.props.onValid(name, value, errors)
  }

}

export default Field
