import ProgramToken from '@apps/crm/admin/tokens/program'
import New from '@apps/team/admin/views/programs/new'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Programs)',
  access: { rights: { $oneOf: ['team:view_progams','team:manage_programs']} },
  access: {},
  collection: {
    endpoint: '/api/admin/team/programs',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: ProgramToken }
    ],
    empty: {
      svg: 'network',
      title: 't(No Programs)',
      text: 't(You have not yet created any programs)',
      buttons: [
        { 
          label: 't(Create Program)', 
          access: { rights: { $oneOf: ['team:manage_programs'] } },
          modal: New 
        }
      ]
    },
    entity: 'program',
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/team/programs/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Program)',
    access: { rights: { $oneOf: ['team:manage_programs'] } },
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
