import SocialCampaignTypeToken from '@apps/campaigns/admin/tokens/social_campaign_type'
import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const SocialCampaignToken = ({ social_campaign, show_provider, show_tags }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign.title }
      </div>
      <SocialCampaignTypeToken social_campaign={ social_campaign } />
      <div className="token-stats">
        { show_provider &&
          <>
            { social_campaign.agency ?
              <span>Agency: { social_campaign.agency.title }</span> :
              social_campaign.team ?
                <span>Team: { social_campaign.team.title }</span> :
                <span>Provider: Truevail</span>
            }
          </>
        }
      </div>
    </div>
  </div>
)

SocialCampaignToken.propTypes = {
  social_campaign: PropTypes.object,
  show_provider: PropTypes.bool
}

export default SocialCampaignToken
