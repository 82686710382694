import { CSSTransition } from 'react-transition-group'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

export const PromptContext = React.createContext()
PromptContext.displayName = 'PromptContext'

export const usePromptContext = () => React.useContext(PromptContext)

class Prompt extends React.Component {

  static childContextTypes = {
    alert: PropTypes.object,
    confirm: PropTypes.object,
    prompt: PropTypes.object
  }

  static propTypes = {
    cancel: PropTypes.bool,
    children: PropTypes.any
  }

  static defaultProps = {
    cancel: false
  }

  state = {
    message: null,
    open: false,
    options: null,
    title: null
  }

  _handleClose = this._handleClose.bind(this)
  _handleClear = this._handleClear.bind(this)

  render() {
    const { cancel, children } = this.props
    const { message, title, open, options } = this.state
    return (
      <PromptContext.Provider value={ this.getChildContext() }>
        { children }
        <CSSTransition in={ open } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-prompt-overlay" onClick={ this._handleClose } />
        </CSSTransition>
        <CSSTransition in={ open } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-prompt-options">
            { title &&
              <div className="maha-prompt-title">
                <T text={ title } />
              </div>
            }
            { message &&
              <div className="maha-prompt-message">
                <T text={ message } />
              </div>
            }
            <div className="maha-prompt-items">
              { options && options.map((option, index) => (
                <Button key={ `option_${index}` } { ...this._getButton(option) }  />
              ))}
              { cancel &&
                <div className="maha-prompt-cancel" onClick={ this._handleClose }>
                  <T text="t(Cancel)" />
                </div>
              }
            </div>
          </div>
        </CSSTransition>
      </PromptContext.Provider>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state
    if(open !== prevState.open && !open) {
      setTimeout(this._handleClear, 500)
    }
  }

  getChildContext() {
    return {
      alert: this._getAlertChildContext(),
      confirm: this._getConfirmChildContext(),
      prompt: this._getPromptChildContext()
    }
  }

  _getButton(option) {
    return {
      ...option,
      className: 'maha-prompt-item',
      onDone: this._handleClose
    }
  }

  _getAlertChildContext() {
    return {
      open: ({ title, message }) => this._handleOpen(title, message, [
        {
          text: 't(OK)',
          handler: this._handleClose
        }
      ]),
      close: this._handleClose
    }
  }

  _getConfirmChildContext() {
    return {
      open: (message, yes = null, no = null) => this._handleOpen(message, null, [
        {
          text: 't(Yes)',
          handler: () => yes ? yes() : this._handleClose()
        }, {
          text: 't(No)',
          handler: () => no ? no() : this._handleClose()
        }
      ]),
      close: this._handleClose
    }
  }

  _getPromptChildContext() {
    return {
      open: (title, options) => this._handleOpen(title, null, options),
      close: this._handleClose
    }
  }

  _handleClear() {
    this.setState({
      message: null,
      open: false,
      options: null,
      title: null
    })
  }

  _handleClose() {
    this.setState({
      open: false
    })
  }

  _handleOpen(title, message, options) {
    this.setState({
      title,
      message,
      options,
      open: true
    })
  }

}

export default Prompt
