import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Lists extends React.PureComponent {

  static propTypes = {
    config: PropTypes.object,
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    return {
      action: 'add'
    }
  }

  _getForm() {
    const { program } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Update Lists)',
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { 
              label: 't(Lists)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'action', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'add', text: 't(Add to list)' },
                    { value: 'remove', text: 't(Remove from list)'}
                  ], 
                  defaultValue: config.action 
                },
                ...program ? [
                  { 
                    label: 't(List)', 
                    name: 'list', 
                    type: 'lookup', 
                    required: true, 
                    prompt: 'Choose a list', 
                    endpoint: `/api/admin/team/programs/${program.id}/lists`, 
                    valueKey: null, 
                    compareKey: 'id', 
                    textKey: 'title', 
                    form: this._getListForm(), defaultValue: config.list_id 
                  }  
                ] : [
                  { 
                    label: 't(List)', 
                    type: 'textarea',
                    editable: false,
                    value: 'You will choose the list when publishing this workflow'
                  }  
                ],
                { 
                  name: 'restore_subscription', 
                  type: 'checkbox', 
                  prompt: 't(Resubscribe if contact already exists and was previously unsubscribed)', 
                  defaultValue: config.restore_subscription 
                }
              ], 
              required: true 
            }
          ]
        }
      ]
    }
  }

  _getListForm() {
    const { program } = this.props
    return {
      title: 't(New List)',
      method: 'POST',
      action: `/api/admin/team/programs/${program.id}/lists`,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone({
      action: config.action,
      ...config?.list ? {
        list_id: config.list.id,
        list: config.list  
      } : {},
      restore_subscription: config.restore_subscription
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Lists
