import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ task }) => {

  const contact = task.contact ? {
    label: task.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${task.contact.id}`
  } : null

  const details = {
    audits: `tasks_tasks/${task.id}`,
    comments: `tasks_tasks/${task.id}`,
    sections: [
      {
        title: 't(Task Details)',
        items: [
          { label: 't(Title)', content: task.title },
          { label: 't(Description)', content: task.description },
          ...contact ? [
            { label: 't(Contact)', content: <Button { ...contact } /> }
          ] : [],
          { label: 't(Owner)', content: task.owner.full_name },
          { label: 't(Assigned To)', content: task.assignees.map(assignee => assignee.full_name).join(', ') },
          { label: 't(Due)', content: task.due_at, format: 'datetime' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  task: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview task={ props.task } />,
  task: null,
  tasks: {
    items: [
      { 
        label: 't(Edit Task)',
        svg: 'square_pen', 
        modal: <Edit task={ props.task } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
