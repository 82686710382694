import LanguageToken from '@apps/maha/admin/tokens/language'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Locale extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Manage Local/Regional Preferences)',
      method: 'PATCH',
      endpoint: '/api/admin/account',
      action: '/api/admin/account',
      cancelSvg: 'chevron_left',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Language)', name: 'language_id', type: 'lookup', endpoint: '/api/admin/lang', valueKey: 'id', textKey: 'text', required: true, format: LanguageToken },
            { label: 't(Timezone)', name: 'timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true },
            { label: 't(Date Format)', name: 'date_format_id', type: 'dropdown', endpoint: '/api/admin/formats', filter: { type: { $eq: 'date' } }, valueKey: 'id', textKey: null, required: true, format: ({ value }) => (
              <div className="token">{ moment().format(value.format) }</div>
            ) },
            { label: 't(Time Format)', name: 'time_format_id', type: 'dropdown', endpoint: '/api/admin/formats', filter: { type: { $eq: 'time' } }, valueKey: 'id', textKey: null, required: true, format: ({ value }) => (
              <div className="token">{ moment().format(value.format) } (<span>{ value.text }</span>)</div>
            ) }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleSuccess() {
    this.context.modal.pop()
  }

}

export default Locale
