import MJSONDesigner from '@admin/components/mjson_designer'
import ImageTools from '@admin/components/image_tools'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    automation_email: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { advisor, automation_email } = this.props
    return {
      endpoint: `/automation_emails/${automation_email.id}`,
      library: `/advisors/${advisor.id}`,
      root_route: `/truevail/admin/advisors/${advisor.id}/automation_email-${automation_email.id}`,
      type: 'email',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design advisor={ props.advisor } automation_email={ props.automation_email } />,
  tasks: {
    items: [
      { 
        label: 't(Generate Image)',
        svg: 'wand_sparkles',
        modal: <ImageTools /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
