import ProfileToken from '@admin/tokens/profile'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Profiles)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/profiles`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'username', sort: 'name', primary: true, format: (profile) => (
        <ProfileToken profile={ profile } />
      ) },
      { label: 't(Status)', key: 'status', sort: 'status', primary: true, collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      title: 't(No Social Profiles)',
      text: 't(There are no profiles)',
      svg: 'user_circle_2'
    },
    entity: 'profile',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/team/profiles/${record.id}`),
  }
})

export default Panel(null, mapPropsToPanel)
