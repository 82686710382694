const triggers = [
  {
    svg: 'calendar_days',
    code: 'registration_created',
    title: 't(Event Registered)',
    text: 't(Contact registers for event)'
  }
]

export default triggers
