import DomainToken from '@apps/websites/admin/tokens/domain'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import New from '../domains/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Domains)',
  collection: {
    endpoint: `/api/admin/websites/websites/${props.website.id}/domains`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'hostname', primary: true, format: DomainToken },
      { label: 't(Status)', key: 'status', primary: true, collapsing: true, format: StatusToken }
    ],
    empty: {
      svg: 'globe',
      title: 't(No Domains)',
      text: 't(You have not yet created any domains for this website)'
    },
    entity: 'item',
    defaultSort: { key: 'hostname', order: 'asc' },
    onClick: (record) => context.router.push(`/websites/${props.website.id}/domains/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Make Primary)',
        show: !record.is_primary,
        request: {
          endpoint: `/api/admin/websites/websites/${props.website.id}/domains/${record.id}/activate`,
          method: 'PATCH',
          onFailure: (result) => context.flash.set('error', 't(Unable to make this domain primary)')
        }
      }, {
        label: 't(Delete Domain)',
        svg: 'trash',
        show: !record.is_system,
        confirm: 't(Are you sure you want to delete this domain?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/websites/websites/${props.website.id}/domains/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 't(Unable to delete this domain)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: <New website={ props.website } />
  }
})

export default Panel(null, mapPropsToPanel)
