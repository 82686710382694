import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import Configuration from './configuration'
import Workflows from './workflows'
import PropTypes from 'prop-types'
import Advisor from './advisor'
import React from 'react'

class WorkflowPublish extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    entity: PropTypes.string,
    workflow: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { entity } = this.props
    return {
      title: 't(Publish Advisor Workflow)',
      action: this._getAction.bind(this),
      method: 'POST',
      props: { 
        entity
      },
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getAction(formdata) {
    const { entity } = this.props
    const { workflow } = formdata
    return `/api/admin/truevail/${entity}/workflows/${workflow?.id}/publishings`
  }

  _getData(formdata) {
    return {
      advisor_id: formdata.advisor.id,
      config: formdata.config
    }
  }

  _getDefault() {
    const { advisor, workflow } = this.props
    return {
      ...advisor ? { advisor } : {},
      ...workflow ? { workflow } : {}
    }
  }

  _getSteps(formdata) {
    return [
      ...!this.props.advisor ? [
        { label: 't(Advisor)', component: Advisor }
      ] : [],
      ...!this.props.workflow ? [
        { label: 't(Workflow)', component: Workflows }
      ] : [],
      ...formdata?.form?.workflows?.length !== 0 ? [
        { label: 't(Configuration)', component: Configuration }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  ...props.workflow_id ? {
    workflow: `/api/admin/truevail/library/workflows/${props.workflow_id}`
  } : {}
})

export default Container(mapResources)(WorkflowPublish)
