import MJSONScreenshot from '@admin/components/mjson_screenshot'
import { DragSource, DropTarget } from 'react-dnd'
import Button from '@admin/components/button'
import Handle from '@admin/components/handle'
import PropTypes from 'prop-types'
import React from 'react'

class Campaign extends React.Component {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    social_campaign: PropTypes.object,
    index: PropTypes.number,
    onMove: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource } = this.props
    const { social_campaign } = this.props
    return connectDropTarget(connectDragPreview(
      <div className="truevail-social-batch-campaigns-assigned-campaign">
        { connectDragSource(
          <div className="truevail-social-batch-campaigns-assigned-campaign-handle">
            <Handle />
          </div>
        ) }
        <div className="truevail-social-batch-campaigns-assigned-campaign-thumbnail">
          <MJSONScreenshot { ...this._getScreenshot(social_campaign) } />
        </div>
        <div className="truevail-social-batch-campaigns-assigned-campaign-details">
          { social_campaign.title }<br />
          { social_campaign.type }            
        </div>
        <Button { ...this._getRemove()} />
      </div>
    ))
  }

  _getRemove() {
    return {
      svg: 'x',
      className: 'truevail-social-batch-campaigns-assigned-campaign-action',
      handler: this._handleRemove
    }
  }

  _getScreenshot(social_campaign) {
    return {
      engine: 'mjson',
      entity: `truevail_social_campaign_variants/${social_campaign.variants[0].code}`,
      width: 100,
      height: 75,
      screenshoted_at: social_campaign.screenshoted_at,
      updated_at: social_campaign.updated_at
    }
  }

  _handleRemove() {
    const { index } = this.props
    this.props.onRemove(index)
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Campaign = DragSource('ITEM', source, sourceCollector)(Campaign)
Campaign = DropTarget('ITEM', target, targetCollector)(Campaign)

export default Campaign
