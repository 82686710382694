import ModalPanel from '@admin/components/modal_panel'
import SourceToken from '@admin/tokens/source'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Service extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    integrations: PropTypes.array,
    showHeader: PropTypes.bool,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    const { showHeader, onBack, onCancel } = this.props
    return {
      title: 't(Choose Service)',
      showHeader,
      leftItems: onBack ? [
        { svg: 'chevron_left', handler: onBack }
      ] : onCancel ? [
        { label: 't(Cancel)', handler: onCancel }
      ] : []
    }
  }

  _getOptions() {
    const { features } = this.context.admin
    const { integrations } = this.props
    return integrations.filter(integration => {
      const { source_name } = integration.service
      if(source_name === 'googlecontacts') return features.googlecontacts === true
      if(source_name === 'googledrive') return features.googledrive === true
      if(source_name === 'canva') return features.canva === true
      return true
    }).map(({ service }) => service)
  }

  _getSearch() {
    const { types } = this.props
    return {
      search: false,
      group: !types || types.length > 1,
      options: this._getOptions(),
      valueKey: null,
      textKey: 'text',
      format: ({ option }) => (
        <SourceToken source={ option } />
      ),
      onChange: this._handleChoose
    }
  }

  _handleChoose(integration) {
    this.props.onChoose(integration)
  }

}

export default Service