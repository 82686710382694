import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Suppliers)',
  collection: {
    endpoint: '/api/admin/truevail/admin/suppliers',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Supplier)', key: 'team.title', primary: true, format: (supplier) => <TeamToken { ...supplier.team } /> }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'user_round',
      title: 't(No Suppliers)',
      text: 't(You have not yet created any suppliers)'
    },
    entity: 'supplier',
    // onClick: (record) => context.router.push(`/campaigns/social/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
