import EditOptionForm from '../components/productfield/edit'
import NewOptionForm from '../components/productfield/new'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class ProductFieldForm extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onDone: PropTypes.func,
    onUpdate: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate(config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(Products)',
      ref: this.formRef,
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { 
              label: 't(Name)',
              name: 'name', 
              type: 'tokenfield',
              required: true, 
              value: config.name 
            },
            { 
              label: 't(Label)', 
              name: 'label', 
              type: 'textfield', 
              value: config.label 
            },
            { 
              label: 't(Instructions)', 
              name: 'instructions', 
              type: 'htmlfield', 
              value: config.instructions 
            },
            { 
              label: 't(Required)', 
              name: 'required', 
              type: 'checkbox', 
              prompt: 't(This field is required)', 
              value: config.required 
            },
            { 
              label: 't(Products)', 
              name: 'products', 
              type: 'collectionfield', 
              orderable: true, 
              cloneable: false, 
              editable: true, 
              entity: 'product',
              newForm: (props) => <NewOptionForm { ...props } allowedPricing={ ['fixed'] } manageInventory={ true } />,
              editForm:(props) => <EditOptionForm { ...props } allowedPricing={ ['fixed'] } manageInventory={ true } />,
              required: true,
              token: ({ value }) => (
                <div className="token">
                  { value.description } { this._getPricing(value) } { value.is_sold_out &&
                    <span className="alert">SOLD OUT</span>
                  }
                </div>
              ), 
              value: config.options 
            }
          ]
        }
      ]
    }
  }
    
  _getPricing(option) {
    if(option.pricing === 'custom') return null
    return (
      <span className="crm-productfield-product-price">
        ({ option.pricing !== 'free' ? numeral(option.price).format('$0.00') : 'FREE' })
      </span>
    )
  }

  _getDefault() {
    return {
      label: '',
      token: '',
      instructions: '',
      required: false,
      products: []
    }
  }

  _handleChange(config) {
    const { label } = config
    const name = _.get(config, 'name.value')
    this.setState({
      config: {
        ...this.state.config,
        ...config,
        ...config.name && ((!label && name.length === 1) || name.slice(0,-1) === label) ? {
          label: config.name.value
        } : {}
      }
    })
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default ProductFieldForm
