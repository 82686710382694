import WorkflowToken from '@apps/truevail/admin/tokens/workflow'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Workflow extends React.PureComponent {

  static propTypes = {
    entity: PropTypes.string,
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    const { advisor } = this.props.formdata
    return {
      endpoint: `/api/admin/truevail/library/advisors/${advisor.id}/workflows`,
      entity: 'workflow',
      compareKey: 'id',
      valueKey: null,
      textKey: 'title',
      format: (workflow) => (
        <WorkflowToken workflow={ workflow } />
      ),
      multiple: false,
      onChoose: this._handleDone
    }
  }

  _handleDone(workflow) {
    this.props.onNext({ workflow })
  }

}

export default Workflow
