import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ domain_segment }) => {

  const domain = {
    className: 'link',
    label: domain_segment.domain.name,
    route: `/admin/truevail/admin/domains/${ domain_segment.domain.id}`
  }

  const details = {
    sections: [
      {
        title: 't(Domain Segment)',
        items: [
          { label: 't(Domain)',  content: <Button { ...domain } /> },
          { label: 't(Segment)', content: domain_segment.segment.text },
          { label: 't(Sending Rate)', content: domain_segment.sending_rate }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  domain_segment: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview domain_segment={ props.domain_segment } />,
  tasks: {
    items: [
      {
        label: 't(Edit Domain Segment)',
        svg: 'square_pen',
        modal: <Edit domain_segment={ props.domain_segment } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
