import ProgramForm from '@apps/crm/admin/components/programform'
import New from '@apps/team/admin/views/profiles/new'
import ProfileToken from '@admin/tokens/profile'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Profiles)',
  access: { rights: { $oneOf: ['team:view_profiles','team:manage_profiles'] } },
  collection: { 
    endpoint: '/api/admin/team/profiles',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'username', sort: 'name', primary: true, format: (profile) => (
        <ProfileToken profile={ profile } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : [],
      { label: 't(Status)', key: 'status', sort: 'status', primary: true, collapsing: true, format: StatusToken }
    ],
    empty: {
      title: 't(No Social Profiles)',
      text: 't(There are no profiles)',
      svg: 'user_circle_2',
      buttons: [
        { 
          label: 't(Connect Social Profile)',
          access: { rights: { $oneOf: ['team:manage_profiles'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    entity: 'profile',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/team/profiles/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Connect Social Profile)',
    access: { rights: { $oneOf: ['team:manage_profiles'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
