import SMSClient from '@apps/phone/admin/components/sms_client'
import EditContact from '@apps/crm/admin/views/contacts/edit'
import Message from '@admin/components/message'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class SMS extends React.Component {

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    const phone_numbers = this._getMobilePhoneNumbers()
    return (
      <>
        { phone_numbers.length > 0 ?
          <SMSClient { ...this._getSMSClient() } /> :
          <Message { ...this._getMessage() } />
        }
      </>
    )
  }

  _getMessage() {
    const { contact } = this.props
    return {
      svg: 'message_square',
      title: 't(No Mobile Phone)',
      text: 't(The deal contact does not have a mobile phone)',
      button: [
        { label: 't(Edit Contact)', modal: <EditContact contact={ contact } />}
      ]
    }
  }

  _getMobilePhoneNumbers() {
    const { contact } = this.props
    return contact.phone_numbers.filter(phone_number => {
      return phone_number.type === 'mobile' && phone_number.can_text
    })
  }

  _getSMSClient() {
    const { contact, program } = this.props
    return {
      contact,
      contact_phone_number: this._getMobilePhoneNumbers()[0],
      program,
      program_phone_number: program.phone_number
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(SMS)',
  component: <SMS contact={ props.contact } program={ props.program } />
})

const mapResourcesToPanel = (props, context) => ({
  contact: `/api/admin/crm/contacts/${props.deal.contact.id}`,
  program: `/api/admin/team/programs/${props.pipeline.program.id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
