import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class ResponsiveField extends React.Component {

  static contextTypes = {
    designer: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.any,
    canResponsive: PropTypes.bool,
    canHover: PropTypes.bool,
    component: PropTypes.any,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    device: 'desktop',
    canResponsive: true,
    canHover: true,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    device: 'desktop',
    responsive: null
  }

  _handleResponsive = this._handleResponsive.bind(this)
  _handleHover = this._handleHover.bind(this)

  render() {
    const { canHover, canResponsive } = this.props
    const { responsive } = this.state
    if(!responsive) return null
    const devices = this._getDevices()
    const Control = this.props.component
    return (
      <div className="responsivefield">
        <div className="responsivefield-header">
          { canResponsive &&
            <Button { ...this._getResponsive() } />
          }
          { canHover &&
            <Button { ...this._getHover() } />
          }
        </div>
        { devices ?
          <div className="responsivefield-input">
            <div className="responsivefield-devices">
              { devices.map((device, index) => (
                <div { ...this._getDevice(device) } key={`device_${index}`}>
                  <T text={ device.label } />
                </div>
              ))}
            </div>
            <div className="responsivefield-body">
              <Control { ...this._getControl() } />
            </div>
          </div> :
          <Control { ...this._getControl() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { responsive } = this.state
    const { value } = this.props
    if(!_.isEqual(responsive, prevState.responsive)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
    if(this.state.device !== prevState.device) {
      this._handleChangethemeDevice(this.state.device)
    }
    if(this.props.device !== prevProps.device) {
      this._handleChangeLocalDevice(this.props.device)
    }
  }

  _getComputedDevice() {
    const { device, responsive } = this.state
    const { isHover, isResponsive } = responsive
    if(device === 'hover') return isHover ? 'hover' : (isResponsive ? 'desktop' : 'all')
    return isResponsive ? device : 'all'
  }

  _getControl() {
    const { responsive } = this.state
    const device = this._getComputedDevice()
    const value = responsive[device]
    return {
      key: device,
      ...this.props,
      defaultValue: value,
      value,
      onChange: this._handleUpdate.bind(this, device)
    }
  }

  _getDefaultValue() {
    const { value } = this.props
    return !_.isNil(value) ? value : null
  }

  _getDenormalizedValue() {
    const { responsive } = this.state
    const { isResponsive, isHover, all, desktop, tablet, mobile, hover } = responsive
    if(!isHover && !isResponsive) return all
    return {
      ...isHover && !isResponsive ? { all } : { desktop },
      ...isHover ? {
        hover
      } : {},
      ...isResponsive ? {
        tablet,
        mobile
      } : {}
    }
  }

  _getDevices() {
    const { responsive } = this.state
    const { isResponsive, isHover } = responsive
    if(!isResponsive && !isHover) return null
    return [
      ...isResponsive  ? [
        { value: 'desktop', label: 't(Desktop)' }
      ] : [
        { value: 'all', label: 't(Desktop)' }
      ],
      ...isHover ? [
        { value: 'hover', label: 't(Hover)' }
      ] : [],
      ...isResponsive ? [
        { value: 'tablet', label: 't(Tablet)' },
        { value: 'mobile', label: 't(Mobile)' }
      ] : []
    ]
  }

  _getDevice(device) {
    const classes = ['responsivefield-device']
    const computed = this._getComputedDevice()
    if(device.value === computed) classes.push('selected')
    return {
      className: classes.join(' '),
      onClick: this._handleDevice.bind(this, device.value)
    }
  }

  _getHover() {
    const { responsive } = this.state
    const { canHover } = this.props
    const { isHover } = responsive
    const classes = ['responsivefield-header-icon']
    if(isHover) classes.push('selected')
    return {
      svg: 'mouse_pointer',
      tooltip: {
        title: 't(Toggle Hover)',
        position: 'top right'
      },
      disabled: !canHover,
      className: classes.join(' '),
      handler: this._handleHover
    }
  }

  _getNormalizedValue(value) {
    const isResponsive = _.isPlainObject(value) && (value.desktop !== undefined || value.tablet !== undefined || value.mobile !== undefined)
    const isHover = _.isPlainObject(value) && value.hover !== undefined
    if(isResponsive || isHover) {
      return {
        isResponsive,
        isHover,
        all: null,
        desktop: null,
        tablet: null,
        mobile: null,
        ...value
      }
    }
    return {
      isResponsive: false,
      isHover: false,
      all: value === undefined ? null : value,
      desktop: null,
      tablet: null,
      mobile: null
    }
  }

  _getResponsive() {
    const { canResponsive } = this.props
    const { responsive } = this.state
    const { isResponsive } = responsive
    const classes = ['responsivefield-header-icon']
    if(isResponsive) classes.push('selected')
    return {
      icon: 'mobile',
      tooltip: {
        title: 't(Toggle Responsive)',
        position: 'top right'
      },
      disabled: !canResponsive,
      className: classes.join(' '),
      handler: this._handleResponsive
    }
  }

  _handleChange() {
    const value = this._getDenormalizedValue()
    this.props.onChange(value)
  }

  _handleChangeLocalDevice(candidate) {
    const { device } = this.state
    this.setState({
      device: candidate === 'desktop' && device === 'hover' ? 'hover' : candidate
    })
  }

  _handleChangethemeDevice(device) {
    const computed = _.includes(['hover','all'], device) ? 'desktop' : device
    this.context.designer.device(computed)
  }

  _handleDevice(device) {
    this.setState({ device })
  }

  _handleHover() {
    const { responsive } = this.state
    const { all, desktop, isHover, isResponsive } = responsive
    const value = isResponsive ? desktop : all
    this.setState({
      responsive: {
        ...responsive,
        isHover: !isHover,
        hover: isHover ? null : value
      }
    })
  }

  _handleResponsive() {
    const { responsive } = this.state
    const { all, desktop, isResponsive } = responsive
    this.setState({
      responsive: {
        ...responsive,
        isResponsive: !isResponsive,
        desktop: !isResponsive ? all : null,
        all: isResponsive ? desktop : null,
        tablet: !isResponsive ? all : null,
        mobile: !isResponsive ? all : null
      }
    })
  }

  _handleSet(value) {
    const responsive = this._getNormalizedValue(value)
    this.setState({ responsive })
  }

  _handleUpdate(device, value) {
    this.setState({
      responsive: {
        ...this.state.responsive,
        [device]: value
      }
    })
  }

}

export default ResponsiveField
