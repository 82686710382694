import ProgramForm from '@apps/crm/admin/components/programform'
import NumberToken from '@apps/phone/admin/tokens/number'
import Panel from '@admin/components/panel'
import Provision from '../provision'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Active Numbers)',
  access: {},
  collection: {
    endpoint: '/api/admin/phone/numbers',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Number)', key: 'formatted', primary: true, format: NumberToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title' }
      ] : []
    ],
    empty: {
      icon: 'hashtag',
      title: 't(No Phone Numbers)',
      text: 't(You have not yet created any phone numbers)',
      buttons: [
        { label: 't(Provision Number)', modal: <ProgramForm form={ Provision } /> }
      ]
    },
    entity: 'phone number',
    defaultQuery: { released_at: false },
    defaultSort: { key: 'number', order: 'asc' },
    onClick: (record) => context.router.push(`/phone/numbers/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <ProgramForm form={ Provision } />
  }
})

export default Panel(null, mapPropsToPanel)
