import SocialCampaignTypeToken from '@apps/campaigns/admin/tokens/social_campaign_type'
import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

class UnscheduledToken extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    onEdit: PropTypes.func
  }

  tokenRef = React.createRef()

  _handleEdit = this._handleEdit.bind(this)
  _handleDragStart = this._handleDragStart.bind(this)

  render() {
    const { social_campaign } = this.props
    return (
      <div { ...this._getToken() }>
        <Button { ...this._getInner() }>
          <div className="campaign-token-preview">
            <div className="campaign-token-preview-inner">
              <MJSONScreenshot { ...this._getScreenshot(social_campaign.variants[0]) } />
            </div>
          </div>
          <div className="campaign-token-label">
            <div className="token-link">
              { social_campaign.title }
            </div>
            <SocialCampaignTypeToken social_campaign={ social_campaign } />
          </div>          
        </Button>
        <Button { ...this._getEdit() } />
      </div>
    )
  }

  _getInner() {
    return {
      className: 'campaign-token-inner',
      handler: this._handleEdit,
      context_menu: {
        tasks: this._getTasks()
      }
    }
  }

  _getEdit() {
    return {
      svg: 'ellipsis_vertical',
      className: 'campaign-token-action',
      tasks: this._getTasks()
    }
  }

  _getScreenshot(entity) {
    return {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${entity.code}`,
      width: 100,
      height: 75,
      screenshoted_at: entity.screenshoted_at,
      updated_at: entity.updated_at
    }
  }

  _getTasks() {
    const { social_campaign } = this.props
    return [
      { 
        label: 't(Manage Social Campaign)', 
        svg: 'settings',
        route: `/admin/campaigns/social/${social_campaign.id}` 
      },
      { 
        label: social_campaign.status === 'scheduled' ? 't(Unschedule Social Campaign)' : 't(Schedule Social Campaign)', 
        svg: 'clock',
        modal: <Edit social_campaign={ social_campaign } /> 
      },
      {
        label: 't(Delete Social Campaign)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this social campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/campaigns/social/${social_campaign.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
        }
      }
    ]
  }

  _getToken() {
    const { social_campaign } = this.props
    return {
      ref: this.tokenRef,
      className: 'campaign-token compact',
      draggable: true,
      title: social_campaign.title,
      onClick: this._handleEdit,
      onDragStart: this._handleDragStart
    }
  }

  _handleDragStart(e) {
    const { social_campaign } = this.props
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.setData('social_campaign', JSON.stringify(social_campaign))
    if (this.tokenRef.current) {
      const rect = this.tokenRef.current.getBoundingClientRect()
      e.dataTransfer.setDragImage(this.tokenRef.current, e.clientX - rect.left, e.clientY - rect.top)
    }
  }

  _handleEdit() {
    const { social_campaign } = this.props
    this.props.onEdit(social_campaign)
  }

}

export default UnscheduledToken
