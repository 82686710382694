import AddressesField from '@apps/crm/admin/components/channelfield/address'
import EmailsField from '@apps/crm/admin/components/channelfield/email'
import PhonesField from '@apps/crm/admin/components/channelfield/phone'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    platform: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    fields: PropTypes.array,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Contact)',
      method: 'POST',
      action: '/api/admin/crm/contacts',
      cancelSvg: 'chevron_left',
      saveText: 'Next',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(First Name)', name: 'first_name', type: 'textfield'},
              { label: 't(Last Name)', name: 'last_name', type: 'textfield' }
            ] },
            { type: 'fields', fields: [
              { label: 't(Organization)', name: 'organization', type: 'textfield'},
              { label: 't(Job Title)', name: 'position', type: 'textfield' }
            ] },
            { label: 't(Email Addresses)', name: 'email_addresses', type: EmailsField },
            { label: 't(Phone Numbers)', name: 'phone_numbers', type: PhonesField },
            { label: 't(Mailing Addresses)', name: 'mailing_addresses', type: AddressesField },
            { label: 't(Social)', type: 'segment', fields: [
              { name: 'website', type: 'urlfield', service: 'website', placeholder: 't(Enter Website URL)' },
              { name: 'linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)' },
              { name: 'instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)' },
              { name: 'facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)' },
              { name: 'threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)' },
              { name: 'youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)' },
              { name: 'x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)' }
            ] },
            { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Birthday)', name: 'birthday', type: 'datefield' },
            { label: 't(Spouse)', name: 'spouse', type: 'textfield' }
          ]
        },
        ...this._getProperties()
      ]
    }
  }

  _getProperties() {
    const { fields } = this.props
    return fields.filter(program => {
      return program.access_type !== 'view'
    }).map(program => ({
      label: <ProgramToken { ...program } />,
      padded: false,
      collapsing: true,
      collapsed: true,
      fields: program.fields.map(field => ({
        type: field.type,
        name: `values.${field.code}`,
        ...field.config
      }))
    }))
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess(contact) {
    this.props.onNext(contact)
  }

}

const mapResources = (props, context) => ({
  fields: '/api/admin/programs/fields'
})

export default Container(mapResources)(New)
