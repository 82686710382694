import SourceToken from '@admin/tokens/source'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    return {
      title: 't(Edit Social Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/agency/social_campaigns/${social_campaign.id}/edit`,
      action: `/api/admin/truevail/agency/social_campaigns/${social_campaign.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Design URL)', name: 'design_url', type: 'urlfield' }
          ]
        },
        { 
          label: 't(Library Details)',
          type: 'segment',
          fields: [
            { 
              label: 't(Folders)', 
              entity: 'folder',
              name: 'folder_ids', 
              type: 'checkboxgroup', 
              endpoint: '/api/admin/truevail/agency/folders', 
              valueKey: 'id', 
              textKey: 'title' 
            },
            { 
              label: 't(Tags)', 
              name: 'tags', 
              type: 'tagfield', 
              endpoint: '/api/admin/tags' 
            }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { social_campaign } = this.props
    return [
      ..._.includes(['photo','video','link','reel'], social_campaign.type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ..._.includes(['photo','video','reel','story'], social_campaign.type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      // ..._.includes(['photo','video','link'], social_campaign.type) ? [
      //   { name: 'LinkedIn', source_name: 'linkedin' }
      // ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
