import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import PhoneButton from '@apps/phone/admin/components/button'
import KeyPad from '@apps/phone/admin/components/keypad'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Dialer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    twilio: PropTypes.object,
    tasks: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object
  }

  inputRef = React.createRef()

  state = {
    channels: [],
    number: '',
    formatted: '',
    value: ''
  }

  _handleCall = this._handleCall.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleDial = this._handleDial.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleKeyPress = this._handleKeyPress.bind(this)
  _handlePlace = this._handlePlace.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { value } = this.state
    return (
      <div className="maha-handset-dialer">
        <div className="maha-handset-dialer-header">
          <div className="maha-input">
            <div className="maha-input-field">
              <Input { ...this._getInput() } />
            </div>
            { value &&
              <Button { ...this._getClear() } />
            }
          </div>
        </div>
        <div className="maha-handset-dialer-contact">
          { this._getChannels() }
        </div>
        <div className="maha-handset-dialer-body">
          <div className="maha-handset-dialer-buttons">
            <KeyPad { ...this._getKeyPad() } />
            <div className="maha-handset-dialer-action">
              <PhoneButton { ...this._getCall() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this.inputRef.current.focus()
    }, 500)
  }

  componentDidUpdate(prevProps, prevState) {
    const { number } = this.state
    if(number !== prevState.number && number) {
      this._handleFetch()
    }
  }

  _getCall() {
    const { number } = this.state
    return {
      svg: 'phone',
      disabled: !number,
      type: 'call',
      handler: this._handlePlace
    }
  }

  _getChannels() {
    const { channels } = this.state
    if(channels.length === 0) return null
    const output = [`${channels[0].display_name}`]
    if(channels.length > 1) output.push(`${channels.length-1} others`)
    return output.join(' or ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getInput() {
    const { formatted } = this.state
    return {
      placeholder: 'Enter Number',
      ref: this.inputRef,
      type: 'text',
      value: formatted,
      onChange: this._handleType,
      onKeyPress: this._handleKeyPress
    }
  }

  _getKeyPad() {
    return {
      onChoose: this._handleDial
    }
  }

  _handleCall(device) {
    const { channels, number } = this.state
    const { program } = this.props
    const contact = channels.length > 0 ? channels[0] : null
    this.context.twilio.call({
      device,
      contact,
      number,
      program
    })
  }

  _handleClear() {
    this.setState({
      channels: [],
      number: '',
      formatted: '',
      value: ''
    })
  }

  _handleDial(number) {
    const { value, valid } = this.state
    if(valid) return
    this._handleFormat(value + number)
  }

  _handleFetch() {
    const { number } = this.state
    this.context.network.request({
      endpoint: '/api/admin/crm/contacts',
      method: 'GET',
      query: {
        $filter: {
          phone: {
            $eq: number
          }
        }
      },
      onSuccess: ({ data }) => {
        this.setState({
          channels: data
        })
      }
    })
  }

  _handleFormat(value) {
    const asyoutype = new AsYouType('US')
    const formatted = asyoutype.input(value)
    const parsed = parsePhoneNumberFromString(formatted, 'US')
    const valid = parsed && parsed.isValid()
    const number = valid ? parsed.number : null
    const channels = []
    this.setState({ channels, number, formatted, value })
  }

  _handleKeyPress(e) {
    if(e.charCode !== 13) return
    this._handleCall()
  }

  _handlePlace() {
    if(!this.state.number) return
    const { account } = this.context.admin
    if(!account.mobile_phone) return this._handleCall('maha')
    this.context.tasks.open({
      items: [
        { label: 'Call with mobile phone', handler: this._handleCall.bind(this, 'mobile') },
        { label: 'Call with Maha phone', handler: this._handleCall.bind(this, 'maha') }
      ]
    })
  }

  _handleType(value) {
    this._handleFormat(value)
  }

}

export default Dialer
