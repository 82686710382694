import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import React from 'react'

class Uploading extends React.Component {

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Message { ...this._getMessage() } />
      </ModalPanel>
    )
  }

  _getMessage() {
    return {
      svg: 'refresh_cw',
      spinning: true,
      title: 't(Uploading to Canva)',
      text: 't(We\'re uploading your editing assets to Canva so you can edit them there)'
    }
  }

  _getPanel() {
    return {
      title: 't(Uploading to Canva)'
    }
  }

}

export default Uploading

