import TicketTypeToken from '@apps/events/admin/tokens/ticket_type'
import NewTicketType from '../ticket_types/new'
import Panel from '@admin/components/panel'
import Edit from '../ticket_types/edit'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Ticket Types)',
  collection: {
    endpoint: `/api/admin/events/events/${props.event.id}/ticket_types`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: TicketTypeToken }
    ],
    sortable: false,
    defaultSort: { key: 'delta', order: 'asc' },
    empty: {
      svg: 'tags',
      title: 't(No Ticket Types)',
      text: 't(You have not yet created any ticket types)',
      buttons: []
    },
    recordTasks: props.event.canceled_at === null && props.event.deleted_at === null ? (record) => [
      {
        label: 't(Edit Ticket Type)',
        svg: 'square_pen',
        modal: <Edit event={ props.event } ticket_type={ record } />
      },
      {
        label: record.is_active ? 't(Deactivate Ticket Type)' : 't(Activate Ticket Type)',
        request: {
          endpoint: `/api/admin/events/events/${props.event.id}/ticket_types/${record.id}/activate`,
          method: 'PATCH',
          body: {
            is_active: !record.is_active
          },
          onFailure: () => this.context.flash.set('error', `Unable to ${record.is_active ? 'deactivate' : 'activate' } ticket type`),
          onSuccess: () => {}
        }
      }
    ] : null,
    entity: 'ticket type'
  },
  task: props.event.canceled_at === null && props.event.deleted_at === null ? {
    svg: 'plus',
    modal: <NewTicketType event={ props.event } />
  } : null
})

export default Panel(null, mapPropsToPanel)
