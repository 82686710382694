const triggers = [
  {
    svg: 'message_square',
    manual: false,
    code: 'outbound_sms',
    title: 't(Outbound SMS)',
    text: 't(Contact enrolled in outbound SMS campaign)'
  },
  {
    svg: 'message_square',
    manual: false,
    code: 'inbound_sms',
    title: 't(Inbound SMS)',
    text: 't(Contact enrolled in inbound SMS campaign)'
  },
  {
    svg: 'phone',
    manual: false,
    code: 'outbound_voice',
    title: 't(Outbound Voice)',
    text: 't(Contact was enrolled in outbound voice campaign)'
  },
  {
    svg: 'phone',
    manual: false,
    code: 'outbound_call',
    title: 't(Outbound Call)',
    text: 't(Program called contact)'
  },
  {
    svg: 'phone',
    manual: false,
    code: 'inbound_voice',
    title: 't(Inbound Voice)',
    text: 't(Contact called program)'
  },
  {
    svg: 'mail',
    code: 'email_received',
    title: 't(Email Received)',
    text: 't(Contact receives email campaign)'
  },
  {
    svg: 'mail_open',
    code: 'email_opened',
    title: 't(Email Opened)',
    text: 't(Contact opens email campaign)'
  },
  {
    svg: 'mouse_pointer',
    code: 'email_clicked',
    title: 't(Email Clicked)',
    text: 't(Contact clicks link in email campaign)'
  }
]

export default triggers
