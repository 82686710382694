import Form from '@admin/components/form'
import * as options from './variables'
import PropTypes from 'prop-types'
import React from 'react'

class Rule extends React.Component {

  static propTypes = {
    rules: PropTypes.array,
    fields: PropTypes.array,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    rule: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const fields = this._getFields()
    return {
      title: 't(Rule)',
      ref: this.formRef,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleSuccess,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 'If', type: 'segment', fields: [
              { entity: 'Field', name: 'if_code', type: 'dropdown', options: fields, valueKey: 'code', textKey: 'name' },
              ...this._getComparison()
            ] },
            ...this._getThen()
          ]
        }
      ]
    }
  }

  _getComparison() {
    const { fields } = this.props
    const { rule } = this.state
    const { if_code } = rule
    if(!if_code) return []
    const field = _.find(fields, { code: if_code })
    const comparisons = options.comparisons.filter(comparison => {
      return !comparison.types || _.includes(comparison.types, field.type)
    })
    const items = [
      { name: 'comparison', type: 'radiogroup', options: comparisons, required: true, defaultValue: comparisons[0].value }
    ]
    if(_.includes(['radiogroup','dropdown','checkboxes','optionsfield'], field.type)) {
      const options = field.type === 'optionsfield' ? field.options.map(option => {
        return { value: option.code, text: option.description }
      }) : field.options
      if(_.includes(['$in','$nin','$int','$nint'], rule.comparison)) {
        items.push({ name: 'value', type: 'checkboxgroup', options, required: true })
      } else if(_.includes(['$eq','$neq'], rule.comparison)) {
        items.push({ name: 'value', type: 'radiogroup', options, required: true })
      }
    } else if(!_.includes(['checkbox','filefield'], field.type)) {
      items.push({ name: 'value', type: 'textfield', required: true, placeholder: 't(Enter Value)' })
    }
    return items
  }

  _getFields() {
    const { fields } = this.props
    return fields.filter(field => {
      return field.type !== 'text'
    })
  }

  _getThen() {
    const { fields, rules } = this.props
    const { rule } = this.state
    const { if_code } = rule
    if(!if_code) return []
    return [
      { label: 't(Then)', type: 'segment', fields: [
        { name: 'action', type: 'radiogroup', options: [
          { value: 'hide', text: 't(hide)' },
          { value: 'show', text: 't(show)' }
        ], required: true, defaultValue: 'hide' },
        { entity: 'Field', name: 'then_code', type: 'dropdown', options: fields.filter(field => {
          return field.code !== if_code && _.find(rules, { then_code: field.code }) === undefined
        }), valueKey: 'code', textKey: 'name', required: true }
      ] }
    ]
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(rule) {
    this.setState({ rule })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(rule) {
    this.props.onDone(rule)
  }

}

export default Rule
