import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Action from './action'
import React from 'react'

class Actions extends React.PureComponent {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    actions: PropTypes.array,
    call: PropTypes.object,
    enrollment: PropTypes.object,
    trigger: PropTypes.object
  }

  render() {
    const { actions, enrollment } = this.props
    const trigger = this._getTrigger()
    return (
      <div className="crm-workflow-actions">
        <div className="crm-workflow-action">
          <div className="crm-workflow-action-icon">
            <div className="crm-workflow-action-action trigger">
              <Icon svg={ trigger.svg } />
            </div>
          </div>
          <div className="crm-workflow-action-details">
            <div className="crm-workflow-action-header">
              <div className="crm-workflow-action-label">
                <strong><T text="t(TRIGGER)" />: </strong>
                <T text={ trigger.text } />
              </div>
              <div className="crm-workflow-action-timestamp">
                <Date datetime={ enrollment.created_at } />
              </div>
            </div>
          </div>
        </div>
        { actions.map((action, index) => (
          <Action { ...this._getAction(action) } key={`action_${index}`} />
        )) }
        { enrollment.status === 'completed' &&
          <div className="crm-workflow-action">
            <div className="crm-workflow-action-icon">
              <div className="crm-workflow-action-action ending">
                <Icon svg="check" />
              </div>
            </div>
            <div className="crm-workflow-action-details">
              <div className="crm-workflow-action-header">
                <div className="crm-workflow-action-label">
                  <strong><T text="t(COMPLETE)" />: </strong>
                  <T text="t(Workflow was completed)" />
                </div>
                <div className="crm-workflow-action-timestamp">
                  <Date datetime={ enrollment.completed_at } />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  _getAction(action) {
    const { call, enrollment } = this.props
    return {
      action,
      call,
      enrollment
    }
  }

  _getTrigger() {
    const { triggers } = this.context.maha
    const { trigger } = this.props
    const [app,code] = trigger.full_code.split(':')
    return _.find(triggers, { app, code })
  }

}

export default Actions
