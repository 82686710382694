import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import New from '@apps/campaigns/admin/views/email/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/campaigns/email',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } compact={ true } />
      ) }
    ],
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any email campaigns for this event)',
      buttons: props.event.deleted_at === null ? [
        { 
          label: 't(Create Email Campaign)', 
          modal: <New event={ props.event } /> 
        }
      ] : null
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Sent)', key: 'sent' },
      { label: 't(Open Rate)', key: 'open_rate' },
      { label: 't(Click Rate)', key: 'click_rate' },
      { label: 't(Bounce Rate)', key: 'bounce_rate' },
      { label: 't(Status)', key: 'status' }
    ],
    entity: 'email campaign',
    defaultQuery: { event_id: props.event.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/campaigns/email/${record.id}`)
  },
  task: props.event.deleted_at === null ? {
    svg: 'plus',
    modal: <New event={ props.event } />
  } : null
})

export default Panel(null, mapPropsToPanel)
