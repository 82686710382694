import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    signer: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { admin } = this.context
    const { contact } = this.props
    const { signer } = this.state
    return {
      title: 't(Add Signer)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleSubmit,
      cancelSvg: 'chevron_left',
      sections: [
        {
          fields: [
            { label: 't(Email)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                ...contact.email_addresses.map(email_addresses => ({
                  value: email_addresses.address,
                  text: (<>Send to contact at <strong>{ email_addresses.address }</strong> for signature</>)
                })),
                { value: 'self', text: (<>Send to me at <strong>{ admin.user.email }</strong> for signature</>) },
                { value: 'custom', text: 't(Send to custom email for signature)' }
              ], required: true, defaultValue: contact.email_addresses.length > 0 ? contact.email_addresses[0].address : 'self' },
              ...signer.strategy === 'custom' ? [
                { name: 'custom', type: 'emailfield', placeholder: 't(Enter custom email address)', required: true }
              ] : []
            ] }
          ]
        }
      ]
    }
  }

  _getEmail() {
    const { admin } = this.context
    const { signer } = this.state
    if(signer.strategy === 'custom') return signer.custom
    if(signer.strategy === 'self') return admin.user.email
    return signer.strategy
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChange(signer) {
    this.setState({ signer })
  }

  _handleSubmit(signer) {
    const email = this._getEmail()
    this.props.onDone({ email })
    this.context.form.pop()
  }

}

export default New
