import Panel from '@admin/components/panel'
import Edit from '../categories/edit'
import New from '../categories/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Categories)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/categories`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      { label: 't(Products)', key: 'products_count', collapsing: true, align: 'right' }
    ],
    empty: {
      svg: 'network',
      title: 't(No Categories)',
      text: 't(You have not yet created any categories)',
      buttons: [
        { label: 't(Create Category)', modal: <New store={ props.store } /> }
      ]
    },
    defaultSort: { key: 'title', order: 'asc' },
    recordTasks: (record) => [
      { 
        label: 't(Edit Category)',
        svg: 'square_pen', 
        modal: <Edit store={ props.store } category={ record } /> 
      },
      {
        label: 't(Delete Category)',
        svg: 'trash',
        deletable: true,
        confirm: 't(Are you sure you want to delete this category?)',
        request: {
          endpoint: `/api/admin/stores/stores/${props.store.id}/categories/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(The category was unable to be deleted)'),
          onSuccess: () => context.flash.set('success', 't(The category was successfully deleted)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: <New store={ props.store } />
  }
})

export default Panel(null, mapPropsToPanel)
