import WorkflowPublish from '@apps/truevail/admin/components/workflow_publish'
import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/agency/workflows/${props.workflow.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      svg: 'workflow',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this workflow)',
      buttons: [
        {
          label: 't(Publish Advisor Workflow)',
          modal: <WorkflowPublish workflow_id={ props.workflow.id } entity="agency" />
        }
      ]  
    },
    entity: 'publishing',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/${record.advisor.team.subdomain}/automation/workflows/${record.advisor_workflow.id}`)
    }
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Advisor Workflow)',
    modal: <WorkflowPublish workflow_id={ props.workflow.id } entity="agency" />
  }
})

export default Panel(null, mapPropsToPanel)
