import * as jsondiffpatch from 'jsondiffpatch'
import PropTypes from 'prop-types'
import Footer from './footer'
import React from 'react'

export const VersionsContext = React.createContext()
VersionsContext.displayName = 'VersionsContext'

export const useVersionsContext = () => React.useContext(VersionsContext)

class VersionContainer extends React.PureComponent {

  static childContextTypes = {
    versions: PropTypes.object
  }
  
  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    endpoint: PropTypes.string
  }

  state = {
    selected: null,
    versions: []
  }

  _handleFetchAll = this._handleFetchAll.bind(this)
  _handlePublish = this._handlePublish.bind(this)
  _handleRedo = this._handleRedo.bind(this)
  _handleRollback = this._handleRollback.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleSetVersion = this._handleSetVersion.bind(this)
  _handleUndo = this._handleUndo.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { children } = this.props
    const version = this._getVersion()
    if(!version) return null
    return (
      <VersionsContext.Provider value={ this.getChildContext() }>
        <div className="maha-versions">
          <div className="maha-versions-body">
            { React.Children.map(children, child => {
              return React.cloneElement(child, this._getProps())
            }) }
          </div>
          <Footer status={ version.status } />
        </div>
      </VersionsContext.Provider>
    )
  }

  componentDidMount() {
    const { endpoint } = this.props
    this._handleInit(null, endpoint)
  }

  componentDidUpdate(prevProps) {
    const { endpoint } = this.props
    if(endpoint !== prevProps.endpoint) {
      this._handleInit(prevProps.endpoint, endpoint)
    }
  }

  componentWillUnmount() {
    const { endpoint } = this.props
    this._handleLeave(endpoint)
  }

  getChildContext() {
    return {
      versions: {
        publish: this._handlePublish,
        redo: this._handleRedo,
        rollback: this._handleRollback,
        save: this._handleSave,
        setVersion: this._handleSetVersion,
        undo: this._handleUndo,
        update: this._handleUpdate
      }
    }
  }

  _getProps() {
    const { versions } = this.state
    return {
      versions,
      version: this._getVersion()
    }
  }

  _getVersion() {
    const { selected, versions } = this.state
    return versions.find(version => {
      return version.record.id === selected
    })
  }

  _handleCommit(value, publish = false, reset = false) {
    const { endpoint } = this.props
    const version = this._getVersion()
    this._handleSet({
      current: value
    })
    const diff = jsondiffpatch.diff(version.previous, value)
    if(!diff) return
    if(reset) this._handleSet({
      status: 'saving' 
    })
    this.context.network.request({
      endpoint: `/api/admin${endpoint}/versions`,
      method: 'PATCH',
      body: { 
        value,
        publish
      },
      onFailure: () => {
        if(reset) this._handleSet({
          status: 'error' 
        })
        this.context.flash.set('error', 't(Unable to save version)')
      },
      onSuccess: ({ data }) => {
        const current = data.value
        const previous = _.cloneDeep(current)
        const patch = jsondiffpatch.diff(version.previous, current)
        this._handleSet({
          ...reset ? { redo: [] } : {},
          current: current,
          previous,
          ...reset ? {
            undo: [
              ...version.undo,
              patch
            ]
          } : {},
          status: 'saved'
        })
      }
    })
  }

  _handleFetchAll() {
    const { endpoint } = this.props
    this.context.network.request({
      endpoint: `/api/admin${endpoint}/versions`,
      method: 'GET',
      onFailure: () => this.context.flash.set('error', 't(Unable to load versions)'),
      onSuccess: ({ data }) => {
        const versions = data.map(record => {
          const version = this.state.versions.find(version => {
            return version.record.id === record.id
          })
          return {
            ...version || {
              current: JSON.parse(JSON.stringify(record.value)),
              previous: JSON.parse(JSON.stringify(record.value)),
              redo: [],
              undo: []
            },
            record,
            status: 'saved'
          }
        })
        this.setState({
          selected: versions?.[0]?.record?.id,
          versions
        })
      }
    })
  }

  _handleInit(prev, next) {
    if(prev) this._handleLeave(prev)
    this._handleFetchAll()
    this._handleJoin(next)
  }

  _handleJoin(endpoint) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.subscribe({
      channel: `/teams/${team.id}/admin${endpoint}/versions`, 
      action: 'refresh', 
      handler: this._handleFetchAll 
    })
  }

  _handleLeave(endpoint) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}/admin${endpoint}/versions`, 
      action: 'refresh', 
      handler: this._handleFetchAll 
    })
  }

  _handlePublish() {
    const { endpoint } = this.props
    const version = this._getVersion()
    this._handleSet({
      status: 'publishing' 
    })
    this.context.network.request({
      endpoint: `/api/admin${endpoint}/versions/publish`,
      method: 'PATCH',
      body: {
        publish_id: version.record.id
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to publish version)'),
      onSuccess: ({ data }) => {
        this._handleSet({
          record: data,
          status: 'published' 
        })
        this._handleFetchAll()
      }
    })
  }

  _handleRedo() {
    const version = this._getVersion()
    if(version.redo.length === 0) return
    const patch = version.redo[version.redo.length - 1]
    const value = jsondiffpatch.patch(version.current, patch)
    this._handleSet({
      current: value,
      redo: version.redo.slice(0, -1),
      status: 'redoing',
      undo: [
        ...version.undo,
        patch
      ]
    }, this._handleCommit.bind(this, value, false, false))
  }

  _handleRollback(version, publish = false) {
    const { endpoint } = this.props
    this.context.network.request({
      endpoint: `/api/admin${endpoint}/versions/rollback`,
      method: 'PATCH',
      body: {
        rollback_id: version.record.id,
        publish
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to rollback version)'),
      onSuccess: () => this._handleFetchAll()
    })
  }

  _handleSave(value, publish = false) {
    this._handleCommit(value, publish, true)
  }

  _handleSet(value, callback = () => {}) {
    const { versions, selected } = this.state
    this.setState({
      versions: versions.map(version => ({
        ...version,
        ...version.record.id === selected ? value : {}
      }))
    }, callback)
  }

  _handleSetVersion(selected) {
    this.setState({ selected })
  }

  _handleUndo() {
    const version = this._getVersion()
    if(version.undo.length === 0) return
    const patch = version.undo[version.undo.length - 1]
    const value = jsondiffpatch.unpatch(version.current, patch)
    this._handleSet({
      current: value,
      redo: [
        ...version.redo,
        patch
      ],
      status: 'undoing',
      undo: version.undo.slice(0, -1)
    }, this._handleCommit.bind(this, value, false, false))
  }

  _handleUpdate(value) {
    const version = this._getVersion()
    const diff = jsondiffpatch.diff(version.current, value)
    if(!diff) return
    this._handleSet({
      current: value
    })
  }

}

export default VersionContainer
