const triggers = [
  {
    svg: 'square_check',
    code: 'response_created',
    title: 't(Form Submitted)',
    text: 't(Contact submits response to form)'
  }
]

export default triggers
