import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Answer extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    answer: PropTypes.object,
    mode: PropTypes.string,
    onDone: PropTypes.func
  }

  static defaultProps = {
    answer: {}
  }

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { answer } = this.props
    this.setState({
      config: {
        ...this._getDefault(),
        ...answer
      }
    })
  }

  _getDefault() {
    return {
      code: getCode(10),
      operation: '$eq',
      text: ''
    }
  }

  _getForm() {
    const { mode } = this.props
    const { config } = this.state
    return {
      title: 't(Answer)',
      cancelSvg: 'chevron_left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      saveText: mode === 'new' ? 'Add' : 'Update',
      sections: [
        {
          fields: [
            { name: 'code', type: 'hidden', value: config.code },
            { label: 't(Answer)', type: 'segment', instructions: 't(Answers are not case sensitive and leading and trailing spaces will be removed)', fields: [
              { name: 'operation', type: 'radiogroup', options: [
                { value: '$eq', text: 't(equals)' },
                { value: '$neq', text: 't(does not equal)' },
                { value: '$lk', text: 't(contains)' },
                { value: '$nlk', text: 't(does not contain)' }
              ], defaultValue: config.operation },
              { name: 'text', type: 'textfield', placeholder: 't(Enter word or phrase)', defaultValue: config.text }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone() {
    const { config } = this.state
    this.props.onDone(config)
    this.context.form.pop()
  }

}

export default Answer
