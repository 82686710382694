import FoldersUpdateBatch from '@apps/truevail/admin/components/folders_update_batch'
import TagsUpdateBatch from '@apps/truevail/admin/components/tags_update_batch'
import LibraryAddBatch from '@apps/truevail/admin/components/library_add_batch'
import LibraryAdd from '@apps/truevail/admin/components/library_add'
import TemplateToken from '@apps/truevail/admin/tokens/template'
import TemplateCard from '@apps/truevail/admin/cards/template'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import types from './types'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: types[props.type].title,
  collection: {
    endpoint: `/api/admin/truevail/agency/templates/${props.type}`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: types[props.type].label, key: 'title', primary: true, format: (template) => (
        <TemplateToken template={ template } show_provider={ false } show_voice={ true } />
      ) },
      { label: 't(Library)', key: 'library_status', sort: 'status', collapsing: true, align: 'center', format: LibraryToken }
    ],
    card: (template) => (
      <TemplateCard template={ template } />
    ),
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['all','first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['all','american english','canadian english','french','german','italian'] },
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], align: 'center', format: LibraryToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'file-o',
      title: types[props.type].empty_title,
      text: types[props.type].empty_text,
      buttons: [
        {
          label: types[props.type].create_label,
          modal: <New type={ props.type } /> 
        }
      ]
    },
    entity: 'template',
    selectable: true,
    batch: {
      batch_type: 'truevail_templates',
      tasks: [
        { 
          label: 't(Add to Library)', 
          svg: 'book_plus',
          action: 'publish', 
          component: <LibraryAddBatch entity="agency" type={ props.type } /> 
        },
        { 
          label: 't(Remove from Library)', 
          svg: 'book_minus',
          action: 'unpublish', 
          config: { entity: 'agency', type: props.type } 
        },
        { 
          label: 't(Update Folders)',
          svg: 'folder',
          action: 'folder', 
          component: <FoldersUpdateBatch entity="agency" type={ props.type } />  
        },
        { 
          label: 't(Update Tags)',
          svg: 'tags',
          action: 'tag', 
          component: <TagsUpdateBatch entity="agency" type={ props.type } />  
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: types[props.type].clone_label,
        modal: <Clone template={ record } />
      },
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: record.status === 'draft',
        modal: <LibraryAdd scope="agency" entity="templates" record={ record } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: record.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', types[props.type].unpublish_success),
          onFailure: () => context.flash.set('error', types[props.type].unpublish_error)
        }
      },
      {
        label: types[props.type].delete_label,
        confirm: types[props.type].delete_confirm,
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', types[props.type].delete_success),
          onFailure: () => context.flash.set('error', types[props.type].delete_error)
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/templates/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <New type={ props.type } />
  }
})

export default Panel(null, mapPropsToPanel)
