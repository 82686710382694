const triggers = [
  {
    svg: 'square_check',
    manual: true,
    code: 'signup_created',
    title: 't(Signup Created)',
    text: 't(Contact created a new signup through signup page)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    svg: 'square_check',
    code: 'team_status_duration',
    title: 't(Team Status Duration)',
    text: 't(Contact\'s team had a specific status for a specific duration)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    svg: 'square_check',
    code: 'team_status_transitioned',
    title: 't(Team Status Transitioned)',
    text: 't(Contact\'s team was transitioned from one status to another)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  }
]

export default triggers
