import SubscriptionPriceToken from '@apps/subscriptions/admin/tokens/subscription_price'
import AllowancesToken from '@apps/subscriptions/admin/tokens/allowances'
import FrequencyToken from '@apps/subscriptions/admin/tokens/frequency'
import TimingToken from '@apps/subscriptions/admin/tokens/timing'
import CardToken from '@apps/finance/admin/tokens/card'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Communication from '../communication'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Contact from '../contact'
import Change from '../change'
import Cancel from '../cancel'
import Method from '../method'
import Pause from '../pause'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

const from_now = (date) => {
  const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days')
  if(diff === -1) return 'yesterday'
  if(diff === 0) return 'today'
  if(diff === 1) return 'tomorrow'
  if(diff > 0) return `in ${pluralize('day', diff, true) }`
  if(diff < 0) return `${pluralize('day', 0 - diff, true) } ago`
}

const getPeriod = (service, period) => {

  const plan = period ? {
    label: period.plan.title,
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}/plans/${period.plan.id}`
  } : null

  return {
    items: [
      { label: 't(Plan)', content: <Button { ...plan } /> },
      ...period.type === 'trial' ? [
        { 
          label: period.status === 'pending' ? 't(Trial Starts On)' : 't(Trial Started)', 
          content: (
            <><Date date={ period.start_on } /> ({ from_now(period.start_on) })</>
          ) 
        },
        { 
          label: 't(Trial Ends)', 
          content: (
            <><Date date={ period.end_on } /> ({ from_now(period.end_on) })</>
          ) 
        }
      ] : [
        { 
          label: period.status === 'pending' ? 't(Starts On)' : 't(Started)', 
          content: (
            <><Date date={ period.start_on } /> ({ from_now(period.start_on) })</>
          ) 
        },
        ...period.end_on ? [
          { 
            label: 't(Ends On)', 
            content: (
              <><Date date={ period.end_on } /> ({ from_now(period.end_on) })</>
            ) 
          }
        ] : [],
        { label: 't(Renewal Frequency)', content: <FrequencyToken frequency={ period.frequency } /> },
        { label: 't(Renewal Timing)', content: <TimingToken timing={ period.timing } /> },
        { label: 't(Pricing)', content: <SubscriptionPriceToken period={ period } /> },
        ...service.model === 'metered' ? [
          { label: 't(Resource Allowances)', content: <AllowancesToken allowances={ period.allowances } plan={ period.plan } /> }
        ] : []
      ]
    ]
  }
}

const Overview = ({ service, subscription }) => {

  const { canceled_on, status } = subscription

  const contact = {
    label: subscription.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${subscription.contact.id}`
  }

  const servicebutton = {
    label: service.title,
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}`
  }

  const details = {
    audits: `subscriptions_subscriptions/${subscription.id}`,
    comments: `subscriptions_subscriptions/${subscription.id}`,
    sections: [
      {
        title: 't(Subscription Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...contact } /> },
          { label: 't(Service)', content: <Button { ...servicebutton } /> },
          { label: 't(Organization)', content: subscription.position ? subscription.position.organization : null },
          { label: 't(To)', content: subscription.to.rfc822 },
          ...subscription.cc.length > 0 ? [
            { label: 't(Cc)', content: subscription.cc.map(cc => cc.rfc822).join(', ') }
          ] : [],
          ...subscription.bcc.length > 0 ? [
            { label: 't(Bcc)', content: subscription.bcc.map(cc => cc.rfc822).join(', ') }
          ] : [],
          { label: 't(Payment Method)', show: subscription.payment_strategy === 'invoice', content: (
            <>Invoice (NET {subscription.payment_terms})</>
          ) },
          { label: 't(Payment Method)', padded: false, show: subscription.payment_strategy === 'card', content: <CardToken payment_method={ subscription.payment_method } /> },
          { label: 't(Canceled)', show: status === 'canceled' , content: subscription.canceled_on, format: 'date' },
          ...subscription.current_cycle ? [
            { label: 't(Current Billing Cycle)', content: (
              <><Date date={ subscription.current_cycle.start_date } /> - <Date date={ subscription.current_cycle.end_date } /></>
            ) }
          ] : [],
          ...subscription.next_renewal_date ? [
            { 
              label: subscription.status === 'pending' ? 't(First Renewal)' : 't(Next Renewal)', 
              content: (
                <><Date date={ subscription.next_renewal_date } /> ({ from_now(subscription.next_renewal_date) })</>
              ) 
            }
          ] : []
        ]
      },
      ...subscription.current_period ? [
        {
          title: 't(Current Plan)',
          ...getPeriod(service, subscription.current_period)
        }  
      ] : [],
      ...subscription.next_period && subscription.next_period.id !== subscription?.current_period?.id ? [
        {
          title: 't(Upcoming Plan)',
          ...getPeriod(service, subscription.next_period)
        }
      ] : []
    ]
  }

  if(status === 'pending') {
    details.alert = { color: 'teal', message: `This subscription is pending and will start on ${moment(subscription.current_period.start_on).format('MM/DD/YY')}` }
  } else if(status === 'trial') {
    details.alert = { color: 'teal', message: `This subscription is in a trial and will end on ${moment(subscription.current_period.end_on).format('MM/DD/YY')}` }
  } else if(status === 'paused') {
    details.alert = { color: 'yellow', message: 't(This subscription is paused)' }
  } else if(status === 'canceling') {
    details.alert = { color: 'orange', message: `This subscription will cancel on ${moment(canceled_on).format('MM/DD/YY')}` }
  } else if(status === 'canceled') {
    details.alert = { color: 'red', message: 't(This subscription was canceled)' }
  } else if(status === 'active') {
    details.alert = { color: 'green', message: 't(This subscription is active)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  service: PropTypes.object,
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview service={ props.service } subscription={ props.subscription } />,
  tasks: {
    items: [
      {
        label: 't(Edit Subscription)',
        svg: 'square_pen',
        show: props.subscription.status !== 'canceled',
        modal: <Edit service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Change Contact)',
        svg: 'user_round',
        show: props.subscription.status !== 'canceled',
        modal: <Contact service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Change Communication Preferences)',
        svg: 'megaphone',
        show: props.subscription.status !== 'canceled',
        modal: <Communication service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Change Payment Method)',
        svg: 'dollar',
        show: props.subscription.status !== 'canceled',
        modal: <Method service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Change Plan)',
        svg: 'copy',
        show: ['active','trial'].includes(props.subscription.status),
        modal: <Change service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Cancel Subscription)',
        svg: 'ban',
        show: props.subscription.status === 'active',
        modal: <Cancel service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Pause Subscription)',
        svg: 'circle_pause',
        show: props.subscription.status === 'active',
        modal: <Pause service={ props.service } subscription={ props.subscription } />
      },
      {
        label: 't(Generate Renewal)',
        svg: 'refresh_cw',
        show: props.subscription.canceled_on === null,
        request: {
          endpoint: `/api/admin/subscriptions/services/${ props.service.id }/subscriptions/${ props.subscription.id }/renew`,
          method: 'PATCH',
          onSuccess: (renewals) => context.flash.set('success', 't(Successfully generated renewal)'),
          onFailure: () => context.flash.set('error', 't(Unable to generate renewals)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
