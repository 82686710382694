import PropTypes from 'prop-types'
import Buttons from './buttons'
import React from 'react'

class Toolbar extends React.PureComponent {

  static propTypes = {
    leftButtons: PropTypes.array,
    centerButtons: PropTypes.array,
    rightButtons: PropTypes.array
  }

  render() {
    const { leftButtons, centerButtons, rightButtons } = this.props
    const columns = [leftButtons || [], centerButtons || [], rightButtons || []]
    return (
      <div className="maha-toolbar">
        { columns.map((buttons, index) => (
          <div className="maha-toolbar-column" key={`column_${index}`}>
            { buttons &&
              <Buttons buttons={ buttons } />
            }
          </div>
        ))}   
      </div>
    )
  }

}

export default Toolbar
