import Notifications from './notifications'
import Search from './search'
import Help from './help'

const badges = [
  { 
    title: 't(Help)',
    weight: 10,
    access: {
      team: { provider: { id: { $eq: 2 } } }
    },
    component: Help, 
    tooltip: 'Help Center' 
  },
  { 
    title: 't(Notifications)', 
    weight: 7, 
    access: true,
    svg: 'bell', 
    sidebar: Notifications, 
    route: '/admin/notifications', 
    channel: '/admin/notifications/unread', 
    sound: '/admin/audio/notification.mp3', 
    tooltip: 'Notifications' 
  },
  { 
    title: 't(Search)', 
    weight: 6, 
    access: true,
    svg: 'search', 
    sidebar: Search, 
    route: '/admin/search', 
    tooltip: 'Search' 
  }
]

export default badges
