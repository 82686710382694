import Scrollbar from '@admin/components/scrollbar'
import email from '../../entities/email/blocks'
import { canAccess } from '@core/utils/access'
import page from '../../entities/page/blocks'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import DragToken from './token'

const Content = (props, context) => {

  const { admin } = context
  const { type } = props

  const blocks = (type === 'email' ? email : page).filter(block => {
    return !block.access || canAccess(block.access, admin)
  })

  const elements = [
    ...type === 'email' ? [
      { 
        label: 't(Sections)', 
        elements: [
          { 
            svg: 'mail', 
            label: 't(Email Article)', 
            entity: 'section', 
            type: 'email_article', 
            action: 'new' 
          },
          { 
            svg: 'hammer', 
            label: 't(Prebuilt Design)', 
            entity: 'section', 
            type: 'email_module', 
            action: 'new' 
          },
          { 
            svg: 'square', 
            label: 't(Blank Section)', 
            entity: 'section', 
            type: 'section', 
            action: 'new' 
          }
        ] 
      }
    ] : [
      { 
        label: 't(Containers)', 
        elements: [
          { 
            svg: 'hammer', 
            label: 't(Prebuilt Design)', 
            entity: 'container', 
            type: 'page_module', 
            action: 'new' 
          },
          { 
            svg: 'square', 
            label: 't(Blank Container)', 
            entity: 'container', 
            type: 'container', 
            action: 'new' 
          }
        ] 
      }
    ],
    { 
      label: 't(Blocks)', 
      elements: blocks.map(block => ({
        label: block.label, 
        entity: 'block',
        svg: block.svg,
        type: block.type,
        action: 'add'
      })) 
    },
    ...type === 'email' ? [
      { 
        label: 't(Rows)',
        elements: [
          { 
            svg: 'square', 
            label: 't(1 Column Row)', 
            entity: 'row', 
            type: 'one_column', 
            action: 'add' 
          },
          { 
            svg: 'columns_2', 
            label: 't(2 Column Row)', 
            entity: 'row', 
            type: 'two_column', 
            action: 'add' 
          },
          { 
            svg: 'columns_3', 
            label: 't(3 Column Row)', 
            entity: 'row', 
            type: 'three_column', 
            action: 'add' 
          },
          { 
            svg: 'columns_4', 
            label: 't(4 Column Row)', 
            entity: 'row', 
            type: 'four_column', 
            action: 'add' 
          }
        ] 
      },
      { 
        label: 't(Columns)', 
        elements: [
          { 
            svg: 'square', 
            label: 't(Empty Column)', 
            entity: 'column', 
            type: 'column', 
            action: 'add' 
          }
        ] 
      }
    ] : []
  ]

  return (
    <Scrollbar className="mjson-designer-content-panel">
      <div className="mjson-designer-content-instructions">
        <T text="Drag and drop any of the elements below into the canvas to build your design" />
      </div>
      <div className="mjson-designer-content-body">
        { elements.map((element, i) => (
          <Fragment key={`element_${i}`}>
            <div className="mjson-designer-content-title">
              <T text={ element.label } />
            </div>
            <div className="mjson-designer-content-elements">
              { element.elements.map((element, j) => (
                <div className="mjson-designer-content-element" key={`element_${i}_${j}`} >
                  <DragToken { ...element }/>
                </div>
              )) }
            </div>
          </Fragment>        
        ) ) }
      </div>
    </Scrollbar>
  )
}

Content.contextTypes = {
  admin: PropTypes.object
}

Content.propTypes = {
  type: PropTypes.string
}

export default Content