import Button from '@admin/components/button'
import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import Token from '@admin/tokens/token'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Dropdown extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    deselectable: PropTypes.bool,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    format: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.string,
    nullable: PropTypes.bool,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    selectFirst: PropTypes.bool,
    tabIndex: PropTypes.number,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    value: PropTypes.any,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    deselectable: true,
    format: Token,
    nullable: false,
    options: [],
    selectFirst: false,
    tabIndex: 0,
    textKey: 'text',
    valueKey: 'value',
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  controlRef = React.createRef()

  state = {
    animating: false,
    active: false,
    direction: null,
    selected: null,
    value: null
  }

  _handleClickOutside = this._handleClickOutside.bind(this)
  _handleOpen = this._handleOpen.bind(this)  
  _handleSetSelected = this._handleSetSelected.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { deselectable, format, nullable, options, tabIndex } = this.props
    const { selected } = this.state
    return (
      <div className={ this._getClass() } tabIndex={ tabIndex } ref={ this.controlRef }>
        <div className="maha-input-field">
          <div className={ this._getDropdownClass() } onClick={ this._handleOpen }>
            <div onClick={ this._handleOpen }>
              { this._getLabel() }
            </div>
            <div className={ this._getMenuClass() }>
              { nullable &&
                <div className="item" onClick={ this._handleChoose.bind(this, null) }>
                  <div className="token">&nbsp;</div>
                </div>          
              }
              { options.map((option, index) => (
                <div key={`option_${index}`} className="item" onClick={ this._handleChoose.bind(this, index) }>
                  <Format { ...option } option={ option } format={ format } value={ this._getText(option) } />
                </div>
              )) }
            </div>
          </div>
        </div>
        { deselectable && selected !== null ?
          <Button { ...this._getDeselect() } /> :
          <div className="maha-input-action" onClick={ this._handleOpen }>
            <Icon icon="caret-down" />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    document.addEventListener('mousedown', this._handleClickOutside)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { active, selected } = this.state
    if(selected !== prevState.selected) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
    if(active !== prevState.active) {
      this.setState({
        animating: true
      })
      setTimeout(() => this.setState({
        animating: false
      }), 250)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  _getClass() {
    const classes = ['maha-dropdown','maha-input']
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value, selectFirst, valueKey } = this.props
    const { options } = this.props
    const first = options.length > 0 ? _.get(options[0], valueKey) : null
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : (selectFirst ? first : null)
  }

  _getDeselect() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleChoose.bind(this, null)
    }
  }

  _getDropdownClass() {
    const { animating, active, direction } = this.state
    const { disabled } = this.props
    const classes = ['ui','fluid','selection','dropdown']
    if(disabled) classes.push('disabled')
    if(direction) classes.push(direction)
    if(active) classes.push('active')
    if(active && !animating) classes.push('visible')
    if(!active && animating) classes.push('visible')
    return classes.join(' ')
  }

  _getIndex(value) {
    const { options, valueKey } = this.props
    const index = options.findIndex(option => {
      const optionValue = valueKey ? _.get(option, valueKey) : option
      return _.isEqual(optionValue, value)
    })
    return index >= 0 ? index : null
  }

  _getLabel() {
    const { entity, format, label, options, placeholder, textKey } = this.props
    const { selected } = this.state
    const option = selected !== null ? options[selected] : null
    if(option && format) return <Format { ...option } option={ option } format={ format } value={ this._getText(option) } />
    const text = option ? _.get(option, textKey) : placeholder || `t(Choose) ${label || entity}`
    return <div className="placeholder"><T text={ text } /></div>
  }

  _getMenuClass() {
    const { active, animating } = this.state
    const classes = ['menu','transition']
    const direction = this.state.direction === 'upward' ? 'up' : 'down'
    if(!animating && !active) classes.push('hidden')
    if(animating || active) classes.push('visible')
    if(animating && active) classes.push(`animating slide ${direction} in`)
    if(animating && !active) classes.push(`animating slide ${direction} out`)
    return classes.join(' ')
  }

  _getScrollContainer(node) {
    const parent = node.parentNode
    if(node.tagName === 'BODY') return null
    const style = getComputedStyle(parent)
    return style['overflow-y'] === 'auto' ? parent : this._getScrollContainer(parent)
  }

  _getValue(index) {
    if(index === null) return null
    if(index < 0) return null
    const { options, valueKey } = this.props
    const option = options[index]
    return valueKey ? _.get(option, valueKey) : option
  }

  _getText(option) {
    const { textKey } = this.props
    return textKey ? _.get(option, textKey) : option
  }

  _handleChange() {
    const { selected } = this.state
    const value = this._getValue(selected)
    this.props.onChange(value)
  }

  _handleChoose(index, e) {
    e.stopPropagation()
    this._handleSetSelected(index)
    this.setState({
      active: false
    })  
  }

  _handleClickOutside(e) {
    const { active } = this.state
    if(!active) return
    const parent = e.target.closest('.maha-dropdown')
    if(parent !== null && parent.isEqualNode(this.controlRef.current)) return
    this.setState({
      active: false
    })
  }

  _handleOpen(e) {
    const { direction, disabled } = this.props
    const { active } = this.state
    const container = this._getScrollContainer(this.controlRef.current)
    if(!container) return this.setState({ direction: null, active: true })
    const rect = container.getBoundingClientRect()
    const percent = ((e.clientY - rect.y) / rect.height) * 100
    if(disabled || active || e.target.className === 'item') return
    this.setState({
      direction: direction || (percent > 75 ? 'upward' : null),
      active: true
    })
  }

  _handleSet(value) {
    const index = this._getIndex(value)
    this._handleSetSelected(index)
  }

  _handleSetSelected(selected) {
    this.setState({ selected })
  }

  _handleValidate() {
    const { selected } = this.state
    const { required } = this.props
    if(required && selected === null) return this.props.onValid(null, ['t(You must choose an option)'])
    const value = this._getValue(selected)
    this.props.onValid(value)
  }

}

export default Dropdown
