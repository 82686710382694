import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Themes)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/themes`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true }
    ],
    empty: {
      title: 't(No Themes)',
      text: 't(There are no themes)',
      icon: 'paint-brush',
      buttons: [
        { 
          label: 't(Create Theme)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' }
    ],
    entity: 'theme',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/team/themes/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Theme)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
