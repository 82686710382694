import ProfilePicker from '@admin/components/profile_picker'
import PropTypes from 'prop-types'
import React from 'react'

class Profile extends React.Component {

  static propTypes = {
    onDone: PropTypes.func
  }

  render() {
    return <ProfilePicker { ...this._getProfilePicker() } />
  }

  _getProfilePicker() {
    return {
      empty: {
        image: '/images/services/canva.png',
        title: 't(Connect to Canva)',
        text: 't(You have not yet connected any Canva accounts)'
      },
      sources: ['canva'],
      onDone: this.props.onDone
    }
  }

}

export default Profile

