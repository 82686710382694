import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Advisors)',
  collection: {
    endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/advisors`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'title',  primary: true, format: (advisor) => <TeamToken { ...advisor.team } /> },
      { label: 't(Status)', key: 'team.status', sort: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    criteria: {
      fields: [
        { label: 't(Advisor)', fields: [
          { name: 'Status', key: 'status', type: 'select', options: ['pending','configured','active','inactive'], format: StatusToken  }
        ] }
      ]
    },
    empty: {
      svg: 'user_round',
      title: 't(No Advisors)',
      text: 't(There are no advsiors for this agency)'
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
