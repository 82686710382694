import ProgramPhoneNumbers from './program_phone_numbers'
import ContactPhoneNumbers from './contact_phone_numbers'
import Container from '@admin/components/container'
import Message from '@admin/components/message'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import React from 'react'
import SMS from './sms'

class New extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    twilio: PropTypes.object
  }

  static propTypes = {
    program_phone_numbers: PropTypes.array,
    contact: PropTypes.object,
    sms: PropTypes.object
  }

  stackRef = React.createRef()

  state = {
    contact_phone_number: null,
    program_phone_number: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleContactPhoneNumber = this._handleContactPhoneNumber.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleProgramPhoneNumber = this._handleProgramPhoneNumber.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    const { program_phone_numbers } = this.props
    return program_phone_numbers.length === 0 ?
      <Message { ...this._getMessage() } /> :
      <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { program_phone_numbers, sms } = this.props
    if(sms) {
      const { program, phone_number } = sms
      this.setState({ program, phone_number }, () => {
        this._handleSMS()
      })
    } else if(program_phone_numbers.length === 1) {
      this._handleProgramPhoneNumber(program_phone_numbers[0])
    } else {
      this._handlePush(ProgramPhoneNumbers, this._getProgramPhoneNumbers())
    }
  }

  _getMessage() {
    return {
      svg: 'phone',
      title: 't(No Phone Numbers)',
      text: 't(You have not provisioned any phone numbers)'
    }
  }

  _getContactPhoneNumbers() {
    const { program_phone_numbers } = this.props
    return {
      program_phone_numbers,
      contact_phone_numbers: this._getMobileNumbers(),
      onBack: this._handlePop,
      onCancel: this._handleCancel,
      onChoose: this._handleContactPhoneNumber
    }
  }

  _getProgramPhoneNumbers() {
    const { program_phone_numbers } = this.props
    return {
      program_phone_numbers,
      onCancel: this._handleCancel,
      onChoose: this._handleProgramPhoneNumber
    }
  }

  _getStack() {
    return {
      display_name: 'smses',
      ref: this.stackRef
    }
  }

  _getSMS() {
    const { program_phone_number, contact_phone_number } = this.state
    const { contact, program_phone_numbers } = this.props
    return {
      contact,
      contact_phone_number,
      contact_phone_numbers: this._getMobileNumbers(),
      program_phone_number,
      program_phone_numbers,
      onBack: this._handlePop,
      onCancel: this._handleCancel,
      onDone: this._handleCancel
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _getMobileNumbers() {
    const { contact } = this.props
    return contact.phone_numbers.filter(number => {
      return number.can_text
    })
  }

  _handleProgramPhoneNumber(program_phone_number) {
    const conatct_phone_numbers = this._getMobileNumbers()
    this.setState({ program_phone_number }, () => {
      if(conatct_phone_numbers.length === 1) {
        return this._handleContactPhoneNumber(conatct_phone_numbers[0])
      }
      this._handlePush(ContactPhoneNumbers, this._getContactPhoneNumbers())
    })
  }

  _handleContactPhoneNumber(contact_phone_number) {
    this.setState({ contact_phone_number }, this._handleSMS)
  }

  _handleSMS() {
    this._handlePush(SMS, this._getSMS())
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

const mapResources = (props, context) => ({
  program_phone_numbers: {
    endpoint: '/api/admin/phone/numbers',
    filter: {
      released_at: {
        $nl: true
      }
    }
  }
})

export default Container(mapResources)(New)
