import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'
import Sent from './sent'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.email_campaign.title,
  manager: {
    path: `/admin/truevail/admin/deliverability/email_campaign/${resources.email_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview email_campaign={ resources.email_campaign } />
      },
      // {
      //   label: 't(DMARC Reports)',
      //   path: '/dmarc_reports',
      //   panel: <DMARCReports domain={ resources.domain } />
      // },
      // {
      //   label: 't(Email Campaigns)',
      //   path: '/email_campaigns',
      //   panel: <EmailCampaigns domain={ resources.domain } />
      // },
      // {
      //   label: 't(Segments)',
      //   path: '/segments',
      //   panel: <DomainSegments domain={ resources.domain } />
      // },
      // {
      //   label: 't(Subdomains)',
      //   path: '/subdomains',
      //   show: resources.domain.tld_id === null,
      //   panel: <Subdomains domain={ resources.domain } />
      // },
      // {
      //   label: 't(Queued)',
      //   path: '/queued',
      //   panel: <Queued domain={ resources.domain } />
      // },
      {
        label: 't(Sent)',
        path: '/sent',
        panel: <Sent email_campaign={ resources.email_campaign } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  email_campaign: `/api/admin/truevail/admin/deliverability/email_campaigns/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
