import ListsUpdateBatch from '@apps/crm/admin/components/lists_update_batch'
import TagsUpdateBatch from '@apps/crm/admin/components/tags_update_batch'
import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Participants)',
  collection: {
    endpoint: `/api/admin/truevail/admin/webinars/${props.webinar.id}/participants`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Registered)', key: 'registered_at', collapsing: true, format: 'datetime' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken },
      { label: 't(Customer)', key: 'is_customer', collapsing: true, align: 'center', format: 'check' },
      { label: 't(Converted)', key: 'is_converted', collapsing: true, align: 'center', format: 'check' }
    ],
    criteria: {
      system: [
        { id: 0, title: 't(Non-Customer Attendees)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: 'abc', value: 'attended' },
            { code: 'ghi', data: null, field: 'is_customer', operator: '$eq', parent: 'abc', value: 'false' }
          ]
        } },
        { id: 1, title: 't(Customer Attendees)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: 'abc', value: 'attended' },
            { code: 'ghi', data: null, field: 'is_customer', operator: '$eq', parent: 'abc', value: 'true' }
          ]
        } },
        { id: 2, title: 't(Non-Customer No Shows)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: 'abc', value: 'noshow' },
            { code: 'ghi', data: null, field: 'is_customer', operator: '$eq', parent: 'abc', value: 'false' }
          ]
        } },
        { id: 3, title: 't(Customer No Shows)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: 'abc', value: 'noshow' },
            { code: 'ghi', data: null, field: 'is_customer', operator: '$eq', parent: 'abc', value: 'true' }
          ]
        } }
      ],
      fields: [
        { label: 't(Participant)', fields: [
          { name: 'Status', key: 'status', type: 'select', options: ['registered','joined','left','noshow','attended'], format: StatusToken  },
          { name: 'Is Customer', key: 'status', type: 'boolean'  }
        ] }
      ]
    },
    defaultSort: { key: 'registered_at', order: 'desc' },
    empty: {
      title: 't(No Participants)',
      text: 't(There are no registered participants for this webinar)',
      svg: 'user_round'
    },
    entity: 'participants',
    selectable: true,
    batch: {
      batch_type: 'truevail_participants',
      tasks: [
        { 
          label: 't(Update Lists)',
          svg: 'list',
          action: 'lists', 
          config: { webinar_id: props.webinar.id },
          component: <ListsUpdateBatch />  
        },
        { 
          label: 't(Update Tags)', 
          svg: 'tag',
          action: 'tags',
          config: { webinar_id: props.webinar.id },
          component: <TagsUpdateBatch /> 
        }
      ]
    },
    onClick: (record) => context.router.push(`/admin/truevail/admin/webinars/${props.webinar.id}/participants/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
