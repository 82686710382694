import AdvisorEmailCampaignToken from '@apps/truevail/admin/tokens/advisor_email_campaign'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Sent Email Campaigns)',
  access: { rights: { $allOf: ['calendar:access_app','truevail:access_app'] } },
  collection: {
    endpoint: '/api/admin/calendar/truevail/email_campaigns_sent',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (email_campaign) => (
        <AdvisorEmailCampaignToken email_campaign={ email_campaign } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(Email Campaigns)',
      text: 't(There are no campaigns for this time range)'
    },
    entity: 'email campaign',
    onClick: (record) => context.router.push(`/truevail/agency/email_campaigns/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
