import LineItems from '@apps/finance/admin/components/line_items'
import ContactToken from '@apps/crm/admin/tokens/contact'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    invoice: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { invoice } = this.props
    return {
      title: 't(Duplicate Invoice)',
      method: 'POST',
      action: '/api/admin/finance/invoices',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(Program)', name: 'program_id', type: 'lookup', endpoint: '/api/admin/programs', filter: { bank_id: { $eq: 'not_null' } }, valueKey: 'id', textKey: 'title', required: true, format: ProgramToken, defaultValue: invoice.program.id },
              { label: 't(Contact)', name: 'contact_id', type: 'lookup', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, format: ContactToken, defaultValue: invoice.customer.id }
            ] },
            { type: 'fields', fields: [
              { label: 't(Date)', name: 'date', type: 'datefield', placeholder: 'Date', required: true, defaultValue: moment() },
              { label: 't(Due)', name: 'due', type: 'datefield', placeholder: 'Date', required: true, defaultValue: moment().add(1, 'week') }
            ] },
            { label: 't(Notes)', name: 'notes', type: 'textfield', placeholder: 'Add notes', defaultValue: invoice.notes },
            { label: 't(Line Items)', name: 'line_items', type: LineItems, required: true, defaultValue: invoice.line_items.map(line_item => ({
              project_id: line_item.project_id,
              revenue_type_id: line_item.revenue_type_id,
              description: line_item.description,
              quantity: line_item.quantity,
              price: line_item.price,
              tax_rate: line_item.tax_rate
            })) }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(invoice) {
    this.context.router.push(`/finance/invoices/${invoice.id}`)
    this.context.modal.close()
  }

}

export default Clone
