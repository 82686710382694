import ServiceToken from '@apps/maha/admin/tokens/service'
import Panel from '@admin/components/panel'
import ModeToken from '@admin/tokens/mode'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Integrations)',
  collection: {
    endpoint: `/api/admin/platform/providers/${props.provider.id}/integrations`,
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Service)', key: 'service.name', sort: 'service', primary: true, format: (integration) => (
        <ServiceToken service={ integration.service } />
      )},
      { label: 't(Mode)', key: 'mode', collapsing: true, format: ModeToken },
      { label: 't(Active)', key: 'is_active', collapsing: true, format: 'check' }
    ],
    recordTasks: (record) => [
      { 
        label: 't(Edit Integration)',
        svg: 'square_pen', 
        modal: <Edit provider={ props.provider } integration={ record } /> 
      },
      { 
        label: 't(Activate Integration)',
        svg: 'check',
        show: !record.is_active,
        request: {
          endpoint: `/api/admin/platform/providers/${props.provider.id}/integrations/${record.id}/activate`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate out this integration)'),
          onSuccess: (result) => context.flash.set('success', 't(The asset has been activated)')
        }
      },
      { 
        label: 't(Dectivate Integration)',
        svg: 'ban',
        show: record.is_active,
        request: {
          endpoint: `/api/admin/platform/providers/${props.provider.id}/integrations/${record.id}/activate`,
          method: 'PATCH',
          body: {
            is_active: false
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to deactivate out this integration)'),
          onSuccess: (result) => context.flash.set('success', 't(The asset has been deactivated)')
        }
      }
    ],
    defaultSort: { key: 'service', order: 'asc' },
    empty: {
      icon: 'cubes',
      title: 't(No Integrations)',
      text: 't(You have not yet created any integrations for this provider)',
      buttons: [
        {
          label: 't(Create Integration)',
          modal: <New provider={ props.provider } />
        }        
      ]
    },
    entity: 'integration'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Integration)',
    modal: <New provider={ props.provider } />
  }
})

export default Panel(null, mapPropsToPanel)
