import OptOutToken from '@apps/crm/admin/tokens/optout'
import OptInToken from '@apps/crm/admin/tokens/optin'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Consent extends React.PureComponent {

  static propTypes = {
    campaign: PropTypes.object,
    config: PropTypes.object,
    fields: PropTypes.array,
    program: PropTypes.object,
    workflow: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    const channel_types = this._getChannelTypes()
    return {
      action: 'add',
      channel_type: channel_types[0].value
    }
  }

  _getForm() {
    const { config } = this.state
    const channel_types = this._getChannelTypes()
    return {
      ref: this.formRef,
      title: 't(Update Consent)',
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Consent)', type: 'segment', fields: [
              { name: 'action', type: 'radiogroup', options: [
                { value: 'add', text: 't(Add Consent)' },
                { value: 'remove', text: 't(Remove Consent)'}
              ], defaultValue: config.action },
              { label: 't(Channel)', name: 'channel_type', type: 'radiogroup', options: channel_types, valueKey: 'value', textKey: 'text', required: true, defaultValue: config.channel_type || channel_types[0] },
              { label: 't(Reason)', name: 'optin_reason', show: config.action === 'add', type: 'radiogroup', options: ['consent','contract','legal obligation','vital interests','public interest','legitimate interest'], format: OptInToken, defaultValue: config.optin_reason || 'consent', required: true },
              { label: 't(Reason)', name: 'optout_reason', show: config.action === 'remove', type: 'radiogroup', options: ['done','never','inappropriate','spam','other'], format: OptOutToken, defaultValue: config.optout_reason || 'done', required: true },
              { name: 'restore_consent', type: 'checkbox', prompt: 't(Restore consent if contact already exists and was previously opted out)', defaultValue: config.restore_consent }
            ], required: true }
          ]
        }
      ]
    }
  }

  _getChannelTypes() {
    const { campaign, program, workflow } = this.props
    if(program && campaign?.type === 'voice') {
      return [
        { value: 'voice', text: 't(Voice)' }
      ]
    }
    if(program && campaign?.type === 'sms') {
      return [
        { value: 'sms', text: 't(SMS)' }
      ]
    }
    if(program && workflow?.event) {
      return this._getWorkflowChannelTypes(workflow.event.contact_config.fields, true)
    } else if(program && workflow?.form) {
      return this._getWorkflowChannelTypes(workflow.form.config.fields)
    } else if(program && workflow?.order) {
      return this._getWorkflowChannelTypes(workflow.order.config.fields, true)
    }
    return [
      { value: 'email', text: 't(Email)' },
      { value: 'voice', text: 't(Voice)' },
      { value: 'sms', text: 't(SMS)' },
      { value: 'postal', text: 't(Postal)' }
    ]
  }

  _getWorkflowChannelTypes(fields, addDefaults) {
    return fields.reduce((channel_types, field) => [
      ...channel_types,
      ...field.contactfield && field.contactfield.type === 'emailfield' ? [
        { value: 'email', text: 't(Email)' }
      ] : [],
      ...field.contactfield && field.contactfield.type === 'phonefield' ? [
        { value: 'voice', text: 't(Voice)' },
        { value: 'sms', text: 't(SMS)' }
      ] : [],
      ...field.contactfield && field.contactfield.type === 'addressfield' ? [
        { value: 'postal', text: 't(Postal)' }
      ] : []
    ], addDefaults ? [
      { value: 'email', text: 't(Email)' }
    ] : [])
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Consent
