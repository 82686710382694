import FormDesigner from '@apps/forms/admin/components/form_designer/wrapper'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Fields = ({ fields, form, program }, { admin }) => {

  const designer = {
    editable: canAccess({ 
      programs: { $canEdit: form.program.id },
      rights: { $allOf: ['forms:manage_forms'] } 
    }, admin),
    form,
    code: form.code,
    program,
    fields,
    endpoint: `/api/admin/forms/forms/${form.id}`
  }

  return <FormDesigner { ...designer } />

}

Fields.contextTypes = {
  admin: PropTypes.object
}

Fields.propTypes = {
  fields: PropTypes.array,
  form: PropTypes.object,
  program: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Fields)',
  component: <Fields fields={ props.fields } form={ props.form } program={ props.program } />
})

const mapResourcesToPanel = (props, context) => ({
  program: `/api/admin/team/programs/${props.form.program.id}`,
  fields: `/api/admin/team/programs/${props.form.program.id}/fields`
})


export default Panel(mapResourcesToPanel, mapPropsToPanel)
