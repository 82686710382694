import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'
import New from './new'

class ReminderField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    defaultValue: PropTypes.array,
    id: PropTypes.string,
    relative: PropTypes.bool,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    reminders: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { reminders } = this.state
    return (
      <div className="tasks-reminderfield">
        <div className="tasks-reminderfield-reminders">
          { reminders.map((reminder, index) => (
            <div className="tasks-reminderfield-reminder" key={`reminder_${index}`}>
              <div className="tasks-reminderfield-reminder-label">
                { this._getDescription(reminder) } ({ reminder.method.toUpperCase() })
              </div>
              { !reminder.delivered_at &&
                <Button { ...this._getRemove(index) } />
              }
            </div>
          ))}
          <div className="tasks-reminderfield-reminder-add">
            <Button { ...this._getAdd() } />
          </div>
        </div>
      </div>
    )
  }

  _getDescription(reminder) {
    const date = reminder.date ? moment(reminder.date).format('MMMM d') : ''
    const time = reminder.time ? moment(`2022-01-01 ${reminder.time}`).format('h:mm A') : ''
    if(reminder.timing === '5_mins') return '5 minutes before'
    if(reminder.timing === '10_mins') return '10 minutes before'
    if(reminder.timing === '15_mins') return '15 minutes before'
    if(reminder.timing === '30_mins') return '30 minutes before'
    if(reminder.timing === '1_hour') return '1 hour before'
    if(reminder.timing === '2_hours') return '2 hours before'
    if(reminder.timing === '1_day') return `1 day before at ${time}`
    if(reminder.timing === '2_days') return `2 days before at ${time}`
    if(reminder.timing === '1_week') return `1 week before at ${time}`
    if(reminder.timing === 'custom') return `${date} at ${time}`
  }

  componentDidMount() {
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { reminders } = this.state
    if(!_.isEqual(reminders, prevState.reminders)) {
      this._handleChange()
    }
  }

  _getAdd() {
    return {
      label: 'Add Reminder',
      className: 'link',
      handler: this._handleNew
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: 'tasks-reminderfield-reminder-action',
      confirm: 't(Are you sure you want to remove this reminder?)',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleNew() {
    const { relative } = this.props
    const props = {
      relative,
      onBack: this._handleBack,
      onDone: this._handleCreate
    }
    this.context.form.push(New, props)
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange() {
    const { reminders } = this.state
    this.props.onChange(reminders)
  }

  _handleCreate(reminder) {
    const { reminders } = this.state
    this.context.form.pop()
    this.setState({
      reminders: [
        ...reminders,
        reminder
      ]
    })
  }

  _handleRemove(index) {
    const { reminders } = this.state
    this.setState({
      reminders: reminders.filter((reminder, i) => {
        return i !== index
      })
    })
  }

  _handleValidate() {
    const { reminders } = this.state
    this.props.onValid(reminders)
  }

}

export default ReminderField
