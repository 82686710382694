const badges = [
  { 
    title: 't(Contacts)', 
    weight: 7.1, 
    access: {
      rights: { $allOf: ['crm:access_app'] }
    },
    svg: 'user_round', 
    route: '/admin/crm/contacts', 
    visible: ['desktop','tablet'],
    tooltip: 't(Contacts)' 
  }
]

export default badges
