import Panel from '@admin/components/panel'
import Logo from '@admin/components/logo'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Orders)',
  collection: {
    endpoint: '/api/admin/stores/orders',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Store)', key: 'store.title', primary: true, format: (order) => (
        <div className="store-token">
          <div className="store-token-logo">
            <Logo team={ order.store.program } width="24" />
          </div>
          <div className="store-token-label">
            <div className="token-link">
              { order.store.title }
            </div>
          </div>
        </div>
      ) },
      { label: 't(Date)', key: 'created_at', collapsing: true, format: 'date' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' }
    ],
    empty: {
      title: 't(No Orders)',
      text: 't(There are no orders for this contact)',
      svg: 'shopping_cart'
    },
    entity: 'order',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/stores/stores/${record.store.id}/orders/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
