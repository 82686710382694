import New from '@apps/datasets/admin/views/types/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Types)',
  collection: {
    endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title.value', sort: 'title', primary: true }
    ],
    empty: {
      svg: 'database',
      title: 't(No Types)',
      text: 't(You have not yet created any types)',
      buttons: [
        { label: 'Create Type', modal: <New dataset={ props.dataset } /> }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Delete Type)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this type? It will delete all associated fields and records.)',
        deletable: true,
        request: {
          endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 't(Unable to delete this type)')
        }
      }
    ],
    entity: 'type',
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/datasets/${props.dataset.id}/types/${record.id}`)
  },
  tasks: {
    svg: 'plus',
    items: [
      { label: 'Create Type', modal: <New dataset={ props.dataset } />  }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
