import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import Confirmation from './new/confirmation'
import Sessions from './new/sessions'
import Tickets from './new/tickets'
import Contact from './new/contact'
import Payment from './new/payment'
import Details from './new/details'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object,
    program: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program } = this.props
    return {
      title: 't(Duplicate Event)',
      action: '/api/admin/events/events',
      method: 'POST',
      defaultValue: this._getDefaultValue(),
      formatData: this._getData,
      getSteps: this._getSteps,
      props: { program },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }
  
  _getDefaultValue() {
    const { event } = this.props
    return {
      title: `Copy of ${event.title}`,
      ..._.omit(event, ['code','title','permalink']),
      ticket_types: event.ticket_types.map(ticket_type => ({
        ..._.omit(ticket_type, ['id']),
        is_active: true
      })),
      sessions: event.sessions.map(session => {
        return _.omit(session, ['id'])
      })
    }
  }

  _getData(event) {
    return {
      program_id: event.program.id,
      ...event
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Sessions)', component: Sessions },
      { label: 't(Payment)', component: Payment },
      { label: 't(Contact)', component: Contact },
      { label: 't(Tickets)', component: Tickets },
      { label: 't(Confirmation)', component: Confirmation }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(event) {
    this.context.router.push(`/events/events/${event.id}`)
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  event: `/api/admin/events/events/${props.event.id}/edit`,
  program: `/api/admin/programs/${props.event.program.id}`
})

export default Container(mapResources)(Clone)
