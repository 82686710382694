import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const WorkflowToken = ({ show_provider, workflow }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-label">
      <div className="token-link">
        { workflow.title }        
      </div>
      { show_provider !== false &&
        <>
          { workflow.agency ?
            <div className="token-stats">
              <span>Agency: { workflow.agency.title }</span>
            </div> :
            workflow.team ?
              <div className="token-stats">
                <span>Team: { workflow.team.title }</span>
              </div> :
              <div className="token-stats">
                <span>Provider: Truevail</span>
              </div>    
          }
        </>      
      }
      <div>
        <T text={ workflow.trigger.text } /> { workflow.tag &&
          <> <span className="automation-workflow-token-target">{ workflow.tag }</span></>
        }
      </div>
    </div>
  </div>
)

WorkflowToken.propTypes = {
  show_provider: PropTypes.bool,
  workflow: PropTypes.object
}

export default WorkflowToken
