import MJSONDesigner from '@admin/components/mjson_designer'
import ImageTools from '@admin/components/image_tools'
import ApplyTemplate from './apply_template'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Embed from '../embed'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    embed: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { advisor, embed } = this.props
    return {
      endpoint: `/forms_embeds/${embed.id}`,
      library: `/advisors/${advisor.id}`,
      preview: `/mjson/dynamic/forms_embeds/${embed.code}`,
      root_route: `/truevail/admin/advisors/${advisor.id}/embed-${embed.id}`,
      type: 'page',
      versionable: true
    }
  }

}

const getApplyTemplate = (props) => ({
  advisor: props.advisor,
  endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/embeds/${props.embed.id}/apply`,
  type: 'embed'
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design advisor={ props.advisor } embed={ props.embed } />,
  tasks: {
    items: [
      {
        label: 't(Apply Template)',
        svg: 'stamp',
        modal: {
          options: { width: 1168 },
          component: <ApplyTemplate { ...getApplyTemplate(props) } />
        }
      },
      { 
        label: 't(Get Embed Code)',
        svg: 'code',
        modal: {
          options: { width: 650, height: 280 },
          component: <Embed embed={ props.embed } />
        }
      },
      { 
        label: 't(Generate Image)',
        svg: 'wand_sparkles',
        modal: <ImageTools palette={props.theme?.value?.theme?.palette } /> 
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  ...props.theme ? {
    theme: `/api/admin/maha_themes/${props.theme.id}/config/versions/published`
  } : {}
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
