import ModalPanel from '@admin/components/modal_panel'
import Searchbox from '@admin/components/searchbox'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Loader from '@admin/components/loader'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Web extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    multiple: PropTypes.bool,
    files: PropTypes.array,
    response: PropTypes.object,
    status: PropTypes.string,
    url: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onClear: PropTypes.func,
    onNext: PropTypes.func,
    onSetResponse: PropTypes.func,
    onSetStatus: PropTypes.func,
    onSetUrl: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleAdd = this._handleAdd.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleLookup = _.debounce(this._handleLookup.bind(this), 500)

  render() {
    const { response, status } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-web">
          <div className="maha-attachments-web-header">
            <Searchbox { ...this._getSearchbox() } />
          </div>
          <div className="maha-attachments-web-body">
            { status === 'pending' &&
              <Message { ...this._getIntro() } />
            }
            { status === 'failed' &&
              <Message { ...this._getFailed() } />
            }
            { status == 'previewing' && <Loader { ...this._getPreviewing() } /> }
            { status === 'previewed' && response['content-type'].match(/(jpeg|jpg|gif|png)/) &&
              <div className="maha-attachments-web-preview">
                <div className="maha-attachments-web-preview-body">
                  <Img nonce={ window.nonce } src={ this._getImagePreview() } />
                </div>
                <div className="maha-attachments-web-preview-footer">
                  <Button { ...this._getImport() } />
                </div>
              </div>
            }
            { status === 'previewed' && !response['content-type'].match(/(jpeg|jpg|gif|png)/) &&
              <Message { ...this._getPreview() } />
            }
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props
    if(status !== prevProps.status && status === 'success') {
      this._handleAdd()
    }
  }

  _getFailed() {
    return {
      svg: 'x',
      title: 't(Invalid URL)',
      text: 'Unable to load URL'
    }
  }

  _getPanel() {
    const { files } = this.props
    const panel = {
      title: 't(Choose File(s))',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack  }
      ],
      rightItems: files.length > 0 ? [
        { label: 't(Next)', handler: this._handleNext }
      ] : []
    }
    return panel
  }

  _getIcon() {
    const content_type = this.props.response['content-type']
    if(content_type.match(/(jpeg|jpg|gif|png)/)) return 'picture-o'
    if(content_type.match(/audio/)) return 'volume-up'
    if(content_type.match(/video/)) return 'play-circle'
    if(content_type.match(/pdf/)) return 'file-pdf-o'
    if(content_type.match(/excel/)) return 'file-excel-o'
    if(content_type.match(/spreadsheet/)) return 'file-excel-o'
    if(content_type.match(/msword/)) return 'file-word-o'
    if(content_type.match(/wordprocessingml/)) return 'file-word-o'
    if(content_type.match(/document/)) return 'file-word-o'
    if(content_type.match(/powerpoint/)) return 'file-powerpoint-o'
    if(content_type.match(/presentationml/)) return 'file-powerpoint-o'
    if(content_type.match(/map/)) return 'map-o'
    if(content_type.match(/zip/)) return 'file-archive-o'
    if(content_type.match(/xml/)) return 'file-code-o'
    if(content_type.match(/html/)) return 'file-code-o'
    return 'file-text-o'
  }

  _getImagePreview() {
    const { admin } = this.context
    const { url } = this.props
    return `${process.env.ADMIN_HOST}/api/admin/assets/url/preview?token=${admin.token}&url=${encodeURIComponent(url)}`
  }

  _getImport() {
    const { multiple } = this.props
    return  {
      label: multiple ? 'Add to Queue' : 'Import',
      color: 'red',
      handler: this._handleAdd
    }
  }

  _getIntro() {
    return {
      svg: 'globe',
      title: 't(Enter a URL)',
      text: 'Enter a URL to download the asset online.'
    }
  }

  _getPreviewing() {
    return {
      label: 'Loading Preview'
    }
  }

  _getPreview() {
    return {
      icon: this._getIcon(),
      button: {
        label: 'Import File',
        handler: this._handleAdd
      }
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Enter a URL...',
      onChange: this._handleLookup
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleLookup(url) {
    if(url.length === 0) {
      return this.props.onSetResponse({
        response: null,
        status: 'pending',
        url: null
      })
    }
    this.props.onSetResponse({
      response: null,
      status: 'previewing',
      url
    })
    this.context.network.request({
      endpoint: '/api/admin/assets/url',
      method: 'GET',
      query: { url },
      onSuccess: ({ data }) => {
        this.props.onSetResponse({
          response: data,
          status: 'previewed',
          url
        })
      },
      onFailure: () => {
        this.props.onSetStatus('failed')
      }
    })
  }

  _handleAdd() {
    const { admin } = this.context
    const { response, url } = this.props
    this.props.onAdd({
      id: url,
      create: {
        endpoint: '/api/admin/assets/url',
        body: { url }
      },
      source_id: 'web',
      name: response.file_name,
      service: 'web',
      content_type: response['content-type'],
      thumbnail: `/api/admin/assets/url?token=${admin.token}&url=${encodeURI(url)}`,
      status: 'pending'
    })
    this.props.onClear()
  }

  _handleClear() {
    this.props.onClear()
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Web
