import ProfileConnect from '@admin/components/profile_connect'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const priority = {
  'instagram_business': 1,
  'facebook': 2,
  'linkedin': 3,
  'google': 4,
  'tiktok': 5
}

class Details extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const profiles = this._getProfiles()
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Title)', 
              name: 'title', 
              type: 'textfield',
              defaultValue: formdata.title,
              required: true 
            },
            { 
              label: 't(Profiles)', 
              name: 'profiles', 
              type: 'checkboxgroup', 
              entity: 'profile', 
              options: profiles,
              compareKey: 'id', 
              valueKey: null, 
              textKey: 'username', 
              format: (profile) => (
                <ProfileToken profile={ profile } />
              ), 
              defaultValue: profiles,
              deselectable: true, 
              required: true, 
              after: (
                <Button { ...this._getAdd() } />
              ) 
            }
          ]
        }
      ]
    }
  }

  _getAdd() {
    return {
      label: '+ t(add profile)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getProfile(value) {
    const { profiles } = this.props
    const profile = profiles.find(profile => {
      return profile.id === value.profile_id
    })
    return profile
  }

  _getProfiles() {
    const { profiles } = this.props
    const sources = this._getSources()
    return profiles.filter(profile => {
      return sources.includes(profile.service.source)
    }).sort((a,b) => {
      return priority[a.service.source] - priority[b.service.source]
    })
  }

  _getSources() {
    const { features } = this.context.admin
    const { type } = this.props.formdata
    return [
      ..._.includes(['photo','video','story','reel'], type) ? ['instagram_business'] : [],
      ..._.includes(['photo','video','link','story','reel'], type) ? ['facebook'] : [],
      ...features.linkedin === true && _.includes(['photo','video','link'], type) ? ['linkedin'] : [],
      ...features.youtube === true && _.includes(['reel','video'], type) ? ['google'] : [],
      ...features.tiktok === true && _.includes(['reel'], type) ? ['tiktok'] : []
    ]
  }

  _handleAdd() {
    const { program_id } = this.props.props
    const props = {
      program_id,
      sources: this._getSources(),
      types: ['posts'],
      onBack: this.context.form.pop,
      onDone: this.context.form.pop
    }
    this.context.form.push(ProfileConnect, props)
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onNext(social_campaign)
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: `/api/admin/programs/${props.props.program_id}/profiles`,
    refresh: `/teams/${context.admin.team.id}/admin/team/programs/${props.props.program_id}/profiles`
  }
})

export default Container(mapResources)(Details)
