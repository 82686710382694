import Button from '@admin/components/button'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import RecipientToken from './recipient'
import PropTypes from 'prop-types'
import NewRecipient from './new'
import React from 'react'

class Notification extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    program: PropTypes.object,
    users: PropTypes.array,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    return {
      strategy: 'user'
    }
  }

  _getForm() {
    const { provider } = this.context
    const { program } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Send Notification)',
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            ...program ? [
              { 
                label: 't(Recipients)', 
                name: 'recipients', 
                type: 'collectionfield', 
                orderable: false, 
                cloneable: false, 
                editable: false, 
                entity: 'recipient', 
                value: config.recipients, 
                newForm: NewRecipient, 
                token: RecipientToken, 
                required: true 
              }
            ] : [
              { 
                label: 't(Recipients)', 
                type: 'textarea',
                editable: false,
                value: 'You will choose the recipeints when publishing this workflow'
              }  
            ],
            { 
              label: 't(Notification)', 
              type: 'segment', 
              fields: [
                { 
                  label: 't(Text)', 
                  name: 'text', 
                  type: 'textarea', 
                  placeholder: 'Enter text', 
                  defaultValue: config.text, 
                  rows: 4, 
                  required: true 
                },
                { 
                  label: 't(Link)', 
                  name: 'url', 
                  type: 'textfield', 
                  placeholder: 'Enter url', 
                  defaultValue: config.url, 
                  prefix: provider.admin_host 
                }
              ], 
              after: <Button { ...this._getTokens() } /> 
            } 
          ]
        }
      ]
    }
  }

  _getTokens() {
    const { onTokens } = this.props
    return {
      label: 't(you can use these tokens in both the text and link)',
      className: 'link',
      handler: onTokens
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Notification
