import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Player from '@apps/phone/admin/components/player'
import Button from '@apps/phone/admin/components/button'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Transcription from './transcription'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Voicemail extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    twilio: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    phone_number: PropTypes.object,
    voicemail: PropTypes.object,
    onPop: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleHandle = this._handleHandle.bind(this)
  _handleInfo = this._handleInfo.bind(this)
  _handlePlaceCall = this._handlePlaceCall.bind(this)

  render() {
    const buttons = this._getButtons()
    const { voicemail } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-handset-detail-container">
          <div className="maha-handset-detail">
            <div className="maha-handset-detail-header">
              <ContactAvatar { ...voicemail.contact } />
              <h2>{ voicemail.contact.display_name }</h2>
              <div className="maha-handset-actions" >
                <div className="maha-handset-action">
                  <Player { ...this._getPlayer() } />
                </div>
                { buttons.map((button, index) => (
                  <div className="maha-handset-action" key={`action_${index}`}>
                    <Button { ...button } />
                  </div>
                ))}
              </div>
            </div>
            <div className="maha-handset-detail-body">
              <Details { ...this._getDetails() } />
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getButtons() {
    const { voicemail } = this.props
    return [
      { icon: 'info', label: 'info', handler: this._handleInfo },
      { svg: 'phone', label: 'call', handler: this._handlePlaceCall },
      { svg: 'check', label: 'handled', handler: this._handleHandle, depressed: voicemail.was_handled }
    ]
  }

  _getClass() {
    const { playing } = this.state
    const classes = ['maha-handset-voicemail-button']
    if(playing) classes.push('playing')
    return classes.join(' ')
  }

  _getDetails() {
    const { voicemail } = this.props
    const items = [
      { label: 't(Time)', content: this._getTimestamp(voicemail) },
      { label: 't(Duration)', content: voicemail.duration, format: 'duration' },
      { component: <Transcription voicemail={ voicemail } /> }
    ]
    return {
      audits: `phone_voicemails/${voicemail.id}`,
      comments: `phone_voicemails/${voicemail.id}`,
      compact: true,
      items
    }
  }

  _getPanel() {
    return {
      title: 't(Voicemail)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ]
    }
  }

  _getPlayer() {
    const { voicemail } = this.props
    return {
      voicemail
    }
  }

  _getTimestamp(call) {
    const time = moment(call.created_at).format('h:mm A')
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const created_at = moment(call.created_at)
    if(today.format('YYMMDD') === created_at.format('YYMMDD')) return `Today, ${time}`
    if(yesterday.format('YYMMDD') === created_at.format('YYMMDD')) return `Yesterday, ${time}`
    if(today.diff(created_at, 'days') < 7) return created_at.format('dddd, h:mm A')
    return created_at.format('MM/DD/YY, h:mm A')
  }

  _handleBack() {
    this.props.onPop()
  }

  _handleCall(device) {
    const { voicemail, phone_number } = this.props
    this.context.twilio.call({
      program: phone_number.program,
      device,
      number: voicemail.phone_number.number,
      contact: voicemail.contact
    })
  }

  _handleHandle() {
    const { voicemail } = this.props
    if(voicemail.was_handled) return
    this.context.network.request({
      endpoint: `/api/admin/phone/voicemails/${voicemail.id}/handled`,
      method: 'PATCH'
    })
  }

  _handleInfo() {
    const { voicemail } = this.props
    this.context.router.push(`/crm/contacts/${voicemail.contact.id}`)
  }

  _handlePlaceCall() {
    const { account } = this.context.admin
    if(!account.mobile_phone) return this._handleCall('maha')
    this.context.tasks.open({
      items: [
        { label: 'Call with mobile phone', handler: this._handleCall.bind(this, 'mobile') },
        { label: 'Call with Maha phone', handler: this._handleCall.bind(this, 'maha') }
      ]
    })
  }

}

const mapResources = (props, context) => ({
  voicemail: `/api/admin/phone/voicemails/${props.voicemail_id}`
})

export default Container(mapResources)(Voicemail)
