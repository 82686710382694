import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import LandingPages from './landing_pages'
import Workflows from './workflows'
import PropTypes from 'prop-types'
import Advisor from './advisor'
import Embeds from './embeds'
import Form from './form'
import React from 'react'

class FormPublish extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    entity: PropTypes.string,
    form: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { entity } = this.props
    return {
      title: 't(Publish Advisor Form)',
      action: this._getAction.bind(this),
      method: 'POST',
      props: { 
        entity
      },
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getAction(formdata) {
    const { entity } = this.props
    const { form } = formdata
    return `/api/admin/truevail/${entity}/forms/${form?.id}/publishings`
  }

  _getData(formdata) {
    return {
      advisor_id: formdata.advisor.id,
      config: formdata.config
    }
  }

  _getDefault() {
    const { advisor, form } = this.props
    return {
      ...advisor ? { advisor } : {},
      ...form ? { form } : {}
    }
  }

  _getSteps(formdata) {
    return [
      ...!this.props.advisor ? [
        { label: 't(Advisor)', component: Advisor }
      ] : [],
      ...!this.props.form ? [
        { label: 't(Form)', component: Form }
      ] : [],
      ...formdata?.form?.landing_pages?.length !== 0 ? [
        { label: 't(Landing Pages)', component: LandingPages }
      ] : [],
      ...formdata?.form?.embeds?.length !==  0 ? [
        { label: 't(Embeds)', component: Embeds }
      ] : [],
      ...formdata?.form?.workflows?.length !== 0 ? [
        { label: 't(Workflows)', component: Workflows }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  ...props.form_id ? {
    form: `/api/admin/truevail/library/forms/${props.form_id}`
  } : {}
})

export default Container(mapResources)(FormPublish)
