import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class SenderField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    suffix: PropTypes.string,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    value: ''
  }

  _handleClear = this._handleClear.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { value } = this.state
    return (
      <div className="maha-input maha-tokenfield">
        <div className="maha-tokenfield-field">
          <div className="maha-input" onClick={ this._handleLookup }>
            <div className="maha-input-field">
              <Input { ...this._getInput() } />
            </div>
            { value.length > 0 &&
              <Button { ...this._getClear() } />
            }
          </div>
        </div>
        <div className="maha-tokenfield-token">
          <span className="maha-tokenfield-token-text">{ this._getFormatted() }</span>
        </div>
      </div>

    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) {
      this.setState({
        value: defaultValue.split('@')[0]
      })
    }
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    if(value !== prevState.value) {
      this.props.onChange(this._getFormatted())
    }
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getFormatted() {
    const { suffix } = this.props
    const { value } = this.state
    return `${value}@${suffix}`
  }

  _getInput() {
    const { locale } = this.context
    const { tabIndex } = this.props
    const { value } = this.state
    return {
      type: 'text',
      tabIndex,
      value,
      placeholder: locale.t('t(Enter From Email Prefix)'),
      onChange: this._handleUpdate
    }
  }

  _handleClear() {
    this.setState({
      value: ''
    })
  }

  _handleUpdate(value) {
    this.setState({ 
      value: value.replace(/[^\w]/g, '').toLowerCase()
    })
  }

}

export default SenderField
