import SocialCampaignTypeToken from '@apps/campaigns/admin/tokens/social_campaign_type'
import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const Socialsocial_campaignToken = ({ social_campaign }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign.title }
      </div>
      <SocialCampaignTypeToken social_campaign={ social_campaign } />
      <div className="token-stats">
        <span className={ social_campaign.status }>{ social_campaign.status }</span>
        { social_campaign.status === 'scheduled' &&
          <span><Date datetime={ social_campaign.post_at } /></span>
        }
        { social_campaign.status === 'posted' &&
          <span><Date datetime={ social_campaign.posted_at } /></span>
        }
      </div>
    </div>
  </div>
)

Socialsocial_campaignToken.propTypes = {
  social_campaign: PropTypes.object
}

export default Socialsocial_campaignToken
