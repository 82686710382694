import Page from '@admin/components/page'
import Variants from './variants'
import Overview from './overview'
import Edit from '../edit'
import React from 'react'

const getTabs = ({ product, store, variants }) => ({
  items: [
    { label: 't(Overview)', component: <Overview store={ store } product={ product } /> },
    { label: 't(Variants)', component: <Variants store={ store } product={ product } /> }
  ]
})

const getTasks = ({ product, store }, { flash, router }) => {

  if(product.deleted_at) return {}

  return {
    items: [
      { 
        label: 't(Edit Product)',
        svg: 'square_pen', 
        modal: <Edit store={ store } product={ product } /> 
      },
      {
        label: 't(Activate Product)',
        svg: 'check',
        show: !product.is_active,
        confirm: 't(Are you sure you want to activate this product?)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/stores/stores/${store.id}/products/${product.id}/activate`,
          body: { is_active: true },
          onFailure: () => this.context.flash.set('error', 't(Unable to activate this product)'),
          onSuccess: () => this.context.flash.set('success', 't(Successfully activated this product)')
        }
      }, {
        label: 'Deactivate Product',
        show: product.is_active,
        confirm: 't(Are you sure you want to deactivate this product?)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/stores/stores/${store.id}/products/${product.id}/activate`,
          body: { is_active: false },
          onFailure: () => this.context.flash.set('error', 't(Unable to deactivate this product)'),
          onSuccess: () => this.context.flash.set('success', 't(Successfully deactivated this product)')
        }
      },
      {
        label: 'Delete Product',
        confirm: `
          Are you sure you want to delete this product? You will also delete all of
          the associated variants
        `,
        request: {
          endpoint: `/api/admin/stores/stores/${store.id}/products/${product.id}`,
          method: 'DELETE',
          onFailure: () => {
            flash.set('error', 't(Unable to delete this product)')
          },
          onSuccess: () => {
            flash.set('success', 't(The product was successfuly deleted)')
            router.goBack()
          }
        }
      }
    ]
  }

}

const mapResourcesToPage = (props, context) => ({
  product: `/api/admin/stores/stores/${props.params.store_id}/products/${props.params.id}`,
  store: `/api/admin/stores/stores/${props.params.store_id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Product)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
