import AccessToken from '@admin/tokens/access'
import Panel from '@admin/components/panel'
import Manage from '../manage'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Access)',
  access: { 
    programs: { $canView: props.program.id },
    rights: { $oneOf: ['team:view_programs','team:manage_programs'] } 
  },
  collection: {
    endpoint: `/api/admin/team/programs/${props.program.id}/access`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Access)', key: 'full_name', primary: true, format: (access) => (
        <AccessToken access={ access } />
      ) }
    ],
    empty: {
      icon: 'user-circle',
      title: 't(No access)',
      text: 't(This programs has no authorized users)',
      button: {
        label: 't(Manage Access)',
        access: { 
          programs: { $canEdit: props.program.id },
          rights: { $oneOf: ['team:manage_programs'] } 
        },
        modal: <Manage program={ props.program } />
      }
    },
    entity: 'access',
    defaultSort: { key: 'path', order: 'asc' }
  },
  tasks: {
    items: [
      { 
        label: 't(Manage Access)', 
        svg: 'shield',
        access: { 
          programs: { $canEdit: props.program.id },
          rights: { $oneOf: ['team:manage_programs'] } 
        },
        modal: <Manage program={ props.program } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
