import SocialCampaignToken from '@apps/campaigns/admin/tokens/campaign/social'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Posted Social Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_social_campaigns','campaigns:manage_social_campaigns'] } },
  collection: {
    endpoint: '/api/admin/calendar/campaigns/social_campaigns_posted',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Social Campaigns)',
      text: 't(You have not yet created any social campaigns)'
    },
    entity: 'social campaign',
    onClick: (record) => context.router.push(`/admin/campaigns/social/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
