import EmailCampaignToken from './email_campaign'
import Page from '@admin/components/page'
import numeral from 'numeral'
import React from 'react'

const RateToken = (email_campaign) => (
  <>
    <div className="maha-dashboard-card-detail-amount">{ email_campaign.amount }</div> 
    <div className="maha-dashboard-card-detail-percent">({ numeral(email_campaign.rate).format('0.00%') })</div>
  </>
)

const mapPropsToPage = (props, context) => ({
  title: 't(Emails)',
  access: { 
    rights: { 
      $and: [
        { $allOf: ['dashboards:access_app'] },
        { $oneOf: ['campaigns:manage_email_campaigns','campaigns:view_email_campaigns'] }
      ]
    } 
  },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/aggregate_email_campaign_metric/report',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      ...props.query.metric === 'delivery_rate' ? [
        { label: 't(Delivery Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : props.query.metric === 'bounce_rate' ? [
        { label: 't(Bounce Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : props.query.metric === 'open_rate' ? [
        { label: 't(Open Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : props.query.metric === 'click_rate' ? [
        { label: 't(Click Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : props.query.metric === 'complain_rate' ? [
        { label: 't(Complain Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : props.query.metric === 'unsubscribe_rate' ? [
        { label: 't(Unsubscribe Rate)', key: 'metric', collapsing: true, align: 'right', format: RateToken }
      ] : []
    ],
    defaultSort: { key: 'sent_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Emails)',
      text: 't(No one has emails)'
    },
    entity: 'email'
  }
})

export default Page(null, mapPropsToPage)
