import TaskToken from '@apps/tasks/admin/tokens/task'
import DueToken from '@apps/tasks/admin/tokens/due'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Tasks Assigned to You)',
  access: { rights: { $oneOf: ['tasks:manage_tasks','tasks:view_tasks'] } },
  collection: {
    endpoint: '/api/admin/calendar/tasks/assigned_to_me',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (task) => <TaskToken task={ task } /> },
      { label: 't(Contact)', key: 'contact.display_name' },
      { label: 't(Assigned To)', key: 'assignee.full_name' },
      { label: 't(Due)', key: 'due_at', collapsing: true, format: (task) => <DueToken task={ task } /> }
    ],
    defaultSort: { key: 'due_at', order: 'desc' },
    empty: {
      svg: 'check',
      title: 't(No Tasks)',
      text: 't(You do have not have and tasks assigned to you due on this day)'
    },
    entity: 'task',
    onClick: (record) => context.router.push(`/tasks/tasks/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
