import FileToken from '@apps/crm/admin/tokens/file'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Files)',
  collection: {
    endpoint: `/api/admin/sales/deals/${props.deal.id}/files`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'asset.file_name', primary: true, format: (file) => <FileToken { ...file } /> },
      { label: 't(Created)', key: 'asset.created_at', sort: 'created_at', collapsing: true, format: 'date' }
    ],
    empty: {
      title: 't(No files)',
      text: 't(There are no files for this deal)',
      svg: 'files',
      buttons: [{
        label: 'Upload File',
        modal: <New deal={ props.deal } />
      }]
    },
    entity: 'files',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(record.asset.path)
  },
  task: {
    svg: 'plus',
    modal: <New deal={ props.deal } />
  }
})

export default Panel(null, mapPropsToPanel)
