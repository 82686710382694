import InputTokens from '@admin/components/input_tokens'
import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Token from '@admin/tokens/token'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class ToggleList extends React.Component{

  static propTypes = {
    context: PropTypes.shape({
      network: PropTypes.object
    }),
    chosen: PropTypes.any,
    defaultQuery: PropTypes.object,
    defaultValue: PropTypes.array,
    endpoint: PropTypes.string,
    exclude_ids: PropTypes.array,
    filter: PropTypes.object,
    full: PropTypes.bool,
    format: PropTypes.any,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    query: PropTypes.string,
    search: PropTypes.bool,
    showToggles: PropTypes.bool,
    sort: PropTypes.object,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onChange: PropTypes.func,
    onSetChosen: PropTypes.func,
    onSetQuery: PropTypes.func,
    onToggleRecord: PropTypes.func
  }

  static defaultProps = {
    exclude_ids: [],
    format: Token,
    full: false,
    multiple: false,
    search: true,
    valueKey: 'value',
    showToggles: true,
    textKey: 'text',
    onChange: () => {}
  }

  _handleToggleRecord = this._handleToggleRecord.bind(this)

  render() {
    const { chosen, endpoint, multiple, options, search, textKey } = this.props
    return (
      <div className="maha-toggle-list">
        <div className="maha-toggle-list-body">
          { search &&
            <div className="maha-toggle-list-header">
              <Searchbox { ...this._getSearchbox() } />
            </div>          
          }
          { multiple && chosen.length > 0 &&
            <div className="maha-toggle-list-summary">
              <InputTokens { ...this._getInputTokens() } />
            </div>
          }
          { endpoint && <Infinite { ...this._getInfinite() } /> }
          { options && <Results { ...this._getResults() } /> }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue && defaultValue.length > 0) this._handleLoad()
  }

  componentDidUpdate(prevProps) {
    const { chosen } = this.props
    if(chosen && !_.isEqual(prevProps.chosen, chosen)) {
      this._handleChange()
    }
  }

  _getInputTokens() {
    const { chosen, textKey } = this.props
    return {
      tokens: chosen,
      textKey,
      onRemove: this._handleToggleRecord
    }
  }

  _getOptions() {
    const { options, query, textKey } = this.props
    return options.filter(option => {
      return query.length === 0 || _.get(option, textKey).search(query) >= 0
    })
  }

  _getResults() {
    const { chosen, format, multiple, options, search, showToggles, textKey, valueKey} = this.props
    return {
      format,
      chosen,
      multiple,
      ...options ? {
        records: this._getOptions()
      } : {},
      search,
      showToggles,
      textKey,
      valueKey,
      onToggleRecord: this._handleToggleRecord
    }
  }

  _getSearchbox() {
    const { query, onSetQuery } = this.props
    return {
      autofocus: true,
      q: query,
      onChange: onSetQuery
    }
  }

  _getInfinite() {
    const { defaultQuery, endpoint, exclude_ids, filter, query, sort } = this.props
    return {
      defaultQuery,
      endpoint,
      exclude_ids,
      filter: {
        ...filter,
        q: query
      },
      sort,
      layout: Results,
      props: this._getResults.bind(this)
    }
  }

  _handleChange() {
    const { chosen, full, valueKey, onChange } = this.props
    onChange(chosen.map(record => {
      return full ? record : _.get(record, valueKey)
    }))
  }

  _handleFetch() {
    const { defaultValue, endpoint } = this.props
    this.props.context.network.request({
      endpoint,
      method: 'GET',
      query: { 
        $filter: { 
          id: { $in: defaultValue }
        } 
      },
      onSuccess: ({ data }) => {
        this.props.onSetChosen(data)
      },
      onFailure: () => {}
    })
  }

  _handleLoad() {
    const { defaultValue, endpoint, options, valueKey } = this.props
    if(!valueKey) return this.props.onSetChosen(defaultValue)
    if(endpoint) return this._handleFetch()
    if(!options) return
    this.props.onSetChosen(options.filter(option => {
      return _.includes(defaultValue, _.get(option, valueKey))
    }))
  }

  _handleToggleRecord(record) {
    const { multiple, onToggleRecord } = this.props
    if(onToggleRecord) onToggleRecord(multiple, record)
  }

}

export default ToggleList
