import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Posting Failures)',
  collection: {
    endpoint: '/api/admin/truevail/admin/postability/posting_failures',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'profile.username', sort: 'profile', primary: true, format: (variant) => (
        <ProfileToken profile={ variant.profile } />
      ) },
      { label: 't(Error)', key: 'posting_error' },
      { label: 't(Posted)', key: 'posted_at', format: 'date' }
    ],
    defaultSort: { key: 'posted_at', order: 'desc' },
    empty: {
      svg: 'x',
      title: 't(No Posting Failures)',
      text: 't(There are no posting failures)'
    },
    entity: 'records',
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${record.profile.team.advisor.id}/social_campaigns/${record.social_campaign.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
