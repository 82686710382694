const getConfigEntities = (config, test) => {
  if(config?.entity && test(config)) return [config]
  if(_.isPlainObject(config)) {
    return getConfigEntities(Object.values(config), test)
  }
  if(_.isArray(config)) {
    return config.reduce((entities, value) => [
      ...entities,
      ...getConfigEntities(value, test)
    ], [])
  }
  return []
}

export default getConfigEntities