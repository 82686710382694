
import Page from '@admin/components/page'
import Publishings from './publishings'
import Overview from './overview'
import Design from './design'
import Emails from './emails'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.workflow.title,
  manager: {
    path: `/admin/truevail/agency/workflows/${resources.workflow.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview workflow={ resources.workflow } />
      },
      {
        label: 't(Design)',
        path: '/design', 
        panel: <Design workflow={ resources.workflow } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails workflow={ resources.workflow } />
      },
      {
        label: 't(Publishings)',
        path: '/publishings',
        panel: <Publishings workflow={ resources.workflow } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  workflow: `/api/admin/truevail/agency/workflows/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
