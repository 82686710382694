import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Payments)',
  access: { rights: { $oneOf: ['finance:manage_revenue'] } },
  collection: {
    endpoint: '/api/admin/finance/payments',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'invoice.customer.display_name', sort: 'customer', primary: true, format: ({ invoice }) => {
        return <ContactToken { ...invoice.customer } />
      } },
      { label: 't(Method)', key: 'method', collapsing: true },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(Amount)', key: 'amount', collapsing: true, primary: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 'Contact', key: 'contact_id', type: 'select', endpoint: '/api/admin/crm/contacts', multiple: true, valueKey: 'id', textKey: 'full_name', format: ContactToken },
            { name: 'Method', key: 'method', type: 'select', multiple: true, options: [
              { value: 'ach', text: 't(ACH)' },
              { value: 'card', text: 't(Credit Card)' },
              { value: 'googlepay', text: 't(Google Pay)' },
              { value: 'applepay', text: 't(Apple Pay)' },
              { value: 'paypal', text: 't(PayPal)' },
              { value: 'check' ,text: 't(Check)' },
              { value: 'ebt', text: 't(Electronic Benefit Transfer (EBT))' },
              { value: 'cash', text: 't(Cash)' },
              { value: 'credit', text: 't(Customer Credit)' },
              { value: 'scholarship', text: 't(Scholarship)' }
            ] },
            { name: 'Card Type', key: 'card_type', type: 'select', multiple: true, options: [
              { value: 'visa', text: 't(Visa)' },
              { value: 'mastercard', text: 't(Master Card)' },
              { value: 'amex', text: 't(American Express)' },
              { value: 'discover', text: 't(Discover)' },
              { value: 'jcb', text: 't(JCB)' }
            ] },
            { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] },
            { name: 'Status', key: 'status', type: 'select', multiple: true, options: ['paid','unpaid','overdue','void'], format: StatusToken  }
          ]
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Contact)', key: 'invoice.customer.display_name' },
      { label: 't(Method)', key: 'method' },
      { label: 't(Date)', key: 'date', format: 'date' },
      { label: 't(Amount)', key: 'amount' },
      { label: 't(Fee)', key: 'fee' },
      { label: 't(Disbursed)', key: 'disbursed' },
      { label: 't(Status)', key: 'status' },
      { label: 't(Braintree ID)', key: 'braintree_id' },
      { label: 't(PayPal ID)', key: 'paypal_id' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/payments/${record.id}`),
    empty: {
      svg: 'dollar_sign',
      title: 't(No Payments)',
      text: 't(You have not yet received any payments)'
    },
    entity: 'payment'
  }
})

export default Panel(null, mapPropsToPanel)
