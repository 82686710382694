import HiddenPaymentField from './fields/hiddenpaymentfield'
import SignatureField from './fields/signaturefield'
import CountryField from './fields/countryfield'
import OptionsField from './fields/optionsfield'
import ProductField from './fields/productfield'
import ContactField from './fields/contactfield'
import AddressField from './fields/addressfield'
import PaymentField from './fields/paymentfield'
import NumberField from './fields/numberfield'
import HiddenField from './fields/hiddenfield'
import Tokens from '@admin/components/tokens'
import PhoneField from './fields/phonefield'
import RadioGroup from './fields/radiogroup'
import CheckBoxes from './fields/checkboxes'
import MoneyField from './fields/moneyfield'
import { getCode } from '@core/utils/codes'
import Stack from '@admin/components/stack'
import TimeField from './fields/timefield'
import TextField from './fields/textfield'
import FileField from './fields/filefield'
import DateField from './fields/datefield'
import TextArea from './fields/textfield'
import Checkbox from './fields/checkbox'
import DropDown from './fields/dropdown'
import PropTypes from 'prop-types'
import Text from './fields/text'
import React from 'react'
import Page from './page'

class Sidebar extends React.Component {

  static childContextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.number,
    changes: PropTypes.number,
    config: PropTypes.object,
    endpoint: PropTypes.string,
    fields: PropTypes.array,
    form: PropTypes.object,
    program: PropTypes.object,
    status: PropTypes.string,
    onAddSection: PropTypes.func,
    onDeleteSection: PropTypes.func,
    onMoveSection: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onUpdate: PropTypes.func
  }

  stackRef = React.createRef()

  _handleDone = this._handleDone.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleReplace = this._handleReplace.bind(this)
  _handleTokens = this._handleTokens.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Page, this._getPage.bind(this))
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props
    if(active !== prevProps.active) {
      if(active !== null) this._handleEdit(prevProps.active !== null)
      if(active === null) this._handlePop()
    }
  }

  getChildContext() {
    return {
      form: {
        push: this._handlePush,
        pop: this._handlePop
      }
    }
  }

  _getFields() {
    return [
      { label: 't(Address Field)', svg: 'map_pin', type: 'addressfield', component: AddressField },
      { label: 't(Checkbox)', svg: 'square_check', type: 'checkbox', component: Checkbox },
      { label: 't(Checkboxes)', svg: 'square_check', type: 'checkboxgroup', component: CheckBoxes },
      { label: 't(Contact Field)', svg: 'user_round', type: 'contactfield', component: ContactField },
      { label: 't(Country Field)', svg: 'flag', type: 'countryfield', component: CountryField },
      { label: 't(Date Field)', svg: 'calendar_days', type: 'datefield', component: DateField },
      { label: 't(Drop Down)', svg: 'chevron_down', type: 'dropdown', component: DropDown },
      { label: 't(File Upload)', svg: 'cloud_upload', type: 'filefield', component: FileField },
      { label: 't(Hidden Field)', svg: 'eye_closed', type: 'hiddenfield', component: HiddenField },
      { label: 't(Instructions)', svg: 'info', type: 'text', component: Text },
      { label: 't(Money Field)', svg: 'dollar_sign', type: 'moneyfield', component: MoneyField },
      { label: 't(Number Field)', svg: 'hash', type: 'numberfield', component: NumberField },
      { label: 't(Phone Field)', svg: 'phone', type: 'phonefield', component: PhoneField },
      { label: 't(Radio Group)', svg: 'cicircle_dotrcle', type: 'radiogroup', component: RadioGroup },
      { label: 't(Signature Field)', svg: 'singature', type: 'signaturefield', component: SignatureField },
      { label: 't(Text Area)', svg: 'case_sensitive', type: 'textarea', component: TextArea },
      { label: 't(Text Field)', svg: 'case_sensitive', type: 'textfield', component: TextField },
      { label: 't(Time Field)', svg: 'clock', type: 'timefield', component: TimeField },
      { label: 't(Hidden Payment Field)', svg: 'ghost', type: 'hiddenpaymentfield', component: HiddenPaymentField },
      { label: 't(Options Field)', svg: 'circle_check', type: 'optionsfield', component: OptionsField },
      { label: 't(Payment Field)', svg: 'dollar_sign', type: 'paymentfield', component: PaymentField },
      { label: 't(Product Field)', svg: 'shopping_cart', type: 'productfield', component: ProductField }
    ]
  }

  _getField() {
    const { active, config, fields, program } = this.props
    const key = `fields[${active}]`
    return {
      config: {
        code: getCode(6),
        ..._.get(config, key)
      },
      program,
      fields,
      onDone: this._handleDone,
      onTokens: this._handleTokens,
      onUpdate: this._handleUpdate.bind(this, key)
    }
  }

  _getPage() {
    const { config, changes, endpoint, fields, form, program, status, onSave } = this.props
    return {
      changes,
      config,
      endpoint,
      fields,
      form,
      program,
      status,
      onSave,
      onUpdate: this._handleUpdate,
      onPop: this._handlePop,
      onPush: this._handlePush
    }
  }

  _getStack() {
    return {
      display_name: 'form_designer',
      ref: this.stackRef
    }
  }

  _getTokens() {
    const { config } = this.props
    return {
      tokens: [{
        title: 't(Form)',
        tokens: config.fields.filter(field => {
          return !_.includes(['text','paymentfield','productfield'], field.type)
        }).map(field => ({
          name: field.name.value,
          token: `response.${field.name.token}`
        }))
      }],
      onPop: this._handlePop
    }
  }

  _handleDone() {
    this.props.onEdit(null, null)
  }

  _handleEdit(replace) {
    const { active } = this.props
    const fields = this._getFields()
    const config = this.props.config.fields[active]
    const { type } = config
    const field = _.find(fields, { type })
    const push = replace ? this._handleReplace : this._handlePush
    push(field.component, this._getField.bind(this))
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleReplace(component, props) {
    this._handlePop()
    setTimeout(this._handlePush.bind(this, component, props), 300)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleTokens() {
    this._handlePush(Tokens, this._getTokens.bind(this))
  }

  _handleUpdate(key, value) {
    const { config } = this.props
    this.props.onUpdate(key, {
      ..._.get(config, key),
      ...value
    })
  }

}

export default Sidebar
