import BillingCycleToken from '@apps/subscriptions/admin/tokens/billing_cycle'
import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Renewals)',
  collection: {
    endpoint: `/api/admin/subscriptions/services/${props.service.id}/renewals`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Organization)', key: 'position.organization', sort: 'organization' },
      { label: 't(Billing Cycle)', key: 'cycle_start_date', format: BillingCycleToken },
      { label: 't(Total)', key: 'invoice.total', primary: true, sort: 'total', collapsing: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken  }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['paid','failed'], format: StatusToken },
            { name: 't(Date Range)', key: 'cycle_start_date', type: 'daterange', include: ['this','last'] },
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Successful Renewals)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'paid' }
          ]
        } },
        { id: 1, title: 't(Failed Renewals)', config: {
          criteria: [
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: null, value: 'failed' }
          ]
        } },
        { id: 2, title: 't(This Month)', config: {
          criteria: [
            { code: 'ghi', data: null, field: 'cycle_start_date', operator: '$dr', parent: null, value: 'this_month' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'repeat',
      title: 't(No Renewals)',
      text: 't(There have not yet been any renewals for this service)'
    },
    entity: 'renewal',
    onClick: (record) => context.router.push(`/admin/subscriptions/services/${props.service.id}/renewals/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
