import MultiForm from '@admin/components/multiform'
import Testimonials from './testimonials'
import PropTypes from 'prop-types'
import Homepage from './homepage'
import Contact from './contact'
import Details from './details'
import Booking from './booking'
import Notes from './notes'
import About from './about'
import Pages from './pages'
import React from 'react'
import Blog from './blog'
import SEO from './seo'

class Website extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Website Setup)',
      cancelSvg: 'chevron_left',
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      showSteps: false,
      onCancel: this._handleCancel,
      onNext: this._handleNext,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { configuration } = this.props
    return configuration
  }

  _getSteps(formdata) {
    const { website_strategy, website_type } = formdata
    return [
      { label: 't(Details)', component: Details },
      ...website_strategy === 'design' ? [
        { label: 't(Contact)', component: Contact },
        { label: 't(SEO)', component: SEO },
        { label: 't(Homepage)', component: Homepage },
        { label: 't(Testimonials)', component: Testimonials },
        ...website_type === 'sevenpage' ? [
          { label: 't(About)', component: About },
          { label: 't(Blog)', component: Blog },
          { label: 't(Pages)', component: Pages },
          { label: 't(Booking)', component: Booking }
        ] : [],
        { label: 't(Notes)', component: Notes }
      ] : []
    ]
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleNext(data) {
    this.props.onNext(data)
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Website
