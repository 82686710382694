import Content from '@apps/forms/admin/tokens/content'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const Overview = ({ form, response }, { admin }) => {

  const formlink = {
    label: form.title,
    className: 'link',
    route: `/admin/forms/forms/${form.id}`
  }
  
  const source = response.embed ? {
    label: response.embed.title,
    className: 'link',
    route: `/admin/forms/forms/${form.id}/embeds/${response.embed.id}`
  } : response.landing_page ? {
    label: response.landing_page.title,
    className: 'link',
    route: `/admin/forms/forms/${form.id}/landing_pages/${response.landing_page.id}`
  } : null

  const contact = {
    label: response.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${response.contact.id}`
  }

  const details = {
    audits: `forms_responses/${response.id}`,
    comments: `forms_responses/${response.id}`,
    sections: [{
      title: 't(Response Details)',
      items: [
        { label: 't(Form)', content: <Button { ...formlink } /> },
        { label: 't(Source)', show: source !== null, content: source ? <Button { ...source } /> : null },
        { label: 't(Contact)', content: <Button { ...contact } /> },
        { label: 't(Duration)', content: `${response.duration} seconds` },
        ...response.location ? [
          { label: 't(IP Address)', content: (
            <>
              {response.location.ipaddress.ipv4 } {response.location.ipaddress.address && response.location.ipaddress.address.city &&
                <>({response.location.ipaddress.address.city}, {response.location.ipaddress.address.region})</>
              }
            </>
          ) }  
        ] : [],
        { label: 't(Contact Status)', content: response.is_known ? 'KNOWN' : 'UNKNOWN' },
        { label: 't(Submitted)', content: response.created_at, format: 'datetime' },
        {
          label: 't(Revenue)',
          access: { rights: { $oneOf: ['finance:manage_revenue', 'finance:view_revenue'] } },
          content: numeral(response.revenue).format('$0.00')
        }
      ]
    }, {
      title: 't(Response Data)',
      items: form.config.fields.filter(field => {
        return field.type !== 'text'
      }).map(field => ({
        label: field.name.value,
        content: <Content data={ response.data } field={ field } />
      }))
    }]
  }

  if(response.invoice_id) {
    const invoice = {
      label: 'View Invoice',
      className: 'link',
      route: `/admin/finance/invoices/${response.invoice_id}`
    }
    details.sections[0].items.push({ label: 'Invoice', content: <Button { ...invoice } /> })
  }

  if(response.enrollment) {
    const enrollment = {
      label: 'View Enrollment',
      className: 'link',
      route: `/admin/automation/workflows/${response.enrollment.workflow_id}/enrollments/${response.enrollment.id}`
    }
    details.sections[0].items.push({ label: 't(Workflow)', content: <Button { ...enrollment } /> })
  }

  if(admin.hasFeature('agreements') && response.agreement) {
    details.sections[0].items.push({ label: 'Signed Agreement', content: <AssetToken { ...response.agreement.signed } /> })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  form: PropTypes.object,
  response: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } response={ props.response } />
})

export default Panel(null, mapPropsToPanel)
