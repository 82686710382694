import MultiForm from '@admin/components/multiform'
import Confirmation from './new/confirmation'
import Details from './new/details'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    store: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { store } = this.props
    return {
      title: 't(Duplicate Store)',
      action: `/api/admin/stores/stores/${store.id}/clone`,
      method: 'POST',
      defaultValue: this._getDefaultValue(),
      formatData: this._getData,
      getSteps: this._getSteps,
      saveText: 'Clone',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefaultValue() {
    const { store } = this.props
    return {
      title: `Copy of ${store.title}`,
      config: store.config,
      program: store.program
    }
  }

  _getData(store) {
    return {
      confirmation: store.confirmation,
      config: store.config,
      contact_config: store.contact_config,
      permalink: store.permalink,
      program_id: store.program.id,
      title: store.title
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 'Confirmation', component: Confirmation }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(store) {
    this.context.router.push(`/stores/stores/${store.id}`)
    this.context.modal.close()
  }

}

export default Clone
