import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Panel from '@admin/components/panel'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Channels)',
  collection: {
    endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/channels`,
    defaultQuery: {
      service: props.service
    },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Channel)', key: 'title', primary: true, format: (channel) => (
        <ChannelToken channel={ channel } />
      ) },
      { label: 't(Type)', key: 'type', collapsing: true, format: ({ type }) => type.toUpperCase() },
      { label: 't(Advisors)', key: 'subscriptions_count', primary: true, collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      svg: 'network',
      title: 't(No Channels)',
      text: 't(You have not yet created any channels for this service)',
      buttons: [
        { 
          label: 't(Create Channel)', 
          modal: <New agency={ props.agency } service={ props.service } /> 
        }
      ]
    },
    entity: 'channel',
    recordTasks: (record) => [
      {
        label: 't(Edit Channel)',
        svg: 'square_pen',
        modal: <Edit agency={ props.agency } channel={ record } />
      },
      {
        label: 't(Delete Channel)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this channel?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/channels/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 'Unable to delete channel'),
          onSuccess: (result) => context.flash.set('success', 'Channel was deleted successfully')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: <New agency={ props.agency } service={ props.service } />
  }
})

export default Panel(null, mapPropsToPanel)
