import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class FoldersUpdateBatch extends React.Component {

  static propTypes = {
    entity: PropTypes.string,
    type: PropTypes.string,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity, type } = this.props
    return {
      title: 't(Update Tags)',
      saveText: 'Next',
      onCancel: this._handleCancel,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'entity', type: 'hidden', value: entity },
            { name: 'type', type: 'hidden', value: type },
            { 
              type: 'segment', 
              fields: [
                { 
                  name: 'strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'add', text: 't(Add Tags)' },
                    { value: 'remove', text: 't(Remove Tags)' }
                  ], 
                  defaultValue: 'add' 
                },
                { 
                  label: 't(Tags)', 
                  name: 'tags', 
                  type: 'tagfield', 
                  endpoint: '/api/admin/tags', 
                  required: true 
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

export default FoldersUpdateBatch
