import Page from '@admin/components/page'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(404 Not Found)',
  message: {
    svg: 'triangle_alert',
    color: 'yellow',
    title: 't(Not Found)',
    text: 't(Unable to locate the requested resource)'
  }
})

export default Page(null, mapPropsToPage)
