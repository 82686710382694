import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Batches)',
  access: { rights: { $oneOf: ['finance:process_expenses'] } },
  collection: {
    endpoint: '/api/admin/finance/batches',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true},
      { label: 't(Exported By)', key: 'user.full_name', sort: 'user', primary: true },
      { label: 't(Items)', key: 'items_count', collapsing: true, align: 'right' },
      { label: 't(Total)', key: 'total', collapsing: true, format: 'currency' },
      { label: 't(Date)', key: 'date', collapsing: true, primary: true, format: 'date' }
    ],
    defaultSort: { key: 'date', order: 'desc' },
    empty: {
      svg: 'file_stack',
      title: 't(No Batches)',
      text: 't(You have not yet created any batches)'
    },
    entity: 'batch',
    onClick: (record) => {
      context.router.push(`/finance/reports?$filter[batch_id][$in][0]=${record.id}`)
    },
    recordTasks: (record) => [
      {
        label: 'Download Batch',
        access: { rights: { $oneOf: ['finance:process_expenses'] } },
        url: context.admin.signUrl(`/api/admin/finance/batches/${record.type}/${record.id}.xlsx?$page[limit]=0&download=true`)
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
