import ModalPanel from '@admin/components/modal_panel'
import IFrame from '@admin/components/html/iframe'
import Message from '@admin/components/message'
import Search from '@admin/components/search'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Results from './results'
import React from 'react'

class EntityChooser extends React.Component {

  static propTypes = {
    chooseFirst: PropTypes.bool,
    compareKey: PropTypes.string,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    format: PropTypes.func,
    instructions: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    preview: PropTypes.func,
    screenshot: PropTypes.func,
    search: PropTypes.bool,
    showHeader: PropTypes.bool,
    textKey: PropTypes.string,
    title: PropTypes.string,
    valueKey: PropTypes.string,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
  }

  static defaultProps = {
    chooseFirst: true,
    multiple: false,
    search: true,
    onDone: () => {}
  }

  state = {
    chosen: [],
    previewing: null
  }

  _handleChoose = this._handleChoose.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handlePreview = this._handlePreview.bind(this)

  render() {
    const { chosen, previewing } = this.state
    const { instructions, preview } = this.props
    const Preview = preview
    return (
      <ModalPanel { ...this._getPanel() }>
        { instructions &&
          <div className="maha-entity-chooser-instructions">
            <T text={ instructions } />
          </div>
        }
        <div className="maha-entity-chooser">
          <div className="maha-entity-chooser-list">
            <Search { ...this._getSearch() } />
          </div>
          <div className="maha-entity-chooser-preview">
            <div className="maha-entity-chooser-preview-body">
              { previewing ?
                <>
                  { preview ? 
                    <Preview { ...previewing } /> :
                    <IFrame { ...this._getIFrame() } /> }
                </> :
                <Message { ...this._getMessage() } /> 
              }
            </div>
            { chosen.length > 0 &&
              <div className="maha-entity-chooser-preview-footer">
                <Button { ...this._getDone() } />                
              </div>
            }
          </div>
        </div>
      </ModalPanel>
    )    
  }

  _getDone() {
    const { entity, multiple } = this.props
    const { chosen } = this.state
    return {
      label: `t(Choose) ${multiple ? pluralize(_.startCase(entity), chosen.length, true) : _.startCase(entity)}`,
      color: 'blue',
      handler: this._handleDone
    }
  }

  _getIFrame() {
    const { previewing } = this.state
    const { screenshot } = this.props
    return {
      src: `/mjson/dynamic/${screenshot(previewing)}?mode=show`
    }
  }

  _getMessage() {
    const { entity } = this.props
    return {
      icon: 'file-o',
      title: `t(Choose) ${_.startCase(entity)}`,
      text: 't(Choose an item to see a preview)'
    }
  }

  _getPanel() {
    const { entity, showHeader, title, onBack, onCancel } = this.props
    return {
      title: title || `t(Choose) ${_.startCase(entity)}`,
      showHeader,
      leftItems: [
        ...onBack ? [
          { svg: 'chevron_left', handler: onBack }
        ] : [
          { label: 't(Cancel)', handler: onCancel }
        ]
      ]
    }
  }

  _getSearch() {
    const { chooseFirst, compareKey, defaultFilter, defaultQuery, defaultValue, endpoint, entity, format, options, multiple, search } = this.props
    const { chosen, previewing } = this.state
    return {
      defaultFilter,
      defaultQuery,
      endpoint,
      entity,
      layout: Results,
      options,
      search,
      props: {
        compareKey,
        chooseFirst,
        chosen,
        defaultValue,
        format, 
        multiple,
        previewing,
        onChooseRecord: this._handleChoose,
        onPreview: this._handlePreview
      }
    }
  }

  _handleChoose(record) {
    const { multiple } = this.props
    const { chosen } = this.state
    this.setState({
      chosen: multiple ? _.xorBy(chosen, [record], (record) => record.id) : [record]
    })
  }

  _handleDone() {
    const { multiple, valueKey } = this.props
    const { chosen } = this.state
    const valueKeys = chosen.map(item => valueKey ? _.get(item, valueKey) : item)
    this.props.onDone((multiple ? valueKeys : valueKeys[0]) || null)
  }

  _handlePreview(previewing) {
    this.setState({ previewing })
  }

}

export default EntityChooser
