import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'
import New from './new'

const core = [
  // { label: 'Age', name: 'age', type: 'dropdown', icon: 'caret-down' },
  { label: 'Race', name: 'race', type: 'checkboxgroup', svg: 'square_check' },
  { label: 'Ethnicity', name: 'ethnicity', type: 'radiogroup', icon: 'circle-o' },
  { label: 'Gender', name: 'gender', type: 'radigroup', icon: 'circle-o' },
  { label: 'Disabiliy', name: 'disability', type: 'radiogroup', icon: 'circle-o' }
]

class TicketFields extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object
  }

  state = {
    hidden: null,
    fields: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    const { fields } = this.state
    if(!fields) return null
    return (
      <div className="maha-fields">
        <div className="maha-fields-body">
          <div className="ticketfieldsfield-field">
            <div className="maha-field-icon">
              <div className="maha-field-badge">
                <Icon icon="font" />
              </div>
            </div>
            <div className="ticketfieldsfield-field-label">
              Name on Ticket <span>(textfield)</span>
            </div>
          </div>
          { core.map((field, index) => (
            <div className={ this._getClass(field.name) } key={`field_${index}`}>
              <div className="maha-field-icon">
                <div className="maha-field-badge">
                  <Icon icon={ field.icon } />
                </div>
              </div>
              <div className="ticketfieldsfield-field-label">
                { field.label } <span>({ field.type })</span>
              </div>
              <div className="ticketfieldsfield-field-action" onClick={ this._handleToggle.bind(this, field.name)}>
                <Icon icon={`toggle-${ this._getToggleIcon(field.name) }`} />
              </div>
            </div>
          ))}
          { fields.map((field, index) => (
            <div className="maha-field" key={`field_${index}`}>
              <div className="maha-field-icon">
                <div className="maha-field-badge">
                  <Icon icon={ this._getIcon(field) } />
                </div>
              </div>
              <div className="maha-field-label">
                { field.name.value } <span>({ this._getType(field) })</span>
              </div>
              <Button { ...this._getEditButton(field, index) } />
            </div>
          ))}
        </div>
        <div className="maha-fields-footer">
          <Button { ...this._getNewButton() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { event } = this.props
    this.setState(event.ticket_config)
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(this.state, prevState) && prevState.fields !== null) {
      this._handleSave()
    }
  }

  _getClass(name) {
    const { hidden } = this.state
    const classes = ['ticketfieldsfield-field']
    if(_.includes(hidden, name)) classes.push('hidden')
    return classes.join(' ')
  }

  _getEdit(field, index) {
    return {
      field,
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getEditButton(field, index) {
    return {
      svg: 'ellipsis',
      className: 'maha-field-extra',
      tasks: [
        { 
          label: 't(Edit Field)', 
          svg: 'square_pen',
          modal: <Edit { ...this._getEdit(field, index) } /> 
        },
        {
          label: 't(Delete Field)',
          svg: 'trash',
          confirm: 't(Are you sure you want to delete this field?)',
          deletable: true,
          handler: this._handleRemove.bind(this, index)
        }
      ]
    }
  }

  _getIcon(field) {
    const type = this._getType(field)
    if(type === 'textfield') return 'font'
    if(type === 'textarea') return 'bars'
    if(type === 'checkboxes') return 'check-square-o'
    if(type === 'checkbox') return 'check-square'
    if(type === 'imagefield') return 'camera'
    if(type === 'emailfield') return 'envelope'
    if(type === 'addressfield') return 'map-marker'
    if(type === 'phonefield') return 'phone'
    if(type === 'datefield') return 'calendar'
    if(type === 'dropdown') return 'caret-down'
    if(type === 'timefield') return 'clock-o'
    if(type === 'moneyfield') return 'dollar'
    if(type === 'radiogroup') return 'circle-o'
    if(type === 'numberfield') return 'hashtag'
    if(type === 'lookup') return 'search'
  }

  _getNew() {
    return {
      onDone: this._handleAdd
    }
  }

  _getNewButton() {
    return {
      label: 't(Add field)',
      className: 'link',
      modal: <New { ...this._getNew() }/>
    }
  }

  _getToggleIcon(name) {
    const { hidden } = this.state
    return _.includes(hidden, name) ? 'off' : 'on'
  }

  _getType(field) {
    return field.contactfield ? field.contactfield.type: field.type
  }

  _handleAdd(field) {
    this.setState({
      fields: [
        ...this.state.fields,
        field
      ]
    })
  }

  _handleNew() {
    this.context.form.push(New, this._getNew())
  }

  _handleRemove(index) {
    this.setState({
      fields: this.state.fields.filter((field, i) => {
        return i !== index
      })
    })
  }

  _handleSave() {
    const ticket_config = this.state
    const { event } = this.props
    this.context.network.request({
      endpoint: `/api/admin/events/events/${event.id}/fields`,
      method: 'PATCH',
      body: { ticket_config },
      onFailure: () => this.context.flash.set('error', 't(Unable to save fields)')
    })
  }

  _handleToggle(name) {
    const { hidden } = this.state
    this.setState({
      hidden: _.xor(hidden, [name])
    })
  }

  _handleUpdate(index, newfield) {
    this.setState({
      fields: this.state.fields.map((field, i) => {
        return i === index ? newfield : field
      })
    })
  }

}

export default TicketFields
