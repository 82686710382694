import Edit from '@apps/team/admin/views/senders/edit'
import New from '@apps/team/admin/views/senders/new'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Senders)',
  access: { 
    programs: { $canView: props.program.id },
    rights: { $oneOf: ['team:view_senders','team:manage_senders']} 
  },
  collection: {
    endpoint: '/api/admin/team/senders',
    defaultQuery: {
      program_id: props.program.id
    },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Sender)', key: 'rfc822', sort: 'name', primary: true },
      { label: 't(Verification)', key: 'email_status', primary: true, collapsing: true, format: StatusToken }
    ],
    empty: {
      title: 't(No Senders)',
      text: 't(There are no senders for this program)',
      icon: 'paper-plane-o',
      buttons: [
        { 
          label: 't(Create Senders)', 
          access: {
            programs: { $canEdit: props.program.id }, 
            rights: { $oneOf: ['team:manage_senders'] } 
          },
          modal: <New program_id={ props.program.id } /> 
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Name)', key: 'name' },
      { label: 't(Email)', key: 'email' }
    ],
    recordTasks: (record) => [
      { 
        label: 't(Edit Sender)', 
        svg: 'square_pen',
        access: {
          programs: { $canEdit: props.program.id }, 
          rights: { $oneOf: ['team:manage_senders'] } 
        },
        modal: <Edit sender={ record } /> 
      }
    ],
    entity: 'sender',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/team/senders/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Senders)', 
    access: {
      programs: { $canEdit: props.program.id }, 
      rights: { $oneOf: ['team:manage_senders'] } 
    },
    modal: <New program_id={ props.program.id } />
  }
})

export default Panel(null, mapPropsToPanel)