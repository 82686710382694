import FormPublish from '@apps/truevail/admin/components/form_publish'
import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forms)',
  collection: {
    endpoint: `/api/admin/truevail/agency/forms/${props.form.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'subscription.advisor.organization', sort: 'agency', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'square_check',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this form)',
      ...props.form.status === 'published' ? {
        buttons: [
          {
            label: 't(Publish Advisor Form)',
            modal: <FormPublish form_id={ props.form.id }entity="agency" />
          }
        ]  
      } : {},
    },
    entity: 'publishing',
    // recordTasks: (record) => [
    //   {
    //     label: 't(Send Preview)',
    //     svg: 'mail',
    //     modal: <Preview publishing={ record } />
    //   },
    //   {
    //     label: 't(Delete Form)',
    //     svg: 'trash',
    //     confirm: 't(Are you sure you want to delete this form?)',
    //     deletable: true,
    //     request: {
    //       endpoint: `/api/admin/truevail/admin/forms/${props.form.id}/publishings/${record.id}`,
    //       method: 'DELETE',
    //       onSuccess: () => context.flash.set('success', 't(Successfully deleted form)'),
    //       onFailure: () => context.flash.set('error', 't(Unable to delete form)')
    //     }
    //   }
    // ],
    onClick: (record) => {
      const { admin, provider, host } = context
      const path = `truevail/agency/forms/${record.agency_form.id}`
      if(admin.team.id === record.subscription.agency.team.id) return context.router.push(`/admin/${path}`)
      host.window.open(`${provider.admin_host}/${record.subscription.agency.team.subdomain}/${path}`)
    }
  },
  ...props.form.status === 'published' ? {
    task: {
      svg: 'plus',
      tooltip: 't(Publish Advisor Form)',
      modal: <FormPublish form_id={ props.form.id } entity="agency" />
    }  
  } :{}

})

export default Panel(null, mapPropsToPanel)
