import MultiForm from '@admin/components/multiform'
import CanvaDesign from './canva_design'
import PropTypes from 'prop-types'
import Strategy from './strategy'
import Schedule from './schedule'
import Truevail from './truevail'
import Details from './details'
import Design from './design'
import Canva from './canva'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program_id } = this.props
    return {
      title: 't(New Social Campaign)',
      action: '/api/admin/campaigns/social',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        program_id
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    const { program_id } = this.props
    return {
      program_id,
      variants: formdata.variants,
      title: formdata.title,
      type: formdata.type,
      truevail_social_campaign_id: formdata.social_campaign?.id,
      scheduling_strategy: formdata.scheduling_strategy,
      date: formdata.date,
      time: formdata.time
    }
  }

  _getSteps(formdata) {
    const { strategy } = formdata
    return [
      { label: 't(Strategy)', component: Strategy },
      ...strategy === 'truevail' ? [
        { label: 't(Truevail)', component: Truevail }
      ] : strategy === 'canva' ? [
        { label: 't(Canva)', component: Canva }
      ] : strategy === 'design' ? [
        { label: 't(Canva)', component: CanvaDesign }
      ] : [
        { label: 't(Type)', component: Type }
      ],
      { label: 't(Details)', component: Details },
      { label: 't(Design)', component: Design },
      { label: 't(Schedule)', component: Schedule }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

export default New
