import ProgramForm from '@apps/crm/admin/components/programform'
import EventToken from '@apps/events/admin/tokens/event'
import Panel from '@admin/components/panel'
import Clone from '../clone'
import React from 'react'
import New from '../new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Upcoming Events)',
  collection: {
    endpoint: '/api/admin/events/events',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Event)', key: 'end_date', primary: true, format: EventToken }
    ],
    criteria: {
      fields: [
        { label: 't(Event)', fields: [
          { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Start Date)', key: 'start_date' },
      { label: 't(End Date)', key: 'end_date' },
      { label: 't(Program)', key: 'program.title'},
      { label: 't(Registrations)', key: 'registrations_count' },
      { label: 't(Tickets)', key: 'tickets_count' },
      { label: 't(Waiting)', key: 'waitings_count' },
      { label: 't(Revenue)', key: 'revenue' }
    ],
    defaultQuery: { type: 'upcoming' },
    defaultSort: { key: 'end_date', order: 'asc' },
    empty: {
      svg: 'calendar_days',
      title: 't(No Events)',
      text: 't(There are no upcoming events)',
      buttons: [
        { label: 't(Create Event)', modal: <ProgramForm form={ New } /> }
      ]
    },
    entity: 'event',
    onClick: (record) => context.router.push(`/events/events/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Duplicate Event)',
        svg: 'copy',
        modal: <Clone event={ record } />
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
