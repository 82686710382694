import Workflows from './automation/workflows'
import LandingPages from './landing_pages'
import Page from '@admin/components/page'
import Emails from './automation/emails'
import Publishings from './publishings'
import Overview from './overview'
import Fields from './fields'
import Embeds from './embeds'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.form.title,
  manager: {
    path: `/admin/truevail/agency/forms/${resources.form.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview form={ resources.form } />
      },
      {
        label: 't(Fields)',
        path: '/fields', 
        panel: <Fields form={ resources.form } />
      },
      {
        label: 't(Automation)',
        children: [
          {
            label: 't(Workflow)',
            path: '/workflow', 
            panel: <Workflows form={ resources.form } />
          },
          {
            label: 't(Emails)',
            path: '/emails', 
            panel: <Emails form={ resources.form } />
          }
        ]
      },
      {
        label: 't(Landing Pages)',
        path: '/landing_pages', 
        panel: <LandingPages form={ resources.form } />
      },
      {
        label: 't(Embeds)',
        path: '/embeds', 
        panel: <Embeds form={ resources.form } />
      },
      {
        label: 't(Publishings)',
        path: '/publishings', 
        panel: <Publishings form={ resources.form } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  form: `/api/admin/truevail/agency/forms/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
