import New from '@apps/team/admin/views/profiles/new'
import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Profiles)',
  access: { 
    programs: { $canView: props.program.id },
    rights: { $oneOf: ['team:view_profiles','team:manage_profiles'] } 
  },
  collection: {
    endpoint: '/api/admin/team/profiles',
    defaultQuery: {
      program_id: props.program.id
    },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'username', sort: 'name', primary: true, format: (profile) => (
        <ProfileToken profile={ profile } />
      ) }
    ],
    empty: {
      title: 't(No Social Profiles)',
      text: 't(There are no profiles for this program)',
      svg: 'user_circle_2',
      buttons: [
        {
          label: 't(Connect Social Profile)', 
          access: {
            programs: { $canEdit: props.program.id },
            rights: { $oneOf: ['team:manage_profiles'] } 
          },
          modal: <New program_id={ props.program.id } /> 
        }
      ]
    },
    entity: 'profile',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/team/profiles/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Connect Social Profile)', 
    access: { 
      programs: { $canEdit: props.program.id },
      rights: { $oneOf: ['team:manage_profiles'] } 
    },
    modal: <New program_id={ props.program.id } />
  }
})

export default Panel(null, mapPropsToPanel)