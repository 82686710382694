import SupportAssignment from '@admin/components/support_assignment'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import TeamToken from '@admin/tokens/team'
import React from 'react'
import Edit from './edit'

const getSupportAssignment = (user) => ({
  title: 't(Assign Support Teams)',
  entity: 'team',
  format: TeamToken,
  valueKey: 'team_ids',
  action: `/api/admin/truevail/admin/support_users/${user.id}/support_teams`,
  assignedEndpoint: `/api/admin/truevail/admin/support_users/${user.id}/support_teams`,
  unassignedEndpoint: `/api/admin/truevail/admin/support_users/${user.id}/support_teams/all`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Support Users)',
  collection: {
    endpoint: '/api/admin/truevail/admin/support_users',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: (user) => (
        <UserToken { ...user } show_email={ true } />
      ) },
      { label: 't(Auto Assign)', key: 'agency_auto_assign', collapsing: true, format: 'check_times' }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    recordTasks: (record) => [
      {
        label: 't(Change Auto Assign)',
        svg: 'refresh_cw',
        modal: <Edit user={ record } />
      },
      {
        label: 't(Manage Support Teams)',
        svg: 'life_buoy',
        modal: <SupportAssignment { ...getSupportAssignment(record) } />
      }
    ],
    entity: 'user'
  }
})

export default Panel(null, mapPropsToPanel)
