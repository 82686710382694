import InputTokens from '@admin/components/input_tokens'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import PlaceToken from './token'
import React from 'react'

class PlacesField extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.number,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    place: null,
    places: null,
    q: ''
  }

  _handleChange = this._handleChange.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleClickOutside = this._handleClickOutside.bind(this)
  _handleLookup = _.debounce(this._handleLookup.bind(this), 250)
  _handleType = this._handleType.bind(this) 
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { place, places } = this.state
    return (
      <div { ...this._getPlacefield() }>
        <div className="maha-input">
          <div className="maha-input-field">
            { place ?
              <InputTokens { ...this._getInputTokens() } /> :
              <Input { ...this._getInput() } />
            }
          </div>
          { place &&
            <Button { ...this._getClear() } />
          }          
          { places &&
            <div className="maha-placesfield-places">
              { places.length === 0 &&
                <div className="maha-placesfield-empty">
                  No places match your query
                </div>
              }
              { places.map((place, index) => (
                <div className="maha-placesfield-place" key={`place_${index}`} onClick={ this._handleChoose.bind(this, place)}>
                  <div className="maha-placesfield-place-icon">
                    <Icon icon="map-marker" />
                  </div>
                  <div className="maha-placesfield-place-details">
                    <strong>{ place.name }</strong><br />
                    { place.address.street }, { place.address.city }, { place.address.state } { place.address.zip } 
                  </div>
                </div>
              ))}
            </div>        
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside)
    const defaultValue = this._getDefaultValue()
    if(defaultValue) return this._handleFetch(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { q, place } = this.state
    if(q !== prevState.q) {
      this._handleLookup()
    }
    if(!_.isEqual(place, prevState.place)) {
      this._handleChange()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { q } = this.state
    return {
      type: 'text',
      placeholder: 'Search for a place',
      value: q,
      onChange: this._handleType
    }
  }

  _getPlacefield() {
    return {
      className: 'maha-placesfield'
    }
  }

  _getInputTokens() {
    const { place } = this.state
    return {
      tokens: [place],
      format: (place) => (
        <PlaceToken place={ place } />
      )
    }
  }

  _handleChange() {
    const { place } = this.state
    this.props.onChange(place ? {
      id: place.id,
      name: place.name
    } : null)
  }

  _handleChoose(place) {
    this.setState({ 
      place,
      places: null,
      q: ''
    })
  }

  _handleClear() {
    this.setState({ 
      place: null,
      places: null,
      q: ''
    })
  }

  _handleClickOutside(e) {
    const parent = e.target.closest('.maha-placesfield')
    if(parent !== null) return
    this.setState({ 
      places: null,
      q: ''
    })
  }

  _handleFetch(place) {
    this.context.network.request({
      endpoint: `/api/admin/places/${place.id}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          place: data
        })
        this.props.onReady()
      }
    })
  }

  _handleLookup() {
    const { q } = this.state
    if(q.length === 0) {
      return this.setState({
        places: null
      })
    }
    this.context.network.request({
      endpoint: '/api/admin/places',
      method: 'GET',
      query: { q },
      onSuccess: ({ data }) => {
        this.setState({
          places: data
        })
      }
    })
  }

  _handleType(q) {
    this.setState({ q })
  }

  _handleUpdate() {}

}

export default PlacesField
