import EmailCampaign from './email_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/email_performances/${props.email_campaign.id}/campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Publishing)', key: 'advisor.title', sort: 'advisor', format: (email_campaign) => (
        <EmailCampaign email_campaign={ email_campaign } />
      ) },
      { label: 't(Sent)', key: 'sent', collapsing: true, align: 'right' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Click)', key: 'click_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Bounce)', key: 'bounce_rate', collapsing: true, align: 'right', format: 'rate'},
      { label: 't(Complain)', key: 'complain_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Unsubscribe)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: 'rate' }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(Your advisors have not yet sent any emails)'
    },
    entity: 'email campaign',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/${record.team.subdomain}/campaigns/email/${record.id}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
