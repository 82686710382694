import ProgramForm from '@apps/crm/admin/components/programform'
import PipelineToken from '@apps/sales/admin/tokens/pipeline'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Sales Pipelines)',
  collection: {
    endpoint: '/api/admin/sales/pipelines',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: PipelineToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Workflow)', fields: [
            { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'filter',
      title: 't(No Sales Pipelines)',
      text: 't(You have not yet created any sales pipelines)',
      buttons: [{
        label: 'Create Sales Pipeline',
        modal: <ProgramForm form={ New } />
      }]
    },
    entity: 'pipeline',
    onClick: (record) => context.router.push(`/sales/pipelines/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
