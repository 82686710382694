import getConfigEntities from '@apps/maha/services/mjson/get_config_entities'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Embeds extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata, props } = this.props
    const { advisor, form } = formdata
    const { entity } = props
    const { embeds } = form
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: embeds.map(embed => ({
            label: embed.title,
            type: 'segment',
            fields: [
              {
                name: `config.embeds.${embed.id}.variant_id`,
                type: 'hidden',
                value: embed.variants[0].id
              },
              {
                label: 't(Theme)', 
                name: `config.embeds.${embed.id}.theme_id`,
                type: 'dropdown',
                endpoint: `/api/admin/truevail/${entity}/advisors/${advisor.id}/themes`, 
                valueKey: 'id',
                textKey: 'title',
                selectFirst: true
              },
              ...this._getBlocks(embed).map(block => ({
                ...block.type === 'button' ? {
                  label: 't(Button)', 
                  name: `config.embeds.${embed.id}.blocks.${block.id}`,
                  type: 'dropdown',
                  endpoint: `/api/admin/truevail/${entity}/advisors/${advisor.id}/themes`, 
                  valueKey: 'id',
                  textKey: 'title',
                  selectFirst: true
                } : {}
              }))
            ]
          }))
        }
      ]
    }
  }

  _getBlocks(embed) {
    const config = this.props[embed.id]
    return getConfigEntities(config, (config) => {
      if(config.type === 'button') return true
    })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(form) {
    const { formdata } = this.props
    this.props.onNext({
      config: {
        ...formdata.config || {},
        ...form.config   
      }
    })
  }

}

//TODO: find matching voice

const mapResources = (props, context) => ({
  ...props.formdata.form.embeds.reduce((versions, embed) => ({
    ...versions,
    [embed.id]: `/api/admin/truevail_template_variants/${embed.variants[0].id}/config/versions/published`
  }), {})
})

export default Container(mapResources)(Embeds)
