import AssetThumbnail from '@admin/components/asset_thumbnail'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class List extends React.Component {

  static propTypes = {
    allow: PropTypes.object,
    chooseCollections: PropTypes.bool,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    source: PropTypes.object,
    onBack: PropTypes.func,
    onClick: PropTypes.func,
    onList: PropTypes.func
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    const records = this._getRecords()
    return (
      <div className="maha-attachments-list-items">
        { records.map((item, index) => (
          <div className={ this._getClass(item) } key={`item_${index}`} onClick={ this._handleClick.bind(this, item) }>
            <div className="maha-attachments-list-item-icon">
              { item.type === 'folder' ?
                <div className="maha-asset-icon">
                  <Icon icon="folder" />
                </div> :
                <AssetThumbnail { ...item } />
              }
            </div>
            <div className="maha-attachments-list-item-name">
              { item.name }
            </div>
            <div className="maha-attachments-list-item-action">
              <Icon { ...this._getIcon(item) } />
            </div>          
          </div>
        )) }
      </div>
    )
  }

  _getClass(item) {
    const classes = ['maha-attachments-list-item']
    if(this._getSelected(item)) classes.push('selected')
    return classes.join(' ')
  }

  _getContentType(content_type) {
    if(content_type === 'image') return ['jpg','jpeg','png','gif'].map(type => {
      return `image/${type}`
    })
    if(content_type === 'audio') return ['mpeg','mp3','wav','wave','x-wav','aiff','x-aifc','x-aiff','x-gsm','gsm','ulaw'].map(type => {
      return `audio/${type}`
    })
    return [content_type]
  }

  _getContentTypes(content_types) {
    if(!content_types) return null
    return content_types.reduce((content_types, content_type) => [
      ...content_types,
      ...this._getContentType(content_type)
    ], [])
  }

  _getDisabled(item) {
    if(item.type !== 'file') return false
    const { allow } = this.props
    const { extensions } = allow
    const content_types = this._getContentTypes(allow.content_types)
    if(!content_types && !extensions) return false
    const name = item.label || item.name
    const extension = name.split('.').pop().toLowerCase()
    const extension_allowed = _.includes(extensions, extension)
    const content_type = item.content_type || item.asset.content_type
    const content_type_allowed = _.includes(content_types, content_type)
    return !(extension_allowed || content_type_allowed)
  }

  _getIcon(item) {
    const selected = this._getSelected(item)
    const { chooseCollections, multiple } = this.props
    return {
      svg: multiple && item.type === 'file' ? (selected ? 'square_check' : 'square') : item.type === 'folder' || (item.type === 'collection' && !chooseCollections) ? 'chevron_right' : null
    }
  }

  _getChecked(item) {
    const selected = this._getSelected(item)
    return {
      svg: item.type === 'file' ? (selected ? 'square_check' : 'square') : null
    }
  }

  _getRecords() {
    const { records } = this.props
    return records.filter(record => {
      return !this._getDisabled(record)
    })
  }

  _getSelected(item) {
    const { files, source } = this.props
    return _.find(files, { id: item.id, service: source.service })
  }

  _handleClick(item) {
    this.props.onClick(item)
  }

}

export default List
