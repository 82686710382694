import IncomingEmails from './incoming_emails'
import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'
const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.sender.name,
  access: { 
    programs: { $canView: resources.sender.program.id },
    rights: { $oneOf: ['team:view_senders','team:manage_senders'] } 
  },
  manager: {
    path: `/admin/team/senders/${resources.sender.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview sender={ resources.sender } />
      },
      {
        label: 't(Incoming Emails)',
        path: '/incoming_emails',
        panel: <IncomingEmails sender={ resources.sender } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  sender: `/api/admin/team/senders/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
