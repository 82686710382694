import ProfileConnect from '@admin/components/profile_connect'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <ProfileConnect { ...this._getConnect() } />
  }

  _getConnect() {
    const { program_id } = this.props
    return {
      program_id,
      sources: this._getSources(),
      types: ['posts'],
      onCancel: this._handleCancel,
      onDone: this._handleDone
    }
  }

  _getSources() {
    const { features } = this.context.admin
    return [
      'instagram_business',
      'facebook',
      ...features.linkedin === true ? ['linkedin'] : [],
      ...features.youtube === true ? ['google'] : [],
      ...features.tiktok === true ? ['tiktok'] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default New
