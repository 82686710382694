import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import Page from '@admin/components/page'
import Designs from './designs'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Content Library)',
  manager: {
    path: '/admin/truevail/library',
    items: [
      {
        label: 't(Email Campaigns)',
        path: '/email_campaigns',
        panel: <EmailCampaigns />
      },
      {
        label: 't(Social Campaigns)',
        path: '/social_campaigns',
        panel: <SocialCampaigns />
      },
      {
        label: 't(Designs)',
        path: '/designs',
        panel: <Designs />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
