import Payment from '@apps/finance/admin/views/invoices/payment'
import Content from '@apps/forms/admin/tokens/content'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Refund from '../refund'
import Cancel from '../cancel'
import numeral from 'numeral'
import Void from '../void'
import React from 'react'

const Overview = ({ event, registration }) => {

  const contact = {
    label: registration.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${registration.contact.id}`
  }

  const eventLink = {
    label: event.title,
    className: 'link',
    route: `/admin/events/events/${event.id}`
  }

  const details = {
    audits: `events_registrations/${registration.id}`,
    comments: `events_registrations/${registration.id}`,
    sections: [{
      title: 't(Registration Details)',
      items: [
        { label: 't(Event)', content: <Button { ...eventLink } /> },
        { label: 't(Contact)', content: <Button { ...contact } /> },
        { label: 't(Duration)', content: `${registration.duration} seconds` },
        ...registration.location ? [
          { label: 't(IP Address)', content: (
            <>
              {registration.location.ipaddress.ipv4 } {registration.location.ipaddress.address && registration.location.ipaddress.address.city &&
                <>({registration.location.ipaddress.address.city}, {registration.location.ipaddress.address.region})</>
              }
            </>
          ) }  
        ] : [],
        { label: 't(Contact Status)', content: registration.is_known ? 'KNOWN' : 'UNKNOWN' },
        { label: 't(Submitted)', content: registration.created_at, format: 'datetime' },
        { label: 't(Revenue)', content: numeral(registration.revenue).format('$0.00') }
      ]
    }]
  }

  if(registration.invoice) {
    const invoice = {
      label: 'View Invoice',
      className: 'link',
      route: `/admin/finance/invoices/${registration.invoice.id}`
    }
    details.sections[0].items.push({ label: 'Invoice', content: <Button { ...invoice } /> })
  }

  if(registration.enrollment) {
    const enrollment = {
      label: 'View Enrollment',
      className: 'link',
      route: `/admin/automation/workflows/${registration.enrollment.workflow_id}/enrollments/${registration.enrollment.id}`
    }
    details.sections[0].items.push({ label: 't(Workflow)', content: <Button { ...enrollment } /> })
  }

  if(registration.canceled_at !== null) {
    details.sections.push({
      title: 't(Cancellation)',
      items: [
        { label: 'Canceled', content: registration.canceled_at, format: 'datetime' },
        { label: 't(Reason)', content: registration.cancellation_reason }
      ]
    })
  }

  details.sections.push({
    title: 't(Registration Data)',
    items: [
      { label: 't(First Name)', content: registration.data.first_name },
      { label: 't(Last Name)', content: registration.data.last_name },
      { label: 't(Email)', content: registration.data.email },
      ...event.contact_config.fields.filter(field => {
        return field.type !== 'text'
      }).map(field => ({
        label: field.name.value,
        content: <Content data={ registration.data } field={ field } />
      }))
    ]
  })

  if(registration.canceled_at !== null) {
    details.alert = { color: 'red', message: 't(This registration was canceled)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  event: PropTypes.object,
  registration: PropTypes.object
}

const mapPropsToPanel = ({ event, registration }, context) => ({
  title: 't(Overview)',
  panel: <Overview registration={ registration } event={ event } />,
  tasks: registration.canceled_at === null ? {
    items: [
      {
        label: 't(Cancel Registration)',
        svg: 'ban',
        modal: <Cancel registration={ registration } event={ event } />
      },
      {
        label: 't(Refund Registration)',
        svg: 'square_arrow_left',
        show: registration.payment && registration.payment.status !== 'captured' && registration.payment.refundable > 0,
        modal: <Refund registration={ registration } event={ event } />
      },
      // {
      //   label: 't(Void Registration)',
      //   show: registration.payment && registration.payment.status === 'captured',
      //   modal: <Void registration={ registration } event={ event } />
      // },
      ...registration.invoice ? [
        {
          label: 't(Receive Payment)',
          svg: 'hand_coins',
          show: registration.invoice.status === 'unpaid',
          modal: <Payment contact_id={ registration.contact.id} invoice_id={ registration.invoice.id } />
        }
      ] : []
    ]
  } : {}
})

export default Panel(null, mapPropsToPanel)
