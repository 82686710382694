import MJSONScreenshot from '@admin/components/mjson_screenshot'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_designs/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})
const DesignCard = ({ design }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <MJSONScreenshot { ...getScreenshot(design) } />
    </div>
    <div className="maha-card-layout-card-details">
      <div className="token-stats">
        <LibraryToken value={ design.library_status } />
      </div>
    </div>
  </div>
)

DesignCard.propTypes = {
  design: PropTypes.object
}

export default DesignCard
