import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Publish from '../../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Batches)',
  collection: {
    endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this social batch)',
      buttons: [
        {
          label: 't(Publish Social Campaigns)',
          modal: <Publish social_batch={ props.social_batch } />
        }
      ]
    },
    entity: 'publishing',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        modal: <Preview publishing={ record } scope="agency" type="social" />
      },
      {
        label: 't(Unschedule Social Campaigns)',
        svg: 'clock',
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished social campaigns)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaigns)')
        }
      },
      {
        label: 't(Delete Social Campaigns)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete these social campaigns?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaigns)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaigns)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Social Campaigns)',
    modal: <Publish social_batch={ props.social_batch } />
  }
})

export default Panel(null, mapPropsToPanel)
