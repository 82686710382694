import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const statuses = {
  draft: { icon: 'x', text: 't(not available in either library)' },
  private: { icon: 'lock', text: 't(only available in private library)' },
  public: { icon: 'lock_open', text: 't(available in all libraries)' }
}

const LibraryToken = ({ value }) => (
  <div className="library-token">
    <Icon svg={ statuses[value].icon }  /><span>
      <T text={ statuses[value].text } />
    </span>
  </div>
)

LibraryToken.propTypes = {
  value: PropTypes.string
}

export default LibraryToken
