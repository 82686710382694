import ModalPanel from '@admin/components/modal_panel'
import List from '@apps/crm/admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class ContactPhoneNumbers extends React.Component {

  static propTypes = {
    contact_phone_numbers: PropTypes.array,
    program_phone_numbers: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <List { ...this._getList() } />
      </ModalPanel>
    )
  }

  _getList() {
    const { contact_phone_numbers } = this.props
    return {
      format: (phone_number) => <Token text={ phone_number.formatted } />,
      items: contact_phone_numbers,
      onClick: this._handleChoose
    }
  }

  _getPanel() {
    const { program_phone_numbers } = this.props
    return {
      title: 't(Choose a Phone Number)',
      leftItems: program_phone_numbers.length > 1 ? [
        { svg: 'chevron_left', handler: this._handleBack }
      ] : [
        { label: 'Cancel', handler: this._handleCancel }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChoose(program) {
    this.props.onChoose(program)
  }

}

export default ContactPhoneNumbers
