import ModalPanel from '@admin/components/modal_panel'
import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import Stack from '@admin/components/stack'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Folder from './folder'
import List from './list'
import Grid from './grid'
import React from 'react'

class FileBrowser extends React.Component {

  static propTypes = {
    allow: PropTypes.object,
    chooseCollections: PropTypes.bool,
    context: PropTypes.object,
    doneText: PropTypes.any,
    files: PropTypes.array,
    folders: PropTypes.array,
    multiple: PropTypes.bool,
    prompt: PropTypes.string,
    q: PropTypes.string,
    showHeader: PropTypes.bool,
    source: PropTypes.object,
    view: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onChangeFolder: PropTypes.func,
    onNext: PropTypes.func,
    onRemove: PropTypes.func,
    onSetQuery: PropTypes.func,
    onSetView: PropTypes.func,
    onToggleView: PropTypes.func,
    onUp: PropTypes.func
  }

  static defaultProps = {
    chooseCollections: false,
    prompt: 'Find a file...',
    showHeader: true
  }

  _handleBack = this._handleBack.bind(this)
  _handleClick = this._handleClick.bind(this)
  _handleChooseFile = this._handleChooseFile.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleToggleView = this._handleToggleView.bind(this)

  render() {
    const { q } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-drive">
          <div className="maha-attachments-drive-head">
            <div className="maha-attachments-drive-head-bar">
              <div className="maha-attachments-drive-search">
                <Searchbox { ...this._getSearchbox() } />
              </div>
              <div className="maha-attachments-drive-view" onClick={ this._handleToggleView }>
                <Icon svg={  this._getIcon() } />
              </div>
            </div>
          </div>
          <div className="maha-attachments-drive-body">
            { q.length === 0 && <Stack { ...this._getStack() } /> }
            { q.length > 0 && <Infinite { ...this._getInfinite() } /> }
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleLoadView()
  }

  componentDidUpdate(prevProps) {
    const { view } = this.props
    if(view !== prevProps.view) {
      this._handleSaveView()
    }
  }

  _getFolder(folder) {
    const { allow, chooseCollections, files, multiple, showHeader, source, view, onUp } = this.props
    return {
      allow,
      chooseCollections,
      files, 
      multiple,
      showHeader,
      source,
      ...folder.type === 'collection' ? {
        collection: folder.item
      } : {
        folder: folder.item        
      },
      view,
      onClick: this._handleClick,
      onUp
    }
  }

  _getIcon() {
    const { view } = this.props
    return view === 'list' ? 'table_properties' : 'layout_grid'
  }

  _getInfinite() {
    const { allow, chooseCollections, files, multiple, q, showHeader, source, view } = this.props
    const empty = {
      icon: 'times-circle',
      title: 't(No Results)',
      text: 't(There are no files that matched your query)'
    }
    const filter = q.length > 0 ? { q } : null
    return {
      endpoint: `/api/admin/profiles/${source.id}/files`,
      filter,
      empty: <Message { ...empty } />,
      notFound: <Message { ...empty } />,
      layout: view === 'list' ? List : Grid,
      props: {
        allow,
        chooseCollections,
        files, 
        multiple,
        showHeader,
        source,
        onClick: this._handleClick
      }
    }
  }

  _getPanel() {
    const { doneText, files, showHeader } = this.props
    return {
      title: 't(Choose File(s))',
      showHeader,
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: files.length > 0 ? [
        { label: doneText, handler: this._handleNext }
      ] : []
    }
  }

  _getSearchbox() {
    const { prompt, onSetQuery } = this.props
    return {
      prompt,
      onChange: onSetQuery
    }
  }

  _getStack() {
    const { folders, source } = this.props
    const root = {
      type: 'folder',
      item: {
        id: null,
        name: source.username  
      }
    }
    return {
      display_name: 'attachments_files',
      cards: [
        { component: Folder, props: this._getFolder.bind(this, root) },
        ...folders.map((folder) => ({ 
          component: Folder, 
          props: this._getFolder.bind(this, folder) 
        }))
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChooseFile(item) {
    const { source, files, onRemove } = this.props
    const index = _.findIndex(files, { id: item.id, service: source.service })
    if(index >= 0) return onRemove(index)
    this.props.onAdd({
      id: item.id,
      create: {
        endpoint: `/api/admin/profiles/${source.id}/files`,
        body: { id: item.id }
      },
      source_id: source.id,
      name: item.name,
      service: source.service,
      content_type: item.content_type,
      thumbnail: item.thumbnail,
      status: 'pending'
    })
  }

  _handleClick(item) {
    const { chooseCollections, onChangeFolder } = this.props
    // if(this._getDisabled(item)) return
    if(item.type === 'collection' && !chooseCollections) onChangeFolder({ type: 'collection', item })
    if(item.type === 'collection' && chooseCollections) this._handleChooseFile(item)
    if(item.type === 'folder') onChangeFolder({ type: 'folder', item })
    if(item.type === 'file') this._handleChooseFile(item)
  }

  _handleLoadView() {
    this.props.context.local_storage.get({
      key: 'file-explorer',
      onSuccess: (view) => {
        this.props.onSetView(view)
      },
      onFailure: () => {}
    })
  }

  _handleNext() {
    this.props.onNext()
  }

  _handleSaveView() {
    const { view } = this.props
    this.props.context.local_storage.set({
      key: 'file-explorer',
      value: view,
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleToggleView() {
    this.props.onToggleView()
  }

}

export default FileBrowser
