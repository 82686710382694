import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Responses)',
  collection: {
    endpoint: '/api/admin/dashboards/forms/responses',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Form)', key: 'form.title', sort: 'form', primary: true },
      { label: 't(Submitted)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'user_round',
      title: 't(No Responses)',
      text: 't(No one has responsed to a form)'
    },
    entity: 'response',
    onClick: (record) => context.router.push(`/forms/forms/${record.form.id}/responses/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
