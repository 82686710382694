import FormPublish from '@apps/truevail/admin/components/form_publish'
import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/admin/forms/${props.form.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.organization', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'sc' },
    empty: {
      svg: 'mail',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this form)',
      buttons: [
        { 
          label: 't(Publish Advisor Form)',
          modal: <FormPublish form_id={ props.form.id } entity="admin" />
        }
      ]
    },
    entity: 'publishing',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/${record.advisor.team.subdomain}/forms/forms/${record.advisor_form.id}`)
    }
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Advisor Form)',
    modal: <FormPublish form_id={ props.form.id } entity="admin" />
  }
})

export default Panel(null, mapPropsToPanel)
