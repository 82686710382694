import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import New from '@apps/automation/admin/views/workflows/new'
import Panel from '@admin/components/panel'
import React from 'react'

const getNew = (props, context) => ({
  program_id: context.admin.programs[0].id,
  triggers: ['truevail:participant_registered','truevail:participant_attended','truevail:participant_noshowed','truevail:participant_converted'],
  params: {
    webinar_id: props.webinar.id
  }
})

const mapPropsToPanel = (props, context) => ({
  title: 't(ty(Workflows))',
  collection: {
    endpoint: '/api/admin/automation/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Workflows)',
      text: 't(There are no workflows for this webinar)',
      svg: 'network',
      buttons: [
        { 
          label: 't(Create Workflow)',
          access: {
            rights: { $allOf: ['automation:access_app','automation:manage_workflows'] }
          },
          modal: <New { ...getNew(props, context) } /> 
        }
      ]
    },
    entity: 'worfklow',
    defaultQuery: { webinar_id: props.webinar.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/automation/workflows/${record.id}`)
  },
  task: {
    svg: 'plus',
    access: {
      rights: { $allOf: ['automation:access_app','automation:manage_workflows'] }
    },
    modal: <New { ...getNew(props, context) } />
  }
})

export default Panel(null, mapPropsToPanel)
