import { DragSource, DropTarget } from 'react-dnd'
import Button from '@admin/components/button'
import Handle from '@admin/components/handle'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

export class Item extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    actions: PropTypes.func,
    format: PropTypes.any,
    item: PropTypes.object,
    itemClass: PropTypes.func,
    itemDisabled: PropTypes.func,
    index: PropTypes.number,
    onClick: PropTypes.func,
    onMove: PropTypes.func
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource } = this.props
    const { format, item, index, onClick, onMove } = this.props
    const actions = this._getActions()
    const output = (
      <div { ...this._getItem() }>
        { onMove &&
          connectDragSource(
            <div className="maha-list-item-handle">
              <Handle />
            </div>
          )
        }
        <div className="maha-list-item-token">
          { format(item, index) }
        </div>
        { actions &&
          <>
            { actions.map((action, i) => (
              <Button { ...this._getAction(action, index) } key={`action_${i}`} />
            )) }
          </>
        }
        { onClick &&
          <div className="maha-list-item-proceed">
            <Icon svg="chevron_right" />
          </div>
        }
      </div>
    )
    return onMove ? connectDropTarget(connectDragPreview(output)) : output
  }

  _getAction(action) {
    return {
      className: 'maha-list-item-action',
      ...action
    }
  }

  _getActions() {
    const { actions, item, index } = this.props
    return actions ? actions(item, index) : []
  }

  _getClass() {
    const { item, itemClass, itemDisabled } = this.props
    const classes = ['maha-list-item']
    if(itemDisabled && itemDisabled(item)) classes.push('disabled')
    if(itemClass) classes.push(...itemClass(item))
    return classes.join(' ')
  }

  _getItem() {
    return {
      className: this._getClass(),
      onClick: this._handleClick
    }
  }

  _handleClick() {
    const { item, onClick } = this.props
    if(!onClick) return
    onClick(item, this.context)
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

let SortItem = DragSource('LIST_ITEM', source, sourceCollector)(Item)
SortItem = DropTarget('LIST_ITEM', target, targetCollector)(SortItem)

export { SortItem }