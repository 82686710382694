import TagsUpdateBatch from '@apps/crm/admin/components/tags_update_batch'
import ScheduleBatch from '@apps/truevail/admin/components/schedule_batch'
import PreviewBatch from '@apps/truevail/admin/components/preview_batch'
import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Schedule from '../schedule'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/agency/email_campaigns/${props.email_campaign.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Status)', key: 'advisor_email_campaign_status', type: 'select', multiple: true, options: ['scheduled','sending','queuing','warming','sent'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Draft)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'advisor_email_campaign_status', operator: '$eq', parent: null, value: 'draft' }
          ]
        } },
        { id: 1, title: 't(Scheduled)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'advisor_email_campaign_status', operator: '$eq', parent: null, value: 'scheduled' }
          ]
        } },
        { id: 2, title: 't(Sent)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'advisor_email_campaign_status', operator: '$eq', parent: null, value: 'sent' }
          ]
        } },
        { id: 3, title: 't(Unpublished)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'advisor_email_campaign_status', operator: '$eq', parent: null, value: 'unpublished' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      svg: 'mail',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this email campaign)',
      buttons: [
        {
          label: 't(Publish Email Campaigns)',
          modal: <Publish email_campaign={ props.email_campaign } />
        }
      ]
    },
    entity: 'publishing',
    selectable: true,
    batch: {
      batch_type: 'truevail_publishings',
      tasks: [
        { 
          label: 't(Update Primary Contact Tags)', 
          svg: 'tag',
          config: { email_campaign_id: props.email_campaign.id },
          action: 'tag',
          component: TagsUpdateBatch
        },
        { 
          label: 't((Re)schedule All)', 
          svg: 'clock',
          action: 'schedule', 
          config: { email_campaign_id: props.email_campaign.id },
          component: <ScheduleBatch type="email" />
        },
        { 
          label: 't(Unschedule All)', 
          svg: 'clock',
          action: 'unschedule', 
          config: { email_campaign_id: props.email_campaign.id },
          confirm: 't(Are you sure you want to unpublish these publishings?)'
        },
        { 
          label: 't(Send Previews)', 
          svg: 'mail',
          action: 'preview', 
          config: { email_campaign_id: props.email_campaign.id },
          component: <PreviewBatch type="email" />
        },
        { 
          label: 't(Republish All)', 
          svg: 'refresh_cw',
          action: 'republish', 
          config: { email_campaign_id: props.email_campaign.id }
        },
        { 
          label: 't(Unpublish All)', 
          svg: 'ban',
          action: 'unpublish', 
          config: { email_campaign_id: props.email_campaign.id },
          confirm: 't(Are you sure you want to unpublish these publishings?)'
        },
        { 
          label: 't(Delete All)', 
          svg: 'trash',
          action: 'delete', 
          deletable: true, 
          config: { email_campaign_id: props.email_campaign.id },
          confirm: 't(Are you sure you want to delete these publishings?)'
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        show: _.includes(['draft','scheduled'], record.advisor_email_campaign.status),
        modal: <Preview publishing={ record } scope="agency" type="email" />
      },
      {
        label: 't(Republish Email Campaign)',
        svg: 'refresh_cw',
        show: _.includes(['draft','scheduled','unpublished'], record.advisor_email_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/republish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully republished email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to republish email campaign)')
        }
      },
      {
        label: record.advisor_email_campaign.status === 'draft' ? 't(Schedule Email Campaign)' : 't(Reschedule Email Campaign)',
        svg: 'clock',
        show: _.includes(['draft','scheduled'], record.advisor_email_campaign.status),
        modal: <Schedule publishing={ record } />
      },
      {
        label: 't(Unpublish Email Campaign)',
        svg: 'ban',
        show: _.includes(['draft','scheduled'], record.advisor_email_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish email campaign)')
        }
      },
      {
        label: 't(Unschedule Email Campaign)',
        svg: 'clock',
        show: record.advisor_email_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unscheduled email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unschedule email campaign)')
        }
      },
      {
        label: 't(Delete Email Campaign)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this email campaign?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete email campaign)')
        }
      }
    ],
    onClick: (record) => context.host.window.open(`/${record.subscription.advisor.team.subdomain}/campaigns/email/${record.advisor_email_campaign.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Email Campaigns)',
    modal: <Publish email_campaign={ props.email_campaign } />
  }
})

export default Panel(null, mapPropsToPanel)
