import ProgramForm from '@apps/crm/admin/components/programform'
import Clone from '@apps/team/admin/views/themes/clone'
import New from '@apps/team/admin/views/themes/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Theme)',
  access: { rights: { $oneOf: ['team:view_templates','team:manage_templates'] } },
  collection: {
    endpoint: '/api/admin/team/themes',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Theme)', key: 'title', primary: true },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : []
    ],
    empty: {
      title: 't(No Themes)',
      text: 't(There are no themes)',
      icon: 'paint-brush',
      buttons: [
        { 
          label: 't(Create Theme)',
          access: { rights: { $oneOf: ['team:manage_templates'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' }
    ],
    entity: 'theme',
    defaultSort: { key: 'created_at', order: 'desc' },
    recordTasks: (record) => [
      { 
        label: 't(Duplicate Theme)', 
        svg: 'copy',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        modal: <Clone theme={ record } /> 
      },
      {
        label: 'Delete Theme',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        confirm: 't(Are you sure you want to delete this theme?)',
        request: {
          endpoint: `/api/admin/team/themes/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this theme)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this theme)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/team/themes/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Theme)',
    access: { rights: { $oneOf: ['team:manage_templates'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
