const triggers = [
  {
    svg: 'heart_handshake',
    code: 'deal_created',
    title: 't(Deal Created)',
    text: 't(Deal was created in pipeline)'
  },
  {
    svg: 'heart_handshake',
    code: 'deal_updated',
    title: 't(Deal Updated)',
    text: 't(Deal was updated in pipeline)'
  },
  {
    svg: 'heart_handshake',
    code: 'deal_transitioned',
    title: 't(Deal Transitioned)',
    text: 't(Deal was transitioned from one stage to another in pipeline)'
  },
  {
    svg: 'heart_handshake',
    code: 'deal_stage_duration',
    title: 't(Deal Stage Duration)',
    text: 't(Deal has been in a specific stage for a specific duration in pipeline)'
  }
]

export default triggers
