import PhoneNumber from '@admin/components/phone_number'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import moment from 'moment'
import bytes from 'bytes'
import React from 'react'

class Format extends React.Component {

  static propTypes = {
    format: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      PropTypes.string
    ]),
    value: PropTypes.any
  }

  render() {
    const { format } = this.props
    if(_.isString(format)) {
      const [,style,details] = format.match(/([^|]*)\|?(.*)/)
      if(style === 'status') {
        return Status(this.props)
      } else if(style === 'currency') {
        return Currency(this.props)
      } else if(style === 'percent') {
        return Percent(this.props)
      } else if(style === 'rate') {
        return Rate(this.props)
      } else if(style === 'number') {
        const template = details || '0'
        return Number(this.props, template)
      } else if(style === 'duration') {
        return Duration(this.props)
      } else if(style === 'date') {
        return DateFormat(this.props, true, false)
      } else if(style === 'datetime') {
        return DateFormat(this.props, true, true)
      } else if(style === 'time') {
        return DateFormat(this.props, false, true)
      } else if(style === 'check_times') {
        return Check(this.props, true)
      } else if(style === 'yes_no') {
        return YesNo(this.props, true)
      } else if(style === 'check') {
        return Check(this.props, false)
      } else if(style === 'capitalize') {
        return Capitalize(this.props)
      } else if(style === 'email') {
        return Email(this.props)
      } else if(style === 'phone') {
        return Phone(this.props)
      } else if(style === 'bytes') {
        return Bytes(this.props)
      } else if(style === 'link') {
        return Link(this.props)
      } else if(style === 'raw') {
        return Raw(this.props)
      } else if(style === 'from') {
        return From(this.props)
      } else if(style === 'element') {
        return Element(this.props)
      } else if(this.props.value === '') {
        return Empty(this.props)
      } else {
        return Default(this.props)
      }
    } else if(_.isFunction(format)) {
      const Component = format
      return <Component { ...this.props } />
    } else {
      return Default(this.props)
    }
  }

}

const Default = (props) => (
  <div className="format">
    { _.isString(props.value) ?
      <T text={ props.value } /> :
      <>{ props.value }</> 
    }
  </div>
)

const From = (props) => {
  const diff = moment(props.value).diff(moment(), 'hour')
  const unit = Math.abs(diff) > 24 ? 'day' : (Math.abs(diff) > 0 ? 'hour' : 'minutes')
  const adjusted = Math.abs(moment(props.value).diff(moment(), unit))
  if(diff > 0) return <span>in { pluralize(unit, adjusted, true) }</span>
  return <span>{ pluralize(unit, adjusted, true) } ago</span>
}

const Element = (props) => {
  return <props.value />
}

const Raw = (props) => {
  return <span dangerouslySetInnerHTML={{ __html: props.value }}></span>
}

const Empty = () => {
  return <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></span>
}

const Status = (props) => {
  return props.value ? <span className={ props.value.toLowerCase() }>{ props.value.toUpperCase() }</span> : <span />
}

const Check = (props, times) => (
  <span className="checktimes">
    <Icon svg={ props.value === true ? 'check' : times ? 'x' : null } />
  </span>
)

const YesNo = (props) => {
  if(props.value === false) return <span className="no">NO</span>
  if(props.value === true) return <span className="yes">YES</span>
  return null
}

const Currency = (props) => {
  return <span>{ numeral(props.value).format('0,0.00') }</span>
}

const Percent = (props) => {
  return <span>{ numeral(props.value).format('0.00%') }</span>
}

const Rate = (props) => {
  return <span>{ numeral(props.value * 100).format('0.00') }</span>
}

const Number = (props, format) => {
  return <span>{ numeral(props.value).format('0,0') }</span>
}

const Duration = (props, format) => {
  const pad = (value) => _.padStart(value, 2, 0)
  const minutes = Math.floor(props.value / 60)
  const seconds = (props.value  - (minutes * 60)) % 60
  const parts = [ pad(minutes), pad(seconds) ]
  return parts.join(':')
}

const DateFormat = (props, date, time) => {
  if(!props.value) return null
  if(time === false) return <Date date={ props.value } />
  return <Date datetime={ props.value } />
}

const Capitalize = (props) => {
  return <span>{ props.value.toUpperCase() }</span>
}

const Email = (props) => {
  return <a href={ `mailto:${ props.value }` }>{ props.value }</a>
}

const Phone = (props) => {
  if(!props.value) return ''
  return <PhoneNumber number={ props.value } />
}

const Bytes = (props) => {
  if(!props.value) return ''
  return bytes.format(parseInt(props.value), { decimalPlaces: 1, unitSeparator: ' ' })
}

const Link = (props) => {
  const button = {
    label: props.value,
    className: 'link',
    link: props.value
  }
  return <Button { ...button }/>
}

export default Format
