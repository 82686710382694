import SocialVariantDesigner from '@admin/components/social_variant_designer'
import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import Panel from '@admin/components/panel'
import React from 'react'

const getSocialVariantDesigner = (social_campaign) => {
  return {
    entity: 'truevail_social_campaign_variants',
    format: VariantVoiceToken,
    social_campaign
  }
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <SocialVariantDesigner { ...getSocialVariantDesigner(props.social_campaign) } />
})

export default Panel(null, mapPropsToPanel)
