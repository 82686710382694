import ModalPanel from '@admin/components/modal_panel'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Articles extends React.Component {

  static contextTypes = {
    help: PropTypes.object
  }

  static propTypes = {
    category: PropTypes.number,
    onArticle: PropTypes.func,
    onBack: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-help-main">
          <Infinite { ...this._getInfinite() } />
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    const { category } = this.props
    return {
      title: category.title,
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ]
    }
  }

  _getInfinite() {
    const { category, onArticle } = this.props
    const empty = {
      svg: 'x',
      title: 't(No Articles)',
      text: 't(There are no articles for this category)'
    }
    return {
      endpoint: '/api/admin/help/articles',
      empty: <Message { ...empty } />,
      notFound: <Message { ...empty } />,
      ...category ? { query: { category_id: category.id } } : {},
      layout: Results,
      props: {
        onArticle
      }
    }
  }

  _handleBack() {
    this.props.onBack()
  }

}

export default Articles
