import VoiceCampaignToken from '@apps/campaigns/admin/tokens/campaign/voice'
import New from '@apps/campaigns/admin/views/voice/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Voice Campaigns)',
  collection: {
    endpoint: '/api/admin/campaigns/voice',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (voice_campaign) => (
        <VoiceCampaignToken voice_campaign={ voice_campaign } compact={ true } />
      ) }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Calls)', key: 'calls_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultQuery: { pipeline_id: props.pipeline.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'phone',
      title: 't(No Voice Campaigns)',
      text: 't(You have not yet created any voice campaigns for this pipeline)',
      buttons: props.pipeline.deleted_at === null ? [
        { 
          label: 't(Create Voice Campaign)',
          modal: <New pipeline={ props.pipeline } /> 
        }
      ] : null
    },
    entity: 'voice campaign',
    onClick: (record) => context.router.push(`/campaigns/voice/${record.id}`)
  },
  task: props.pipeline.deleted_at === null ? {
    svg: 'plus',
    modal: <New pipeline={ props.pipeline } />
  } : null
})

export default Panel(null, mapPropsToPanel)
