import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Chooser from './chooser'
import moment from 'moment'
import React from 'react'

const formats = [
  'MM-DD-YYYY',
  'MM-DD-YY',
  'YYYY-MM-DD',
  'M/D/YY',
  'M/D/YYYY',
  'MM/DD/YY',
  'MM/DD/YYYY',
  'MMDDYY',
  'MMDDYYYY'
]

class Datefield extends React.Component {

  static contextTypes = {
    form: PropTypes.object,
    locale: PropTypes.object
  }

  static propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    prompt: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    clearable: true,
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    date: '',
    value: ''
  }

  _handleBegin = this._handleBegin.bind(this)
  _handleBlured = this._handleBlured.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { clearable } = this.props
    const { date } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-datefield-handle" onClick={ this._handleBegin }>
          <Icon icon="calendar-o" />
        </div>
        <div className="maha-datefield-field" onClick={ this._handleEdit }>
          <div className="maha-datefield-input">
            <Input { ...this._getInput() } />
          </div>
          { clearable && date &&
            <Button { ...this._getClear() } />
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { date } = this.state
    if(date !== prevState.date) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getChooser() {
    const { date } = this.state
    return {
      value: date,
      onCancel: this._handleCancel,
      onChoose: this._handleChoose
    }
  }

  _getClass() {
    const { disabled} = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-datefield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getFormatted() {
    const date = this.state.value.trim()
    const parsed = moment(date, formats, true)
    if(!parsed.isValid()) return null
    return parsed.format('YYYY-MM-DD')
  }

  _getInput() {
    const { id, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      ref: this.inputRef.current,
      type: 'text',
      id,
      placeholder: !focused ? this._getPlaceholder() : null,
      tabIndex,
      value,
      onBlur: this._handleBlured,
      onKeyDown: this._handleKeyDown,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getParsed(date) {
    return moment(date, formats).format('YYYY-MM-DD')
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _getRaw() {
    const { date } = this.state
    const raw = date && date.length > 0 ? moment(date, formats).format('YYYY-MM-DD') : null
    return raw
  }

  _handleBegin() {
    this.context.form.push(Chooser, this._getChooser.bind(this))
  }

  _handleBlured() {
    const { onBlur } = this.props
    const date = this._getFormatted()
    this.setState({
      focused: false,
      ...date ? {
        date,
        value: date
      } : {}
    })
    if(onBlur) onBlur()
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChange() {
    const raw = this._getRaw()
    this.props.onChange(raw)
  }

  _handleChoose(date) {
    this._handleSet(date)
    this.context.form.pop()
  }

  _handleClear() {
    this.setState({
      date: '',
      value: ''
    })
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyDown(e) {
    if(e.which === 13) this.inputRef.current.blur()
  }

  _handleSet(value) {
    const date = value ? this._getParsed(value) : ''
    this.setState({
      date,
      value: date
    })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

  _handleValidate() {
    const { date } = this.state
    const { required } = this.props
    if(required && (!date || date.length === 0)) {
      return this.props.onValid(null, ['This field is required'])
    }
    if(date.length > 0) {
      const parsed = moment(date, formats)
      if(!parsed.isValid()) {
        return this.props.onValid(null, ['Please enter a valid date'])
      }
    }
    const raw = this._getRaw()
    this.props.onValid(raw)
  }

}

export default Datefield
