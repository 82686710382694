import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    provider: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    signup_page: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { signup_page } = this.props
    const { provider } = this.context
    return {
      title: 't(Duplicate Signup Page)',
      action: `/api/admin/truevail/admin/signup_pages/${signup_page.id}/clone`,
      method: 'POST',
      saveText: 't(Clone)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 't(Enter the title)', required: true, defaultValue: `Copy of ${signup_page.title}` },
            { label: 't(Owner)', name: 'owner_id', type: 'dropdown', endpoint: '/api/admin/truevail/admin/agencies', valueKey: 'team.id', textKey: 'team.title', required: true, defaultValue: signup_page.owner.id },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/signup', required: true, host: provider.admin_host, placeholder: '/path/to/signup' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(signup_page) {
    this.context.router.push(`/truevail/admin/signup_pages/${signup_page.id}`)
    this.context.modal.close()
  }

}

export default Clone
