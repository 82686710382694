import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_designs/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const DesignToken = ({ design, show_provider }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(design) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { design.title }
      </div>
      <div className="token-stats">
        { show_provider &&
          <>
            { design.agency ?
              <span>Agency: { design.agency.title }</span> :
              design.team ?
                <span>Team: { design.team.title }</span> :
                <span>Provider: Truevail</span>
            }
          </>
        }
      </div>
    </div>
  </div>
)

DesignToken.propTypes = {
  design: PropTypes.object,
  show_provider: PropTypes.bool
}

export default DesignToken
