import RichText from '@admin/components/richtext'
import StatusToken from '@admin/tokens/status'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Comment extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    comment: PropTypes.object,
    variant: PropTypes.object,
    onDelete: PropTypes.func,
    onHide: PropTypes.func,
    onReply: PropTypes.func
  }

  render() {
    const { comment, onReply } = this.props
    return (
      <div className={ this._getClass() } onClick={ onReply }>
        <div className="campaigns-social-conversations-post-comment-avatar">
          <Avatar user={ this._getAvatar() } />
        </div>
        <div className="campaigns-social-conversations-post-comment-details">
          <div className="campaigns-social-conversations-post-comment-user">
            { comment.user_name || 'Unknown' }
          </div>
          <div className="campaigns-social-conversations-post-comment-text">
            <div dangerouslySetInnerHTML={{ __html: comment.text.replace('\n', '<br />') }} />
          </div>
          { comment.is_hidden ? 
            <div className="campaigns-social-conversations-post-comment-hidden">
              HIDDEN
            </div> : comment.status === 'posted' ?
              <div className="campaigns-social-conversations-post-comment-date">
                { moment(comment.posted_at).format('MMMM DD, h:mm A') }
              </div> :
              <div className="campaigns-social-conversations-post-comment-status">
                <StatusToken status={ comment.status } />
              </div>
          }
          <div className="campaign-social-campaign-comment-token-actions">
            <div className="campaign-social-campaign-comment-token-action">
              <Icon icon="heart-o" /> { comment.likes_count }
            </div>
          </div>
        </div>
        <div className="campaigns-social-conversations-post-comment-actions">
          <Button { ...this._getActions() } />        
        </div>
      </div>
    )
  }

  _getClass() {
    const { comment } = this.props
    const classes = ['campaigns-social-conversations-post-comment']
    if(comment.is_hidden) classes.push('hidden')
    return classes.join(' ')
  }

  _getActions() {
    const { comment, variant, onDelete } = this.props
    return {
      svg: 'ellipsis_vertical',
      className: 'campaigns-social-conversations-post-comment-action',
      tasks: [
        { 
          label: 't(Delete Comment)',
          svg: 'trash',
          className: 'link',
          show: variant.profile.service.source === 'instagram_business' || variant.profile.profile_id === comment.user_service_id,
          confirm: 't(Are you sure you want to delete this comment?)',
          deletable: true,
          handler: onDelete
        },
        { 
          label: 't(Hide Comment)',
          svg: 'eye_closed',
          className: 'link',
          show: variant.profile.service.source === 'facebook' && variant.profile.profile_id !== comment.user_service_id && !comment.is_hidden,
          handler: this._handleHide.bind(this, true)
        },
        { 
          label: 't(Show Comment)',
          svg: 'eye',
          className: 'link',
          show: variant.profile.service.source === 'facebook' && variant.profile.profile_id !== comment.user_service_id && comment.is_hidden,
          handler: this._handleHide.bind(this, false)
        }
      ]
    }
  }

  _getAvatar() {
    const { comment, variant } = this.props
    return { 
      initials: comment.user_name ? comment.user_name.split(' ').slice(0,2).map(word => word[0]).join('') : 'U',
      ...variant.profile.profile_id === comment.user_service_id ? {
        photo: variant.profile.photo
      } : {}
    }
  }

  _handleHide(is_hidden) {
    this.props.onHide(is_hidden)
  }

}

export default Comment
