import AccessTypeToken from '@admin/tokens/access_type'
import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import Unassigned from './unassigned'
import PropTypes from 'prop-types'
import Assigned from './assigned'
import React from 'react'

class Assignment extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      modal: PropTypes.object,
      network: PropTypes.object
    }),
    action: PropTypes.string,
    adding: PropTypes.bool,
    assigned: PropTypes.array,
    assigneeFormat: PropTypes.any,
    assignedEndpoint: PropTypes.any,
    assignedFilter: PropTypes.func,
    bodyFormat: PropTypes.func,
    defaultAssigned: PropTypes.array,
    defaultUnassigned: PropTypes.array,
    endpoint: PropTypes.string,
    empty: PropTypes.object,
    footer: PropTypes.any,
    method: PropTypes.string,
    query: PropTypes.string,
    revokeable: PropTypes.bool,
    types: PropTypes.array,
    typeFormat: PropTypes.any,
    typeKey: PropTypes.string,
    typeTextKey: PropTypes.string,
    typeValueKey: PropTypes.string,
    title: PropTypes.string,
    unassigned: PropTypes.array,
    unassignedEndpoint: PropTypes.any,
    unassignedFilter: PropTypes.func,
    onAddAssigned: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onRemoveAssigned: PropTypes.func,
    onRevokeAssigned: PropTypes.func,
    onSetAdding: PropTypes.func,
    onSetAssigned: PropTypes.func,
    onSetQuery: PropTypes.func,
    onSetUnassigned: PropTypes.func,
    onUpdateAssigned: PropTypes.func
  }

  static defaultProps = {
    empty: {},
    typeFormat: AccessTypeToken,
    typeKey: 'type',
    typeValueKey: 'value',
    typeTextKey: 'text',
    onCancel: () => {},
    onChange: () => {},
    onDone: () => {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { footer } = this.props
    return (
      <ModalPanel { ...this._getPanel() } >
        <div className={ this._getClass() }>
          <div className="maha-assignment-body">
            <div className="maha-assignment-assigned">
              <Button { ...this._getAdd() } />
              <Assigned { ...this._getAssigned() } />
            </div>
            <Unassigned { ...this._getUnassigned() } />
          </div>
          { footer &&
            <div className="maha-assignment-footer">
              { footer }
            </div>
          }
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { assignedFilter, defaultAssigned, defaultUnassigned } = this.props
    const assigned = assignedFilter ? assignedFilter(defaultAssigned) : defaultAssigned
    this.props.onSetAssigned(assigned)
    if(defaultUnassigned) this.props.onSetUnassigned(defaultUnassigned)
  }

  componentDidUpdate(prevProps) {
    const { assigned } = this.props    
    if(!_.isEqual(assigned, prevProps.assigned)) {
      this._handleChange()
    }
  }

  _getAdd() {
    return {
      label: 't(Assign a group or user)',
      className: 'maha-assignment-add',
      handler: this._handleBeginAdd
    }
  }

  _getAssigned() {
    const { assigned, assigneeFormat, empty, revokeable, typeFormat, typeKey, onRemoveAssigned, onRevokeAssigned } = this.props
    const types = this._getTypes()
    return {
      assigned,
      assigneeFormat,
      empty,
      revokeable,
      typeFormat,
      typeKey,
      types,
      onRemove: onRemoveAssigned,
      onRevoke: onRevokeAssigned,
      onUpdate: this._handleUpdate
    }
  }

  _getClass() {
    const { adding } = this.props
    const classes = ['maha-assignment']
    if(adding) classes.push('adding')
    return classes.join(' ')
  }

  _getPanel() {
    const { action, title, onBack, onCancel } = this.props
    return {
      title,
      leftItems: onBack ? [
        { svg: 'chevron_left', handler: onBack }
      ] : [
        { label: 't(Cancel)', handler: onCancel }
      ],
      rightItems: action ? [
        { label: 't(Save)', handler: this._handleSave }
      ] : [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _getTypes() {
    const { types } = this.props
    return types ? types.map((type) => {
      return _.isString(type) ? {
        value: type,
        text: type
      } : type
    }) : null
  }

  _getUnassigned() {
    const { assigned, unassigned, assigneeFormat, onSetQuery } = this.props
    return {
      assigned,
      unassigned,
      assigneeFormat,
      onChoose: this._handleAdd,
      onSetQuery
    }
  }

  _handleAdd(assignee) {
    const { typeKey, typeValueKey } = this.props
    const types = this._getTypes()
    this.props.onAddAssigned({
      ...assignee,
      ...types ? {
        [typeKey]: types[0][typeValueKey]
      } : {}
    })
    this.props.onSetAdding(false)
  }

  _handleBeginAdd() {
    this.props.onSetAdding(true)
  }

  _handleChange() {
    const { assigned } = this.props
    this.props.onChange(assigned)
  }

  _handleDone() {
    const { assigned } = this.props
    this.props.onDone(assigned)
  }

  _handleSave() {
    const { action, assigned, bodyFormat, method } = this.props
    this.props.context.network.request({
      endpoint: action,
      method,
      body: bodyFormat(assigned),
      onSuccess: this._handleDone,
      onFailure: () => {}
    })
  }

  _handleUpdate(assignee, index) {
    const { typeKey, typeValueKey } = this.props
    const types = this._getTypes()
    const cur = types.findIndex(type => {
      return assignee[typeKey] === type[typeValueKey]
    })
    this.props.onUpdateAssigned({
      ...assignee,
      [typeKey]: types[(cur + 1) % types.length][typeValueKey]
    }, index)
  }

}

export default Assignment