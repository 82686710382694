import RecipientsField from '@apps/phone/admin/components/recipientsfield'
import RecordingField from '@apps/phone/admin/components/recordingfield'
import SpeakField from '@apps/phone/admin/components/speakfield'
import Container from '@admin/components/container'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Dial extends React.PureComponent {

  static propTypes = {
    config: PropTypes.object,
    users: PropTypes.array,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    return {
      code: getCode(10),
      strategy: 'none',
      recipients: []
    }
  }

  _getForm() {
    const { config } = this.state
    const { users } = this.props
    return {
      ref: this.formRef,
      title: 't(Dial)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { name: 'code', type: 'hidden', defaultValue: config.code },
            { label: 't(Announcement)', type: 'segment', instructions: 't(Play this announcement before dialing out to recipients)', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'none', text: 't(No announcement)' },
                { value: 'say', text: 't(Speak text)' },
                { value: 'play', text: 't(Play an audio file)' }
              ], defaultValue: config.strategy },
              ...this._getStrategy()
            ] },
            { label: 't(Recipients)', name: 'recipients', type: RecipientsField, users, instructions: 't(Add as many recipients as you like. When an incoming call arrives, all phones will ring and the call will be transfered to the first phone that answers)', required: true, defaultValue: config.recipients }
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { config } = this.state
    if(config.strategy === 'say') {
      return [
        { name: 'say', type: SpeakField, placeholder: 'Connecting you to...', required: true, defaultValue: config.say }
      ]
    }
    if(config.strategy === 'play') {
      return [
        { name: 'recording_id', type: RecordingField, required: true, defaultValue: config.recording_id }
      ]
    }
    return []
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone() {
    const { config } = this.state
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

const mapResources = (props, context) => ({
  users: {
    endpoint: '/api/admin/users',
    filter: {
      is_active: {
        $eq: true
      }
    }
  }
})

export default Container(mapResources)(Dial)
