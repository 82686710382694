import ConversationToken from '@apps/phone/admin/tokens/conversation'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  state = {
    selected: null
  }

  render() {
    const { records } = this.props
    return (
      <div className="maha-handset-search-results">
        { records.map((conversation, index) => (
          <div className="maha-handset-search-result" key={`conversation_${index}`} onClick={ this._handleChoose.bind(this, conversation) }>
            <div className="maha-handset-search-result-token">
              <ConversationToken conversation={ conversation } perspective="program" />
            </div>
            <div className="maha-handset-search-result-proceed">
              <Icon svg="chevron_right" />
            </div>
          </div>
        ))}
      </div>
    )
  }

  _handleChoose(channel) {
    this.props.onChoose(channel)
  }

}

export default Results
