import ProfileConnect from '@admin/components/profile_connect'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Profiles from './profiles'
import React from 'react'

class ProfilePicker extends React.Component {

  static propTypes = {
    empty: PropTypes.object,
    program_id: PropTypes.number,
    showHeader: PropTypes.bool,
    sources: PropTypes.array,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  static defaultProps = {
    empty: {
      icon: 'user-circle',
      title: 't(No Accounts)',
      text: 't(You have not connected any third party accounts)'
    },
    showHeader: true,
    onChoose: () => {}
  }

  stackRef = React.createRef()

  _handleConnect = this._handleConnect.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handleProfiles()
  }

  _getStack() {
    return {
      display_name: 'profile_picker',
      ref: this.stackRef
    }
  }

  _handleConnect() {
    const { program_id, showHeader, sources, types } = this.props
    const props = {
      program_id,
      showHeader,
      sources,
      types,
      onBack: this._handlePop,
      onDone: this._handlePop
    }
    this._handlePush(ProfileConnect, props)
  }

  _handleDone(profile) {
    this.props.onDone(profile)
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleProfiles() {
    const { empty, program_id, sources, showHeader, types, onBack, onCancel } = this.props
    const props = {
      empty,
      program_id,
      showHeader,
      sources,
      types,
      onBack,
      onCancel,
      onChoose: this._handleDone,
      onConnect: this._handleConnect
    }
    this._handlePush(Profiles, props)
  }

}

export default ProfilePicker
