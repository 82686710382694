import VersionHistory from '@admin/components/version_history'
import Menu from '@admin/components/menu'
import PropTypes from 'prop-types'
import Content from './content'
import React from 'react'

class Sidebar extends React.PureComponent {

  static propTypes = {
    cacheKey: PropTypes.string,
    config: PropTypes.object,
    social_campaign: PropTypes.object,
    title: PropTypes.string,
    version: PropTypes.object,
    versions: PropTypes.array,
    onChange: PropTypes.func,
    onSave: PropTypes.func
  }

  render() {
    const { version } = this.props
    if(version) return <Menu { ...this._getMenu()} />
    return  <Content { ...this._getContent() } /> 
  }

  _getMenu() {
    return {
      type: 'pointing',
      items: [
        { label: 't(Content)', component: <Content { ...this._getContent() } /> },
        { label: 't(Versions)', component: <VersionHistory { ...this._getVersionHistory() } /> }
      ]
    }
  }

  _getContent() {
    const { cacheKey, config, title, version, versions, onChange, onSave } = this.props
    return {
      key: cacheKey,
      config,
      title,
      version,
      versions,
      onChange,
      onSave
    }
  }

  _getVersionHistory() {
    const { version, versions } =  this.props
    return { 
      publish_rollback: true,
      version, 
      versions 
    }
  }

}

export default Sidebar
