import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import Unassigned from './unassigned'
import PropTypes from 'prop-types'
import Assigned from './assigned'
import React from 'react'

class SupportTeams extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    action: PropTypes.string,
    assigned: PropTypes.array,
    assignedEndpoint: PropTypes.string,
    compareKey: PropTypes.string,
    entity: PropTypes.string,
    format: PropTypes.any,
    title: PropTypes.string,
    unassigned: PropTypes.array,
    unassignedEndpoint: PropTypes.string,
    valueKey: PropTypes.string
  }

  static defaultProps = {
    compareKey: 'id'
  }

  state = {
    assigned: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleAssignAll = this._handleAssignAll.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleUnassignAll = this._handleUnassignAll.bind(this)

  render() {
    if(!this.state.assigned) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-support-assignment">
          <Unassigned { ...this._getUnassigned() } />
          <Assigned { ...this._getAssigned() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { assigned } = this.props
    this.setState({ assigned })
  }

  _getAssigned() {
    const { entity, format } = this.props
    const { assigned } = this.state
    return {
      entity,
      format,
      assigned,
      onAssignAll: this._handleAssignAll,
      onRemove: this._handleRemove,
      onUnassignAll: this._handleUnassignAll
    }
  }

  _getPanel() {
    const { title } = this.props
    return {
      title,
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 't(Save)', handler: this._handleSave }
      ]
    }
  }

  _getUnassigned() {
    const { compareKey, entity, format, unassigned } = this.props
    const { assigned } = this.state
    return {
      assigned,
      compareKey,
      entity,
      format,
      unassigned,
      onAdd: this._handleAdd
    }
  }

  _handleAdd(item) {
    this.setState({
      assigned: [
        ...this.state.assigned,
        item
      ]
    })
  }

  _handleAssignAll() {
    const { unassigned } = this.props
    this.setState({
      assigned: unassigned
    })
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleRemove(index) {
    const { assigned } = this.state
    this.setState({
      assigned: assigned.filter((a, i) => {
        return i !== index
      })
    })
  }

  _handleSave() {
    const { action, compareKey, valueKey } = this.props
    const { assigned } = this.state
    this.context.network.request({
      endpoint: action,
      method: 'PATCH',
      body: {
        [valueKey]: assigned.map(item => {
          return _.get(item, compareKey)
        })
      },
      onSuccess: () => this.context.modal.close(),
      onFailure: () => this.context.flash.set('error', 't(Unable to save assignments)')
    })
  }

  _handleUnassignAll() {
    this.setState({
      assigned: []
    })
  }

}

const mapResources = (props) => ({
  assigned: props.assignedEndpoint,
  unassigned: props.unassignedEndpoint
})

export default Container(mapResources)(SupportTeams)