import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import Recipient from './recipient'
import React from 'react'

class Recipientsfield extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    users: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    defaultValue: PropTypes.array,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    recipients: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this.setState({
      recipients: defaultValue
    })
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { recipients } = this.state
    if(!_.isEqual(recipients, prevState.recipients)) {
      this.props.onChange(recipients)
    }
  }

  _getFormatted(number) {
    const phoneNumber = parsePhoneNumberFromString(number)
    return phoneNumber.formatNational()
  }

  _getList() {
    const { recipients } = this.state
    return {
      actions: (item, index) => [
        {
          svg: 'x',
          confirm: 't(Are you sure you want to remove this recipient?)',
          handler: this._handleRemove.bind(this, index)
        }
      ],
      items: recipients,
      empty: <Token text='t(You have not yet added any recipients)' />,
      format: (recipient) => <Token text={ this._getRecipient(recipient) } />
    }
  }

  _getAdd() {
    return {
      label: '+ t(add recipient)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getRecipient(recipient) {
    return recipient.user_id ? this._getUser(recipient.user_id) : this._getFormatted(recipient.number)
  }

  _getUser(id) {
    const { users } = this.props
    const user = _.find(users, { id })
    return user ? user.full_name : ''
  }

  _handleAdd() {
    const props = {
      onDone: this._handleCreate
    }
    this.context.form.push(Recipient, props)
  }

  _handleCreate(recipient) {
    const { recipients } = this.state
    this.setState({
      recipients: [
        ...recipients,
        recipient
      ]
    })
  }

  _handleRemove(index) {
    const { recipients } = this.state
    this.setState({
      recipients: recipients.filter((recipient, i) => {
        return i !== index
      })
    })
  }

  _handleValidate() {
    const { recipients } = this.state
    const { required, onValid } = this.props
    if(required && (!recipients || recipients.length === 0)) {
      return onValid(null, ['You must add at least 1 recipient'])
    }
    onValid(recipients)
  }

}

export default Recipientsfield
