import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Trigger from './trigger'
import Library from './library'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    params: PropTypes.object,
    program_id: PropTypes.number,
    triggers: PropTypes.array,
    trigger: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Workflow)',
      action: '/api/admin/truevail/agency/workflows',
      method: 'POST',
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { params, program_id, triggers, trigger } = this.props
    return {
      ...params || {},
      ...program_id ? { program_id } : {},
      ...triggers ? { triggers } : {},
      ...trigger ? { trigger } : {}
    }
  }

  _getData(workflow) {
    return _.omit(workflow, 'triggers')
  }

  _getSteps(formdata) {
    const { trigger } = this.props
    return [
      ...!trigger ? [
        { label: 't(Trigger)', component: Trigger }
      ] : [],
      { label: 't(Details)', component: Details },
      { label: 't(Library)', component: Library }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(workflow) {
    this.context.router.push(`/truevail/agency/workflows/${workflow.id}`)
    this.context.modal.close()
  }

}

export default New
