import ProgramForm from '@apps/crm/admin/components/programform'
import New from '@apps/campaigns/admin/views/social/new'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class SocialSchedule extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    onEdit: PropTypes.func,
    onUnschedule: PropTypes.func
  }

  state = {
    hover: false
  }

  _handleDragLeave = this._handleDragLeave.bind(this)
  _handleDragOver = this._handleDragOver.bind(this)
  _handleDrop = this._handleDrop.bind(this)

  render() {
    return (
      <div { ...this._getUnscheduled() }>
        <Search { ...this._getSearch() } />
      </div>
    )
  }

  _getSearch() {
    const { team } = this.context.admin
    const { endpoint, onEdit } = this.props
    return {
      endpoint,
      refresh: `/teams/${team.id}/admin/campaigns/social`,
      prompt: 't(Search unscheduled campaigns)',
      defaultQuery: {
        status: 'draft'
      },
      sort: {
        key: 'created_at',
        order: 'desc'
      },
      empty: {
        svg: 'messages_square',
        title: 't(Unscheduled Campaigns)',
        text: 't(There are currently no unscheduled social campaigns)',
        buttons: [
          { 
            label: 't(Create Social Campaign)', 
            access: { rights: { $oneOf: ['campaigns:manage_social_campaigns'] } },
            modal: <ProgramForm form={ New } />
          }
        ]
      },
      label: 'draft',
      valueKey: null,
      layout: Results,
      props: {
        onEdit
      }
    }
  }

  _getUnscheduled() {
    const { hover } = this.state
    const classes = ['campaigns-social-schedule-unscheduled']
    if(hover) classes.push('hover')
    return {
      className: classes.join(' '),
      onDragLeave: this._handleDragLeave,
      onDragOver: this._handleDragOver,
      onDrop: this._handleDrop
    }
  }

  _handleDragOver(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      hover: true
    })
  }

  _handleDragLeave(e) {
    e.preventDefault()
    e.stopPropagation()
    if(e.currentTarget.contains(e.relatedTarget)) return
    this.setState({
      hover: false
    })
  }

  _handleDrop(e) {
    e.preventDefault()
    const social_campaign = JSON.parse(e.dataTransfer.getData('social_campaign'))
    this.setState({
      hover: false
    })
    if(social_campaign.status !== 'scheduled') return
    this.props.onUnschedule(social_campaign)
  }

}

export default SocialSchedule
