import ProgramForm from '@apps/crm/admin/components/programform'
import NoteToken from '@apps/crm/admin/tokens/note'
import Panel from '@admin/components/panel'
import React from 'react'
import Edit from './edit'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Notes)',
  access: { rights: { $oneOf: ['crm:view_contacts','crm:manage_contacts'] } },
  collection: {
    endpoint: `/api/admin/crm/contacts/${props.contact.id}/notes`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Note)', key: 'text', primary: true, format: (note) => (
        <NoteToken note={ note } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'pencil',
      title: 't(No Notes)',
      text: 't(There are not yet any notes for this contact)',
      buttons: [
        {
          label: 't(Create Note)',
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <ProgramForm form={ New } props={{ contact:props.contact }} />
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Edit Note)',
        svg: 'square_pen',
        show: record.user.id === context.admin.user.id,
        modal: <Edit contact={ props.contact } note={ record } />
      },
      {
        label: 't(Delete Note)',
        svg: 'trash',
        show: record.user.id === context.admin.user.id,
        confirm: 't(Are you sure you want to delete this note?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/crm/contacts/${props.contact.id}/notes/${record.id}`,
          method: 'DELETE'
        }
      }
    ],
    entity: 'note'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Note)',
    access: { rights: { $oneOf: ['crm:manage_contacts'] } },
    modal: <ProgramForm form={ New } props={{ contact:props.contact }} />
  }
})

export default Panel(null, mapPropsToPanel)
