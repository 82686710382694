import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Columns extends React.Component {

  static propTypes = {
    columns: PropTypes.array,
    onChange: PropTypes.func,
    onToggleHidden: PropTypes.func
  }

  state = {
    show: false
  }

  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { columns } = this.props
    const { show } = this.state
    return (
      <td className="mobile config maha-table-layout-columns" onClick={ this._handleToggle }>
        <div className="maha-table-layout-columns-icon">
          <Icon svg="settings" />
        </div>
        { show &&
          <div className="maha-table-layout-columns-chooser" onMouseLeave={ this._handleToggle }>
            { columns.map((column, index) => (
              <div className="maha-table-layout-column" key={`column_${index}`} onClick={ this._handleToggleColumn.bind(this, column) }>
                <div className="maha-table-layout-column-label">
                  <T text={ column.label } />
                </div>
                <div className="maha-table-layout-column-icon">
                  { column.visible && !column.primary && <Icon svg="check" /> }
                  { column.primary && <Icon svg="lock" /> }
                </div>
              </div>
            ))}
          </div>
        }
      </td>
    )
  }

  _handleToggle(e) {
    this.setState({
      show: !this.state.show
    })
    e.stopPropagation()
  }

  _handleToggleColumn(column, e) {
    this.props.onToggleHidden(column.key)
    e.stopPropagation()
  }

}

export default Columns
