import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import DurationToken from '@admin/tokens/duration'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'


const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/email_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      { label: 't(Duration)', key: 'sending_duration', collapsing: true, align: 'right', format: (email_campaign) => (
        <DurationToken duration={ email_campaign.sending_duration } />
      ) },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['sending','sent','canceled'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 1, title: 't(Sending)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'sending' }
          ]
        } },
        { id: 2, title: 't(Sent)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'sent' }
          ]
        } },
        { id: 3, title: 't(Canceled)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'canceled' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(There are no email campaigns in the process of sending)'
    },
    recordTasks: (record) => [
      {
        label: 't(Promote Emails)',
        confirm: 't(Are you sure you want to bypass the queue and deliver these emails?)',
        request: {
          endpoint: `/api/admin/truevail/admin/deliverability/email_campaigns/${record.id}/promote`,
          method: 'PATCH'
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/deliverability/email_campaigns/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
