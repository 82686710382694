import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Transition from '../transition'
import PropTypes from 'prop-types'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

const types = {
  social: 't(Social Media)',
  email: 't(Email)',
  legacy_website: 't(Legacy Website)',
  website: 't(Website)'
}

const Overview = ({ agency, subscription }) => {

  const agency_button = {
    label: agency.team.title,
    className: 'link',
    route: `/truevail/agency/agencies/${agency.id}`
  }

  const unschedule = {
    label: 't(Unschedule Transition)',
    className: 'link',
    confirm: 't(Are you sure you want to unschedule this transition?)',
    request: {
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/subscriptions/${subscription.id}/transition`,
      method: 'PATCH',
      body: {
        action: 'unschedule'
      }
    }
  }

  const details = {
    ...subscription.deleted_at ? {
      alert: { color: 'red', message: 't(This subscription was deleted)' }
    } : subscription.status === 'active' ? {
      alert: { color: 'green', message: 't(This subscription is active)' }
    } : subscription.status === 'inactive' ? {
      alert: { color: 'grey', message: 't(This subscription is inactive)' }
    } : subscription.status === 'pending' ? {
      alert: { color: 'teal', message: 't(This subscription is pending)' }
    } : subscription.status === 'pending' ? {
      alert: { color: 'red', message: 't(This subscription is inactive)' }
    } : subscription.status === 'paused' ? {
      alert: { color: 'yellow', message: 't(This subscription is paused)' }
    } : subscription.status === 'activating' ? {
      alert: { color: 'teal', message: (
        <>This subscription will be activated on { moment(subscription.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : subscription.status === 'pausing' ? {
      alert: { color: 'teal', message: (
        <>This subscription will be paused on { moment(subscription.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : subscription.status === 'resuming' ? {
      alert: { color: 'teal', message: (
        <>This subscription will be resumed on { moment(subscription.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : subscription.status === 'reactivating' ? {
      alert: { color: 'teal', message: (
        <>This subscription will be reactivated on { moment(subscription.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : subscription.status === 'canceling' ? {
      alert: { color: 'teal', message: (
        <>This subscription will be canceled on { moment(subscription.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : {},
    audits: `truevail_subscriptions/${subscription.id}`,
    comments: `truevail_subscriptions/${subscription.id}`,
    sections: [
      {
        title: 't(Subscription Details)',
        items: [
          { label: 't(Agency)', content: <Button { ...agency_button } /> },
          { label: 't(Type)', content: types[subscription.type] },
          { label: 't(Channels)', content: subscription.channels.length > 0 ? subscription.channels.map(((channel, index) => (
            <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div>
          ))) : null }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  agency: PropTypes.object,
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agency={ props.agency } subscription={ props.subscription } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Subscription)', 
        svg: 'square_pen',
        modal: <Edit agency={ props.agency } subscription={ props.subscription } /> 
      },
      {
        label: 't(Activate Subscription)',
        svg: 'check',
        show: props.subscription.status === 'pending',
        modal: <Transition agency={ props.agency } subscription={ props.subscription } action="activate" />
      },
      {
        label: 't(Pause Subscription)',
        svg: 'circle_pause',
        show: props.subscription.status === 'active',
        modal: <Transition agency={ props.agency } subscription={ props.subscription } action="pause" />
      },
      {
        label: 't(Cancel Subscription)',
        svg: 'ban',
        show: props.subscription.status === 'active',
        modal: <Transition agency={ props.agency } subscription={ props.subscription } action="cancel" />
      },
      {
        label: 't(Resume Subscription)',
        svg: 'circle_pause',
        show: props.subscription.status === 'paused',
        modal: <Transition agency={ props.agency } subscription={ props.subscription } action="resume" />
      },
      {
        label: 't(Reactivate Subscription)',
        svg: 'check',
        show: props.subscription.status === 'inactive',
        modal: <Transition agency={ props.agency } subscription={ props.subscription } action="reactivate" />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)