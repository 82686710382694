import Search from '@admin/components/search'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React from 'react'

class Unassigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    compareKey: PropTypes.string,
    entity: PropTypes.string,
    format: PropTypes.any,
    unassigned: PropTypes.array,
    onAdd: PropTypes.func
  }

  render() {
    return (
      <div className="maha-support-assignment-unassigned">
        <Search { ...this._getSearch() } />
      </div>
    )    
  }

  _getSearch() {
    const { assigned, compareKey, entity, format, unassigned, onAdd } = this.props
    return {
      empty: {
        svg: 'users_round',
        title: `No ${_.startCase(pluralize(entity))}`,
        text: `There are no unassigned ${pluralize(entity)} that match your query`
      },
      options: unassigned.filter(unassigned_item => {
        return !assigned.find(item => {
          return _.get(item, compareKey) === _.get(unassigned_item, compareKey)
        })
      }),
      valueKey: null,
      textKey: 'title',
      entity: _.startCase(pluralize(entity)),
      onChoose: onAdd,
      format
    }
  }

}

export default Unassigned
