
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {

  static propTypes = {
    current: PropTypes.object,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onToday: PropTypes.func
  }

  render() {
    const { current } = this.props
    return (
      <div className="maha-calendar-header">
        <div className="maha-calendar-header-actions">
          <Button { ...this.getToday() } />
          <Button { ...this.getPrev() } />
          <Button { ...this.getNext() } />
        </div>
        <div className="maha-calendar-header-label">
          { current.format('MMMM YYYY')}
        </div>
      </div>
    )
  }

  getPrev() {
    return {
      svg: 'chevron_left',
      className: 'maha-calendar-header-action',
      tooltip: 't(Previous Month)',
      handler: this.props.onPrev
    }
  }

  getNext() {
    return {
      svg: 'chevron_right',
      className: 'maha-calendar-header-action',
      tooltip: 't(Next Month)',
      handler: this.props.onNext
    }
  }

  getToday() {
    return {
      svg: 'calendar_days',
      label: 't(Today)',
      tooltip: 't(Today)',
      className: 'maha-calendar-header-action',
      handler: this.props.onToday
    }
  }

}

export default Header
