import Searchbox from '@admin/components/searchbox'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Export from './export'
import React from 'react'

class Header extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    collectionActions: PropTypes.array,
    columns: PropTypes.array,
    criteria: PropTypes.object,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    defaultSort: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    filter: PropTypes.object,
    filtering: PropTypes.bool,
    filters: PropTypes.array,
    layout: PropTypes.string,
    layouts: PropTypes.array,
    query: PropTypes.object,
    search: PropTypes.bool,
    sort: PropTypes.object,
    tasks: PropTypes.object,
    onLayout: PropTypes.func,
    onRefresh: PropTypes.func,
    onSetQuery: PropTypes.func,
    onToggleFilter: PropTypes.func
  }

  render() {
    const { collectionActions, columns, criteria, filters, layouts, search, tasks } = this.props
    if(!filters && !columns && !search && !tasks) return null
    return (
      <div className="maha-collection-header">
        <div className="maha-collection-header-bar">
          { (filters || criteria) &&
            <Button { ...this._getFilter() } />
          }
          { search &&
            <Searchbox { ...this._getSearchbox() } />
          }
          { layouts.length > 1 &&
            <Button { ...this._getLayout() } />          
          }
          <Export { ...this._getExport() } />
          { collectionActions &&
            <>
              { collectionActions.map((action, index) => (
                <Button { ...this._getAction(action) } key={`action_${index}`} />
              ))}
            </>
          }
          <Button { ...this._getRefresh() } />
        </div>
        { tasks &&
          <Button { ...this._getTasks() } />
        }
      </div>
    )
  }

  _getAction(action) {
    const { entity, filter, sort } = this.props
    return {
      icon: action.icon,
      className: 'maha-collection-header-action',
      tooltip: action.tooltip ? {
        title: action.tooltip,
        position: 'bottom right'
      } : null,
      handler: action.handler.bind(this, { entity, filter, sort } )
    }
  }

  _getExport() {
    const { columns, defaultFilter, defaultQuery, defaultSort, endpoint, entity, query, filter, sort } = this.props
    return {
      columns,
      defaultFilter,
      defaultQuery,
      defaultSort,
      endpoint,
      entity,
      filter,
      query,
      sort: sort.key ? (sort.order === 'desc' ? '-' : '') + sort.key : null
    }
  }

  _getFilter() {
    const { filtering } = this.props
    return {
      svg: filtering ? 'x' : 'filter',
      className: 'maha-collection-header-action',
      handler: this.props.onToggleFilter,
      tooltip: {
        title: filtering ? 'Close Filter' : 'Filter Records',
        position: 'bottom left'
      }
    }
  }

  _getLayout() {
    const { layouts } = this.props
    const currentIndex = layouts.findIndex(layout => {
      return layout.value === this.props.layout
    })
    const nextIndex = (currentIndex + 1) % layouts.length
    const nextLayout = layouts[nextIndex]
    return {
      icon: nextLayout.icon,
      svg: nextLayout.svg,
      className: 'maha-collection-header-action',
      tooltip: {
        title: `${nextLayout.label} Layout`,
        position: 'bottom right'
      },
      handler: this.props.onLayout
    }
  }

  _getRefresh() {
    return {
      svg: 'refresh_cw',
      className: 'maha-collection-header-action',
      handler: this.props.onRefresh,
      tooltip: {
        title: 't(Reload Records)',
        position: 'bottom right'
      }
    }
  }

  _getSearchbox() {
    const { onSetQuery } = this.props
    return {
      onChange: onSetQuery
    }
  }

  _getTasks() {
    const { tasks } = this.props
    return {
      icon: tasks.icon,
      className: 'maha-collection-header-button',
      tasks: tasks.items
    }
  }

}

export default Header
