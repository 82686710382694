import FoldersUpdateBatch from '@apps/truevail/admin/components/folders_update_batch'
import TagsUpdateBatch from '@apps/truevail/admin/components/tags_update_batch'
import LibraryAddBatch from '@apps/truevail/admin/components/library_add_batch'
import LibraryAdd from '@apps/truevail/admin/components/library_add'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Workflows)',
  collection: {
    endpoint: '/api/admin/truevail/agency/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      { label: 't(Library)', key: 'library_status', sort: 'status', collapsing: true, align: 'center', format: LibraryToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      svg: 'workflow',
      title: 't(No Workflow)',
      text: 't(You have not yet created any workflows)',
      buttons: [
        { 
          label: 't(Create New Workflow)', 
          modal: New 
        }
      ]
    },
    entity: 'workflow',
    selectable: true,
    batch: {
      batch_type: 'truevail_workflows',
      tasks: [
        { 
          label: 't(Add to Library)', 
          svg: 'book_plus',
          action: 'publish', 
          component: <LibraryAddBatch entity="agency" /> 
        },
        { 
          label: 't(Remove from Library)', 
          svg: 'book_minus',
          action: 'unpublish', 
          config: { entity: 'agency' } 
        },
        { 
          label: 't(Update Folders)',
          svg: 'folder',
          action: 'folder', 
          component: <FoldersUpdateBatch entity="agency" />  
        },
        { 
          label: 't(Update Tags)',
          svg: 'tags',
          action: 'tag', 
          component: <TagsUpdateBatch entity="agency" />  
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Duplicate Workflow)',
        svg: 'copy',
        modal: <Clone workflow={ record } />
      },
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: record.status === 'draft',
        modal: <LibraryAdd scope="agency" entity="workflows" record={ record } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: record.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/agency/workflows/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished workflow)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish workflow)')
        }
      },
      {
        label: 't(Delete Workflow)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this workflow?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/workflows/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted workflow)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete workflow)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/workflows/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
