import FoldersUpdateBatch from '@apps/truevail/admin/components/folders_update_batch'
import TagsUpdateBatch from '@apps/truevail/admin/components/tags_update_batch'
import LibraryAddBatch from '@apps/truevail/admin/components/library_add_batch'
import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import SocialCampaignCard from '@apps/truevail/admin/cards/social_campaign'
import LibraryAdd from '@apps/truevail/admin/components/library_add'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social)',
  collection: {
    endpoint: '/api/admin/truevail/admin/social_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'title', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) },
      { label: 't(Variants)', key: 'variants_count', collapsing: true, align: 'right' },
      { label: 't(Publishings)', key: 'publishings_count', collapsing: true, align: 'right' },
      { label: 't(Library)', key: 'library_status', sort: 'status', collapsing: true, align: 'center', format: LibraryToken }
    ],
    card: (social_campaign) => (
      <SocialCampaignCard social_campaign={ social_campaign } />
    ),
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: StatusToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Social Campaigns)',
      text: 't(You have not yet created any social campaigns)',
      buttons: [
        { 
          label: 't(Create Social Campaign)', 
          modal: New 
        }
      ]
    },
    entity: 'social campaign',
    selectable: true,
    batch: {
      batch_type: 'truevail_social_campaigns',
      tasks: [
        { 
          label: 't(Add to Library)', 
          svg: 'book_plus',
          action: 'publish', 
          component: <LibraryAddBatch entity="admin" /> 
        },
        { 
          label: 't(Remove from Library)', 
          svg: 'book_minus',
          action: 'unpublish', 
          config: { entity: 'admin' } 
        },
        { 
          label: 't(Update Folders)',
          svg: 'folder',
          action: 'folder', 
          component: <FoldersUpdateBatch entity="admin" />  
        },
        { 
          label: 't(Update Tags)',
          svg: 'tags',
          action: 'tag', 
          component: <TagsUpdateBatch entity="admin" />  
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Duplicate Social Campaign)',
        svg: 'copy',
        modal: <Clone social_campaign={ record } />
      },
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: record.status === 'draft',
        modal: <LibraryAdd scope="admin" entity="social_campaigns" record={ record } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: record.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/admin/social_campaigns/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this social campaign from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this social campaign from the library)')
        }
      },
      {
        label: 't(Delete Social Campaign)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this social campaign?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/social_campaigns/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/social_campaigns/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Social Campaign)', 
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
