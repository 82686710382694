import AutoAssignToken from '@apps/truevail/admin/tokens/auto_assign'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object,
    user: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agency, user } = this.props
    return {
      title: 't(Edit Auto Assign)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/users/${user.id}/edit`,
      action: `/api/admin/truevail/admin/agencies/${agency.id}/users/${user.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'advisor_auto_assign', 
              type: 'radiogroup', options: [
                { value: false, text: 'no' },
                { value: true, text: 'yes' }
              ], 
              defaultValue: false, 
              format: ({ value }) => (
                <AutoAssignToken scope="agency" value={ value } />
              ) 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(advisor) {
    this.context.modal.close()
  }

}

export default Edit