import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Contact extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const contact = this.props.contact || {}
    return {
      title: 't(Contact)',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      cancelSvg: 'chevron_left',
      saveText: 'Done',
      sections: [
        {
          fields: [
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true, defaultValue: contact.first_name },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true, defaultValue: contact.last_name },
            { label: 't(Email)', name: 'email', type: 'emailfield', required: true, defaultValue: contact.email },
            { label: 't(Phone)', name: 'phone', type: 'phonefield', required: true, defaultValue: contact.phone },
            { label: 't(Address)', name: 'address', type: 'addressfield', required: true, defaultValue: contact.address }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleSuccess(contact) {
    this.props.onDone(contact)
  }

}

export default Contact
