import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Library from './library'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Social Batch)',
      method: 'POST',
      action: '/api/admin/truevail/admin/social_batches',
      getSteps: this._getSteps,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Library)', component: Library }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(social_batch) {
    this.context.router.push(`/admin/truevail/admin/social_batches/${social_batch.id}`)
    this.context.modal.close()
  }

}

export default New
