import CheckboxGroup from '@admin/components/form/checkboxgroup'
import StatusToken from '@admin/tokens/status'
import PropTypes from 'prop-types'
import React from 'react'

class ChannelMapField extends React.PureComponent {

  static propTypes = {
    channels: PropTypes.array,
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    subscriptions: PropTypes.array,
    to_agency_id: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { subscriptions } = this.props
    const { config } = this.state
    return (
      <div className="truevail-channelmapfield">
        { subscriptions.map((subscription, index) => (
          <div className="truevail-channelmapfield-subscription" key={`subscriptions_${index}`}>
            <div className="truevail-channelmapfield-subscription-header">
              <div className="truevail-channelmapfield-subscription-header-details">
                { subscription.type.toUpperCase() } - { subscription.channels.map(c => c.title).join(', ') }
              </div>
              <div className="truevail-channelmapfield-subscription-header-status">
                <StatusToken value={ subscription.status } />
              </div>
            </div>
            <div className="truevail-channelmapfield-subscription-body">
              <CheckboxGroup { ...this._getChannels(subscription) } />
            </div>
          </div>
        )) }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getChannels(subscription) {
    const { channels, to_agency_id } = this.props
    return {
      options: channels.filter(channel => {
        return channel.service === subscription.type && channel.agency_id === to_agency_id
      }).map(channel => ({
        value: channel.id,
        text: channel.title
      })),
      onChange: this._handleUpdate.bind(this, subscription.id)
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleUpdate(subscription_id, channel_ids) {
    const { config } = this.state
    this.setState({
      config: {
        ...config,
        [subscription_id]: channel_ids
      }
    })
  }

  _handleValidate() {
    const { config } = this.state
    this.props.onValid(config)
  }

}

export default ChannelMapField
