import Message from '@admin/components/message'
import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Options extends React.Component{

  static propTypes = {
    compareKey: PropTypes.string,
    empty: PropTypes.object,
    excludeIds: PropTypes.array,
    format: PropTypes.any,
    group: PropTypes.bool,
    layout: PropTypes.any,
    multiple: PropTypes.bool,
    options: PropTypes.any,
    props: PropTypes.object,
    selected: PropTypes.array,
    showCheck: PropTypes.bool,
    textKey: PropTypes.any,
    valueKey: PropTypes.any,
    onToggle: PropTypes.func
  }

  render() {
    const { empty, format, options, showCheck, textKey } = this.props
    const Layout = this.props.layout
    if(options.length == 0) return <Message { ...empty }/>
    if(Layout) return <Layout { ...this._getLayout() } />
    const grouped = this._getGrouped()
    return (
      <div className="maha-search-options">
        { Object.keys(grouped).map((group, i) => (
          <div key={`group_${i}`}>
            { group !== 'default' &&
              <div className="maha-search-results-header">
                <T text={ group } />
              </div>
            }
            { grouped[group].map((option, index) => (
              <div key={`filter_${option.id || index}`} className={ this._getClass(option) } onClick={ this._handleChoose.bind(this, option) }>
                <div className="maha-search-item-label">
                  <Format { ...option } option={ option } format={ format } value={ _.get(option, textKey) } />
                </div>
                <div className="maha-search-item-icon">
                  { showCheck && this._getChecked(option) &&
                    <Icon svg="check" />
                  }
                </div>
              </div>
            )) }
          </div>
        )) }
      </div>
    )
  }

  _getChecked(option) {
    const { selected, compareKey, valueKey } = this.props
    if(!selected) return false
    const key = compareKey || valueKey
    return selected.find(item => {
      return key ? _.get(option, key) === _.get(item, key) : _.isEqual(item, option)
    }) !== undefined
  }

  _getClass(option) {
    const classes = ['maha-search-item']
    if(this._getChecked(option)) classes.push('selected')
    return classes.join(' ')
  }

  _getLayout() {
    const { options, props } = this.props
    return {
      records: options,
      ...props
    }
  }

  _getGrouped() {
    const { excludeIds, group, options } = this.props
    return options.filter(option => {
      return !excludeIds || !_.includes(excludeIds, option.id)
    }).reduce((grouped, option) => ({
      ...grouped,
      ...group && option.group ? {
        [option.group]: [
          ...grouped[option.group] || [],
          option
        ]
      } : {
        default: [
          ...grouped.default || [],
          option
        ]
      }
    }), {})
  }

  _handleChoose(option, e) {
    e.stopPropagation()
    const { multiple } = this.props
    this.props.onToggle(multiple, option)
  }

}

export default Options
