import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class MoneyField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    placeholder: '0.00',
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    value: '',
    text: ''
  }

  _handleBlur = this._handleBlur.bind(this)
  _handleChange = _.throttle(this._handleChange.bind(this), 250, { trailing:  true })
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { text } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-icon">
          <Icon icon="dollar" />
        </div>
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
        </div>
        { text && text.length > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const classes = ['maha-input','maha-moneyfield']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused, text } = this.state
    return {
      disabled,
      id,
      tabIndex,
      className: 'ui input',
      type: 'textfield',
      placeholder: !focused ? '0.00' : null,
      value: text,
      onBlur: this._handleBlur,
      onChange: this._handleUpdate,
      onFocus: this._handleFocus
    }
  }

  _getRawValue(value) {
    const raw = !_.isNil(value) ? `${value}` : ''
    return raw.length > 0 ? numeral(raw).format('0.00') : ''
  }

  _getReturnValue(value) {
    return value.length > 0 ? Number(value) : null
  }

  _handleBlur() {
    const { text } = this.state
    const value = this._getRawValue(text)
    this.setState({
      focused: false,
      text: value,
      value
    })
  }

  _handleChange() {
    const value = this._getReturnValue(this.state.value)
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      text: '',
      value: ''
    })
  }

  _handleFocus() {
    this.setState({
      focused: true
    })
  }

  _handleSet(val) {
    const value = this._getRawValue(val)
    this.setState({ value, text: value })
  }

  _handleUpdate(text) {
    if(!text.match(/^-?\d*\.?\d{0,2}$/)) return
    this.setState({ text })
  }

  _handleValidate() {
    const { min, max, required } = this.props
    const value = this._getReturnValue(this.state.value)
    if(required === true && value === '') {
      this.props.onValid(value, ['This field is required'])
    } else if(min !== undefined && Number(value) < min) {
      this.props.onValid(value, [`This field must be greater than or equal to  ${min}`])
    } else if(max !== undefined && Number(value) > max) {
      this.props.onValid(value, [`This field must be less than or equal to ${max}`])
    } else {
      this.props.onValid(value)
    }
  }

}

export default MoneyField
