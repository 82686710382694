import ModalPanel from '@admin/components/modal_panel'
import Voicemail from './voicemail'
import PropTypes from 'prop-types'
import Contacts from './contacts'
import Dialer from './dialer'
import Calls from './calls'
import React from 'react'
import Tabs from './tabs'
import SMS from './sms'

class Phone extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    phone_numbers: PropTypes.array,
    phone_number: PropTypes.object,
    onClose: PropTypes.func,
    onPhoneNumber: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  state = {
    receipts: {}
  }

  _handleClose = this._handleClose.bind(this)
  _handleFetch = this._handleFetch.bind(this)

  render() {
    if(!this.props.phone_number) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <Tabs { ...this._getTabs() } />
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { phone_number } = this.props
    this._handleFetch()
    this._handleJoin(phone_number)
  }

  componentWillUnmount() {
    const { phone_number } = this.props
    this._handleLeave(phone_number)
  }

  _getPanel() {
    return {
      title: 't(Phone)',
      leftItems: [
        { svg: 'x', handler: this._handleClose, tooltip: {
          title: 't(Close Phone)',
          position: 'bottom left'
        } }
      ]
    }
  }

  _getProps() {
    const { phone_number, onPop, onPush } = this.props
    return {
      phone_number,
      onPop,
      onPush
    }
  }

  _getTabs() {
    const { receipts } = this.state
    const { phone_numbers, phone_number, onPhoneNumber } = this.props
    return {
      phone_numbers,
      phone_number,
      tabs: [
        { svg: 'phone', label: 't(Calls)', component: Calls, count: receipts.missed_calls, props: this._getProps.bind(this) },
        { svg: 'messages_square', label: 't(SMS)', component: SMS, count: receipts.unread_messages, props: this._getProps.bind(this) },
        { svg: 'user_round', label: 't(Contacts)', component: Contacts, props: this._getProps.bind(this) },
        { icon: 'th', label: 'Keypad', component: Dialer, props: this._getProps.bind(this) },
        { icon: 'voicemail', label: 'Voicemail', component: Voicemail, count: receipts.unheard_voicemails, props: this._getProps.bind(this) }
      ],
      onPhoneNumber
    }
  }

  _handleClose() {
    this.props.onClose()
  }

  _handleFetch() {
    const { phone_number } = this.props
    this.context.network.request({
      endpoint: `/api/admin/phone/numbers/${phone_number.id}/receipts`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          receipts: data
        })
      }
    })
  }

  _handleJoin(phone_number) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.subscribe({ 
      channel: `/teams/${team.id}/admin/phone/numbers/${phone_number.id}/receipts`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

  _handleLeave(phone_number) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}/admin/phone/numbers/${phone_number.id}/receipts`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

}

export default Phone
