import Dealboard from '@apps/sales/admin/components/dealboard'
import New from '@apps/sales/admin/views/deals/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Deals)',
  component: <Dealboard fields={ props.fields} pipeline={ props.pipeline } />,
  task: {
    svg: 'plus',
    modal: <New fields={ props.fields} pipeline={ props.pipeline } />
  }
})

export default Panel(null, mapPropsToPanel)
