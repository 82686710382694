import SubscriptionProfilesToken from '@apps/truevail/admin/tokens/subscription_profiles'
import SchedulingToken from '@apps/truevail/admin/tokens/scheduling'
import TimeframeToken from '@apps/truevail/admin/tokens/timeframe'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const types = {
  social: 't(Social Media)',
  email: 't(Email)',
  legacy_website: 't(Legacy Website)',
  website: 't(Website)'
}

const Overview = ({ subscription }) => {

  const sender = subscription.sender ? {
    label: subscription.sender.rfc822,
    className: 'link',
    route: `/team/senders/${subscription.sender.id}`
  } : null

  const list = subscription.list ? {
    label: subscription.list.title,
    className: 'link',
    route: `/crm/lists/${subscription.list.id}`
  } : null

  const email_template = subscription.email_template ? {
    label: subscription.email_template.title,
    className: 'link',
    route: `/team/templates/${subscription.email_template.id}`
  } : null

  const url = subscription.url ? {
    label: subscription.url,
    className: 'link',
    link: subscription.url
  } : null

  const login_url = subscription.login_url ? {
    label: subscription.login_url,
    className: 'link',
    link: subscription.login_url
  } : null

  const details = {
    comments: `truevail_subscriptions/${subscription.id}`,
    sections: [
      {
        title: 't(Subscription Details)',
        items: [
          { label: 't(Type)', content: types[subscription.type] },
          ...subscription.type === 'email' ? [
            { label: 't(Sender)', content: subscription.sender ? <Button { ...sender } /> : null },
            { label: 't(List)', content: subscription.list ? <Button { ...list } /> : null },
            { label: 't(Email Template)', content: subscription.email_template ? <Button { ...email_template } /> : null },
            { label: 't(Scheduling)', content: <SchedulingToken value={ subscription.scheduling } /> }
          ] : subscription.type === 'social' ? [
            { label: 't(Profiles)', content: subscription.subscription_profiles.length > 0 ? <SubscriptionProfilesToken subscription_profiles={ subscription.subscription_profiles } /> : empty },
            { label: 't(Scheduling)', content: <SchedulingToken value={ subscription.scheduling } /> },
            { label: 't(Scheduling Timeframe)', content: <TimeframeToken value={ subscription.scheduling_timeframe } /> }
          ] : subscription.type === 'legacy_website' ? [
            { label: 't(Url)', content: subscription.url ? <Button { ...url } /> : null },
            { label: 't(Login Url)', content: subscription.login_url ? <Button { ...login_url } /> : null }
          ] : subscription.type === 'website' ? [
            { label: 't(Post Author)', content: subscription.post_author ? subscription.post_author.full_name : null },
            { label: 't(Post Template)', content: subscription.post_template ? subscription.post_template.title : null },
            { label: 't(Scheduling)', content: <SchedulingToken value={ subscription.scheduling } /> }
          ] : [],
          ...subscription.type !== 'legacy_website' ? [
            { label: 't(Channels)', content: subscription.channels.length > 0 ? subscription.channels.map(((channel, index) => (
              <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div>
            ))) : null }
          ] : []
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object,
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview subscription={ props.subscription } />,
  tasks: {
    show: props.subscription.type !== 'legacy_website',
    items: [
      { 
        label: 't(Edit Subscription)',
        svg: 'square_pen', 
        modal: <Edit subscription={ props.subscription } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)