import Toolbar from '@admin/components/toolbar'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.PureComponent {

  static propTypes = {
    darkMode: PropTypes.bool,
    device: PropTypes.string,
    devices: PropTypes.array,
    editable: PropTypes.bool,
    images: PropTypes.bool,
    orientation: PropTypes.string,
    settings: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.object,
    versionable: PropTypes.bool,
    website: PropTypes.object,
    onAction: PropTypes.func,
    onDark: PropTypes.func,
    onDevice: PropTypes.func,
    onImages: PropTypes.func,
    onNavigate: PropTypes.func,
    onOrientation: PropTypes.func,
    onSettings: PropTypes.func
  }

  render() {
    return (
      <div className="mjson-designer-header">
        <Toolbar { ...this._getToolbar() } />
      </div>
    )
  }

  _getToolbar() {
    const { darkMode, device, devices,images, orientation, editable, type, versionable, version, website } = this.props
    const { onDark, onOrientation, onImages } = this.props
    return {
      leftButtons: editable ? [
        {
          svg: 'file_text',
          tooltip: 't(Content)',
          show: website !== undefined,
          handler: this._handleSettings.bind(this, 'navigation')
        },
        {
          svg: 'copy',
          tooltip: 't(Reusable Content)',
          show: website !== undefined,
          handler: this._handleSettings.bind(this, 'settings')
        },
        {
          svg: 'image',
          tooltip: 't(Media)',
          show: website !== undefined,
          handler: this._handleSettings.bind(this, 'assets')
        }
      ] : [],
      centerButtons: [
        ...devices.map(({ value, label }, index) => ({
          tooltip: label,
          active: value === device,
          label,
          handler: this._handleDevice.bind(this, value)
        })),
        {
          svg: 'image',
          tooltip: images ? 't(Hide Images)' : 't(Show Images)',
          null: !images,
          handler: onImages
        },
        {
          svg: darkMode ? 'moon' : 'sun',
          tooltip: 't(Color Mode)',
          handler: onDark
        },
        {
          svg: 'smartphone',
          tooltip: 't(Orientation)',
          disabled: device === 'desktop',
          rotated: orientation === 'landscape',
          handler: onOrientation
        }
      ],
      rightButtons: [
        ...editable ? [
          {
            svg: 'rotate_ccw',
            tooltip: 't(Undo)',
            disabled: version.undo.length === 0,
            handler: this._handleAction.bind(this, 'undo')
          },
          {
            svg: 'rotate_cw',
            tooltip: 't(Redo)',
            disabled: version.redo.length === 0,
            handler: this._handleAction.bind(this, 'redo')
          }
        ] : [],
        ...type === 'email' ? [
          {
            label: 't(Send Preview)',
            tooltip: 't(Send Preview)',
            handler: this._handleAction.bind(this, 'preview')
          }
        ] : [
          {
            label: 't(Preview)',
            tooltip: 't(Preview)',
            handler: this._handleAction.bind(this, 'preview')
          }
        ],
        ...(editable && versionable) ? [
          {
            label: 't(Publish)',
            tooltip: 't(Publish)',
            disabled: version.record.is_published,
            filled: version.record.is_published === false,
            handler: this._handleAction.bind(this, 'publish')
          }            
        ] : []
      ]
    }
  }

  _handleAction(action, data) {
    this.props.onAction(action, data)
  }

  _handleDevice(device) {
    this.props.onDevice(device)
  }

  _handleSettings(settings) {
    this.props.onSettings(settings)
  }

}

export default Header
