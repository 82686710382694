import ProgramForm from '@apps/crm/admin/components/programform'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Export from '@apps/finance/admin/components/export'
import ProgramToken from '@apps/crm/admin/tokens/program'
import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import New from '../invoices/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Invoices)',
  access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
  collection: {
    endpoint: '/api/admin/finance/invoices',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'customer.display_name', sort: 'customer', primary: true, format: ({ customer }) => {
        return <ContactToken { ...customer } />
      } },
      { label: 't(Program)', key: 'program.title', sort: 'program' },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(Total)', key: 'total', collapsing: true, primary: true, format: 'currency'  },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken  }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 'Contact', key: 'contact_id', type: 'select', endpoint: '/api/admin/crm/contacts', multiple: true, valueKey: 'id', textKey: 'full_name', format: ContactToken },
            { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', multiple: true, valueKey: 'id', textKey: 'title', format: ProgramToken },
            { name: 'Project', key: 'project_id', type: 'select', endpoint: '/api/admin/finance/projects', multiple: true, valueKey: 'id', textKey: 'display', format: ProjectToken },
            { name: 'Event', key: 'event_id', type: 'select', endpoint: '/api/admin/events/events', multiple: true, valueKey: 'id', textKey: 'title' },
            { name: 'Form', key: 'form_id', type: 'select', endpoint: '/api/admin/forms/forms', multiple: true, joined: true, valueKey: 'id', textKey: 'title' },
            { name: 'Store', key: 'store_id', type: 'select', endpoint: '/api/admin/stores/stores', multiple: true, joined: true, valueKey: 'id', textKey: 'title' },
            { name: 'Service', key: 'store_id', type: 'select', endpoint: '/api/admin/subscriptions/services', joined: true, multiple: true, valueKey: 'id', textKey: 'title' },
            { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] },
            { name: 'Status', key: 'status', type: 'select', multiple: true, options: ['paid','unpaid','void'], format: StatusToken  }
          ]
        }
      ]
    },
    collectionActions: [
      { icon: 'table', tooltip: 'Download Invoices', handler: ({ filter }) => {
        context.modal.open(<Export filter={ filter } />)
      }  }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/invoices/${record.id}`),
    empty: {
      svg: 'dollar_sign',
      title: 't(No Invoice)',
      text: 't(You have not yet created any invoices)',
      buttons: [
        { 
          label: 't(Create Invoice)', 
          access: { rights: { $oneOf: ['finance:manage_revenue'] } },
          modal: <ProgramForm form={ New } /> 
        }
      ]
    },
    entity: 'invoice'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Invoice)', 
    access: { rights: { $oneOf: ['finance:manage_revenue'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
