import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import Extension from './extension'
import PropTypes from 'prop-types'
import React from 'react'

class Extensionsfield extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    extensions: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this.setState({
      extensions: defaultValue
    })
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { extensions } = this.state
    if(!_.isEqual(extensions, prevState.extensions)) {
      this.props.onChange(extensions)
    }
  }

  _getAdd() {
    return {
      label: '+ t(add extension)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getList() {
    const { extensions } = this.state
    return {
      actions: (item, index) => [
        {
          svg: 'pencil',
          handler: this._handleEdit.bind(this, item, index)
        },
        {
          svg: 'x',
          confirm: 't(Are you sure you want to remove this extension?)',
          handler: this._handleRemove.bind(this, index)
        }
      ],
      items: extensions,
      empty: <Token text='t(You have not yet added any extensions)' />,
      format: (extension) => (
        <Token>
          <span className="crm-recipientsfield-recipient-extension">
            { extension.extension }
          </span>
          { extension.name }
        </Token>
      )
    }
  }

  _getNew() {
    return {
      mode: 'new',
      onDone: this._handleCreate
    }
  }

  _getEdit(extension, index) {
    return {
      extension,
      mode: 'edit',
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _handleAdd() {
    this.context.form.push(Extension, this._getNew())
  }

  _handleCreate(extension) {
    const { extensions } = this.state
    this.setState({
      extensions: [
        ...extensions,
        extension
      ]
    })
  }

  _handleEdit(extension, index) {
    this.context.form.push(Extension, this._getEdit(extension, index))
  }

  _handleRemove(index) {
    const { extensions } = this.state
    this.setState({
      extensions: extensions.filter((extension, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(i, updated) {
    const { extensions } = this.state
    this.setState({
      extensions: extensions.map((extension, index) => {
        return index === i ? updated : extension
      })
    })
  }

  _handleValidate() {
    const { extensions } = this.state
    const { required, onValid } = this.props
    if(required && (!extensions || extensions.length === 0)) {
      return onValid(null, ['You must add at least 1 extension'])
    }
    onValid(extensions)
  }

}

export default Extensionsfield
