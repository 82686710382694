import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const services = {
  braintree: 'Braintree',
  facebook: 'Facebook',
  forwardtofriend: 't(Forward to Friend)',
  instagram: 'Instagram',
  instagram_business: 'Instagram for Business',
  linkedin: 'LinkedIn',
  medium: 'Medium',
  pinterest: 'Pinterest',
  snapchat: 'Snapchat',
  stripe: 'Stripe',
  website: 'Website',
  tiktok: 'TikTok',
  twitter: 'Twitter',
  vimeo: 'Vimeo',
  youtube: 'YouTube',
  x: 'X'
}

const ServiceToken = ({ value }) => (
  <div className="service-token">
    <div className="service-token-icon">
      <Img src={`/images/services/${value}.png`} />
    </div>
    <div className="service-token-details">
      <T text={ services[value] } />
    </div>
  </div>
)

ServiceToken.propTypes = {
  value: PropTypes.string
}

export default ServiceToken
