import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Mapping from './mapping'
import React from 'react'

class Mappings extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    metadata: PropTypes.object,
    object_type: PropTypes.string,
    parsed: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  state = {
    mappings: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleMap = this._handleMap.bind(this)
  _handleMapping = this._handleMapping.bind(this)

  render() {
    const { mappings } = this.state
    if(!mappings) return <Loader />
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="import-mappings">
          <table className="ui unstackable table">
            <thead>
              <tr>
                <th>Column</th>
                <th>Field</th>
                <th className="collapsing"></th>
                <th className="collapsing"></th>
              </tr>
            </thead>
            <tbody>
              { mappings.map((mapping, index) => (
                <tr key={`item_${index}`}>
                  <td>
                    { typeof mapping.header === 'number' ? `Column ${mapping.header + 1}` : (mapping.header || 'NO HEADER') }
                  </td>
                  <td>
                    { mapping.field ? this._getFieldLabel(mapping.field) : <span className="alert">UNMAPPED</span> }
                  </td>
                  <td><Button { ...this._getEdit(mapping) } /></td>
                  <td><Button { ...this._getRemove(index) } /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleInitialMappings()
  }

  _getDefaultMapping(header, name) {
    const fields = this._getFields()
    const field = _.find(fields, { name })
    if(!field) return { header, field: null, type: null }
    return {
      header,
      field: field ? name : null,
      type: field ? field.type : null
    }
  }

  _getEdit(mapping) {
    return {
      svg: 'pencil',
      className: '',
      handler: this._handleMapping.bind(this, mapping)
    }
  }

  _getFieldMap() {
    const fields = this._getFields()
    return fields.reduce((fieldmap, field) => [
      ...fieldmap,
      ...field.matches ? [{ name: field.name, matches: field.matches }] : [],
      ...field.regex ? [{ name: field.name, regex: field.regex }] : []
    ], [])
  }

  _getFields() {
    const { fields } = this.props.metadata
    return fields.reduce((fields, segment) => [
      ...fields,
      ...segment.fields ? segment.fields : [segment]
    ], [])
  }

  _getFieldLabel(name) {
    const fields = this._getFields()
    const field = _.find(fields, { name })
    return <T text={ field.label } />
  }

  _getMapped() {
    const { mappings } = this.state
    return mappings.filter(mapping => {
      return mapping.field !== null
    })
  }

  _getMapping(mapping) {
    const { metadata, onPop } = this.props
    const { mappings } = this.state
    const { fields } = metadata
    return {
      fields,
      mappings,
      mapping,
      onBack: onPop,
      onDone: this._handleMap.bind(this, mapping.header)
    }
  }

  _getPanel() {
    const mapped = this._getMapped()
    return {
      title: 't(Map Columns)',
      instructions: 't(In order to translate your data into valid records, you must first map the columns from your data to fields inour database. We\'ve done our best to guess the mapping based upon the column names. Please map as many columns as you can below (data from unmapped columns will be ignored))',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: mapped.length > 0 ? [
        { label: 't(Next)', handler: this._handleDone }
      ] : null
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: '',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone() {
    const { _import } = this.props
    const { mappings } = this.state
    this.props.onDone({
      ..._import,
      config: {
        ..._import.config,
        mappings
      }
    })
  }

  _handleInitialMappings() {
    const { parsed } = this.props
    const fieldmap = this._getFieldMap()
    const mappings = parsed.headers.map(header => {
      if(!header || _.isInteger(header)) return { header, field: null, type: null }
      const text = header.replace(/[^A-Za-z0-9]/g, '').toLowerCase().replace(/^(contact|customer|user)/g,'')
      const field = fieldmap.find(item => {
        const term = item.matches ? item.matches.find(match => {
          return text === match
        }) !== undefined : false
        if(term) return true
        const regex = item.regex ? new RegExp(item.regex).test(text) : false
        if(regex) return true
        return false
      })
      const name = field ? field.name : null
      return this._getDefaultMapping(header, name)
    })
    this.setState({ mappings })
  }

  _handleMap(key, value) {
    const { mappings } = this.state
    this.setState({
      mappings: [
        ...mappings.map(mapping => ({
          ...mapping,
          ...mapping.header === key ? value : {}
        }))
      ]
    })
    this.props.onPop()
  }

  _handleMapping(mapping) {
    this.props.onPush(Mapping, this._getMapping(mapping))
  }

  _handleRemove(index) {
    const { mappings } = this.state
    this.setState({
      mappings: [
        ...mappings.map((mapping, i) => {
          return i == index ? {
            field: null,
            header: mapping.header,
            type: null
          } : mapping
        })
      ]
    })
  }

}

const mapResources = (props, context) => ({
  metadata: `/api/admin/${props.object_type}/imports/metadata`
})

export default Container(mapResources)(Mappings)
