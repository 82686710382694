import Preview from '@apps/truevail/admin/components/preview'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = (props) => {

  const { publishing } = props

  const advisor = {
    label: publishing.subscription.advisor.name,
    className: 'link',
    route: `/admin/truevail/agency/advisors/${publishing.subscription.advisor.id}`
  }

  const social_batch = props.social_batch ? {
    label: props.social_batch.title,
    className: 'link',
    route: `/admin/truevail/agency/social_batches/${props.social_batch.id}`
  } : null

  const details = {
    sections: [
      {
        title: 't(Publishing Details)',
        items: [
          { label: 't(Advisor)', content: <Button { ...advisor } /> },
          { label: 't(Social Batch)', content: social_batch ? <Button { ...social_batch } /> : null },
        ]
      }
    ]
  }

  if(publishing.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social batch was deleted)' }
  } else if(publishing.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social batch is not available in the library)' }
  } else if(publishing.status === 'published') {
    details.alert = { color: 'green', message: 't(This social batch is available in the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  publishing: PropTypes.object,
  social_batch: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview publishing={ props.publishing } social_batch={ props.social_batch } />,
  tasks: {
    items: [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        modal: <Preview publishing={ props.publishing } scope="agency" type="social" />
      },
      {
        label: 't(Unschedule Social Campaigns)',
        svg: 'clock',
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings/${props.publishing.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished social campaigns)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaigns)')
        }
      },
      {
        label: 't(Delete Social Campaigns)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete these social campaigns?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publishings/${props.publishing.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaigns)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaigns)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
