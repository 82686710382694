import OrganizerToken from '../../tokens/organizer'
import Page from '@admin/components/page'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Organizers)',
  collection: {
    endpoint: '/api/admin/events/organizers',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: OrganizerToken }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'user_round',
      title: 't(No Organizers)',
      text: 't(You have not yet created any organizers)',
      buttons: [
        { label: 'Create Organizer', modal: New }
      ]
    },
    recordTasks: (organizer) => [
      { label: 'Edit Organizer', modal: <Edit organizer={ organizer } /> }
    ],
    entity: 'event'
  },
  task: {
    svg: 'plus',
    modal: New
  }
})

export default Page(null, mapPropsToPage)
