import Dependencies from '@admin/components/dependencies'
import Attachments from '@admin/components/attachments'
import ReactQuill, { Quill } from 'react-quill'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import colors from '@core/utils/colors'
import PropTypes from 'prop-types'
import React from 'react'

const color = colors.map(color => color.hex)

const getIcon = (icon, tooltip) => {
  return `<div data-tooltip="${tooltip}" data-inverted="true"><i class="fa fa-${icon}"></i></span>`
}

const icons = Quill.import('ui/icons')
icons.color = getIcon('font', 'Color')
icons.bold = getIcon('bold', 'Bold')
icons.italic = getIcon('italic', 'Italic')
icons.underline = getIcon('underline', 'Underline')
icons.list = {
  ordered: getIcon('list-ol', 'Ordered List'),
  bullet: getIcon('list-ul', 'Unordered List')
}
icons.link = getIcon('link', 'Insert Link')
icons.attach = getIcon('paperclip', 'Attach File')

class Composer extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    autofocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    reference: PropTypes.func,
    inline: PropTypes.bool,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func
  }

  state = {
    attachments: [],
    html: ''
  }

  quillRef = React.createRef()

  _handleAttach = this._handleAttach.bind(this)
  _handleAttachments = this._handleAttachments.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { attachments } = this.state
    return (
      <div className="email-composer">
        <div className="email-composer-body">
          <ReactQuill { ...this._getEditor() } />
        </div>
        { attachments.length > 0 &&
          <div className="email-composer-attachments">
            { attachments.map((asset, index) => (
              <div className="email-composer-attachment" key={`attachment_${index}`}>
                <Button { ...this._getRemove(index) } />
                <AssetToken { ...asset } shareable={ false } />
              </div>
            ))}
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { autofocus, reference, tabIndex } = this.props
    const { root } = this.quillRef.current.getEditor()
    if(autofocus) root.focus()
    if(tabIndex) root.tabIndex = tabIndex
    if(reference) {
      reference({
        set: this._handleSet
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(this.state, prevState)) {
      this._handleChange()
    }
  }

  _getAttach() {
    return {
      icon: 'paperclip',
      className: 'email-composer-footer-action',
      handler: this._handleAttach
    }
  }

  _getAttachments() {
    return {
      multiple: true,
      prompt: 'Upload File(s)',
      onChooseAssets: this._handleAttachments,
      onBack: this._handleBack
    }
  }

  _getEditor() {
    const { defaultValue, tabIndex } = this.props
    return {
      ref: this.quillRef,
      defaultValue,
      tabIndex,
      modules: {
        toolbar: {
          container: [
            [{ color },'bold','italic','underline', { list: 'ordered' }, { list: 'bullet' },'link','attach']
          ],
          handlers: {
            attach: this._handleAttach,
            template: this._handleTemplate
          }
        }
      },
      onChange: this._handleUpdate
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: 'icon',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleAttach() {
    this.context.modal.push(<Attachments { ...this._getAttachments() } />)
  }

  _handleAttachments(assets) {
    const { attachments } = this.state
    this.setState({
      attachments: [
        ...attachments,
        ...assets
      ]
    })
  }

  _handleBack() {
    this.context.modal.pop()
  }

  _handleChange() {
    const { attachments, html } = this.state
    this.props.onChange({
      attachments: attachments.map(asset => ({
        asset_id: asset.id
      })),
      html
    })
  }

  _handleRemove(index) {
    const { attachments } = this.state
    this.setState({
      attachments: attachments.filter((attachment, i) => {
        return i !== index
      })
    })
  }

  _handleSet(html) {
    this.setState({
      html: html.replace(/&lt;%/g, '<%').replace(/%&gt;/g, '%>').replace(/<\/p>(\n)?/g, '</p>\n')
    })
    const editor = this.quillRef.current.getEditor()
    this.quillRef.current.setEditorContents(editor, html)
  }

  _handleUpdate(html) {
    this.setState({
      html: html.replace(/&lt;%/g, '<%').replace(/%&gt;/g, '%>').replace(/<\/p>(\n)?/g, '</p>\n')
    })
  }

}

const dependencies = {
  styles: [
    { url: '/css/quill.snow.css' }
  ]
}

Composer = Dependencies(dependencies)(Composer)

export default Composer
