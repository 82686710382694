import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    template: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { template } = this.props
    return {
      title: 't(Duplicate Template)',
      action: `/api/admin/team/templates/${template.id}/clone`,
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Title)', 
              name: 'title', 
              type: 'textfield', 
              required: true, 
              defaultValue: `Copy of ${template.title}` 
            },
            { 
              label: 't(Theme)', 
              name: 'theme_id', 
              type: 'dropdown', 
              endpoint: '/api/admin/team/themes', 
              valueKey: 'id', 
              textKey: 'title', 
              required: true, 
              selectFirst: true, 
              defaultValue: template.theme.id
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.router.push(`/team/templates/${result.id}`)
    this.context.modal.close()
  }

}

export default Clone
