import SocialCampaignToken from '@apps/campaigns/admin/tokens/campaign/social'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/social_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'advisor.title', sort: 'advisor', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) },
      { label: 't(Preview)', key: 'publishing.preview_status', collapsing: true, align: 'center', format: (social_campaign) => {
        return social_campaign.publishing?.social_campaign_id  ? <StatusToken status={ social_campaign.publishing.preview_status } /> : null
      } }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Date Range)', key: 'post_at', type: 'daterange', include: ['day','this','last','next'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['scheduled','posting','posted'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Posting Today)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'today' }
          ]
        } },
        { id: 1, title: 't(Posted Yesterday)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'yesterday' }
          ]
        } },
        { id: 2, title: 't(Stuck Posting)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'posting' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'post_at', order: 'desc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Social Campaigns)',
      text: 't(There are no published social campaigns for this advisor)'
    },
    entity: 'social campaign',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        show: ['draft','scheduled'].includes(record.status) && record.publishing?.social_campaign_id ,
        modal: <Preview publishing={ record.publishing } scope="agency" type="social" />
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/advisors/${props.advisor.id}/social_campaigns/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
