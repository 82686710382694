import Assignment from '@admin/components/assignment'
import UserToken from '@admin/tokens/user'
import PropTypes from 'prop-types'
import React from 'react'

class Users extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    role_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Assignment { ...this._getAssignment() } />
  }

  _getAssignment() {
    const { role_id } = this.props
    return {
      title: 't(Manage Users)',
      action: `/api/admin/team/roles/${role_id}/users`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        assignments: assigned.map(assignee => ({
          user_id: assignee.id,
          role: assignee.role
        }))
      }),
      assigneeFormat: (user) => <UserToken { ...user }  />,
      assignedEndpoint: `/api/admin/team/roles/${role_id}/users`,
      assignedFilter: (assigned) => assigned.map(assignee => ({
        ...assignee.user
      })),
      empty: {
        svg: 'users_round',
        title: 't(Add users)',
        text: 't(Please add users to this role)'
      },
      unassignedEndpoint: '/api/admin/users',
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.full_name.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          return assignment.id === assignee.id
        }) === undefined
      },
      onCancel: this._handleCancel, 
      onDone: this._handleDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Users
