import Page from '@admin/components/page'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title:  `Transitions from ${props.query.from} to ${props.query.to}`,
  access: { rights: { $allOf: ['dashboards:access_app','truevail:access_app'] } },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/transition_durations',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Team)', key: 'team.title', sort: 'team', primary: true, format: (team) => <TeamToken { ...team } /> },
      { label: 't(Days)', key: 'duration', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'days', order: 'asc' },
    empty: {
      svg: 'square_check',
      title: 't(No Signups)',
      text: 't(No one has signed up yet)'
    },
    entity: 'signup',
    onClick: (record) => {
      if(record.type === 'agency') context.router.push(`/admin/truevail/admin/agencies/${record.agency_id}`)
      if(record.type === 'advisor') context.router.push(`/admin/truevail/admin/advisors/${record.advisor_id}`)
      if(record.type === 'supplier') context.router.push(`/admin/truevail/admin/suppliers/${record.supplier_id}`)
    }
  }
})

export default Page(null, mapPropsToPage)
