import FlowchartDesigner from '@admin/components/flowchart_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Designer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    programfields: PropTypes.array,
    workflow: PropTypes.object
  }

  render() {
    return <FlowchartDesigner { ...this._getFlowchartDesigner() } />
  }

  _getFlowchartDesigner() {
    const { workflow } = this.props
    const { admin } = this.context
    const { steps, status } = workflow
    return {
      editable: workflow.editable && canAccess({ 
        programs: { $canEdit: workflow.program.id },
        rights: { $allOf: ['automation:manage_workflows'] } 
      }, admin),
      endpoint: `/automation_workflows/${workflow.id}/config`,
      fields: this._getFields(),
      program: workflow.program,
      properties: this._getProperties(),
      tokens: this._getTokens(),
      workflow,
      blocks: [
        {
          icon: workflow.trigger.icon,
          svg: workflow.trigger.svg,
          label: 't(Trigger)',
          type: 'trigger',
          action: 'trigger',
          token: () => <T text={ workflow.trigger.text } />
        },
        { action: 'ifthen' },
        { action: 'wait' },
        { action: 'goal' },
        { action: 'set' },
        { action: 'property' },
        { action: 'consent' },
        { action: 'list' },
        { action: 'workflow' },
        ...admin.hasRights({ allOf: ['sales:access_app'] }) ? [{ action: 'deal' }] : [],
        ...admin.hasRights({ allOf: ['tasks:access_app'] }) ? [{ action: 'task' }] : [],
        { action: 'email' },
        { action: 'sms' },
        { action: 'internal_email' },
        { action: 'internal_sms' },
        { action: 'notification' },
        {
          svg: 'check',
          label: 't(Complete)',
          type: 'ending',
          action: 'ending',
          token: () => <T text="t(Workflow is complete)" />
        }
      ],
      defaultValue: steps,
      status
    }
  }

  _getFields() {
    const { programfields, workflow } = this.props
    return [
      ...programfields.length > 0 ? [
        { 
          label: workflow.program.title, 
          fields: programfields.map(field => ({
            name: field.name.value,
            key: `contact.values.${field.code}`,
            type: field.type
          }))
        }
      ] : [],
      ...this._getEmailFields(),
      ...workflow.store ? this._getOrderFields(workflow.store) : [],
      ...workflow.event ? this._getRegistrationFields(workflow.event) : [],
      ...workflow.form ? this._getResponseFields(workflow.form) : [],
      ...workflow.signup_page ? this._getSignupFields(workflow.signup_page) : [],
      ...(workflow.team_status ||workflow.from_team_status || workflow.to_team_status) ? this._getTeamFields() : []
    ]
  }

  _getEmailFields() {
    const { workflow } = this.props
    if(!workflow.email_campaign && !workflow.email) return []
    return [
      {
        label: 't(Email)',
        fields: [
          ...workflow.email ? [
            { name: workflow.email.title, key: 'email.activities', type: 'activity' }
          ] : [],
          ...workflow.email_campaign ? [
            { name: workflow.email_campaign.title, key: 'email.activities', type: 'activity' }
          ] : []
        ]
      }
    ]
  }

  _getProperties() {
    const { workflow, programfields } = this.props
    return programfields.length > 0 ? [
      { 
        label: workflow.program.title, 
        fields: programfields.map(field => ({
          ...field,
          name: `values.${field.code}`
        }))
      }
    ] : []
  }

  _getRegistrationFields(event) {
    return [
      {
        label: 't(Registration)',
        fields: [
          { name: 't(First Name)', key: 'registration.first_name', type: 'textfield' },
          { name: 't(Last Name)', key: 'registration.last_name', type: 'textfield' },
          { name: 't(Email)', key: 'registration.email', type: 'emailfield' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            ...field,
            name: field.name.value,
            key: `registration.${field.code}`
          }))
        ]
      }
    ]
  }

  _getResponseFields(form) {
    return [
      {
        label: 't(Response)',
        fields: form.config.fields.filter(field => {
          return field.type !== 'text' && field.name
        }).map(field => ({
          ...field,
          name: field.name.value,
          key: `response.${field.code}`
        }))
      }
    ]
  }

  _getOrderFields(store) {
    return [
      {
        label: 't(Order)',
        fields: [
          { name: 't(First Name)', key: 'order.first_name', type: 'textfield' },
          { name: 't(Last Name)', key: 'order.last_name', type: 'textfield' },
          { name: 't(Email)', key: 'order.email', type: 'emailfield' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            ...field,
            name: field.name.value,
            key: `order.${field.code}`
          }))
        ]
      }
    ]
  }

  _getSignupFields(signup_page) {
    return [
      {
        label: 't(Signup)',
        fields: [
          { name: 't(First Name)', key: 'signup.first_name', type: 'textfield' },
          { name: 't(Last Name)', key: 'signup.last_name', type: 'textfield' },
          { name: 't(Email)', key: 'signup.email', type: 'emailfield' }
        ]
      }
    ]
  }

  _getTeamFields(signup_page) {
    return [
      {
        label: 't(Configuration)',
        fields: [
          { name: 't(Email Strategy)', key: 'configuration.email_strategy', type: 'select', options: ['optin','outout'] },
          { name: 't(Logo Strategy)', key: 'configuration.logo_strategy', type: 'select', options: ['design','upload','text'] },
          { name: 't(Social Strategy)', key: 'configuration.social_strategy', type: 'select', options: ['optin','outout'] },
          { name: 't(Website Strategy)', key: 'configuration.website_strategy', type: 'select', options: ['url','design','optout'] }
        ]
      }
    ]
  }

  _getTokens() {
    const { programfields, workflow } = this.props
    return [
      ...programfields.length > 0 ? [
        { 
          title: workflow.program.title, 
          tokens: programfields.map(field => ({
            name:   field.name.value,
            token: `program.${field.name.token}`
          }))
        }
      ] : [],
      ...workflow.store ? this._getOrderTokens(workflow.store) : [],
      ...workflow.event ? this._getRegistrationTokens(workflow.event) : [],
      ...workflow.form ? this._getResponseTokens(workflow.form) : [],
      ...workflow.pipeline ? this._getDealTokens(workflow.pipeline) : [],
      ...workflow.signup_page ? this._getSignupTokens(workflow.signup_page) : []
    ]
  }

  _getResponseTokens(form) {
    const { provider } = this.context
    return [
      {
        title: 't(Response Tokens)',
        tokens: [
          ...form.config.fields.filter(field => {
            return field.type !== 'text' && field.name
          }).map(field => ({
            name: field.name.value,
            token: `response.${field.name.token}`
          })),
          { name: `${provider.title} Path`, token: 'response.admin_path' },
          { name: `${provider.title} URL`, token: 'response.admin_url' }
        ]
      },
      this._gePaymentTokens('response')
    ]
  }

  _getOrderTokens(store) {
    const { provider } = this.context
    return [
      {
        title: 't(Order Tokens)',
        tokens: [
          { name: 't(First Name)', token: 'order.first_name' },
          { name: 't(Last Name)', token: 'order.last_name' },
          { name: 't(Email)', token: 'order.email' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `order.${field.name.token}`
          })),
          { name: `${provider.title} Path`, token: 'order.admin_path' },
          { name: `${provider.title} URL`, token: 'order.admin_url' }
        ]
      },
      this._gePaymentTokens('order')
    ]
  }

  _getRegistrationTokens(event) {
    const { provider } = this.context
    return [
      {
        title: 't(Registration Tokens)',
        tokens: [
          { name: 't(First Name)', token: 'registration.first_name' },
          { name: 't(Last Name)', token: 'registration.last_name' },
          { name: 't(Email)', token: 'registration.email' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `registration.${field.name.token}`
          })),
          { name: `${provider.title} Path`, token: 'registration.admin_path' },
          { name: `${provider.title} URL`, token: 'registration.admin_url' }
        ]
      },
      this._gePaymentTokens('registration')
    ]
  }

  _getDealTokens(deal) {
    return [
      {
        title: 't(Deal Tokens)',
        tokens: [
          { name: 't(Stage)', token: 'deal.stage' }
        ]
      }
    ]
  }

  _gePaymentTokens(model) {
    return {
      title: 't(Payment Tokens)',
      tokens: [
        { name: 't(Method)', token: `${model}.payment_method` },
        { name: 't(Amount)', token: `${model}.payment_amount` },
        { name: 't(Card)', token: `${model}.payment_card` }
      ]
    }
  }

  _getSignupTokens(signup_page) {
    return [
      {
        title: 't(Signup)',
        tokens: [
          { name: 't(Full Name)', token: 'signup.full_name' },
          { name: 't(First Name)', token: 'signup.first_name' },
          { name: 't(Last Name)', token: 'signup.last_name' },
          { name: 't(Email)', token: 'signup.email' },
          { name: 't(organization)', token: 'signup.organization' },
          { name: 't(Agreed To Terms)', token: 'signup.agreed_to_terms' },
          { name: 't(Referred By)', token: 'signup.referred_by' },
          { name: 't(Activation URL)', token: 'signup.activation_url' }
        ]
      },
      {
        title: 't(Configuration)',
        tokens: [
          { name: 't(Email Strategy)', token: 'configuration.email_strategy' },
          { name: 't(Logo Strategy)', token: 'configuration.logo_strategy' },
          { name: 't(Social Strategy)', token: 'configuration.social_strategy' },
          { name: 't(Website Strategy)', token: 'configuration.website_strategy' }
        ]
      }
    ]
  }

}

const mapResourcesToPanel = (props, context) => ({
  programfields: `/api/admin/team/programs/${props.workflow.program.id}/fields`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Designer workflow={ props.workflow } programfields={ props.programfields } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
