import StatusToken from '@admin/tokens/status'
import Page from '@admin/components/page'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Domains)',
  access: { rights: { $oneOf: ['domains:view_domains','domains:manage_domains'] } },
  collection: {
    endpoint: '/api/admin/domains/domains',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, visible: true },
      { label: 't(Regsitration)', key: 'registration_status', collapsing: true, format:  StatusToken },
      { label: 't(DNS)', key: 'dns_status', collapsing: true, format:  StatusToken }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'globe',
      title: 't(No Domains)',
      text: 't(You have not yet added any domains)',
      buttons: [
        { 
          label: 't(Add a Domain)',
          access: { rights: { $oneOf: ['domains:manage_domains'] } },
          modal: New 
        }
      ]
    },
    entity: 'site',
    onClick: (record) => context.router.push(`/domains/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Add a Domain)', 
    access: { rights: { $oneOf: ['domains:manage_domains'] } },
    modal: New
  }
})

export default Page(null, mapPropsToPage)
