import UserStatusToken from '@apps/truevail/admin/tokens/user_status'
import SupportAssignment from '@admin/components/support_assignment'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import TeamToken from '@admin/tokens/team'
import Edit from './edit'
import React from 'react'
import New from './new'

const getSupportUsers = (agency) => ({
  title: 't(Assign Support Users)',
  entity: 'user',
  format: (user) => <UserToken { ...user } />,
  compareKey: 'account_id',
  valueKey: 'account_ids',
  action: `/api/admin/truevail/admin/agencies/${agency.id}/users/support_users`,
  assignedEndpoint: `/api/admin/truevail/admin/agencies/${agency.id}/users/support_users`,
  unassignedEndpoint: `/api/admin/truevail/admin/agencies/${agency.id}/users/support_users/all`
})

const getSupportTeams = (agency, user) => ({
  title: 't(Assign Support Teams)',
  entity: 'team',
  format: TeamToken,
  valueKey: 'team_ids',
  action: `/api/admin/truevail/admin/agencies/${agency.id}/users/${user.id}/support_teams`,
  assignedEndpoint: `/api/admin/truevail/admin/agencies/${agency.id}/users/${user.id}/support_teams`,
  unassignedEndpoint: `/api/admin/truevail/admin/agencies/${agency.id}/users/${user.id}/support_teams/all`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  collection: {
    endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: (user) => (
        <UserToken { ...user } show_email={ true } show_type={ true } />
      ) },
      { label: 't(Auto Assign)', key: 'advisor_auto_assign', collapsing: true, format: 'check_times' },
      { label: 't(Activation)', key: 'account.activation_sent_at', primary: true, collapsing: true, format: UserStatusToken }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      svg: 'user_round',
      title: 't(No Active Users)',
      text: 't(There are no active users for this advisor)',
      buttons: [
        { 
          label: 't(Add User)', 
          modal: <New agency={ props.agency } /> 
        }
      ]
    },
    entity: 'user',
    recordTasks: (record) => [
      {
        label: 't(Change Auto Assign)',
        svg: 'refresh_cw',
        modal: <Edit agency={ props.agency } user={ record } />
      },
      {
        label: 't(Manage Support Teams)',
        svg: 'life_buoy',
        modal: <SupportAssignment { ...getSupportTeams(props.agency, record) } />
      },
      {
        label: 't(Send Activation Email)',
        svg: 'mail',
        show: record.account.activated_at === null,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/activate`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate user)')
        }
      },
      {
        label: 't(Activate User)',
        svg: 'check',
        show: record.account.activated_at !== null && !record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate user)')
        }
      },
      {
        label: 't(Deactivate User)',
        svg: 'x',
        show: record.account.activated_at !== null && record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: false
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to deactivate user)')
        }
      }
    ]
  },
  tasks : {
    svg: 'ellipsis_vertical',
    items: [
      { 
        label: 't(Add User)', 
        svg: 'user_round',
        modal: <New agency={ props.agency } /> 
      },
      { 
        label: 't(Manage Support Users)',
        svg: 'life_buoy',
        modal: <SupportAssignment { ...getSupportUsers(props.agency) } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
