import Button from '@admin/components/button'
import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Card extends React.Component {

  static propTypes = {
    context: PropTypes.object,
    card: PropTypes.object,
    code: PropTypes.string,
    columns: PropTypes.array,
    defaults: PropTypes.array,
    display: PropTypes.array,
    handler: PropTypes.func,
    hidden: PropTypes.array,
    records: PropTypes.array,
    recordTasks: PropTypes.func,
    rowClass: PropTypes.func,
    selectable: PropTypes.bool,
    selected: PropTypes.object,
    selectAll: PropTypes.bool,
    selectValue: PropTypes.string,
    sort: PropTypes.object,
    sortable: PropTypes.bool,
    total: PropTypes.number,
    visible: PropTypes.array,
    onClick: PropTypes.func,
    onReachBottom: PropTypes.func,
    onSelect: PropTypes.func,
    onSetHidden: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSort: PropTypes.func,
    onToggleHidden: PropTypes.func
  }

  render() {
    const { card, records, recordTasks, selectable } = this.props
    const format = card.format || card
    return (
      <div className="maha-card-layout">
        <div className="maha-card-layout-cards">
          { records.map((record, index) => (
            <div { ...this._getCard(record, index) } key={`record_${index}`}>
              <div className="maha-card-layout-card">
                <div className="maha-card-layout-card-title" onClick={ this._handleSelect.bind(this, record) }>
                  { selectable &&
                    <Icon { ...this._getSelect(record) } /> 
                  }
                  { record[card.title || 'title'] }
                </div>
                <div className="maha-card-layout-card-body">
                  <Format { ...record } format={ format } />
                </div>
                { recordTasks &&
                  <Button { ...this._getRecordTasks(record) } />
                }
              </div>
            </div>
          ) ) }
        </div>
      </div>
    )
  }

  _getChecked(record) {
    const { selected, selectValue } = this.props
    const value = _.get(record, selectValue)
    const included = _.includes(selected.values, value)
    if(selected.mode === '$in') return included
    return !included
  }

  _getClass(record) {
    const { handler, onClick } =  this.props
    const checked = this._getChecked(record)
    const classes = ['maha-card-layout-card-cell']
    if(handler || onClick) classes.push('maha-card-layout-card-link')
    if(checked) classes.push('selected')
    return classes.join(' ')
  }

  _getCard(record, index) {
    return {
      className: this._getClass(record),
      onClick: this._handleClick.bind(this, record, index)
    }
  }

  _handleClick(record, index) {
    const { onClick, handler } = this.props
    if(handler) handler(record, index)
    if(onClick) onClick(record, index)
  }

  _getRecordTasks(record) {
    return {
      svg: 'ellipsis_vertical',
      className: 'maha-card-layout-card-tasks',
      tasks: this.props.recordTasks(record)
    }
  }

  _getSelect(record) {
    return {
      svg: this._getChecked(record) ? 'square_check' : 'square'
    }
  }

  _handleSelect(record, e) {
    e.stopPropagation()
    const { selectValue } = this.props
    const value = _.get(record, selectValue)
    this.props.onSelect(value)
  }

  _handleTasks(record, e) {
    e.stopPropagation()
    const { recordTasks } = this.props
    this.props.context.tasks.open({
      items: recordTasks(record)
    })
  }

}

export default Card