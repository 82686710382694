import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const getField = (field) => {
  const type = field.type === 'contactfield' ? field.contactfield.type : field.type
  if(type === 'addressfield') {
    return [
      { label: `${field.name.value} - Full Address`, key: `data.${field.code}.description` },
      { label: `${field.name.value} - Street 1`, key: `data.${field.code}.street_1` },
      { label: `${field.name.value} - Street 2`, key: `data.${field.code}.street_2` },
      { label: `${field.name.value} - City`, key: `data.${field.code}.city` },
      { label: `${field.name.value} - State/Province`, key: `data.${field.code}.state_province` },
      { label: `${field.name.value} - Postal Code`, key: `data.${field.code}.postal_code` },
      { label: `${field.name.value} - County`, key: `data.${field.code}.county` },
      { label: `${field.name.value} - Latitude`, key: `data.${field.code}.latitude` },
      { label: `${field.name.value} - Longitude`, key: `data.${field.code}.longitude` }
    ]
  } else if(type === 'productfield') {
    return field.products.reduce((fields, product) => [
      ...fields,
      { label: `${field.name.value} - ${product.description} - Quantity`, key: `data.${field.code}.summary.${product.code}.quantity` },
      { label: `${field.name.value} - ${product.description} - Total`, key: `data.${field.code}.summary.${product.code}.total` }
    ], [])
  } else if(type === 'paymentfield') {
    return [
      { label: `${field.name.value}`, key: `data.${field.code}.summary` }
    ]
  } else if(type === 'optionsfield') {
    return [
      { label: `${field.name.value} - Option`, key: `data.${field.code}.summary.option` },
      { label: `${field.name.value} - Amount`, key: `data.${field.code}.summary.amount` }
    ]
  }
  return [
    { label: field.name.value, key: `data.${field.code}` }
  ]
}

const mapPropsToPanel = (props, context) => {
  const payment = props.form.config.fields.find(field => {
    return _.includes(['productfield','paymentfield','optionsfield'], field.type)
  }) !== undefined
  return {
    title: 't(Responses)',
    access: {},
    collection: {
      endpoint: `/api/admin/forms/forms/${props.form.id}/responses`,
      table: [
        { label: 't(ID)', key: 'id', collapsing: true, visible: false },
        { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
          return <ContactToken { ...contact } />
        } },
        { label: 'Created At', key: 'created_at',  collapsing: true, format: 'datetime' },
        ...context.admin.hasRights({ allOf: ['finance:access_app'] }) ? [
          { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right', format: 'currency' }
        ] : []
      ],
      criteria: {
        fields: [
          {
            label: 't(Form)',
            virtuals: props.form.config.fields
          }
        ]
      },
      export: [
        ...props.form.config.fields.filter(field => {
          return field.type !== 'text'
        }).reduce((fields, field) => [
          ...fields,
          ...getField(field)
        ], []),
        ...payment ? [
          { label: 't(Payment Method)', key: 'payment.method' },
          { label: 'Payment Reference', key: 'payment.reference' },
          { label: 'Payment Amount', key: 'payment.amount' },
          { label: 'Payment Fee', key: 'payment.fee' },
          { label: 'Payment Disbursed', key: 'payment.disbursed' },
          { label: 'Payment Date', key: 'payment.date' }
        ] : [],
        { label: 't(Created)', key: 'created_at' }
      ],
      empty: {
        svg: 'user_round',
        title: 't(No Responses)',
        text: 't(There are no responses to this form)'
      },
      defaultSort: { key: 'created_at', order: 'desc' },
      entity: 'responses',
      onClick: (record) => context.router.push(`/forms/forms/${props.form.id}/responses/${record.id}`)
    }
  }
}

export default Panel(null, mapPropsToPanel)
