import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import DurationToken from '@admin/tokens/duration'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/email_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      { label: 't(Duration)', key: 'sending_duration', collapsing: true, align: 'right', format: (email_campaign) => (
        <DurationToken duration={ email_campaign.sending_duration} />
      ) },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' }
    ],
    defaultQuery: {
      domain_id: props.domain.id
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(There are no email campaigns in the process of sending)'
    },
    recordTasks: (record) => [
      {
        label: 't(Promote Emails)',
        confirm: 't(Are you sure you want to bypass the queue and deliver these emails?)',
        request: {
          endpoint: `/api/admin/truevail/admin/deliverability/email_campaigns/${record.id}/promote`,
          method: 'PATCH'
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
