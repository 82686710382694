import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Results from '@admin/components/import/results'
import Loader from '@admin/components/loader'
import PropTypes from 'prop-types'
import React from 'react'

class Records extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    metadata: PropTypes.object,
    is_valid: PropTypes.bool,
    is_duplicate: PropTypes.bool,
    is_nonunique: PropTypes.bool,
    is_omitted: PropTypes.bool,
    errors: PropTypes.bool,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    index: 0,
    items: null,
    status: 'success',
    total: 0
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleIndex = this._handleIndex.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { items, status } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        { items &&
          <div className="maha-import-review">
            { status === 'loading' && <Loader { ...this._getLoader() } /> }
            { (status === 'loaded' || status === 'success') &&
              <Results { ...this._getResults() } />
            }
          </div>
        }
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleFetch()
  }

  _getFields() {
    const { fields } = this.props.metadata
    return fields.reduce((fields, segment) => [
      ...fields,
      ...segment.fields ? segment.fields : [segment]
    ], [])
  }

  _getFieldLabel(name) {
    const fields = this._getFields()
    const field = _.find(fields, { name })
    return field.label
  }

  _getPanel() {
    return {
      title: 't(Review Records)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _getResults() {
    const { index, items, total } = this.state
    const item = items ? items[index] : null
    return {
      errors: item.errors,
      record: Object.keys(item.values).map(key => ({
        key: this._getFieldLabel(key),
        value: item.values[key]
      })),
      index,
      total,
      onChange: this._handleIndex
    }
  }

  _getType(name) {
    const fields = this._getFields()
    const field = _.find(fields, { name })
    return field.type
  }

  _handleFetch() {
    const { _import, is_duplicate, is_nonunique, is_omitted, is_valid } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${_import.object_type}/imports/${_import.id}/items`,
      method: 'GET',
      query: {
        $filter: {
          ...!_.isNil(is_duplicate) ? { is_duplicate: { $eq: is_duplicate } } : {},
          ...!_.isNil(is_nonunique) ? { is_nonunique: { $eq: is_nonunique } } : {},
          ...!_.isNil(is_omitted) ? { is_omitted: { $eq: is_omitted } } : {},
          ...!_.isNil(is_valid) ? { is_valid: { $eq: is_valid } } : {}
        }
      },
      onSuccess: this._handleSuccess
    })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleIndex(index) {
    this.setState({ index })
  }

  _handleSuccess(result) {
    this.setState({
      items: result.data,
      total: result.pagination.total
    })
  }

}

const mapResources = (props, context) => ({
  metadata: `/api/admin/${props._import.object_type}/imports/metadata`
})

export default Container(mapResources)(Records)
