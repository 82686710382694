import Chart from '@admin/components/chart'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    all: PropTypes.number,
    card: PropTypes.func,
    chart: PropTypes.object,
    code: PropTypes.string,
    columns: PropTypes.array,
    handler: PropTypes.func,
    layout: PropTypes.any,
    records: PropTypes.array,
    recordTasks: PropTypes.func,
    rowClass: PropTypes.func,
    selectable: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectValue: PropTypes.string,
    selected: PropTypes.object,
    sort: PropTypes.object,
    sortable: PropTypes.bool,
    total: PropTypes.number,
    status: PropTypes.string,
    table: PropTypes.array,
    onClick: PropTypes.func,
    onLoadMore: PropTypes.func,
    onReachBottom: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSort: PropTypes.func
  }

  render() {
    const { layout, chart } = this.props
    return (
      <>
        { chart && 
          <div className="maha-collection-chart">
            <Chart { ...this._getChart() } />
          </div>
        }
        { React.createElement(layout, { ...this._getLayout() }) }
      </>
    )
  }

  _getChart() {
    const { chart, records } = this.props
    return {
      ...chart,
      datasets: chart.datasets(records)
    }
  }

  _getLayout() {
    const { all, card, code, handler, records, recordTasks, rowClass, table, selectAll } = this.props
    const { selectValue, selectable, selected, sort, sortable, status, total } = this.props
    const { onClick, onLoadMore, onReachBottom, onSelect, onSelectAll, onSort } = this.props
    return {
      all,
      card,
      code,
      columns: table,
      handler,
      records,
      recordTasks,
      rowClass,
      selectAll,
      selectValue,
      selectable,
      selected,
      sort,
      sortable,
      status,
      total,
      onClick,
      onLoadMore,
      onReachBottom,
      onSelect,
      onSelectAll,
      onSort
    }
  }

}

export default Results
