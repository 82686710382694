import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ recipients }) => recipients ? recipients.map((recipient, index) => (
  <div key={`recipient_${index}`}>
    { recipient.strategy === 'user' &&
      <>{ recipient.user.full_name }</>
    }
    { recipient.strategy === 'primary_user' &&
      <>Primary User</>
    }
  </div>
)) : null

Token.propTypes = {
  recipients: PropTypes.array
}

export default Token
