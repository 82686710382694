const triggers = [
  {
    svg: 'plus',
    code: 'enrollment_created',
    title: 't(Enrollment Created)',
    text: 't(Contact is manually enrolled in workflow)'
  },
  {
    svg: 'mail',
    code: 'email_received',
    title: 't(Email Received)',
    text: 't(Contact receives automation email)'
  },
  {
    svg: 'mail_open',
    code: 'email_opened',
    title: 't(Email Opened)',
    text: 't(Contact opens automation email)'
  },
  {
    svg: 'mouse_pointer',
    code: 'email_clicked',
    title: 't(Email Clicked)',
    text: 't(Contact clicks link in automation email)'
  }
]

export default triggers
