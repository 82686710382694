import ProgramForm from '@apps/crm/admin/components/programform'
import FileToken from '@apps/crm/admin/tokens/file'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Files)',
  access: { rights: { $oneOf: ['crm:view_contacts','crm:manage_contacts'] } },
  collection: {
    endpoint: `/api/admin/crm/contacts/${props.contact.id}/files`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'asset.file_name', primary: true, format: (file) => <FileToken { ...file } /> },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : [],
      { label: 't(Created)', key: 'asset.created_at', sort: 'created_at', collapsing: true, format: 'date' }
    ],
    empty: {
      title: 't(No files)',
      text: 't(There are no files for this contact)',
      svg: 'files',
      buttons: [
        {
          label: 't(Upload File)',
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <ProgramForm form={ New } props={{ contact:props.contact }} />
        }
      ]
    },
    entity: 'files',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(record.asset.path)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Upload File)',
    access: { rights: { $oneOf: ['crm:manage_contacts'] } },
    modal: <ProgramForm form={ New } props={{ contact:props.contact }} />
  }
})

export default Panel(null, mapPropsToPanel)
