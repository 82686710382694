import FileTypeToken from '@admin/tokens/file_type'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import bytes from 'bytes'
import React from 'react'

class Attachment extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    attachment: PropTypes.object,
    email: PropTypes.object
  }

  render() {
    const { attachment } = this.props
    return (
      <Button { ...this._getAttachment() }>
        <div className="maha-email-attachment-icon">
          <FileTypeToken content_type={ attachment.content_type } />
        </div>
        <div className="maha-email-attachment-label">
          <div className="maha-email-attachment-label-filename">
            { attachment.file_name }
          </div>
          <div className="maha-email-attachment-label-filesize">
            { bytes(attachment.file_size) }
          </div>
        </div>
      </Button>
    )
  }

  _getAttachment() {
    const { attachment, email } = this.props
    const { admin } = this.context
    return {
      className: 'maha-email-attachment',
      url: attachment.signed_url || admin.signUrl(`/api/admin/email/${email.profile_id}/${email.id}/attachments/${attachment.id}`)
    }
  }

}

export default Attachment
