import Container from '@admin/components/container'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Audit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.string,
    compact: PropTypes.bool,
    entries: PropTypes.array,
    showall: PropTypes.bool
  }

  static defaultProps = {
    showall: false,
    compact: false
  }

  state = {
    showall: false
  }

  _handleShowAll = this._handleShowAll.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      showall: props.showall
    }
  }

  render() {
    const shown = this._getEntries()
    const { entries } = this.props
    const { showall } = this.state
    if(entries.length == 0) return null
    return (
      <div className={ this._getClass() }>
        { entries.length > 3 && !showall &&
          <div className="maha-audit-more">
            <Button { ...this._getShowAll() } />
          </div>
        }
        { shown.map((entry, index) => (
          <div className="maha-audit-entry" key={`entry_${entry.id}`}>
            <div className="maha-audit-entry-avatar">
              <Avatar user={ this._getSubject(entry) } width="16" height="16" presence={ false } />
            </div>
            <div className="maha-audit-entry-details">
              <div className="maha-audit-entry-text">
                { entry.story }
                { ` by ${this._getSubject(entry).full_name}` }
              </div>
              <div className="maha-audit-entry-timestamp">
                <Date datetime={ entry.created_at } />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getClass() {
    const { compact } = this.props
    const classes = ['maha-audit']
    if(compact) classes.push('compact')
    return classes.join(' ')
  }

  _getEntries() {
    const { showall } = this.state
    const { entries } = this.props
    return showall ? entries : entries.slice(-3)
  }

  _getShowAll() {
    const { entries } = this.props
    const remaining = entries.length - 3
    return {
      label: `Show ${pluralize('more entry', remaining, true)} `,
      className: 'tiny link',
      handler: this._handleShowAll
    }
  }

  _getActor(entry) {
    const { provider } = this.context
    const { contact, service, user } = entry
    if(contact) return entry
    if(user) {
      return {
        user: {
          ...user,
          ...user.type === 'support' ? {
            full_name: `${user.full_name} (${provider.title} Support)`
          } : {}
        }
      }
    }
    if(service) {
      return {
        user: {
          full_name: service.name,
          photo: `/images/services/${service.source_name}.png`
        }
      }
    }
    return { user: { full_name: provider.title,  photo: provider.logo } }
  }

  _getSubject(entry) {
    const actor = this._getActor(entry)
    if(actor.user) return actor.user
    if(actor.contact) return actor.contact
  }

  _handleShowAll() {
    this.setState({
      showall: true
    })
  }

}

const mapResources = (props, context) => ({
  entries: `/api/admin/${props.entity}/audits`
})

export default Container(mapResources)(Audit, () => null)
