import qs from 'qs'

export const toQuery = (transforms) => {
  const { crop, rotation } = transforms
  const adjustments = transforms.adjustments || {}
  const flip = []
  if(rotation) {
    if(rotation.x % 360 > 0) flip.push('v')
    if(rotation.y % 360 > 0) flip.push('h')
  }
  return qs.stringify({
    ...transforms.to ? { to: transforms.to } : {},
    ...transforms.q ? { q: transforms.q } : {},
    ...transforms.overlay ? { overlay: transforms.overlay } : {},
    ...transforms.fit ? { fit: transforms.fit } : {},
    ...transforms.bg ? { bg: transforms.bg } : {},
    ...transforms.w ? { w: transforms.w } : {},
    ...transforms.h ? { h: transforms.h } : {},
    ...transforms.fm ? { fm: transforms.fm } : {},
    ...adjustments.bri ? { bri: adjustments.bri } : {},
    ...adjustments.con ? { con: adjustments.con } : {},
    ...adjustments.vib ? { vib: adjustments.vib } : {},
    ...adjustments.sat ? { sat: adjustments.sat } : {},
    ...adjustments.sep ? { sep: adjustments.sep } : {},
    ...adjustments.hue ? { sep: adjustments.hue } : {},
    ...rotation && rotation.z % 360 !== 0 ? { rot: rotation.z } : {},
    ...flip.length > 0 ? { flip: flip.join('.') } : {},
    ...crop ? {
      crop: [crop.l,crop.t,crop.w,crop.h].join('.')
    } : {},
    ...transforms.dpi ? { dpi: transforms.dpi } : {}
  }, { encode: false })
}
