import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Wait extends React.PureComponent {

  static propTypes = {
    config: PropTypes.object,
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    return {
      strategy: 'datetime',
      until_date: moment().add(1,'day').format('MM/DD/YYYY'),
      until_time: '12:00 PM',
      duration_days: 0,
      duration_hours: 0,
      duration_mins: 0
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Wait)',
      compact: true,
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelSvg: 'chevron_left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { 
              name: 'strategy', 
              type: 'radiogroup', 
              required: true, 
              options: [
                { value: 'datetime', text: 't(Until a specific date and time)' },
                { value: 'time', text: 't(Until a specific time)' },
                { value: 'duration', text: 't(For a specific duration of time)'}
              ], 
              defaultValue: config.strategy 
            },
            { 
              label: 't(Until)',
              type: 'fields', 
              fields: [
                { name: 'until_date', type: 'datefield', required: true, placeholder: 'Date', defaultValue: config.until_date },
                { name: 'until_time', type: 'timefield', required: true, placeholder: 'Time', defaultValue: config.until_time }
              ],
              show: config.strategy === 'datetime'
            },
            { 
              label: 't(Until)',
              type: 'fields', 
              fields: [
                { name: 'until_time', type: 'timefield', required: true, placeholder: 'Time', defaultValue: config.until_time }
              ],
              show: config.strategy === 'time'
            },
            { 
              label: 't(Duration)', 
              type: 'fields', 
              fields: [
                { name: 'duration_days', type: 'numberfield', units: 'days', required: true, placeholder: 'Days', defaultValue: config.duration_days },
                { name: 'duration_hours', type: 'numberfield', units: 'hrs', required: true, placeholder: 'Hours', defaultValue: config.duration_hours },
                { name: 'duration_mins', type: 'numberfield', units: 'mins', required: true, placeholder: 'Mins', defaultValue: config.duration_mins }
              ],
              show: config.strategy === 'duration'
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone(config) {
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Wait
