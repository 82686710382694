import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Menu from '@admin/components/menu'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class List extends React.Component {

  static contextTyoes = {
    admin: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.object,
    cacheKey: PropTypes.string,
    folder: PropTypes.object,
    selected: PropTypes.array,
    profile: PropTypes.object,
    onActive: PropTypes.func,
    onFlag: PropTypes.func,
    onForward: PropTypes.func,
    onMove: PropTypes.func,
    onMark: PropTypes.func,
    onReply: PropTypes.func,
    onSelect: PropTypes.func,
    onSync: PropTypes.func
  }

  state = {
    classification: 'focused',
    q: ''
  }

  _handleChange = this._handleChange.bind(this)
  _handleSync = this._handleSync.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { folder } = this.props
    const { q } = this.state
    return (
      <div className="email-client-list">
        <Searchbox { ...this._getSearchbox() } />
        { folder.alias === 'inbox' && q.length === 0 &&
          <Menu { ...this._getMenu() } />
        }
        <Infinite { ...this._getInfinite() } />
      </div>
    )
  }

  _getInfinite() {
    const { active, cacheKey, selected, profile, onActive, onFlag, onForward, onMark, onMove, onSelect, onReply } = this.props
    const { user } = this.context.admin
    const { classification, q } = this.state
    const folder_name = q.length > 0 ? 'search' : this.props.folder.alias
    const profile_id = profile ? profile.id : 'all'
    return {
      key: `${cacheKey}:${folder_name}:${classification}:${q}`,
      endpoint: `/api/admin/email/${profile_id}/${folder_name}`,
      refresh: `/users/${user.id}/admin/email/${folder_name}`,
      filter: {
        ...folder_name === 'inbox' && classification ? { classification: { $eq: classification } } : {},
        ...q.length > 0 ? { q } : {}
      },
      layout: Results,
      ...folder_name === 'syncing' ? {
        empty: {
          icon: 'refresh fa-spin',
          title: 't(Syncing Email)',
          text: 't(This make take a few minutes)'
        },
        notFound: {
          icon: 'refresh fa-spin',
          title: 't(No results found)',
          text: 't(We\'re still synching all of your email, so try again later)'
        }
      } : {
        empty: {
          svg: 'mail',
          title: 't(No Emails)',
          text: 't(There are no emails in this folder)'
        },
        notFound: {
          svg: 'mail',
          title: 't(No Emails)',
          text: 't(There are no emails that match your query)'
        }
      },
      onPullDown: this._handleSync,
      props: {
        active,
        folder: this.props.folder,
        selected,
        onActive,
        onFlag,
        onForward,
        onMark,
        onMove,
        onReply,
        onSelect
      }
    }
  }

  _getMenu() {
    return {
      color: 'darkblue',
      type: 'pointing',
      items: [
        { label: 't(Focused)', value: 'focused' },
        { label: 't(Other)', value: 'other' }
      ],
      onChange: this._handleChange
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Search',
      color: 'darkblue',
      onChange: this._handleType
    }
  }

  _handleSync() {
    this.props.onSync()
  }

  _handleType(q) {
    this.setState({ q })
  }

  _handleChange(item) {
    this.setState({
      classification: item.value
    })
  }

}

export default List
