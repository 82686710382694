import Preview from '@admin/components/preview'
import PropTypes from 'prop-types'
import Sidebar from './sidebar'
import Canvas from './canvas'
import React from 'react'

class FormDesigner extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.number,
    changes: PropTypes.number,
    config: PropTypes.object,
    defaultValue: PropTypes.object,
    editable: PropTypes.bool,
    endpoint: PropTypes.string,
    form: PropTypes.object,
    fields: PropTypes.array,
    program: PropTypes.object,
    status: PropTypes.string,
    onAdd: PropTypes.func,
    onClone: PropTypes.func,
    onEdit: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onSetConfig: PropTypes.func,
    onSetStatus: PropTypes.func,
    onUpdate: PropTypes.func
  }

  static defaultProps = {
    editable: true
  }

  _handleSave = this._handleSave.bind(this)

  render() {
    const { editable } = this.props
    return (
      <div className="designer">
        <div className="designer-main">
          <Canvas { ...this._getCanvas() } />
        </div>
        { editable &&
          <div className="designer-sidebar">
            <Sidebar { ...this._getSidebar() } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this.props.form.config || this._getDefault()
    this.props.onSetConfig(defaultValue)
  }

  _getCanvas() {
    const { active, config, editable, onAdd, onClone, onEdit, onMove, onRemove } = this.props
    return {
      active,
      config,
      editable,
      onAdd,
      onClone,
      onEdit,
      onMove,
      onRemove
    }
  }

  _getDefault() {
    return {
      page: null,
      header: null,
      body: null,
      footer: null,
      settings: null,
      fields: []
    }
  }

  _getSidebar() {
    const { active, changes, config, endpoint, form, fields, program, status, onEdit, onUpdate } = this.props
    return {
      active,
      changes,
      config,
      endpoint,
      form,
      fields,
      program,
      status,
      onEdit,
      onSave: this._handleSave,
      onUpdate
    }
  }

  _handleSave(endpoint, config) {
    this.props.onSetStatus('saving')
    this.context.network.request({
      endpoint,
      method: 'PATCH',
      body: {
        config
      },
      onSuccess: ({ data }) => {
        this.props.onSetConfig(config)
        this.props.onSetStatus('ready')
      },
      onFailure: () => {}
    })

  }

}

export default FormDesigner
