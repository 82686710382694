import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Verifications)',
  collection: {
    endpoint: `/api/admin/crm/imports/${props.import.id}/verifications`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email)', key: 'rfc822', primary: true },
      { label: 't(Attempts)', key: 'verification_attempts', collapsing: true },
      { label: 't(Status)', key: 'verification_status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Status)', key: 'verification_status', type: 'select', multiple: true, options: ['pending','failed','success'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Pending)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'verification_status', operator: '$eq', parent: null, value: 'pending' }
          ]
        } },
        { id: 0, title: 't(Failed)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'verification_status', operator: '$eq', parent: null, value: 'failed' }
          ]
        } },
        { id: 0, title: 't(Success)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'verification_status', operator: '$eq', parent: null, value: 'success' }
          ]
        } },
      ]
    },
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      svg: 'mail',
      title: 't(No Emails)',
      text: 't(Your import did not include any emails)'
    },
    entity: 'contact',
    onClick: (record) => context.router.push(`/crm/contacts/${record.contact.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
