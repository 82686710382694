import InputTokens from '@admin/components/input_tokens'
import Button from '@admin/components/button'
import IconToken from '@admin/tokens/icon'
import PropTypes from 'prop-types'
import Chooser from './chooser'
import React from 'react'

class IconField extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.any,
    theme: PropTypes.object,
    website: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    icon: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleClear = this._handleClear.bind(this)
  _handleClick = this._handleClick.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { icon } = this.state
    return (
      <div className="mjson-designer-iconfield maha-input" onClick={ this._handleClick }>
        <div className="maha-input-field">
          { icon ?
            <InputTokens { ...this._getInputTokens() } /> :
            <div className="maha-input-placeholder">
              Choose an icon
            </div>
          }
        </div>
        { icon &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { icon } = this.state
    if(!_.isEqual(icon, prevState.icon)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getChooser() {
    const { theme, website } = this.props
    return {
      theme,
      website,
      onChoose: this._handleUpdate
    }
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getIcon() {
    const { icon } = this.state
    return {
      icon,
      color: '2185d0',
      size: 24
    }
  }

  _getInputTokens() {
    const { icon } = this.state
    return {
      tokens: [icon],
      format: () => (
        <IconToken { ...this._getIcon() } />
      )
    }
  }

  _handleChange() {
    const { icon } = this.state
    this.props.onChange(icon)
  }

  _handleClear() {
    this.setState({ icon: null })
  }

  _handleClick() {
    this.context.form.push(Chooser, this._getChooser())
  }

  _handleSet(icon) {
    this.setState({ icon })
  }

  _handleUpdate(icon) {
    this.setState({ icon })
  }

}

export default IconField
