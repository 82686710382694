import Preferences from './preferences'
import Divider from './divider'
import Follow from './follow'
import Button from './button'
import Video from './video'
import Share from './share'
import Image from './image'
import Text from './text'
import Web from './web'


const blocks = [
  { label: 't(Text)', type: 'text', svg: 'case_upper', component: Text },
  { label: 't(Divider)', type: 'divider', svg: 'minus', component: Divider },
  { label: 't(Image)', type: 'image', svg: 'image', component: Image },
  { label: 't(Button)', type: 'button', svg: 'mouse_pointer', component: Button },
  { label: 't(Preferences)', type: 'preferences', svg: 'check', component: Preferences },
  { label: 't(Social Share)', type: 'share', svg: 'share2', component: Share },
  { label: 't(Social Follow)', type: 'follow', svg: 'facebook', component: Follow },
  { label: 't(Video)', type: 'video', svg: 'sqaure_play', component: Video },
  { label: 't(Web Version)', type: 'web', svg: 'globe', component: Web }
]

export default blocks