import EmailCampaignCard from '@apps/truevail/admin/cards/library_email_campaign'
import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/library/email_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'advisor.title', sort: 'advisor', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ true } />
      ) }
    ],
    card: (email_campaign) => (
      <EmailCampaignCard email_campaign={ email_campaign } />
    ),
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Email Campaigns)',
      text: 't(There are no published email campaigns)'
    },
    entity: 'email campaign',
    onClick: (record) => context.router.push(`/admin/truevail/admin/email_campaigns/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
