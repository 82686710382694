import MJSONPreview from '@admin/components/mjson_preview'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ email }, { admin }) => {

  const program = {
    label: email.program.title,
    className: 'link',
    route: `/team/programs/${email.program.id}`
  }

  const details = {
    audits: `automation_emails/${email.id}`,
    comments: `automation_emails/${email.id}`,
    header: <MJSONPreview entity={ email } table="automation_emails" />,
    sections: [
      {
        title: 't(Email Details)',
        items: [
          { label: 't(Title)', content: email.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
        ]
      },
      {
        title: 't(Email Settings)',
        items: [
          ...email.template ? [
            { label: 't(Template)', content: email.template.title }
          ] : [],
          { label: 't(From)', content: email.sender.rfc822 },
          ...email.meta.cc ? [
            { label: 't(CC)', content: email.meta.cc }
          ] : [],
          ...email.meta.bcc ? [
            { label: 't(BCC)', content: email.meta.bcc }
          ] : [],
          { label: 't(Subject)', content: email.subject },
          { label: 't(Preview Text)', content: email.preview_text }
        ]
      }
    ]
  }

  if(email.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This email was deleted)' }
  }

  if(email.workflow.event) {
    const event = {
      label: email.workflow.event.title,
      className: 'link',
      route: `/events/events/${email.workflow.event.id}`
    }
    details.sections[0].items.push({ label: 't(Event)', content: <Button { ...event } /> })
  }

  if(email.workflow.email) {
    const email = {
      label: email.workflow.email.title,
      className: 'link',
      route: `/automation/emails/${email.workflow.email.id}`
    }
    details.sections[0].items.push({ label: 't(Email)', content: <Button { ...email } /> })
  }

  if(email.workflow.email_campaign) {
    const email_campaign = {
      label: email.workflow.email_campaign.title,
      className: 'link',
      route: `/campaigns/email/${email.workflow.email_campaign.id}`
    }
    details.sections[0].items.push({ label: 't(Email Campaign)', content: <Button { ...email_campaign } /> })
  }

  if(email.workflow.field) {
    const form = {
      label: email.workflow.field.name.value,
      className: 'link',
      route: `/team/programs/${email.workflow.program.id}/properties`
    }
    details.sections[0].items.push({ label: 't(Property)', content: <Button { ...form } /> })
  }

  if(email.workflow.form) {
    const form = {
      label: email.workflow.form.title,
      className: 'link',
      route: `/forms/forms/${email.workflow.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(email.workflow.list) {
    const wlist = {
      label: email.workflow.list.title,
      className: 'link',
      route: `/crm/lists/${email.workflow.list.id}`
    }
    details.sections[0].items.push({ label: 't(List)', content: <Button { ...wlist } /> })
  }

  if(email.workflow.pipeline) {
    const pipeline = {
      label: email.workflow.pipeline.title,
      className: 'link',
      route: `/sales/pipelines/${email.workflow.pipeline.id}`
    }
    details.sections[0].items.push({ label: 't(Pipeline)', content: <Button { ...pipeline } /> })
  }

  if(email.workflow.service) {
    const service = {
      label: email.workflow.service.title,
      className: 'link',
      route: `/subscriptions/services/${email.workflow.service.id}`
    }
    details.sections[0].items.push({ label: 't(Service)', content: <Button { ...service } /> })
  }

  if(email.workflow.store) {
    const store = {
      label: email.workflow.store.title,
      className: 'link',
      route: `/stores/stores/${email.workflow.store.id}`
    }
    details.sections[0].items.push({ label: 't(Store)', content: <Button { ...store } /> })
  }

  if(email.workflow) {
    const workflow = {
      label: email.workflow.title,
      className: 'link',
      route: `/automation/workflows/${email.workflow.id}`
    }
    details.sections[0].items.push({ label: 't(Workflow)', content: <Button { ...workflow } /> })
  }

  if(email.sms_campaign) {
    const sms_campaign = {
      label: email.sms_campaign.title,
      className: 'link',
      route: `/campaigns/sms/${email.sms_campaign.id}`
    }
    details.sections[0].items.push({ label: 'SMS Campaign', content: <Button { ...sms_campaign } /> })
  }

  if(email.voice_campaign) {
    const voice_campaign = {
      label: email.voice_campaign.title,
      className: 'link',
      route: `/campaigns/voice/${email.voice_campaign.id}`
    }
    details.sections[0].items.push({ label: 'Voice Campaign', content: <Button { ...voice_campaign } /> })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  email: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email={ props.email } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Email)',  
        svg: 'square_pen',
        access: {
          programs: { $canEdit: props.email.program.id },
          rights: { $oneOf: ['automation:manage_workflows'] }
        },
        modal: <Edit email={ props.email } /> 
      },
      {
        label: 't(Delete Email)',  
        svg: 'trash',
        access: {
          programs: { $canEdit: props.email.program.id },
          rights: { $oneOf: ['automation:manage_workflows'] }
        },
        confirm: 't(Are you sure you want to delete this email? You will also delete all of the associated performance data)',
        request: {
          endpoint: `/api/admin/automation/emails/${props.email.id}`,
          method: 'DELETE'
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
