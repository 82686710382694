import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import React from 'react'

class Downloading extends React.Component {

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Message { ...this._getMessage() } />
      </ModalPanel>
    )
  }

  _getMessage() {
    return {
      svg: 'refresh_cw',
      spinning: true,
      title: 't(Downloading from Canva)',
      text: 't(We\'re downloading your edited assets from Canva)'
    }
  }

  _getPanel() {
    return {
      title: 't(Downloading from Canva)'
    }
  }

}

export default Downloading

