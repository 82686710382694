import EmailToken from '@apps/automation/admin/tokens/email'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: '/api/admin/truevail/admin/templates/automation_email',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email) => (
        <EmailToken email={ email } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Emails)',
      text: 't(There are no emails for this workflow)',
      svg: 'mail'
    },
    entity: 'email',
    defaultQuery: { workflow_id: props.workflow.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/truevail/admin/templates/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
