import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class HiddenPaymentFieldForm extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onDone: PropTypes.func,
    onUpdate: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate(config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Hidden Payment)',
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { 
              label: 't(Name)',
              name: 'name', 
              type: 'tokenfield',
              required: true, 
              value: config.name 
            },
            { 
              label: 't(Description)', 
              name: 'description', 
              required: true, 
              type: 'textfield' , 
              value: config.description 
            },
            { 
              label: 't(Project)', 
              name: 'project_id', 
              type: 'dropdown', 
              endpoint: '/api/admin/finance/projects', 
              valueKey: 'id',
              textKey: 'display',
              required: true,
              format: ProjectToken, 
              value: config.project_id
            },
            { 
              label: 't(Revenue Type)', 
              name: 'revenue_type_id', 
              type: 'dropdown',
              endpoint: '/api/admin/finance/revenue_types',
              valueKey: 'id', 
              textKey: 'display', 
              required: true, 
              format: RevenueTypeToken,
              value: config.revenue_type_id
            },
            { 
              label: 'Fixed Price',
              name: 'price', 
              required: true, 
              type: 'moneyfield', 
              placeholder: '0.00',
              value: config.price
            },
            { 
              label: 't(Tax Rate)', 
              name: 'tax_rate', 
              required: true, 
              type: 'ratefield',
              value: config.tax_rate
            }
          ]
        }
      ]
    }
  }

  _getDefault() {
    return {
      name: '',
      description: '',
      project_id: null,
      revenue_type_id: null,
      price: null,
      tax_rate: null
    }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default HiddenPaymentFieldForm
