import ModalPanel from '@admin/components/modal_panel'
import SourceToken from '@admin/tokens/source'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Accounts extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Add Signing Account)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ]
    }
  }

  _getSearch() {
    return {
      search: false,
      options: ['adobesign'],
      valueKey: null,
      textKey: 'text',
      format: ({ option }) => (
        <SourceToken source={ option } />
      ),
      onChange: this._handleChoose
    }
  }

  _handleChoose(service) {
    const { admin, host } = this.context
    const url = admin.signUrl(`/admin/oauth/${service}/authorize?type=signatures`)
    host.window.signin(url)
    this.props.onPop(-2)
  }

  _handleBack() {
    this.props.onPop()
  }

}

export default Accounts
