import NameserversTable from '@admin/components/dnstable/nameservers'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ domain }) => {

  const nameserverstable = {
    instructions: 't(In order to resolve queries, you must set your domain to use the following nameservers)',
    nameservers: domain.nameservers,
    last_verified_at: domain.last_verified_at,
    check: {
      request: {
        endpoint: `/api/admin/truevail/admin/tlds/${domain.id}/verify`,
        method: 'PATCH',
        onFailure: () => context.flash.set('error', 't(Unable to verify nameservers)')
      }
    }
  }

  const details = {
    audits: `maha_domains/${domain.id}`,
    comments: `maha_domains/${domain.id}`,
    sections: [
      {
        title: 't(Domain Details)',
        items: [
          { label: 't(Name)', content: domain.name }
        ]
      },
      {
        title: 't(DNS)',
        items: [
          { padded: true, component: <NameserversTable { ...nameserverstable } /> }
        ]
      }
    ]
  }

  if(domain.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This domain was deleted)' }
  } else if(domain.nameserver_status === 'pending') {
    details.alert = { color: 'teal', message: 't(The nameservers have not yet been set for this domain. Before we can resolve DNS queries, you must set the nameservers according to the table below)' }
  } else if(domain.nameserver_status === 'unverified') {
    details.alert = { color: 'red', message: 't(Something has changed with your DNS settings and the nameserver below are no longer mapped appropriately for this domain)' }
  } else if(domain.nameserver_status === 'verified') {
    details.alert = { color: 'green', message: 't(The nameservers are set properly and actively resolving queries for this domain)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  domain: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview domain={ props.domain } />,
  taks: {
    items: [
      {
        label: 't(Delete Domain)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this domain?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/tlds/${props.domain.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete domain)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted domain)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
