import Page from '@admin/components/page'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Subscriptions)',
  access: { rights: { $allOf: ['dashboards:access_app','truevail:access_app'] } },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/signups',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'team', primary: true, format: (advisor) => <TeamToken { ...advisor.team } /> },
      { label: 't(Signed Up)', key: 'signup.created_at', sort: 'signed_up_at', collapsing: true,  format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'square_check',
      title: 't(No Signups)',
      text: 't(No one has signed up yet)'
    },
    entity: 'signup',
    onClick: (record) => context.router.push(`/admin/truevail/agency/advisors/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
