import ProgramForm from '@apps/crm/admin/components/programform'
import Page from '@admin/components/page'
import StoreToken from '../../tokens/store'
import Clone from './clone'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Stores)',
  collection: {
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: StoreToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : [],
      { label: 't(Orders)', key: 'orders_count', collapsing: true, align: 'right' },
      ...context.admin.hasRights({ allOf: ['finance:access_app'] }) ? [
        { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right', format: 'currency' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Event)', fields: [
            { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    empty: {
      icon: 'building-o',
      title: 't(No Stores)',
      text: 't(You have not yet created any stores)',
      buttons: [
        { label: 't(Create Store)', modal: <ProgramForm form={ New } />  }
      ]
    },
    endpoint: '/api/admin/stores/stores',
    entity: 'store',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/stores/stores/${record.id}`),
    recordTasks: (record) => [
      { 
        label: 't(Edit Store)',
        svg: 'square_pen',
        modal: <Edit store={ record } /> 
      },
      { 
        label: 't(Duplicate Store)', 
        svg: 'copy',
        modal: <Clone store={ record } />
      },
      {
        label: 't(Delete Store)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this store? You will loose access to all of the order and revenue information)',
        deletable: true,
        request: {
          endpoint: `/api/admin/stores/stores/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('error', 't(Successfully deleted this store)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete this store)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
