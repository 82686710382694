import HoldMusicToken from '@apps/phone/admin/tokens/hold_music'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

const Overview = ({ number }, { admin }) => {

  const program = {
    label: number.program.title,
    className: 'link',
    route: `/admin/team/programs/${ number.program.id}`
  }

  const details = {
    audits: `phone_phone_numbers/${number.id}`,
    comments: `phone_phone_numbers/${number.id}`,
    sections: [{
      title: 't(Phone Number Details)',
      items: [
        { label: 't(Number)', content: number.formatted },
        { label: 't(Program)', show: admin.team.has_programs, content:  <Button { ...program } /> },
        { label: 't(Hold Music)', content: <HoldMusicToken number={ number } /> }
      ]
    }]
  }

  if(number.released_at) {
    details.alert = { color: 'red', message: `This phone number was released on ${moment(number.released_at).format('MMM DD, YYYY')}` }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  number: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview number={ props.number } />,
  tasks: !props.number.released_at ? {
    items: [
      { 
        label: 't(Edit Phone Number)',
        svg: 'square_pen', 
        modal: <Edit number={ props.number } /> 
      },
      {
        label: 't(Release Phone Number)',
        confirm: 't(Are you sure you want to release this phone number?)',
        request: {
          endpoint: `/api/admin/phone/numbers/${props.number.id}`,
          method: 'DELETE'
        }
      }
    ]
  } : null
})

export default Panel(null, mapPropsToPanel)
