import PurposeField from '@apps/campaigns/admin/components/purposefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    voice_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { voice_campaign } = this.props
    return {
      title: 't(Edit Voice Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/campaigns/voice/${voice_campaign.id}/edit`,
      action: `/api/admin/campaigns/voice/${voice_campaign.id}`,
      cancelSvg: 'chevron_left',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Purpose)', name: 'purpose', show: voice_campaign.direction === 'outbound', type: PurposeField, channel: 'voice', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
