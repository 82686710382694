const badges = [
  { 
    title: 't(Tasks)', 
    weight: 7.3,
    access: {
      rights: { $allOf: ['tasks:access_app'] }
    },
    svg: 'check', 
    route: '/admin/tasks/tasks/to_me', 
    visible: ['desktop','tablet'], 
    tooltip: 'Tasks' 
  }
]

export default badges
