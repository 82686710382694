import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sessions)',
  collection: {
    endpoint: '/api/admin/analytics/sessions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Duration)', key: 'duration', collapsing: true, align: 'right', format: 'duration' },
      { label: 't(Date)', key: 'started_at', collapsing: true, format: 'datetime' }
    ],
    empty: {
      svg: 'chart_line',
      title: 't(No Sessions)',
      text: 't(There are not yet any sessions for this form)'
    },
    defaultFilter: {
      store_id: {
        $eq: props.store.id
      }
    },
    defaultSort: { key: 'started_at', order: 'desc' },
    entity: 'sessions'
  }
})

export default Panel(null, mapPropsToPanel)
