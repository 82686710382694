import Dropdown from '@admin/components/form/dropdown'
import ProfileToken from '@admin/tokens/profile'
import PropTypes from 'prop-types'
import React from 'react'

class SocialPreview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  state = {
    index: 0
  }

  _handleIndex = this._handleIndex.bind(this)

  render() {
    return (
      <div className="maha-social-preview">
        <div className="maha-social-preview-header">
          <Dropdown { ...this._getDropdown() } />
        </div>
        <div className="maha-social-preview-body">
          <iframe { ...this._getIFrame() } />
        </div>
      </div>
    )
  }

  _getDropdown() {
    const { social_campaign } = this.props
    return {
      options: social_campaign.variants.map((variant, index) => ({
        ...variant,
        index
      })),
      deselectable: false,
      valueKey: 'index',
      textKey: 'name',
      format: ProfileToken,
      selectFirst: true,
      onChange: this._handleIndex
    }
  }

  _getIFrame() {
    const { team } = this.context.admin
    const { social_campaign } = this.props
    const { index } = this.state
    return {
      src: `${team.public_host}/mjson/dynamic/campaigns_social_campaign_variants/${social_campaign.variants[index].code}?mode=show`
    }
  }

  _handleIndex(index) {
    this.setState({ index })
  }

}

export default SocialPreview
