import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import Features from '../features'
import PropTypes from 'prop-types'
import Delete from '../delete'
import Edit from '../edit'
import User from '../user'
import React from 'react'

const Overview = ({ team }) => {

  const details = {
    audits: `maha_teams/${team.id}`,
    sections: [
      {
        title: 't(Team Details)',
        items: [
          { label: 't(Title)', content: team.title },
          { label: 't(Provider)', content: team.provider.title },
          { label: 't(Domain)', content: team.domain.name },
          { label: 't(Engine)', content: team.engine },
          { label: 't(Payment Processor)', content: team.payment_processor },
          { label: 't(Support Users)', content: team.support_users.length > 0 ? team.support_users.map(((user, index) => (
            <div key={`user_${index}`}>{ user.full_name }</div>
          ))) : null }
        ]
      }
    ]
  }

  if(!team.is_active) {
    details.alert = { color: 'red', message: 't(This team has been disabled)' }
  } else if(team.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This team was deleted)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  team: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview team={ props.team } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Team)', 
        svg: 'square_pen',
        modal: <Edit team_id={ props.team.id } /> 
      },
      { 
        label: 't(Manage Features)', 
        svg: 'settings',
        modal: <Features team={ props.team } /> 
      },
      {
        label: 't(Disable Team)',
        svg: 'x',
        show: props.team.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/teams/${props.team.id}/activate`,
          body: { is_active: false },
          onFailure: (result) => context.flash.set('error', 't(Unable to disable team)')
        }
      }, {
        label: 't(Enable Team)',
        svg: 'check',
        show: !props.team.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/teams/${props.team.id}/activate`,
          body: { is_active: true },
          onFailure: (result) => context.flash.set('error', 't(Unable to enable team)')
        }
      }, {
        label: 't(Create User)',
        svg: 'user_round',
        modal: <User team_id={ props.team.id } />
      }, {
        label: 't(Delete Team)',
        svg: 'trash',
        deletable: true,
        modal: {
          component: <Delete team={ props.team } />,
          options: {
            width: 400,
            height: 280
          }
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
