import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Calls)',
  collection: {
    endpoint: `/api/admin/team/usage/${props.usage.id}/calls`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Sent/Received)', key: 'created_at', primary: true, format: 'datetime' },
      { label: 't(Direction)', key: 'direction', primary: true },
      { label: 't(From)', key: 'from_number.formatted', primary: true },
      { label: 't(To)', key: 'to_number.formatted', primary: true },
      { label: 't(Duration)', key: 'seconds', collapsing: true, primary: true, format: 'duration', align: 'right' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'phone',
      title: 't(No Calls)',
      text: 't(No calls were sent or received during this billing cycle)'
    },
    entity: 'call',
    onClick: (record) => context.router.push(`/team/calls/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
