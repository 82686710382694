import Button from '@admin/components/button'
import CanvaEditor from './canva_editor'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class CanvaButton extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    label: PropTypes.string,
    photos: PropTypes.array,
    title: PropTypes.string,
    onDone: PropTypes.func
  }

  static defaultProps = {
    label: 't(Edit in Canva)'
  }

  state = {
    canva_url: null,
    job_id: null
  }

  _handleClick = this._handleClick.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { label } = this.props
    return (
      <Button { ...this._getButton() }>
        <span />
        <T text={ label } />
      </Button>
    )
  }

  _getButton() {
    return {
      className: 'maha-canva-editor-button',
      handler: this._handleClick
    }
  }

  _getCanvaEditor() {
    const { canva_url, job_id } = this.state
    const { photos, title } = this.props
    return {
      photos,
      canva_url,
      job_id,
      title,
      onDone: this._handleDone,
      onUpdate: this._handleUpdate
    }
  }

  _handleClick() {
    this.context.modal.open(<CanvaEditor { ...this._getCanvaEditor() } />)
  }

  _handleDone(assets) {
    this.props.onDone(assets.map(asset => ({
      asset_id: asset.id,
      asset,
      transforms: {
        crop: {
          t: 0,
          l: 0,
          w: asset.metadata.width,
          h: asset.metadata.height
        }
      }
    })))
    this.context.modal.close()
  }

  _handleUpdate(config) {
    this.setState(config)
  }
  

}

export default CanvaButton
