import Container from '@admin/components/container'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class PreviewBatch extends React.Component {

  static propTypes = {
    agency: PropTypes.object,
    type: PropTypes.object,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agency, type } = this.props
    return {
      title: 't((Re)schedule All)',
      saveText: 'Next',
      onCancel: this._handleCancel,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { 
              label: 't(Introduction)',
              name: 'preview_introduction', 
              type: 'htmlfield', 
              toolbar: ['b','em','u','ol','ul','a'], 
              defaultValue: agency.config[`${type}_preview_message`]
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

const mapResources = (props, context) => ({
  agency: '/api/admin/truevail/agency'
})

export default Container(mapResources)(PreviewBatch)
