import NotificationRecipientToken from '@admin/components/flowchart_designer/blocks/notification/recipient'
import EmailRecipientToken from '@admin/components/flowchart_designer/blocks/internal_email/recipient'
import NewNotificationRecipient from '@admin/components/flowchart_designer/blocks/notification/new'
import SmsRecipientToken from '@admin/components/flowchart_designer/blocks/internal_sms/recipient'
import NewEmailRecipient from '@admin/components/flowchart_designer/blocks/internal_email/new'
import NewSmsRecipeint from '@admin/components/flowchart_designer/blocks/internal_sms/new'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Workflows extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    version: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata, props, version } = this.props
    const { advisor, form } = formdata
    const { entity } = props

    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: form.workflows.map(workflow => ({
        label: `${form.workflows[0].title}`,
        fields: version.value.steps.reduce((fields, step, index) => [
          ...fields,
          ...(step.type === 'communication' && step.action === 'email') ? [{
            label: `Step #${index +1}: ${step.action.toUpperCase()} - ${step.config.email.title}`,
            type: 'segment',
            fields: [
              {
                label: 't(Template)', 
                name: `config.workflows.${workflow.id}.steps.${index}.template_id`, 
                type: 'dropdown',
                endpoint: `/api/admin/truevail/${entity}/advisors/${advisor.id}/templates`, 
                valueKey: 'id',
                textKey: 'title',
                selectFirst: true
              },
              {
                label: 't(From)', 
                name: `config.workflows.${workflow.id}.steps.${index}.sender_id`, 
                type: 'dropdown',
                endpoint: `/api/admin/truevail/${entity}/advisors/${advisor.id}/senders`, 
                valueKey: 'id',
                textKey: 'rfc822',
                selectFirst: true,
                required: true
              }
            ]  
          }]: (step.type === 'contact' && step.action === 'list') ? [{
            label: `Step #${index +1}: LIST`,
            type: 'segment',
            fields: [
              { 
                label: 't(List)', 
                name: `config.workflows.${workflow.id}.steps.${index}.list_id`, 
                type: 'dropdown',
                endpoint: `/api/admin/truevail/${entity}/advisors/${advisor.id}/lists`, 
                valueKey: 'id', 
                textKey: 'title', 
                selectFirst: true,
                required: true
              }
            ]
          }] : (step.type === 'administrative' && step.action === 'email') ? [{
            label: `Step #${index +1}: SEND INTERNAL EMAIL`,
            type: 'segment',
            fields: [
              { 
                label: 't(Recipients)', 
                name: `config.workflows.${workflow.id}.steps.${index}.recipients`, 
                type: 'collectionfield', 
                orderable: false, 
                cloneable: false, 
                editable: false, 
                entity: 'recipient', 
                newForm: (props) => <NewEmailRecipient endpoint={`/api/admin/truevail/${entity}/advisors/${advisor.id}/users`} { ...props } />, 
                token: EmailRecipientToken, 
                required: true 
              }
            ]
          }] : (step.type === 'administrative' && step.action === 'sms') ? [{
            label: `Step #${index +1}: SEND INTERNAL SMS`,
            type: 'segment',
            fields: [
              { 
                label: 't(Recipients)', 
                name: `config.workflows.${workflow.id}.steps.${index}.recipients`, 
                type: 'collectionfield', 
                orderable: false, 
                cloneable: false, 
                editable: false, 
                entity: 'recipient', 
                newForm: (props) => <NewSmsRecipeint endpoint={`/api/admin/truevail/${entity}/advisors/${advisor.id}/users`} { ...props } />, 
                token: SmsRecipientToken, 
                required: true 
              }
            ]
          }] : (step.type === 'administrative' && step.action === 'notification') ? [{
            label: `Step #${index +1}: SEND NOTIFICATION`,
            type: 'segment',
            fields: [
              { 
                label: 't(Recipients)', 
                name: `config.workflows.${workflow.id}.steps.${index}.recipients`, 
                type: 'collectionfield', 
                orderable: false, 
                cloneable: false, 
                editable: false, 
                entity: 'recipient', 
                newForm: (props) => <NewNotificationRecipient endpoint={`/api/admin/truevail/${entity}/advisors/${advisor.id}/users`} { ...props } />, 
                token: NotificationRecipientToken, 
                required: true 
              }
            ]
          }] : []
        ], [])
      }))
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }


  _handleSuccess(form) {
    const { formdata } = this.props
    this.props.onSave({
      config: {
        ...formdata.config || {},
        ...form.config   
      }
    })
  }

}

const mapResources = (props, context) => ({
  version: `/api/admin/truevail_workflows/${props.formdata.form.workflows[0].id}/config/versions/published`
})

export default Container(mapResources)(Workflows)

