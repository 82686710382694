import Container from '@admin/components/container'
import Searchbox from '@admin/components/searchbox'
import Transition from './transition'
import PropTypes from 'prop-types'
import Stage from './stage'
import React from 'react'

class Dealboard extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    fields: PropTypes.array,
    pipeline: PropTypes.object,
    stages: PropTypes.array
  }

  state = {
    q: ''
  }

  _handleTransition = this._handleTransition.bind(this)
  _handleType = _.debounce(this._handleType.bind(this), 250)

  render() {
    const { stages } = this.props
    return (
      <div className="sales-dealboard">
        <div className="sales-dealboard-header">
          <Searchbox { ...this._getSearchbox() } />
        </div>
        <div className="sales-dealboard-body">
          { stages.map((stage, index) => (
            <Stage { ...this._getStage(stage) } key={`stage_${stage.id}`} />
          )) }
        </div>
      </div>
    )
  }

  _getStage(stage) {
    const { fields, pipeline } = this.props
    const { q } = this.state
    return {
      fields,
      stage,
      pipeline,
      q,
      onTransition: this._handleTransition
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Search',
      onChange: this._handleType
    }
  }

  _getTransition(deal, from_stage, to_stage) {
    return {
      deal,
      from_stage,
      to_stage
    }
  }

  _handleTransition(deal, from_stage, to_stage) {
    this.context.modal.open(<Transition { ...this._getTransition(deal, from_stage, to_stage) } />)
  }

  _handleType(q) {
    this.setState({ q })
  }

}

const mapResources = (props, context) => ({
  stages: `/api/admin/sales/pipelines/${props.pipeline.id}/stages`
})

export default Container(mapResources)(Dealboard)
