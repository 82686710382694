import ProductToken from '@apps/stores/admin/tokens/product'
import Panel from '@admin/components/panel'
import New from '../products/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Products)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/products`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (product) => (
        <ProductToken product={ product } />
      ) }
    ],
    empty: {
      svg: 'shopping_cart',
      title: 't(No products)',
      text: 't(You have not yet created any products)',
      buttons: [
        { label: 't(Create Product)', modal: <New store={ props.store } /> }
      ]
    },
    criteria: {
      fields: [
        { label: 't(Product)', fields: [
          {  name: 't(Category)', key: 'category_id',type: 'select', endpoint: `/api/admin/stores/stores/${props.store.id}/categories`, valueKey: 'id', textKey: 'title' }
        ] }
      ]
    },
    recordTasks: (product) => [
      {
        label: 't(Activate Product)',
        svg: 'check',
        show: !product.is_active,
        confirm: 't(Are you sure you want to activate this product?)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/stores/stores/${props.store.id}/products/${product.id}/activate`,
          body: { is_active: true },
          onFailure: () => context.flash.set('error', 't(Unable to activate this product)'),
          onSuccess: () => context.flash.set('success', 't(Successfully activated this product)')
        }
      },
      {
        label: 't(Deactivate Product)',
        svg: 'x',
        show: product.is_active,
        confirm: 't(Are you sure you want to deactivate this product?)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/stores/stores/${props.store.id}/products/${product.id}/activate`,
          body: { is_active: false },
          onFailure: () => context.flash.set('error', 't(Unable to deactivate this product)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deactivated this product)')
        }
      },
      {
        label: 't(Delete Product)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this product? You will also delete all of the associated variants)',
        deletable: true,
        request: {
          endpoint: `/api/admin/stores/stores/${props.store.id}/products/${product.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this product)'),
          onSuccess: () => context.flash.set('success', 't(The product was successfuly deleted)')
        }
      }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/stores/stores/${props.store.id}/products/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <New store={ props.store } />
  }
})

export default Panel(null, mapPropsToPanel)
