import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    relative: PropTypes.bool,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    reminder: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { relative } = this.props
    const { reminder } = this.state
    return {
      title: 't(New Reminder)',
      method: 'POST',
      cancelSvg: 'chevron_left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Method)', name: 'method', type: 'radiogroup', options: [
              { value: 'email', text: 'Email' },
              { value: 'sms', text: 'SMS' },
              { value: 'notification', text: 'Notification' }
            ], defaultValue: 'email' },
            { label: 't(Timing)', type: 'segment', fields: [
              ...relative ? [
                { name: 'timing', type: 'radiogroup', options: [
                  { value: '5_mins', text: 't(5 minutes before)' },
                  { value: '10_mins', text: 't(10 minutes before)' },
                  { value: '15_mins', text: 't(15 minutes before)' },
                  { value: '30_mins', text: 't(30 minutes before)' },
                  { value: '1_hour', text: 't(1 hour before)' },
                  { value: '2_hours', text: 't(2 hours before)' },
                  { value: '1_day', text: 't(1 day before)' },
                  { value: '2_days', text: 't(2 days before)' },
                  { value: '1_week', text: 't(1 week before)' },
                  { value: 'custom', text: 't(Custom)' }
                ], defaultValue: '5_mins' }
              ] : [
                { name: 'timing', type: 'hidden', value: 'custom' }
              ],
              ...!relative || reminder.timing === 'custom' ? [
                { label: 't(Date)', name: 'date', type: 'datefield', defaultValue: moment() }
              ] : [],
              ..._.includes(['1_day','2_days','1_week','custom'], reminder.timing) ? [
                { label: 't(Time)', name: 'time', type: 'timefield', defaultValue: moment().add(1,'hour').startOf('hour') }
              ] : []
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(reminder) {
    this.setState({ reminder })
  }

  _handleSuccess(reminder) {
    this.props.onDone(reminder)
  }

}

export default New
