import ShortLink from '@admin/components/shortlink'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Cancel from '../cancel'
import Clone from '../clone'
import moment from 'moment'
import Edit from '../edit'
import qs from 'qs'

const Overview = ({ event }, { admin }) => {

  const program = {
    label: event.program.title,
    className: 'link',
    route: `/admin/team/programs/${event.program.id}`
  }

  const googlequery = qs.stringify({
    action: 'TEMPLATE',
    text: event.title,
    Overview: event.description,
    dates: [
      moment(event.sessions[0].starts_at).format('YYYYMMDD[T]HHmmss[Z]'),
      moment(event.sessions[0].ends_at).format('YYYYMMDD[T]HHmmss[Z]')
    ].join('/'),
    ...event.sessions[0].location ? {
      location: event.sessions[0].location.address.description
    } : {},
    sf: true,
    output: 'xml'
  })

  const shortlink = {
    shortlink: event.shortlink,
    text: 't(Share this event)',
    title: event.title,
    buttons: [
      {
        title: 't(iCal)',
        svg: 'calendar_days',
        url: admin.signUrl(`/api/admin/events/events/${event.id}/ical`)
      },
      {
        title: 't(Google)',
        icon: 'google',
        url: `https://www.google.com/calendar/render?${googlequery}`
      }
    ]
  }

  const details = {
    audits: `events_events/${event.id}`,
    comments: `events_events/${event.id}`,
    before: <ShortLink { ...shortlink } />,
    sections: [
      {
        title: 't(Event Details)',
        items: [
          { label: 't(Title)', content: event.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
        ]
      },
      {
        title: 't(Options)',
        items: [
          { label: 't(Pay by Check)', content: event.payment_config.check_enabled ? 'ENABLED' : 'DISABLED' },
          { label: 't(Pay Later)', content: event.payment_config.async_enabled ? 'ENABLED' : 'DISABLED' }
        ]
      }
    ]
  }

  if(event.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This event was deleted)' }
  } else if(event.canceled_at !== null) {
    details.alert = { color: 'red', message: 't(This event was canceled)' }
  }

  if(event.organizers.length > 0) {
    details.sections[0].items.push({
      label: 't(Organizers)',
      content: event.organizers.map((organizer, index) => (
        <Fragment key={`organizer_${index}`}>
          { organizer.name }<br />
        </Fragment>
      ))
    })
  }

  if(event.canceled_at !== null) {
    details.sections.push({
      title: 't(Cancellation)',
      items: [
        { label: 'Canceled', content: event.canceled_at, format: 'datetime' },
        { label: 't(Reason)', content: event.cancellation_reason }
      ]
    })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  event: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview event={ props.event } />,
  tasks: props.event.deleted_at === null ? {
    items: [
      {
        label: 't(Edit Event)',
        svg: 'square_pen',
        show: props.event.canceled_at === null,
        access: { 
          programs: { $canEdit: props.event.program.id },
          rights: { $oneOf: ['events:manage_events'] } 
        },
        modal: <Edit event={ props.event } />
      },
      {
        label: 't(Cancel Event)',
        show: props.event.canceled_at === null,
        access: { 
          programs: { $canEdit: props.event.program.id },
          rights: { $oneOf: ['events:manage_events'] } 
        },
        modal: <Cancel event={ props.event } />
      },
      {
        label: 't(Duplicate Event)',
        access: { 
          programs: { $canEdit: props.event.program.id },
          rights: { $oneOf: ['events:manage_events'] } 
        },
        modal: <Clone event={ props.event } />
      },
      {
        label: 't(Delete Event)',
        svg: 'trash',
        access: { 
          programs: { $canEdit: props.event.program.id },
          rights: { $oneOf: ['events:manage_events'] } 
        },
        confirm: 't(Are you sure you want to delete this event? You will loose access to all of the registration and revenue information.)',
        deletable: true,
        request: {
          endpoint: `/api/admin/events/events/${props.event.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this event)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this event)')
        }
      }
    ]
  } : null
})

export default Panel(null, mapPropsToPanel)
