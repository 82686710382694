import PageContainer from '../../entities/page/containers/new'
import EmailSection from '../../entities/email/sections/new'
import PageSection from '../../entities/page/sections/new'
import EmailColumn from '../../entities/email/columns/new'
import EmailBlock from '../../entities/email/blocks/new'
import PageColumn from '../../entities/page/columns/new'
import PageBlock from '../../entities/page/blocks/new'
import EmailRow from '../../entities/email/rows/new'
import PageRow from '../../entities/page/rows/new'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

const entities = {
  email_section: { label: 'section', component: EmailSection },
  email_row: { label: 'row', component: EmailRow },
  email_column: { label: 'column', component: EmailColumn },
  email_block: { label: 'block', component: EmailBlock },
  page_container: { label: 'container', component: PageContainer },
  page_section: { label: 'section', component: PageSection },
  page_row: { label: 'row', component: PageRow },
  page_column: { label: 'column', component: PageColumn },
  page_block: { label: 'section', component: PageBlock }
}

class SegmentsField extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    entity: PropTypes.string,
    pageType: PropTypes.string,
    value: PropTypes.array,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    segments: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleCreate = this._handleCreate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { segments } = this.state
    const { value } = this.props
    if(!_.isEqual(segments, prevState.segments)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    const { entity } = this.props
    return {
      label: `+ t(add new) ${entities[entity].label}`,
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput(segment, index) {
    return {
      type: 'textfield',
      value:  segment.meta.label,
      onChange: this._handleUpdate.bind(this, index)
    }
  }

  _getList() {
    const { segments } = this.state
    const { entity } = this.props
    return {
      actions: (item, index) => [
        {
          svg: 'x',
          confirm: `t(Are you sure you want to delete this) ${entities[entity].label}?`,
          handler: this._handleRemove.bind(this, index)
        }
      ],
      empty: <Token text={`t(You have not yet added any) ${pluralize(entities[entity].label)}`} />,
      items: segments,
      format: (segment) => (
        <div className={`segmentsfield-segment ${segment.entity}`}>
          <div className="segmentsfield-segment-icon">
            <Icon icon="square" />
          </div>
          <div className="segmentsfield-segment-label">
            <Input { ...this._getInput(segment, segment.index) } />
          </div>
        </div>
      ),
      onMove: this._handleMove
    }
  }

  _getNew() {
    const { pageType, website } = this.props
    return {
      context: 'template',
      pageType,
      website,
      onDone: this._handleCreate
    }
  }

  _getSegment(segment, index) {
    const { entity } = this.props
    return {
      entity,
      index,
      segment,
      onMove: this._handleMove.bind(this, index),
      onRemove: this._handleRemove.bind(this, index),
      onUpdate: this._handleUpdate.bind(this, index)
    }
  }

  _handleAdd() {
    const { entity } = this.props
    const Component = entities[entity].component
    this.context.modal.open(<Component { ...this._getNew() } />)
  }

  _handleCreate(segment) {
    const { segments } = this.state
    this.setState({
      segments: [
        ...segments,
        segment
      ]
    })
  }

  _handleChange() {
    const { segments } = this.state
    this.props.onChange(segments)
  }

  _handleMove(from, to) {
    const { segments } = this.state
    this.setState({
      segments: (from < to) ? [
        ...segments.slice(0, from),
        ...segments.slice(from + 1, to + 1),
        segments[from],
        ...segments.slice(to + 1)
      ] : [
        ...segments.slice(0, to),
        segments[from],
        ...segments.slice(to, from),
        ...segments.slice(from + 1)
      ]
    })
  }

  _handleRemove(index) {
    const { segments } = this.state
    this.setState({
      segments: segments.filter((segment, i) => {
        return i !== index
      })
    })
  }

  _handleSet(segments) {
    this.setState({ segments })
  }

  _handleUpdate(index, label) {
    const { segments } = this.state
    this.setState({
      segments: segments.map((segment, i) => {
        if(i !== index) return segment
        return {
          ...segment,
          meta: {
            label
          }
        }
      })
    })
  }

}

export default SegmentsField
