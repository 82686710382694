import Panel from '@admin/components/panel'
import moment from 'moment'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Holidays)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/holidays',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Holiday)', key: 'date', format: (holiday) => (
        <div className="token">
          { holiday.description } ({ moment(holiday.date).format(context.admin.account.date_format) })
        </div>
      ) }
    ],
    defaultSort: { key: 'date', order: 'desc' },
    empty: {
      icon: 'gift',
      title: 't(No Upcoming Holidays)',
      text: 't(You have not yet have any upcoming holidays)',
      buttons: [
        { 
          label: 't(Create Holiday)', 
          modal: New 
        }
      ]
    },
    entity: 'holiday',
    recordTasks: (record) => [
      {
        label: 't(Delete Holiday)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this holiday?',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/deliverability/holidays/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted holiday)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete holiday)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
