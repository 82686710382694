import FormDesigner from '@apps/forms/admin/components/form_designer/wrapper'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Fields = ({ form }) => {

  const designer = {
    form,
    code: form.code,
    endpoint: `/api/admin/truevail/agency/forms/${form.id}`
  }

  return <FormDesigner { ...designer } />

}

Fields.propTypes = {
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Fields)',
  component: <Fields form={ props.form } />
})

export default Panel(null, mapPropsToPanel)
