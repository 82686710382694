import DomainToken from '@apps/crm/admin/tokens/domain'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Domains)',
  collection: {
    endpoint: '/api/admin/truevail/admin/tlds',
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: DomainToken },
      { label: 't(Nameserver)', key: 'nameserver_status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'globe',
      title: 't(No Domains)',
      text: 't(You have not yet created any domains for this domain)',
      buttons: [
        { label: 't(Create Domain)', modal: New }
      ]
    },
    entity: 'domain',
    onClick: (record) => context.router.push(`/truevail/admin/tlds/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Domain)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
