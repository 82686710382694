import Button from '@admin/components/button'
import Link from '@admin/components/link'
import PropTypes from 'prop-types'
import React from 'react'

class ComposerLink extends React.Component {

  static propTypes = {
    link: PropTypes.object,
    onRemove: PropTypes.func
  }

  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { link } = this.props
    return (
      <div className="maha-composer-link">
        <Link link={ link } active={ false } />
        <Button { ...this._getRemove() } />
      </div>
    )
  }

  _getRemove() {
    return {
      svg: 'x',
      className: '',
      handler: this._handleRemove
    }
  }

  _handleRemove() {
    this.props.onRemove()
  }

}

export default ComposerLink
