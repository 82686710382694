import ButtonStyleField from '../buttonstylefield'
import Form from '@admin/components/form'
import ButtonField from '../buttonfield'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    theme: PropTypes.object,
    website: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    button: {
      content: {},
      styles: {}
    }
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { device, theme, website } = this.props
    const { button } = this.state
    return {
      title: 't(New Button)',
      cancelSvg: 'chevron_left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Button)', name: 'content.button', type: ButtonField, theme, website, value: button.content.button }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              fields: [
                { label: 't(Button)',  name: 'styles.button', device, theme, type: ButtonStyleField, value: button.styles.button }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(button) {
    this.setState({ button })
  }

  _handleDone(button) {
    this.props.onDone(button)
  }

}

export default New
