import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Panel from '@admin/components/panel'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Agency)', key: 'subscription.agency.organization', sort: 'agency', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'agency', order: 'asc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this social batch)',
      buttons: [
        {
          label: 't(Publish Agency Social Batches)',
          svg: 'users_round',
          modal: <Publish social_batch={ props.social_batch } />
        }
      ]
    },
    entity: 'publishing',
    onClick: (record) => {
      const { admin, provider, host } = context
      const path = `truevail/agency/social_batches/${record.agency_social_batch.id}`
      if(admin.team.id === record.subscription.agency.team.id) return context.router.push(`/admin/${path}`)
      host.window.open(`${provider.admin_host}/${record.subscription.agency.team.subdomain}/${path}`)
    }
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Agency Social Batches)',
    modal: <Publish social_batch={ props.social_batch } />
  }
})

export default Panel(null, mapPropsToPanel)
