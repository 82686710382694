import ProgramForm from '@apps/crm/admin/components/programform'
import ListToken from '@apps/crm/admin/tokens/list'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context) => ({
  title: 't(Lists)',
  access: { rights: { $oneOf: ['crm:view_lists','crm:manage_lists'] } },
  collection: {
    endpoint: '/api/admin/crm/lists',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: ListToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : [],
      { label: 't(Type)', key: 'type', collapsing: true, format: ({ type }) => type.toUpperCase() },
      { label: 't(Contacts)', key: 'contacts_count', collapsing: true, align: 'right' }
    ],
    criteria: {
      fields: [
        { label: 't(List)', fields: [
          { name: 't(Type)', key: 'type', type: 'select', options: [
            { value: 'private', text: 't(Private)' },
            { value: 'public', text: 't(Public)' }
          ] }
        ] }
      ]
    },
    empty: {
      title: 't(No Lists)',
      text: 't(You have not yet created any lists)',
      svg: 'clipboard_list',
      buttons: [
        { 
          label: 't(Create List)',
          access: { rights: { $oneOf: ['crm:manage_lists'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Contacts)', key: 'contacts_count' }
    ],
    entity: 'list',
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/admin/crm/lists/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create List)',
    access: { rights: { $oneOf: ['crm:manage_lists'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
