import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Blocked)',
  collection: {
    endpoint: `/api/admin/phone/numbers/${props.number.id}/blocked`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Number)', key: 'number.formatted', primary: true },
      { label: 't(Blocked)', key: 'created_at', primary: true, format: 'date' }
    ],
    recordTasks: (record) => [
      {
        label: 't(Unblock Number)',
        confirm: 't(Are you sure you want to unblock this number?)',
        request: {
          endpoint: `/api/admin/phone/numbers/${props.number.id}/blocked/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to unblock this number)')
        }
      }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'ban',
      title: 't(No Blocked Numbers)',
      text: 't(This number has not yet blocked any numbers)'
    },
    entity: 'number'
  },
  task: {
    svg: 'plus',
    modal: <New number={ props.number } />
  }
})

export default Panel(null, mapPropsToPanel)
