import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Library from './library'
import types from '../types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    form: PropTypes.string,
    type: PropTypes.string,
    workflow: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { form, type, workflow } = this.props
    return {
      title: types[type].new_label,
      method: 'POST',
      action: '/api/admin/truevail/admin/templates',
      props: {
        form,
        type,
        workflow
      },
      getSteps: this._getSteps,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps() {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Library)', component: Library }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(template) {
    this.context.router.push(`/admin/truevail/admin/templates/${template.id}`)
    this.context.modal.close()
  }

}

export default New
