const triggers = [
  {
    svg: 'shopping_cart',
    code: 'order_created',
    title: 't(Order Created)',
    text: 't(Contact places an order in store)'
  },
  {
    icon: 'truck',
    code: 'order_shipped',
    title: 't(Order Shipped)',
    text: 't(Contact order is shipped from store)'
  }
]

export default triggers
