import FileBrowser from '@admin/components/file_browser'
import { CSSTransition } from 'react-transition-group'
import Stack from '@admin/components/stack'
import Icon from '@admin/components/icon'
import Truevail from '../truevail'
import Unsplash from '../unsplash'
import PropTypes from 'prop-types'
import Sources from '../sources'
import Device from '../device'
import Photos from '../photos'
import Review from '../review'
import Phone from '../phone'
import Dalle from '../dalle'
import Drive from '../drive'
import React from 'react'
import Web from '../web'

class Explorer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    allow: PropTypes.object,
    counts: PropTypes.object,
    custom: PropTypes.array,
    doneText: PropTypes.string,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    sources: PropTypes.array,
    title: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
    onRemove: PropTypes.func
  }

  stackRef = React.createRef()

  state = {
    review: false
  }

  _handleChooseSource = this._handleChooseSource.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleToggleReview = this._handleToggleReview.bind(this)

  render() {
    const { files, multiple } = this.props
    return (
      <div className={ this._getClass() }>
        <Stack { ...this._getStack() } />
        <CSSTransition in={ multiple && files.length > 0 } classNames="slideup" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <Review { ...this._getReview() } />
        </CSSTransition>
      </div>
    )
  }

  _getClass() {
    const { files, multiple } = this.props
    const classes = ['maha-attachments-explorer']
    if(multiple && files.length > 0) classes.push('reviewable')
    return classes.join(' ')
  }

  _getReview() {
    const { files, onRemove } = this.props
    const { review } = this.state
    return {
      files,
      review,
      onToggleReview: this._handleToggleReview,
      onRemove
    }
  }

  _getStack() {
    const { files } = this.props
    return {
      display_name: 'attachments_explorer',
      ref: this.stackRef,
      // key: `sources-${files.length}`,
      initial: [
        { component: Sources, props: this._getSources.bind(this) }
      ]
    }
  }

  _getSourcePanel(service) {
    return service.type === 'photos' ? Photos : FileBrowser
  }

  _getServices() {
    const { allow, custom, sources } = this.props
    const { admin, provider } = this.context
    const { clientWidth } = document.body
    return [
      ...clientWidth < 768 ? [
        { service: 'camera', label: 't(Your Camera)', component: Phone, id: 'camera', icon: 'camera', type: 'photos' },
        { service: 'device', label: 't(Your Device)', component: Phone, id: 'device' }
      ] : [
        { service: 'device', label: 't(Your Device)', panel: Device, id: 'device' }
      ],
      { service: 'web', label: 't(The Web)', panel: Web, id: 'web' },
      ..._.includes(admin.rights, 'drive:access_app') ? [
        { service: 'maha', label: `t(${provider.title} Drive)`, panel: Drive, id: 'maha', image: `/imagecache/w=20&h=20${provider.logo}` }
      ] : [],
      { service: 'unsplash', label: 't(Unsplash)', panel: Unsplash, type: 'photos', id: 'unsplash' },
      { service: 'dalle', label: 't(Dall-E 3)', panel: Dalle, type: 'photos', id: 'dalle' },
      ...custom ? custom : [],
      ...sources.map(source => ({
        ...source,
        panel: this._getSourcePanel(source.service)
      }))
    ].filter(source => {
      const service_allowed = !allow.services || _.includes(allow.services, source.service)
      const type_allowed = !allow.types || !source.type || _.includes(allow.types, source.type)
      const truevail_allowed = source.service !== 'truevail' || allow.truevail
      const unsplash_allowed = source.service !== 'unsplash' || allow.unsplash
      const dalle_allowed = source.service !== 'dalle' || allow.dalle
      return service_allowed && type_allowed && unsplash_allowed && truevail_allowed && dalle_allowed
    })
  }

  _getSource(source) {
    const { allow, doneText, files, multiple, onAdd, onRemove } = this.props
    return {
      allow,
      doneText,
      files,
      multiple,
      source,
      ...source.props || {},
      onAdd,
      onBack: this._handlePop,
      onNext: this._handleNext,
      onPop: this._handlePop,
      onPush: this._handlePush,
      onRemove
    }
  }

  _getSources() {
    const { allow, counts, doneText, files, multiple, title, onAdd, onBack, onCancel, onRemove } = this.props
    return {
      allow,
      counts,
      doneText,
      files,
      multiple,
      sources: this._getServices(),
      title,
      onAdd,
      onBack,
      onCancel,
      onChooseSource: this._handleChooseSource,
      onNext: this._handleNext,
      onPop: this._handlePop,
      onPush: this._handlePush,
      onRemove
    }
  }

  _handleChooseSource(source) {
    this._handlePush(source.panel, this._getSource.bind(this, source))
  }

  _handleNext() {
    this.props.onNext()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleToggleReview() {
    const { review } = this.state
    this.setState({
      review: !review
    })
  }

}

export default Explorer
