import BounceTypeToken from '../../../tokens/bounce_type'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Bounces)',
  access: {},
  collection: {
    endpoint: `/api/admin/automation/emails/${props.email.id}/bounces`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => (
        <ContactToken { ...email.contact } property="rfc822" /> 
      ) },
      { label: 't(Bounce Type)', key: 'bounce_type', primary: true, padded: true, collapsing: true, format: BounceTypeToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'mail',
      title: 't(No Deliveries)',
      text: 't(This email has not yet been sent)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
