import SocialCampaignTypeToken from '@apps/campaigns/admin/tokens/social_campaign_type'
import MJSONScreenshot from '@admin/components/mjson_screenshot'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (social_campaign) => ({
  engine: 'mjson',
  entity: `truevail_social_campaign_variants/${social_campaign.variants[0].code}`,
  width: 100,
  height: 75,
  screenshoted_at: social_campaign.variants[0].screenshoted_at
})

const SocialCampaignCard = ({ social_campaign }) => (
  <div className="maha-card-layout-card-body">
    <div className="maha-card-layout-card-screenshot">
      <MJSONScreenshot { ...getScreenshot(social_campaign) } />
    </div>
    <div className="maha-card-layout-card-details">
      <SocialCampaignTypeToken social_campaign={ social_campaign } />
      <div className="token-stats">
        { social_campaign.variants_count } variants, { social_campaign.publishings_count } publishings<br />
        <LibraryToken value={ social_campaign.library_status } />
      </div>
    </div>
  </div>
)

SocialCampaignCard.propTypes = {
  social_campaign: PropTypes.object
}

export default SocialCampaignCard
