import ListToken from '@apps/crm/admin/tokens/list'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forms)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/lists`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: ListToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      title: 't(No Lists)',
      text: 't(You have not yet created any lists for this advisor)',
      svg: 'clipboard_list',
      buttons: [
        { 
          label: 't(Create List)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'list',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/crm/lists/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Form)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
