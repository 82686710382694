import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  photo: {
    title: 't(Photo Post)',
    text: 't(Post with one or more photos and a caption)'
  },
  video: {
    title: 't(Video Post)',
    text: 't(Post with a video and a caption)'
  },
  link: {
    title: 't(Link Post)',
    text: 't(Post with a link and a caption)'
  },
  reel: {
    title: 't(Reel)',
    text: 't(Reel with a video)'
  },
  story: {
    title: 't(Story)',
    text: 't(Story with a photo or video)'
  }
}

const getServices = (type, features) => [
  ..._.includes(['photo','video','story','reel'], type) ? ['Instagram'] : [],
  ..._.includes(['photo','video','link','story','reel'], type) ? ['Facebook'] : [],
  ...features.linkedin === true && _.includes(['photo','video','link'], type) ? ['LinkedIn'] : [],
  ...features.youtube === true && _.includes(['reel','video'], type) ? ['YouTube'] : [],
  ...features.tiktok === true && _.includes(['reel'], type) ? ['TikTok'] : []
]

const SocialTypeToken = ({ value }, { admin }) => {
  const services = getServices(value, admin.features)
  return (
    <div className="truevail-social-type-token">
      <strong><T text={ service_types[value].title } /></strong><br />
      <span>{ services.join(', ') }</span><br />
      <T text={ service_types[value].text } />
    </div>
  )
}

SocialTypeToken.contextTypes = {
  admin: PropTypes.object
}

SocialTypeToken.propTypes = {
  value: PropTypes.string
}

export default SocialTypeToken
