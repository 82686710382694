import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import React from 'react'

class Downloading extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    design: PropTypes.object,
    profile: PropTypes.object,
    onNext: PropTypes.func
  }

  render() {
    return <Message { ...this._getMessage() } />
  }

  componentDidMount() {
    this._handleDownload()
  }

  _handleDownload() {
    const { canva_id, canva_title, profile } = this.props.design
    this.context.network.request({
      endpoint: `/api/admin/profiles/${profile.id}/canva/convert`,
      method: 'POST',
      body: {
        design_id: canva_id
      },
      onSuccess: ({ data }) => {
        this.props.onNext({
          profile,
          title: canva_title,
          type: 'photo',
          canva_template: {
            entity: {
              content: {
                photos: data.map(asset => ({ 
                  photo: {
                    asset_id: asset.id,
                    asset,
                    transforms: {
                      crop: {
                        l: 0,
                        t: 0,
                        w: asset.metadata.width,
                        h: asset.metadata.height
                      }
                    }
                  } 
                }))
              }
            }
          }
        })
      }
    })
  }

  _getMessage() {
    return {
      svg: 'refresh_cw',
      spinning: true,
      title: 't(Downloading from Canva)',
      text: 't(We\'re downloading design assets from Canva)'
    }
  }

}

export default Downloading

