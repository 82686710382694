
import { useTasksContext } from '@admin/components/tasks'
import React from 'react'
import Card from './card'

const CardContainer = (props) => {
  
  const { tasks } = useTasksContext()

  const mergedProps = {
    context: {
      tasks
    },
    ...props
  }

  return <Card { ...mergedProps } />

}

CardContainer.propTypes = {}

export default CardContainer