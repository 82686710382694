import { CSSTransition } from 'react-transition-group'
import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

export const TasksContext = React.createContext()
TasksContext.displayName = 'TasksContext'

export const useTasksContext = () => React.useContext(TasksContext)

class Tasks extends React.Component {

  static childContextTypes = {
    tasks: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.func,
    allowed: PropTypes.any,
    children: PropTypes.any
  }

  state = {
    code: null,
    component: null,
    position: null,
    title: null,
    items: null,
    open: false,
    onDone: null
  }

  _handleClickOutside = this._handleClickOutside.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handleOpen = this._handleOpen.bind(this)

  render() {
    const { component, open, title } = this.state
    const allowed = this._getAllowed()
    if(!allowed.length === 0) return null
    const show = open && component !== null
    return (
      <TasksContext.Provider value={ this.getChildContext() }>
        { this.props.children }
        <CSSTransition key="maha-tasks-list" in={ show } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div { ...this._getList() }>
            { component }
            { title &&
              <div className="maha-tasks-list-header">
                { title }
              </div>
            }
            { allowed.length > 0 &&
              <div className="maha-tasks-list-body">
                { allowed.map((item, index) => (
                  <div className="maha-tasks-list-item" key={`task_${index}`}>
                    <Button { ...this._getButton(item) }/>
                  </div>
                )) }
              </div>
            }
            <Button { ...this._getCancel() } />
          </div>
        </CSSTransition>
      </TasksContext.Provider>
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state
    if(open !== prevState.open && !open) {
      setTimeout(this._handleClear, 500)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  getChildContext() {
    return {
      tasks: {
        open: this._handleOpen,
        close: this._handleClose
      }
    }
  }

  _getAllowed() {
    const { admin } = this.context
    const { items } = this.state
    return items ? items.filter(item => {
      if(item.show !== undefined && !item.show) return false
      if(item.access !== undefined && !canAccess(item.access, admin)) return false
      return true
    }) : []
  }

  _getButton(item) {
    const { deletable } = item
    const classes = ['maha-task']
    if(deletable === true) classes.push('deletable')
    return {
      ...item,
      className: classes.join(' '),
      onDone: this._handleClose.bind(this)
    }
  }

  _getCancel() {
    return {
      label: 't(Cancel)',
      className: 'maha-tasks-cancel',
      handler: this._handleClose
    }
  }

  _getList() {
    const { position, width } = this.state
    return {
      className: 'maha-tasks-list',
      style: window.innerWidth > 768  ? {
        ...position ? {
          ...position.top ? {
            top: `calc(${position.top}px + 0.8rem)`
          } : {},
          ...position.bottom ? {
            transform: 'translate(0, -100%)',
            top: `calc(${position.bottom}px - 0.8rem)`
          } : {},
          ...position.left ? {
            left: position.left
          } : {},
          ...position.right ? {
            right: position.right
          } : {}
        } : {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%'
        },
        ...width ? {
          width: `${width}px`
        } : {}
      } : {}
    }
  }

  _handleClickOutside(e) {
    const { code, open } = this.state
    if(!open) return
    setTimeout(() => {
      if(code !== this.state.code) return
      this.setState({
        open: false
      })
    }, 150)
  }

  _handleClose() {
    const { onDone } = this.state
    if(onDone) onDone()
    this.setState({
      open: false
    })
  }

  _handleClear() {
    this.setState({
      code: null,
      position: null,
      width: null,
      title: null,
      items: null,
      component: null,
      open: false,
      onDone: null
    })
  }

  _handleOpen(tasks) {
    this.setState({
      code: getCode(5),
      position: tasks.position,
      width: tasks.width,
      title: tasks.title,
      items: tasks.items,
      component: tasks.component,
      open: true,
      onDone: tasks.onDone
    })
  }

}

export default Tasks
