import SocialVariantDesigner from '@admin/components/social_variant_designer'
import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import React from 'react'

const getSocialVariantDesigner = (social_campaign) => {
  return {
    entity: 'campaigns_social_campaign_variants',
    format: ProfileToken,
    social_campaign
  }
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <SocialVariantDesigner { ...getSocialVariantDesigner(props.social_campaign) } />
})

export default Panel(null, mapPropsToPanel)
