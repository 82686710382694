import AttendingToken from '@apps/events/admin/tokens/attending'
import Checkin from '@apps/events/admin/components/checkin'
import Panel from '@admin/components/panel'
import Scan from '../sessions/scan'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Attendance)',
  collection: {
    endpoint: `/api/admin/events/events/${props.event.id}/sessions/${props.session.id}/attendings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: AttendingToken },
      { key: 'is_checked', collapsing: true, primary: true, format: (ticket) => (
        <Checkin event={ props.event } session={ props.session} ticket={ ticket } />
      ) }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'user_round',
      title: 't(No Tickets)',
      text: 't(No one has purchased a ticket for this event)',
      buttons: []
    },
    entity: 'ticket'
  },
  tasks: {
    items: [
      { label: 'Scan Tickets', modal: <Scan event={ props.event } session={ props.session } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
