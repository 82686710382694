import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import PhoneButton from '@apps/phone/admin/components/button'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import SMS from '../sms/sms'
import React from 'react'

class Contact extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    twilio: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handlePlaceCall = this._handlePlaceCall.bind(this)
  _handleInfo = this._handleInfo.bind(this)
  _handleSMS = this._handleSMS.bind(this)

  render() {
    const { contact } = this.props
    const phone = this._getPhone()
    const buttons = this._getButtons()
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-handset-detail-container">
          <div className="maha-handset-detail">
            <div className="maha-handset-detail-header">
              <ContactAvatar { ...contact } />
              <h2>{ contact.display_name }</h2>
              { phone &&
                <p>{ phone.formatted }</p>
              }
              <div className="maha-handset-actions">
                { buttons.map((button, index) => (
                  <div className="maha-handset-action" key={`action_${index}`}>
                    <PhoneButton { ...button } />
                  </div>
                ))}
              </div>
            </div>
            <div className="maha-handset-detail-body">
              <Details { ...this._getDetails() } />
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getButtons() {
    const { contact } = this.props
    const disabled = contact.phone_numbers.length === 0
    return [
      { icon: 'info', label: 'profile', handler: this._handleInfo },
      { svg: 'phone', label: 'call', handler: this._handlePlaceCall, disabled },
      { svg: 'messages_square', label: 'sms', handler: this._handleSMS, disabled }
    ]
  }

  _getDetails() {
    const { contact } = this.props
    return {
      sections: [
        {
          title: 't(Phone Numbers)',
          items: [
            ...contact.phone_numbers.length > 0 ? contact.phone_numbers.map(phone_number => ({
              label: 't(Phone)',
              content: <Button { ...this._getCallButton(phone_number) } />
            })) : [
              { content: 'none' }
            ]
          ]
        }
      ]
    }
  }

  _getCallButton(phone_number) {
    return {
      label: phone_number.formatted,
      className: 'link',
      handler: this._handlePlace.bind(this, phone_number.number)
    }
  }

  _getPanel() {
    return {
      title: 't(Contact)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ]
    }
  }

  _getPhone() {
    const { contact } = this.props
    return contact.phone_numbers[0]
  }

  _getSMS(contact_phone_number) {
    const { program, onPop } = this.props
    return {
      contact_phone_number,
      program_phone_number: program.phone_number,
      program,
      onBack: onPop
    }
  }

  _handleBack() {
    this.props.onPop()
  }

  _handleCall(number, device) {
    const { contact, program } = this.props
    this.context.twilio.call({
      program,
      device,
      number,
      contact
    })
  }

  _handleInfo() {
    const { contact } = this.props
    this.context.router.push(`/crm/contacts/${contact.id}`)
  }

  _handlePlace(number) {
    const { account } = this.context.admin
    if(!account.mobile_phone) return this._handleCall(number, 'maha')
    this.context.tasks.open({
      items: [
        { label: 'Call with mobile phone', handler: this._handleCall.bind(this, number, 'mobile') },
        { label: 'Call with Maha phone', handler: this._handleCall.bind(this, number, 'maha') }
      ]
    })
  }

  _handlePlaceCall() {
    const { contact } = this.props
    const { phone_numbers } = contact
    if(phone_numbers.length === 1) {
      this._handlePlace(phone_numbers[0].number)
    } else {
      this.context.tasks.open({
        items: phone_numbers.map(phone_number => ({
          label: phone_number.formatted,
          handler: this._handlePlace.bind(this, phone_number.number)
        }))
      })
    }
  }

  _handleSMS() {
    const { contact } = this.props
    if(contact.phone_numbers.length === 1) {
      const phone_number = contact.phone_numbers[0]
      return this.props.onPush(SMS, this._getSMS(phone_number))
    }
    this.context.tasks.open({
      items: contact.phone_numbers.map(phone_number => (
        { label: phone_number.formatted, handler: () => {
          this.props.onPush(SMS, this._getSMS(phone_number))
        }}
      ))
    })
  }

}

const mapResources = (props, context) => ({
  contact: `/api/admin/crm/contacts/${props.contact_id}`
})

export default Container(mapResources)(Contact)
