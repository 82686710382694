import Memberships from '@apps/finance/admin/views/memberships/projects'
import React from 'react'

const userTasks = (user, context) => [
  {
    label: 't(Manage Projects)',
    svg: 'users_round',
    access: { rights: { $oneOf: ['finance:manage_projects'] } },
    show: user.is_active,
    modal: <Memberships user_id={ user.id } />
  }
]

export default userTasks
