import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Records from './records'
import React from 'react'

class Review extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    entity: PropTypes.string,
    summary: PropTypes.func,
    onBack: PropTypes.func,
    onDone: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    const items = this._getItems()
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="import-summary">
          { items.map((item, index) => (
            <div className="import-summary-item" key={`item_${index}`}>
              <div className="import-summary-item-icon">
                <div className={`import-summary-item-icon-circle ${item.color}`}>
                  <Icon svg={`${item.svg}`} />
                </div>
              </div>
              <div className="import-summary-item-label">
                <T text={ item.label } />
                { item.items &&
                  <ul>
                    { item.items.map((item, index) => (
                      <li key={`item_${index}`}>{ item }</li>
                    )) }
                  </ul>              
                }
              </div>
              { item.handler &&
                <div className="import-summary-item-action">
                  <Button { ...this._getReview(item.handler) } />
                </div>            
              }
            </div>
          )) }
        </div>
      </ModalPanel>
    )
  }

  _getItems() {
    const { _import, entity, summary } = this.props
    return [
      { 
        svg: 'check',
        color: 'green',
        label: `${ pluralize(`new ${entity}`, _import.valid_count, true) } will be created`,
        handler: this._handleReview.bind(this, { is_valid: true, is_duplicate: false, is_nonunique: false })
      },
      ...!_.includes(['overwrite','discard'], _import.config.duplicate_strategy) && _import.duplicate_count > 0 ? [
        {
          svg: 'x',
          color: 'red',
          label: `${ pluralize(`duplicate ${entity}`, _import.duplicate_count, true) } will be ignored`,
          handler: this._handleReview.bind(this, { is_valid: true, is_duplicate: true, is_nonunique: false })
        }
      ] : [],
      ..._.includes(['overwrite','discard'], _import.config.duplicate_strategy) && _import.duplicate_count > 0 ? [
        {
          svg: 'minimize_2',
          color: 'green',
          label: `${ pluralize('record', _import.duplicate_count, true) } will be merged${ this._getMerge() }`,
          handler: this._handleReview.bind(this, { is_duplicate: true })
        }
      ] : [],
      ..._import.empty_count > 0 ? [
        {
          svg: 'x',
          color: 'red',
          label: `${ pluralize('empty record', _import.empty_count, true) } in your input that will be ignored`,
          handler: this._handleReview.bind(this, { is_empty: true })
        }
      ] : [],
      ..._import.nonunique_count > 0 ? [
        {
          svg: 'x',
          color: 'red',
          label: `${ pluralize('nonunique record', _import.nonunique_count, true) } in your input that will be ignored`,
          handler: this._handleReview.bind(this, { is_nonunique: true })
        }
      ] : [],
      ..._import.error_count > 0 ? [
        {
          svg: 'triangle_alert',
          color: 'red',
          label: `${ pluralize('record', _import.error_count, true) } have errors and will be skipped`,
          handler: this._handleReview.bind(this, { is_valid: false })
        }
      ] : [],
      ...summary ? summary(_import) : []
    ]
  }

  _getMerge() {
    const { _import } = this.props
    const { duplicate_strategy } = _import.config
    if(duplicate_strategy === 'overwrite') return ', overwriting existing data'
    if(duplicate_strategy === 'discard') return ', but will not overwrite existing data'
    return ''
  }

  _getPanel() {
    return {
      title: 't(Review Import)',
      rightItems: [
        { label: 't(Next)', color: 'red', handler: this._handleDone  }
      ]
    }
  }

  _getReview(handler) {
    return {
      label: 't(Review)',
      color: 'blue',
      size: 'mini',
      basic: true,
      handler
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone() {
    const { _import } = this.props
    this.props.onDone(_import)
  }

  _handleReview(params) {
    const { _import } = this.props
    const { onPop } = this.props
    this.props.onPush(Records, {
      _import,
      ...params,
      onBack: onPop,
      onDone: onPop
    })
  }

}

const mapResources = (props, context) => ({
  _import: `/api/admin/${props._import.object_type}/imports/${props._import.id}`
})

export default Container(mapResources)(Review)
