import ProfilePicker from '@admin/components/profile_picker'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Profile extends React.Component {

  static propTypes = {
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <ProfilePicker { ...this._getProfilePicker() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', disabled: true }
      ]
    }
  }

  _getProfilePicker() {
    return {
      empty: {
        image: '/images/services/canva.png',
        title: 't(Connect to Canva)',
        text: 't(You have not yet connected any Canva accounts)'
      },
      showHeader: false,
      sources: ['canva'],
      onDone: this._handleSuccess
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess(profile) {
    this.props.onDone({profile})
  }

}

export default Profile

