import { useMemo } from 'react'

export const assets = (state, props) => {
  return useMemo(() => {
    return state.files.reduce((assets, file) => [
      ...assets,
      ...file.asset ? [file.asset] : []
    ], [])
  }, [state.files])
}

export const counts = (state, props) => {
  return useMemo(() => {
    return state.files.reduce((counts, file) => ({
      ...counts,
      [file.source_id]: (counts[file.source_id] || 0) + 1
    }), {})
  }, [state.files])
}

export const processed = (state, props) => {
  return useMemo(() => {
    return state.files.find((file) => {
      return file.asset === undefined
    }) === undefined
  }, [state.files])
}

export const retry = (state, props) => {
  return useMemo(() => {
    return state.files.find((file) => {
      return file.status === 'failed'
    }) !== undefined
  }, [state.files])
}