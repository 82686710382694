import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

class ScheduledToken extends React.PureComponent {

  static propTypes = {
    social_campaign: PropTypes.object,
    onEdit: PropTypes.func
  }

  tokenRef = React.createRef()


  _handleEdit = this._handleEdit.bind(this)
  _handleDragStart = this._handleDragStart.bind(this)

  render() {
    const { social_campaign } = this.props
    return (
      <div { ...this._getToken() }>
        <Button { ...this._getButton() }>
          <MJSONScreenshot { ...this._getScreenshot(social_campaign.variants[0]) } />
          <div className="campaigns-social-schedule-scheduled-token-time">{ moment(social_campaign.post_at).format('h:mm A') }</div>
        </Button>
      </div>
    )
  }

  _getButton() {
    const { social_campaign } = this.props
    return {
      className: 'campaigns-social-schedule-scheduled-token-inner',
      route: `/admin/campaigns/social/${social_campaign.id}`,
      context_menu: {
        tasks: [
          { 
            label: 't(Manage Social Campaign)', 
            svg: 'settings',
            route: `/admin/campaigns/social/${social_campaign.id}` 
          },
          { 
            label: social_campaign.status === 'scheduled' ? 't(Unschedule Social Campaign)' : 't(Schedule Social Campaign)', 
            show: social_campaign.status !== 'posted',
            svg: 'clock',
            modal: <Edit social_campaign={ social_campaign } /> 
          },
          {
            label: 't(Delete Social Campaign)',
            show: social_campaign.status !== 'posted',
            svg: 'trash',
            confirm: 't(Are you sure you want to delete this social campaign? You will also delete all of the associated workflows and performance data)',
            request: {
              endpoint: `/api/admin/campaigns/social/${social_campaign.id}`,
              method: 'DELETE',
              onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
            }
          }
        ]  
      }
    }
  }

  _getClass() {
    const { social_campaign } = this.props
    const classes = ['campaigns-social-schedule-scheduled-token']
    if(social_campaign.status === 'failed') classes.push('failed')
    if(social_campaign.status === 'posted') classes.push('posted')
    return classes.join(' ')
  }

  _getScreenshot(entity) {
    return {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${entity.code}`,
      width: 32,
      height: 23,
      screenshoted_at: entity.screenshoted_at,
      updated_at: entity.updated_at  
    }
  }

  _getToken() {
    const { social_campaign } = this.props
    const isPosted = social_campaign.status === 'posted'
    return {
      ref: this.tokenRef,
      className: this._getClass(),
      title: social_campaign.title,
      draggable: !isPosted,
      onDragStart: this._handleDragStart,
      onDragOver: this._handleDragOver
    }
  }

  _handleDragStart(e) {
    const { social_campaign } = this.props
    if (social_campaign.status === 'posted') {
      e.preventDefault()
      return false
    }
    e.stopPropagation()
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('social_campaign', JSON.stringify(social_campaign))
    if (this.tokenRef.current) {
      const rect = this.tokenRef.current.getBoundingClientRect()
      e.dataTransfer.setDragImage(this.tokenRef.current, e.clientX - rect.left, e.clientY - rect.top)
    }
  }

  _handleDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'none'
  }

  _handleEdit() {
    const { social_campaign } = this.props
    this.props.onEdit(social_campaign)
  }

}

export default ScheduledToken
