import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Library extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Publish)', 
              name: 'status',
              type: 'radiogroup',
              options: [
                { value: 'draft', text: 't(Dont publish to library)' },
                { value: 'published', text: 't(Publish to Library)' }
              ],
              defaultValue: 'draft'
            },
            { 
              label: 't(Folders)', 
              entity: 'folder',
              name: 'folder_ids', 
              type: 'checkboxgroup', 
              endpoint: '/api/admin/truevail/agency/folders', 
              valueKey: 'id', 
              textKey: 'title' 
            },
            { 
              label: 't(Tags)', 
              name: 'tags', 
              type: 'tagfield', 
              endpoint: '/api/admin/tags' 
            }
          ]
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onSave(social_campaign)
  }

}

export default Library
