import MultiForm from '@admin/components/multiform'
import Description from './description'
import PropTypes from 'prop-types'
import Details from './details'
import Tastes from './tastes'
import Notes from './notes'
import Color from './color'
import Style from './style'
import React from 'react'

class Logo extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Logo Setup)',
      cancelSvg: 'chevron_left',
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      showSteps: false,
      onCancel: this._handleCancel,
      onNext: this._handleNext,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { configuration } = this.props
    return configuration
  }

  _getSteps(formdata) {
    const { logo_strategy } = formdata
    return [
      { label: 't(Details)', component: Details },
      ...logo_strategy === 'design' ? [
        { label: 't(Description)', component: Description },
        { label: 't(Tastes)', component: Tastes },
        { label: 't(Color)', component: Color },
        { label: 't(Style)', component: Style },
        { label: 't(Notes)', component: Notes }
      ] : logo_strategy === 'brand' ? [
        { label: 't(Notes)', component: Notes }
      ] : []

    ]
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleNext(data) {
    this.props.onNext(data)
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Logo
