import ServiceToken from '@apps/subscriptions/admin/tokens/service'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: '/api/admin/subscriptions/subscriptions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Service)', key: 'service.title', primary: true, format: (subscription) => (
        <ServiceToken service={ subscription.service } />
      ) },
      { label: 't(Plan)', key: 'current_period.plan.title', sort: 'plan' },
      { label: 't(Renewals)', key: 'renewals_count', collapsing: true, align: 'right' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, format: 'currency' }
    ],
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'asc' },
    empty: {
      svg: 'repeat',
      title: 't(No Subscriptions)',
      text: 't(There are no subscriptions for this contact)'
    },
    entity: 'subscription',
    onClick: (record) => context.router.push(`/admin/subscriptions/services/${record.service.id}/subscriptions/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
