import ModalPanel from '@admin/components/modal_panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Chooser extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    fields: PropTypes.array,
    onChoose: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { fields } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-contactfield-options">
          { fields.reduce((groups, group, i) => [
            ...groups,
            <div className="maha-criterion-type" key={`type_${i}`}>
              <T text={ group.label } />
            </div>,
            ...group.fields.map((field, j) => (
              <div { ...this._getField(field) } key={`type_${i}_property_${j}`}>
                <T text={ field.label } />
              </div>
            ))
          ], []) }
        </div>
      </ModalPanel>
    )
  }

  _getField(field) {
    return {
      className: 'maha-criterion-item',
      onClick: this._handleChoose.bind(this, field)
    }
  }

  _getPanel() {
    return {
      title: 't(Choose Field)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChoose(field) {
    this.props.onChoose(field)
    this.context.form.pop()
  }

}

export default Chooser
