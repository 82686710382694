import StatusToken from '@apps/events/admin/tokens/status'
import Content from '@apps/forms/admin/tokens/content'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Tickets)',
  collection: {
    endpoint: `/api/admin/events/events/${props.event.id}/registrations/${props.registration.id}/tickets`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 'Attendee', key: 'name', primary: true },
      { label: 'Ticket Type', key: 'ticket_type.name', sort: 'ticket_type', primary: true },
      ...!_.includes(props.event.ticket_config.hidden, 'gender') ? [{ label: 'Gender', key: 'values.gender', visible: false }] : [],
      ...!_.includes(props.event.ticket_config.hidden, 'age') ? [{ label: 'Age', key: 'values.age', visible: false }] : [],
      ...!_.includes(props.event.ticket_config.hidden, 'race') ? [{ label: 'Race', key: 'values.race', visible: false }] : [],
      ...!_.includes(props.event.ticket_config.hidden, 'ethnicity') ? [{ label: 'Ethnicity', key: 'values.ethnicity', visible: false }] : [],
      ...props.event.ticket_config.fields.map(field => ({
        label: field.name.value,
        key: `values.${field.code}`,
        sort: field.code,
        visible: false,
        format: ({ values }) => <Content data={ values } field={ field } />
      })),
      { label: 't(Status)', key: 'status', primary: true, collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'user_round',
      title: 't(No Tickets)',
      text: 't(No one has purchased a ticket for this event)',
      buttons: []
    },
    entity: 'event',
    onClick: (record) => context.router.push(`/events/events/${props.event.id}/tickets/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
