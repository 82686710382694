import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ role }) => {

  const details = {
    audits: `maha_roles/${role.id}`,
    comments: `maha_roles/${role.id}`,
    sections: [
      {
        title: 't(Role Details)',
        items: [
          { label: 't(Title)', content: role.title }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  role: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview role={ props.role } />,
  tasks: {
    items: [
      {
        label: 't(Edit Role)',
        svg: 'square_pen',
        access: { rights: { $oneOf: ['team:manage_users'] } },
        modal: <Edit role={ props.role } />
      },
      {
        label: 't(Delete Role)',
        svg: 'trash',
        access: { rights: { $oneOf: ['team:manage_users'] } },
        confirm: 't(Are you sure you want to delete this role)',
        show: props.role.type === 'custom',
        deletable: true,
        request: {
          method: 'DELETE',
          endpoint: `/api/admin/team/roles/${props.role.id}`,
          onSuccess: (result) => {
            context.flash.set('success', 't(Successfully deleted this role)')
            context.router.goBack()
          },
          onFailure: (result) => {
            context.flash.set('error', 't(Unable to delete this role)')
          }
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
