import DesignCard from '@apps/truevail/admin/cards/library_design'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Designs)',
  collection: {
    endpoint: '/api/admin/truevail/library/designs',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true }
    ],
    card: (design) => (
      <DesignCard design={ design } />
    ),
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      svg: 'palette',
      title: 't(No Designs)',
      text: 't(There are no published designs)'
    },
    entity: 'design',
    onClick: (record) => context.router.push(`/admin/truevail/admin/designs/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
