import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Create Template)',
      method: 'POST',
      action: '/api/admin/email/templates',
      cancelSvg: 'chevron_left',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      saveText: 'Save',
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield' },
            { label: 't(Subject)', name: 'subject', type: 'textfield' },
            { label: 't(Body)', name: 'body', type: 'htmlfield', toolbar: ['color','b','em','u','ul','ol','ul','a'] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleSuccess(agreement) {
    this.props.onDone(agreement)
  }

}

export default New
