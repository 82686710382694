import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const AppToken = ({ icon, svg, title }) => (
  <div className="maha-app-token">
    <div className="maha-app-token-icon">
      <Icon icon={ icon } svg={ svg } />
    </div>
    <T text={ title } />
  </div>
)

AppToken.propTypes = {
  icon: PropTypes.string,
  svg: PropTypes.string,
  title: PropTypes.string
}

export default AppToken
