import EditCollectionItem from './collection_item/edit'
import EditLandingPage from './landing_pages/edit'
import EditCollection from './collection/edit'
import EditContainer from './containers/edit'
import NewCollection from './collection/new'
import NewContainer from './containers/new'
import EditTemplate from './templates/edit'
import EditSection from './sections/edit'
import EditColumn from './columns/edit'
import NewSection from './sections/new'
import EditModal from './modals/edit'
import EditBlock from './blocks/edit'
import EditEmbed from './embeds/edit'
import NewColumn from './columns/new'
import NewModule from './modules/new'
import NewBlock from './blocks/new'
import EditPost from './posts/edit'
import EditPage from './pages/edit'
import EditRow from './rows/edit'
import NewRow from './rows/new'
import Library from './library'
import React from 'react'

const entities = {
  library: Library,
  new: {
    block: NewBlock,
    collection: NewCollection,
    container: NewContainer,
    column: NewColumn,
    page_module: (props) => <NewModule { ...props } type="page_module" />,
    row: NewRow,
    section: NewSection
  },
  edit: {
    block: EditBlock,
    collection: EditCollection,
    collection_item: EditCollectionItem,
    column: EditColumn,
    container: EditContainer,
    embed: EditEmbed,
    landing_page: EditLandingPage,
    modal: EditModal,
    page: EditPage,
    post: EditPost,
    row: EditRow,
    section: EditSection,
    template: EditTemplate
  }
}

export default entities
