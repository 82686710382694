import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Emojis from '@admin/components/emojis'
import PropTypes from 'prop-types'
import React from 'react'

class TextField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    autoComplete: PropTypes.string,
    clearable: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    emojis: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    sanitize: PropTypes.func,
    suffix: PropTypes.string,
    tabIndex: PropTypes.number,
    trim: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    clearable: true,
    autoComplete: 'off',
    disabled: false,
    editable: true,
    emojis: false,
    maxLength: null,
    placeholder: '',
    prefix: null,
    sanitize: (value) => value,
    suffix: null,
    tabIndex: 0,
    trim: true,
    validate: () => true,
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    value: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 150)
  _handleClear = this._handleClear.bind(this)
  _handleBlur = this._handleBlur.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleInsertEmoji = this._handleInsertEmoji.bind(this)
  _handleKeyDowned = this._handleKeyDowned.bind(this)
  _handleKeyPressed = this._handleKeyPressed.bind(this)
  _handleKeyUped = this._handleKeyUped.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleSet = this._handleSet.bind(this)

  render() {
    const { clearable ,disabled, emojis, maxLength } = this.props
    const { value } = this.state
    return (
      <div className={ this._getClass() }>
        { maxLength &&
          <div className={ this._getMaxClass() }>
            { value.length } / { maxLength }
          </div>
        }
        <div className="maha-input-field">
          { this.props.prefix && <div className="ui label">{this.props.prefix}</div> }
          <Input { ...this._getInput() } />
          { clearable && value && value.length > 0 && !disabled &&
            <Button { ...this._getClear() } />
          }
          { emojis &&
            <div className="maha-input-emojis">
              <Emojis { ...this._getEmojis() } />
            </div>
          }
          { this.props.suffix && <div className="ui label">{this.props.suffix}</div> }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getAriaLabel() {
    const { entity, label } = this.props
    const { t } = this.context.locale
    if(entity && entity.length > 0) return t(entity)
    if(label && label.length > 0) return t(label)
  }

  _getClass() {
    const { disabled, label, maxLength, prefix, suffix } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-textfield']
    if(!label && maxLength !== null) classes.push('unlabeled')
    if(prefix) classes.push('left labeled')
    if(suffix) classes.push('right labeled')
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getInput() {
    const { autoComplete, disabled, editable, id, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      ref: this.inputRef,
      type: 'text',
      'aria-label': this._getAriaLabel(),
      disabled: disabled || !editable,
      id,
      value,
      tabIndex,
      autoComplete,
      placeholder: !focused ? this._getPlaceholder() : null,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused,
      onKeyDown: this._handleKeyDowned,
      onKeyPress: this._handleKeyPressed,
      onKeyUp: this._handleKeyUped
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEmojis() {
    return {
      onChoose: this._handleInsertEmoji
    }
  }

  _getMaxClass() {
    const { value } = this.state
    const { maxLength } = this.props
    const classes = ['maha-input-extra']
    if(value && value.length >= maxLength) classes.push('max')
    return classes.join(' ')
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _handleBlur() {
    this.inputRef.current.blur()
  }

  _handleBlured() {
    const { onBlur } = this.props
    const { value } = this.state
    this.setState({
      value: value.trim(),
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value && value.length > 0 ? value : null)
  }

  _handleClear() {
    this._handleSet('')
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleInsertEmoji(emoji) {
    const { value } = this.inputRef.current
    if(document.selection) {
      this.inputRef.current.focus()
      const selection = document.selection.createRange()
      selection.text = emoji
    } else if (this.inputRef.current.selectionStart || this.inputRef.current.selectionStart === '0') {
      const beginning = value.substring(0, this.inputRef.current.selectionStart)
      const ending = value.substring(this.inputRef.current.selectionEnd, value.length)
      this._handleSet(beginning + emoji + ending)
    } else {
      this._handleSet(value + emoji)
    }
  }

  _handleKeyDowned(e) {
    const { onKeyDown } = this.props
    if(onKeyDown) onKeyDown(e)
  }

  _handleKeyPressed(e) {
    const { onEnter, onKeyPress } = this.props
    if(onKeyPress) onKeyPress(e)
    if(e.key !== 'Enter') return
    if(onEnter) onEnter()
  }

  _handleKeyUped(e) {
    const { onKeyUp } = this.props
    if(onKeyUp) onKeyUp(e)
    if(e.which == 13) e.preventDefault()
  }

  _handleSet(value) {
    if(this.props.maxLength && value && value.length > this.props.maxLength) return
    this.setState({
      value: value || ''
    })
  }

  _handleUpdate(value, e) {
    const sanitized = this.props.sanitize(value)
    if(!this.props.validate(sanitized)) return e.preventDefault()
    this._handleSet(sanitized)
  }

}

export default TextField
