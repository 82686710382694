import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Types)',
  collection: {
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true }
    ],
    endpoint: `/api/admin/sites/sites/${props.params.site_id}/types`,
    empty: {
      svg: 'tag',
      title: 't(No Types)',
      text: 't(You have not yet created any types for this site)',
      buttons: [
        { label: 'Create Type', modal: <New site_id={ props.params.site_id } /> }
      ]
    },
    entity: 'type',
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/sites/sites/${props.params.site_id}/types/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: <New site_id={ props.params.site_id } />
  }
})

export default Page(null, mapPropsToPage)
