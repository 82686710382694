import ModalPanel from '@admin/components/modal_panel'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import React from 'react'

class Members extends React.PureComponent {

  static propTypes = {
    _import: PropTypes.object,
    profile: PropTypes.object,
    list_id: PropTypes.string,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Infinite { ...this._getInfinite() } />
      </ModalPanel>
    )
  }

  _getInfinite() {
    const { profile, list_id } = this.props
    const empty = {
      svg: 'user_round',
      title: 't(No Contacts)',
      text: 't(There are no contacts available)'
    }
    return {
      endpoint: `/api/admin/profiles/${profile.id}/lists/${list_id}/members`,
      layout: Results,
      empty: <Message {...empty} />
    }
  }

  _getPanel() {
    return {
      title: 't(Preview Contacts)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 't(Next)', handler: this._handleDone }
      ]
    }
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleDone() {
    const { _import, profile, list_id } = this.props
    this.props.onDone({
      ..._import,
      profile_id: profile.id,
      service: profile.service.source,
      config: {
        ..._import.config,
        list_id
      }
    })
  }

}

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array
  }

  render() {
    const { records } = this.props
    return (
      <div className="import-contacts">
        { records.map((contact, index) => (
          <div className="import-contact" key={`contact_${index}`}>
            <div className="import-contact-label">
              { contact.first_name } { contact.last_name } (
              { contact.email_addresses.length > 0 ? contact.email_addresses[0].address: 'no email' }
              )
            </div>
          </div>
        )) }
      </div>
    )
  }

}

export default Members
