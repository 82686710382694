import ProfileConnect from '@admin/components/profile_connect'
import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import Img from '@admin/components/html/img'
import Contacts from './service/contacts'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import Lists from './service/lists'
import PropTypes from 'prop-types'
import React from 'react'
import File from './file'

class Chooser extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    cancel: PropTypes.object,
    object_type: PropTypes.string,
    profiles: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onFetch: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)

  render() {
    const sources = this._getSources()
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="import-sources-body">
          { sources.map((source, index) => (
            <div className="import-source" key={`source_${index}`} onClick={ this._handleClick.bind(this, source) }>
              <div className="import-source-service">
                { (source.service) &&
                  <Img src={`/images/services/${source.service.source_name}.png`} />
                }
                { (source.image) &&
                  <Img src={`/images/services/${source.image}.png`} />
                }
                { source.icon &&
                  <Icon icon={ source.icon } />
                }
              </div>
              <div className="import-source-label">
                <T text={ source.username || source.name || source.label } />
              </div>
            </div>
          ))}
        </div>
      </ModalPanel>
    )
  }

  _getConnect() {
    const { onPop } = this.props
    return {
      types: ['contacts'],
      onBack: onPop,
      onDone: onPop
    }
  }

  _getPanel() {
    const { onBack, onCancel } = this.props
    return {
      title: 't(Choose Contact Source)',
      leftItems: onBack ? [
        { svg: 'chevron_left', handler: onBack }
      ] : [
        { label: 't(Cancel)', handler: onCancel }
      ]
    }
  }

  _getService(service) {
    if(service === 'constantcontact') return { component: Lists, label: 'Constant Contact' }
    if(service === 'googlecontacts') return { component: Contacts, label: 'Google Contacts' }
    if(service === 'mailchimp') return { component: Lists, label: 'MailChimp' }
    if(service === 'outlook') return { component: Contacts, label: 'Outlook 365' }
  }

  _getSources() {
    const { profiles } = this.props
    return [
      { label: 't(Excel or CSV file)', image: 'excel', component: File },
      ...profiles.map(profile => ({
        ...profile,
        ...this._getService(profile.service.source_name)
      })),
      { 
        label: 'Add a source', 
        icon: 'plus-circle', 
        component: ProfileConnect, 
        props: this._getConnect.bind(this) 
      }
    ]
  }

  _getSource(profile) {
    const { _import, object_type, onDone, onPop, onPush } = this.props
    return {
      _import,
      object_type,
      profile,
      onDone,
      onBack: onPop,
      onPop,
      onPush
    }
  }

  _handleBack() {
    this.props.onPop()
  }

  _handleClick(source) {
    if(!source.props) source.props = this._getSource.bind(this)
    this.props.onPush(source.component, source.props(source))
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/profiles',
    refresh: `/accounts/${context.admin.account.id}/admin/profiles`,
    filter: {
      type: {
        $in: ['contacts']
      }
    }
  }
})

export default Container(mapResources)(Chooser)
