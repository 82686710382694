import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ team }) => {

  const details = {
    audits: `maha_teams/${team.id}`,
    comments: `maha_teams/${team.id}`,
    sections: [
      {
        title: 't(Team Details)',
        items: [
          { label: 't(Title)', content: team.title },
          { label: 't(Public Domain)', content: team.public_domain }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  team: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview team={ props.team } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Settings)',
        svg: 'square_pen', 
        rights: ['team:manage_team'], modal: Edit 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
