import Message from '@admin/components/message'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class Assigned extends React.Component {

  static propTypes = {
    assigneeFormat: PropTypes.any,
    assigned: PropTypes.array,
    empty: PropTypes.object,
    revokeable: PropTypes.bool,
    typeFormat: PropTypes.any,
    typeKey: PropTypes.string,
    types: PropTypes.array,
    onRemove: PropTypes.func,
    onRevoke: PropTypes.func,
    onUpdate: PropTypes.func
  }

  render() {
    const { assigned, empty } = this.props
    return (
      <>
        { assigned.length > 0 ? 
          <List { ...this._getList() } /> :
          <Message { ...empty } />
        }
      </>
    )
  }

  _getList() {
    const { assigned, assigneeFormat, revokeable, types } = this.props
    return {
      className: null,
      items: assigned,
      itemDisabled: (assignee) => assignee.is_revoked === true,
      format: assigneeFormat,
      actions: (assignee, index) => [
        ...types ? [
          this._getUpdate(assignee, index)
        ] : [],
        ...revokeable && assignee.id ? [
          this._getRevoke(assignee, index)
        ] : [
          this._getRemove(index)
        ]
      ],
      scrollable: true,
      scrollOnAdd: true,
      transition: {
        classNames: 'highlight',
        timeout: 1000,
        exit: false
      }
    }
  }

  _getRemove(index) {
    return {
      svg: 'x',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _getRevoke(assignee, index) {
    return {
      icon: assignee.is_revoked === true ? 'refresh' : 'times',
      handler: this._handleRevoke.bind(this, index)
    }
  }

  _getUpdate(assignee, index) {
    const { typeKey } = this.props
    const type = assignee.is_revoked ? 'revoked' : assignee[typeKey]
    const TypeComponent = this.props.typeFormat
    const typeProps = {
      [typeKey]: type,
      is_revoked: assignee.is_revoked
    }
    return {
      padded: true,
      component: <TypeComponent { ...typeProps } />,
      ...!assignee.is_revoked ? {
        handler: this._handleUpdate.bind(this, assignee, index)
      } : {}
    }
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

  _handleRevoke(index) {
    this.props.onRevoke(index)
  }

  _handleUpdate(assignee, index) {
    this.props.onUpdate(assignee, index)
  }

}

export default Assigned