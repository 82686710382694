import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Panel from '@admin/components/panel'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/admin/email_campaigns/${props.email_campaign.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Agency)', key: 'subscription.agency.organization', sort: 'agency', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'agency', order: 'asc' },
    empty: {
      svg: 'mail',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this email campaign)',
      buttons: [
        {
          label: 't(Publish Email Campaigns)',
          modal: <Publish email_campaign={ props.email_campaign } />
        }
      ]
    },
    entity: 'publishing',
    onClick: (record) => {
      const { admin, provider, host } = context
      const path = `truevail/agency/email_campaigns/${record.agency_email_campaign.id}`
      if(admin.team.id === record.subscription.agency.team.id) return context.router.push(`/admin/${path}`)
      host.window.open(`${provider.admin_host}/${record.subscription.agency.team.subdomain}/${path}`)
    }
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Email Campaigns)',
    modal: <Publish email_campaign={ props.email_campaign } />
  }
})

export default Panel(null, mapPropsToPanel)
