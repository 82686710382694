import Panel from '@admin/components/panel'
import Edit from '../rates/edit'
import New from '../rates/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Rates)',
  access: { rights: { $oneOf: ['finance:manage_configuration'] } },
  collection: {
    endpoint: '/api/admin/finance/rates',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Year)', key: 'year', primary: true },
      { label: 't(Value)', key: 'value', primary: true, collapsing: true }
    ],
    defaultSort: { key: 'year', order: 'asc' },
    empty: {
      icon: 'percent',
      title: 't(No Rates)',
      text: 't(You have not yet created any rates)',
      buttons: [
        { 
          label: 't(Create Rate)',
          access: { rights: { $oneOf: ['finance:manage_configuration'] } },
          modal: New 
        }
      ]
    },
    entity: 'rate',
    recordTasks: (record) => [
      {
        label: 'Edit Rate',
        access: { rights: { $oneOf: ['finance:manage_configuration'] } },
        modal: <Edit id={ record.id } />
      }
    ]
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Rate)',
    access: { rights: { $oneOf: ['finance:manage_configuration'] } },
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
