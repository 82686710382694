import New from '@apps/platform/admin/views/providers/new'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Providers)',
  collection: {
    endpoint: '/api/admin/platform/providers',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true }
    ],
    defaultSort: { key: 'title', order: 'desc' },
    empty: {
      svg: 'globe',
      title: 't(No Providers)',
      text: 't(You have not yet added any providers)',
      buttons: [
        { label: 't(Create Domain)', modal: New }
      ]
    },
    entity: 'provider',
    onClick: (record) => context.router.push(`/platform/providers/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Provider)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
