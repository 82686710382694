import Error from '@admin/components/error'
import PropTypes from 'prop-types'
import React from 'react'

class Sidebar extends React.Component {

  static propTypes = {
    sidebar: PropTypes.func
  }

  static contextTypes = {
    logger: PropTypes.object
  }

  render() {
    const { sidebar } = this.props
    return (
      <div className="maha-portal-sidebar">
        { sidebar &&
          <Error>
            <this.props.sidebar />
          </Error>
        }
      </div>
    )
  }

}

export default Sidebar
