import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'
import he from 'he'

class EnvelopeToken extends React.Component {

  static propTypes = {
    email: PropTypes.object,
    perspective: PropTypes.string,
    showFolder: PropTypes.bool
  }

  render() {
    const { email, perspective, showFolder } = this.props
    const folder = this._getFolder()
    return (
      <div className={ this._getClass() }>
        <div className="email-envelope-icon">
          { !email.is_read &&
            <Icon icon="circle" />
          }
        </div>
        <div className="email-envelope-details">
          <div className="email-envelope-flags">
            { email.is_flagged &&
              <div className="email-envelope-flag">
                <Icon icon="flag" />
              </div>
            }
            { email.has_attachments &&
              <div className="email-envelope-flag">
                <Icon icon="paperclip" />
              </div>
            }
            { email.was_replied &&
              <div className="email-envelope-flag">
                <Icon icon="reply" />
              </div>
            }
            { email.import_thread_id &&
              <div className="email-envelope-flag">
                <Icon svg="check" />
              </div>
            }
            { email.priority === 'high' &&
              <div className="email-envelope-flag">
                <Icon icon="exclamation" />
              </div>
            }
            { email.priority === 'low' &&
              <div className="email-envelope-flag">
                <Icon icon="arrow-down" />
              </div>
            }
          </div>
          { perspective === 'from' ?
            <div className="email-envelope-contact">
              { email.from ? (email.from.name || email.from.address) : null }
            </div> :
            <div className="email-envelope-contact">
              { [
                ...email.to,
                ...email.cc
              ].map(address => address.name || address.address).join(', ') }
            </div>
          }
          <div className="email-envelope-row">
            <div className="email-envelope-subject">
              { email.subject ? he.decode(email.subject, { strict: false }) : '(no subject)' }
            </div>
            <div className="email-envelope-timestamp">
              { this._getTimestamp() }
            </div>
          </div>
          <div className="email-envelope-row">
            <div className="email-envelope-snippet">
              { email.snippet ? he.decode(email.snippet, { strict: false }) : '(no preview)' }
            </div>
            { showFolder &&
              <div className="email-envelope-timestamp">
                <div className="email-envelope-folder">
                  { folder }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )

  }

  _getClass() {
    const { email } = this.props
    const classes = ['email-envelope']
    if(!email.is_read) classes.push('unread')
    return classes.join(' ')
  }

  _getFolder() {
    const { email } = this.props
    if(_.includes(['focused','other'], email.folder)) return 'inbox'
    return email.folder
  }

  _getTimestamp() {
    const { email } = this.props
    const timestamp = moment(email.date)
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    if(timestamp.isSame(today, 'day')) return timestamp.format('h:mm A')
    if(timestamp.isSame(yesterday, 'day')) return 'Yesterday'
    return timestamp.format('MM/DD/YY')
  }

}

export default EnvelopeToken
