import SMSClient from '@apps/phone/admin/components/sms_client'
import EditContact from '@apps/crm/admin/views/contacts/edit'
import Message from '@admin/components/message'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class SMS extends React.Component {

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    const { program } = this.props
    const phone_numbers = this._getMobilePhoneNumbers()
    if(!program.phone_number) return <Message { ...this._getNoPhone() } />
    if(phone_numbers.length === 0) return <Message { ...this._getNoMobile() } />
    return <SMSClient { ...this._getSMSClient() } />
  }

  _getNoPhone() {
    return {
      svg: 'message_square',
      title: 't(No Phone Number)',
      text: 't(This program has not provisioned a phone number)'
    }
  }

  _getNoMobile() {
    const { contact } = this.props
    return {
      svg: 'message_square',
      title: 't(No Mobile Phone)',
      text: 't(The deal contact does not have a mobile phone)',
      button: [
        { label: 't(Edit Contact)', modal: <EditContact contact={ contact } />}
      ]
    }
  }

  _getMobilePhoneNumbers() {
    const { contact } = this.props
    return contact.phone_numbers.filter(phone_number => {
      return phone_number.type === 'mobile' && phone_number.can_text
    })
  }

  _getSMSClient() {
    const { contact, program } = this.props
    return {
      contact,
      program,
      contact_phone_number: this._getMobilePhoneNumbers()[0],
      program_phone_number: program.phone_number
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(SMS)',
  component: <SMS contact={ props.contact } program={ props.program } />
})

const mapResourcesToPanel = (props, context) => ({
  contact: `/api/admin/crm/contacts/${props.response.contact.id}`,
  program: `/api/admin/team/programs/${props.form.program.id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
