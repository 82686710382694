import Merge from '@apps/team/admin/views/tags/merge'
import New from '@apps/team/admin/views/tags/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Tags)',
  access: { rights: { $oneOf: ['team:manage_configuration']} },
  collection: {
    endpoint: '/api/admin/team/tags',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Tag)', key: 'text', primary: true }
    ],
    defaultQuery: {
      program_id: props.program.id
    },
    defaultSort: { key: 'text', order: 'asc' },
    empty: {
      svg: 'tag',
      title: 't(No Tags)',
      text: 't(You have not yet created any tags)',
      buttons: [
        { 
          label: 't(Create Tag)', 
          access: { rights: { $oneOf: ['team:manage_configuration'] } },
          modal: <New program_id={ props.program.id } />
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Merge Tag)',
        svg: 'merge',
        access: { rights: { $oneOf: ['team:manage_configuration'] } },
        modal: <Merge tag={record} />
      },
      {
        label: 't(Delete Tag)',
        svg: 'trash',
        access: { rights: { $oneOf: ['team:manage_configuration'] } },
        confirm: 't(Are you sure you want to delete this tag?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/team/tags/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this tag)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this tag)')
        }
      }
    ],
    entity: 'tag'
  },
  task: {
    access: { rights: { $oneOf: ['team:manage_configuration'] } },
    tooltip: 't(New Tag)',
    svg: 'plus',
    modal: <New program_id={ props.program.id } />
  }
})

export default Panel(null, mapPropsToPanel)
