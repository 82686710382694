import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subdomains)',
  collection: {
    endpoint: `/api/admin/truevail/admin/deliverability/domains/${props.domain.id}/subdomains`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'globe',
      title: 't(No Subdomains)',
      text: 't(There are no subdomains)'
    },
    entity: 'subdomain',
    onClick: (record) => context.router.push(`/admin/truevail/admin/domains/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
