import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Results extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    errors: PropTypes.object,
    index: PropTypes.number,
    record: PropTypes.array,
    total: PropTypes.number,
    onChange: PropTypes.func
  }

  _handleNext = this._handleNext.bind(this)
  _handlePrevious = this._handlePrevious.bind(this)

  render() {
    const { errors, record, index, total } = this.props
    return (
      <div className="maha-import-preview-result">
        <div className="maha-import-preview-pager">
          <Button { ...this._getPrevious() } />
          <div className="maha-import-preview-pager-item">
            <label>{ numeral(index + 1).format('0,0') } / { numeral(total).format('0,0') }</label>
          </div>
          <Button { ...this._getNext() } />
        </div>
        <div className="maha-import-preview-body">
          { errors &&
            <div className="maha-import-preview-errors">
              <p><strong>We found the following errors with this record:</strong></p>
              { Object.keys(errors).map((field, index) => (
                <div key={`error_${index}`}>&bull; <strong>{ field }</strong> - { errors[field] }</div>
              )) }
            </div>      
          }
          <table className="maha-import-preview-record">
            <tbody>
              { record.map((property, index) => (
                <tr key={`property_${index}`}>
                  <th><T text={ property.key } /></th>
                  <td>{ property.value }</td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  _getNext() {
    const { index, total } = this.props
    return {
      svg: 'chevron_right',
      className: 'maha-import-preview-pager-item',
      disabled: index >= total - 1,
      handler: this._handleNext
    }
  }

  _getPrevious() {
    const { index } = this.props
    return {
      svg: 'chevron_left',
      className: 'maha-import-preview-pager-item',
      disabled: index <= 0,
      handler: this._handlePrevious
    }
  }

  _handleNext() {
    const { index } = this.props
    this.props.onChange(index + 1)
  }

  _handlePrevious() {
    const { index } = this.props
    this.props.onChange(index - 1)
  }

}

export default Results
