import BillingCycleToken from '@apps/subscriptions/admin/tokens/billing_cycle'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Renewals)',
  collection: {
    endpoint: '/api/admin/subscriptions/renewals',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Billing Cycle)', key: 'cycle_start_date', format: BillingCycleToken },
      { label: 't(Service)', key: 'subscription.service.title' },
      { label: 't(Total)', key: 'invoice.total', primary: true, sort: 'total', collapsing: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'repeat',
      title: 't(No Renewals)',
      text: 't(There have not yet been any renewals for this advisor)'
    },
    entity: 'renewal',
    onClick: (record) => context.router.push(`/admin/subscriptions/services/${record.subscription.service.id}/renewals/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
