import EmailTemplateToken from '@apps/crm/admin/tokens/template'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Templates)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/templates`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Template)', key: 'title', primary: true, templateat: (template) => (
        <EmailTemplateToken template={ template } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'square_check',
      title: 't(No Templates)',
      text: 't(You have not yet created any templates for this advisor)',
      buttons: [
        { 
          label: 't(Create New Template)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'template',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/team/templates/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Template)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
