import TeamToken from '@admin/tokens/team'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 'Suspensions',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/suspensions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Team)', key: 'title', primary: true, format: TeamToken},
      { label: 't(Reason)', key: 'suspension_reason' },
      { label: 't(Suspended)', key: 'suspended_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'building-o',
      title: 't(No Suspensions)',
      text: 't(There are not currently any deliverability suspensions)'
    },
    entity: 'team',
    recordTasks: (record) => [
      {
        label: 't(Restore Deliverability)',
        request: {
          endpoint: `/api/admin/truevail/admin/deliverability/suspensions/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully restored deliverability)'),
          onFailure: () => context.flash.set('error', 't(Unable to restore deliverability)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
