import Infinite from '@admin/components/infinite'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Results from './results'
import Field from './field'
import React from 'react'

class Lookup extends React.PureComponent {

  static propTypes = {
    id: PropTypes.string,
    isFull: PropTypes.bool,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    q: PropTypes.string,
    recipients: PropTypes.array,
    tabIndex: PropTypes.number,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onType: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { label } = this.props
    return (
      <div className="maha-recipientfield-lookup">
        <div className="maha-recipientfield-lookup-header">
          <div className="maha-recipientfield-lookup-header-label">
            <T text={ label } />:
          </div>
          <div className="maha-recipientfield-lookup-header-field">
            <Field { ...this._getField() } />
          </div>
          <Button { ...this._getClose() } />
        </div>
        <div className="maha-recipientfield-lookup-body">
          <Infinite { ...this._getInfinite() } />
        </div>
      </div>
    )
  }

  _getClose() {
    const { onClose } = this.props
    return {
      svg: 'x',
      className: 'maha-recipientfield-lookup-header-action',
      handler: onClose
    }
  }

  _getField() {
    const { id, isFull, q, recipients, tabIndex, onAdd, onMove, onRemove, onType } = this.props
    return {
      id,
      isFull,
      q,
      recipients,
      tabIndex,
      onAdd,
      onMove,
      onRemove,
      onType
    }
  }

  _getInfinite() {
    const { q } = this.props
    return {
      endpoint: '/api/admin/crm/email_addresses',
      method: 'GET',
      filter: {
        q
      },
      layout: Results,
      props: {
        q,
        onChoose: this._handleChoose
      }
    }
  }

  _handleChoose(email_address) {
    this.props.onAdd(email_address)
  }

}

export default Lookup
