import Container from '@admin/components/container'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class ScheduleBatch extends React.Component {

  static propTypes = {
    agency: PropTypes.object,
    type: PropTypes.object,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    const { agency, type } = this.props
    return {
      title: 't((Re)schedule All)',
      saveText: 'Next',
      onCancel: this._handleCancel,
      onChange: this._handleUpdate,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { 
              label: 't(Scheduling)', 
              type: 'segment', 
              fields: [
                { 
                  label: 't(Send At)', 
                  type: 'fields', 
                  show: config.scheduling_strategy !== 'optin', 
                  fields: [
                    { name: 'date', type: 'datefield', required: true, defaultValue: moment().add(1,'day') },
                    { name: 'time', type: 'timefield', required: true, defaultValue: '9:00AM' }
                  ], 
                  after: (
                    <span className="alert"><T text={'Emails will be scheduled in the advisor\'s timezone'} /></span>
                  ), 
                  required: true 
                }
              ], 
              required: true 
            },
            { 
              label: 't(Preview Email)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'preview_strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'send', text: 't(Send preview to advisor reviewers)' },
                    { value: 'nothing', text: 't(Do nothing)' }
                  ], 
                  defaultValue: 'send' 
                },
                { 
                  name: 'preview_introduction',
                  type: 'htmlfield', 
                  toolbar: ['b','em','u','ol','ul','a'], 
                  show: config.preview_strategy === 'send', 
                  placeholder: 't(Enter an optional email introduction)', 
                  defaultValue: agency.config[`${type}_preview_message`]
                }
              ], 
              required: true 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

const mapResources = (props, context) => ({
  agency: '/api/admin/truevail/agency'
})

export default Container(mapResources)(ScheduleBatch)
