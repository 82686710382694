import ProfileToken from '@admin/tokens/profile'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Picker from './picker'
import React from 'react'

class Profilefield extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    disabled: PropTypes.bool,
    endpoint: PropTypes.bool,
    formatter: PropTypes.func,
    profiles: PropTypes.array,
    profile: PropTypes.object,
    selected: PropTypes.number,
    tabIndex: PropTypes.number,
    types: PropTypes.array,
    onChange: PropTypes.func,
    onChoose: PropTypes.func,
    onClear: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    endpoint: '/api/admin/profiles',
    formatter: (profile) => profile.id,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  _handleRemove = this._handleRemove.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handlePick = this._handlePick.bind(this)

  render() {
    const { profile, tabIndex } = this.props
    return (
      <div className={ this._getClass() } onClick={ this._handlePick } tabIndex={ tabIndex }>
        { profile ?
          <div className="profilefield-profile">
            <div className="profilefield-profile-token">
              <ProfileToken profile={ profile } />
            </div>
            <Button { ...this._getRemove() } />
          </div> :
          <div className="profilefield-placeholder">
            Choose a profile
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props
    if(profile !== prevProps.profile) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled } = this.props
    const classes = ['maha-input','profilefield']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getPicker() {
    const { profiles, types, onChoose } = this.props
    return {
      profiles,
      types,
      onChoose
    }
  }

  _getRemove() {
    return {
      svg: 'x',
      className: 'profilefield-profile-remove',
      handler: this._handleRemove
    }
  }

  _handleChange() {
    const { formatter } = this.props
    if(!this.props.profile) return
    const profile = formatter(this.props.profile)
    this.props.onChange(profile)
  }

  _handlePick() {
    const { disabled } = this.props
    if(disabled) return
    this.context.form.push(Picker, this._getPicker.bind(this))
  }

  _handleRemove() {
    this.props.onClear()
  }

}

export default Profilefield
