import MultiForm from '@admin/components/multiform'
import Translation from './translation'
import PropTypes from 'prop-types'
import Previews from './previews'
import Details from './details'
import Voices from './voices'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Edit Agency)',
      method: 'PATCH',
      endpoint: '/api/admin/truevail/agency/edit',
      action: '/api/admin/truevail/agency',
      getSteps: this._getSteps,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Voices)', component: Voices },
      { label: 't(Translation)', component: Translation },
      { label: 't(Previews)', component: Previews }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default New
