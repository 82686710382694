import { DragSource, DropTarget } from 'react-dnd'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

class Recipient extends React.PureComponent {

  static propTypes = {
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
    index: PropTypes.number,
    recipient: PropTypes.object,
    onAdd: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { connectDragPreview, connectDragSource, connectDropTarget, recipient } = this.props
    return (
      connectDropTarget(
        connectDragSource(
          connectDragPreview(
            <div { ...this._getRecipient() }>
              <div className="maha-recipientfield-recipient-label">
                { recipient.name || recipient.address }
              </div>
              <Button { ...this._getRemove() } />
            </div>
          )
        )
      )
    )
  }

  _getClass() {
    const { recipient } = this.props
    const classes = ['maha-recipientfield-recipient']
    if(!EMAIL_REGEX.test(recipient.address)) classes.push('error')
    return classes.join(' ')
  }

  _getRecipient() {
    return {
      className: this._getClass()
    }
  }

  _getRemove() {
    return {
      svg: 'x',
      className: 'maha-recipientfield-recipient-action',
      handler: this._handleRemove
    }
  }

  _handleRemove() {
    const { index } = this.props
    this.props.onRemove(index)
  }

}

const source = {
  beginDrag: (props) => ({
    id: props.id,
    index: props.index,
    recipient: props.recipient
  }),
  endDrag: (props, monitor, component) => {
    const result = monitor.getDropResult()
    if(result.action !== 'added') return
    props.onRemove(props.index)
  }
}

const target = {
  drop: (props, monitor, component) => {
    const item = monitor.getItem()
    if(props.isFull) return { action: false }
    if(props.id === item.id) {
      props.onMove(item.index, props.index)
      return { action: 'moved' }
    } else {
      props.onAdd(item.recipient, props.index)
      return { action: 'added' }
    }
  }
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop()
})

Recipient = DragSource('RECIPIENT', source, sourceCollector)(Recipient)
Recipient = DropTarget('RECIPIENT', target, targetCollector)(Recipient)

export default Recipient
