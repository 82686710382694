import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Domains)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/domains',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true },
      { label: 't(Warming)', key: 'warming_enabled', collapsing: true, format: 'check_times' },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' }
    ],
    defaultFilter: { tld_id: { $eq: 'null' } },
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      svg: 'globe',
      title: 't(No Domains)',
      text: 't(There are no domains)'
    },
    entity: 'domain',
    onClick: (record) => context.router.push(`/admin/truevail/admin/domains/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)