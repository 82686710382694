import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import bytes from 'bytes'
import React from 'react'

class Validating extends React.Component {

  static contextTypes = {
    uploader: PropTypes.object
  }

  static propTypes = {
    files: PropTypes.array,
    multiple: PropTypes.bool,
    requirements: PropTypes.array,
    onAlternate: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    failed: null,
    status: 'validating',
    validations: null
  }

  _handleAlternate = this._handleAlternate.bind(this)

  render() {
    const { status, validations } = this.state
    if(!validations) return null
    return (
      <>
        { status === 'validating' &&
          <Message { ...this._getValidating() } />        
        }
        { status === 'failed' &&
          <Message { ...this._getFailed() } />        
        }
      </>
    )
  }

  componentDidMount() {
    const { files } = this.props
    this.setState({ 
      validations: files.map(file => ({
        id: file.id,
        name: file.name,
        status: 'validating',
        error: null
      }))
    }, this._handleValidateFiles)
  }

  componentDidUpdate(prevProps, prevState) {
    const { validations } = this.state
    if(!_.isEqual(validations, prevState.validations)) {
      this._handleCheckValidations()
    }
  }

  _getValidating() {
    const { multiple } = this.props
    return {
      icon: 'circle-o-notch fa-spin',
      title: multiple ? 't(Validating Files)' : 't(Validating File)',
      text: multiple ? 't(Verifying that your files meet the minimum requirements)' : 't(Verifying that your file meets the minimum requirements)'
    }
  }

  _getFailed() {
    const { multiple } = this.props
    const { failed } = this.state
    return {
      svg: 'x',
      color: 'red',
      title: 't(Validation Failed)',
      text:  multiple ? 't(One of your files did not meet the minimum requirements)' : 't(Your file did not meet the minimum requirements)',
      component: (
        <>
          <strong>{ failed.name }</strong><br />
          <span className="alert">The uploaded file must have { failed.error.message }</span><br />
          The file you uploaded has { failed.error.description }
        </>
      ),
      buttons: [
        { label: 't(Try another file)', handler: this._handleAlternate }
      ]
    }
  }

  _getRatio(ratio) {
    if(ratio === 16/9) return '16:9'
    if(ratio === 10/8) return '10:8'
    if(ratio === 7/5) return '7:5'
    if(ratio === 4/3) return '4:3'
    if(ratio === 5/3) return '5:3'
    if(ratio === 3/2) return '3:2'
    if(ratio === 9/16) return '9:16'
    if(ratio === 8/10) return '8:10'
    if(ratio === 5/7) return '5:7'
    if(ratio === 3/4) return '3:4'
    if(ratio === 3/5) return '3:5'
    if(ratio === 2/3) return '2:3'
    return `${Math.round(ratio * 100) / 100}:1`
  }

  _handleAlternate() {
    this.props.onAlternate()
  }

  _handleCheckValidations() {
    const { validations } = this.state
    const failed = validations.find(validation => {
      return validation.status === 'failed'
    })
    if(failed) return this._handleFailed(failed)
    const completed = validations.find(validation => {
      return validation.status !== 'completed'
    })
    if(!completed) return this._handleCompleted()
  }

  _handleFailed(failed) {
    this.setState({
      status: 'failed',
      failed
    })
  }

  _handleCompleted() {
    this.props.onDone()
  }

  _handleValidateFiles() {
    const { files } = this.props
    files.map(file => {
      this._handleValidateGetFile(file)
    })
  }

  _handleValidateGetFile(file) {
    if(!file.create) {
      const resumable = this.context.uploader.getFile(file.id)
      return this._handleValidateFile(file, resumable.file)
    }
    fetch(file.id).then(res => res.blob()).then(blob => {
      this._handleValidateFile(file, blob)
    })
  }

  _handleValidateFile(file, resumable) {
    if(resumable.type.match(/image/)) return this._handleValidateImage(file, resumable)
    if(resumable.type.match(/video/)) return this._handleValidateVideo(file, resumable)
    this._handleValidateResult(file, {
      type: resumable.type.split('/')[0],
      size: resumable.size
    })
  }

  _handleValidateImage(file, resumable) {
    const el = document.createElement('img')
    el.src = window.URL.createObjectURL(resumable)
    el.onload = (e) => {
      window.URL.revokeObjectURL(el.src)
      const { width, height } = el
      const ratio = width / height
      this._handleValidateResult(file, { 
        type: resumable.type.split('/')[0],
        size: resumable.size,
        width,
        height,
        ratio
      })
    }
  }

  _handleValidateVideo(file, resumable) {
    const el = document.createElement('video')
    el.src = window.URL.createObjectURL(resumable)
    el.onloadedmetadata = (e) => {
      window.URL.revokeObjectURL(el.src)
      const width = el.videoWidth
      const height = el.videoHeight
      const duration = el.duration
      const ratio = width / height
      this._handleValidateResult(file, { 
        type: resumable.type.split('/')[0],
        size: resumable.size,
        duration,
        width,
        height,
        ratio
      })
    }
  }

  _handleValidateResult(file, attributes) {
    const { requirements } = this.props
    const { validations } = this.state
    const error = requirements.reduce((error, requirement) => {
      if(error) return error
      if(requirement.type === 'type') {
        const ext = file.name.split('.').pop()
        if(!_.includes(requirement.value, ext)) {
          return {
            message: `file type of ${requirement.value.split(', ')}`,
            description: `has a file type of ${ext}`
          }  
        }
      }
      if(requirement.type === 'ratio' && attributes.ratio !== requirement.value) {
        return {
          message: `an exact aspect ratio of ${requirement.text || requirement.value}`,
          description: `an aspect ratio of ${this._getRatio(attributes.ratio)}`
        }
      }
      if(requirement.type === 'min_ratio' && attributes.ratio < requirement.value) {
        return {
          message: `a mimimum aspect ratio of ${requirement.text || requirement.value}`,
          description: `an aspect ratio of ${this._getRatio(attributes.ratio)}`
        }
      }
      if(requirement.type === 'max_ratio' && attributes.ratio > requirement.value) {
        return {
          message: `a maximum aspect ratio of ${requirement.text || requirement.value}`,
          description: `an aspect ratio of ${this._getRatio(attributes.ratio)}`
        }
      }
      if(requirement.type === 'min_resolution') {
        const [width, height] = requirement.value.toLowerCase().split('x').map(n => parseInt(n))
        if(attributes.width < width || attributes.height < height) {
          return {
            message: `a minimum resolution of ${width}x${height}`,
            description: `a resolution of ${attributes.width}x${attributes.height}`
          }
        }
      }
      if(requirement.type === 'max_resolution') {
        const [width, height] = requirement.value.toLowerCase().split('x').map(n => parseInt(n))
        if(attributes.width > width || attributes.height > height) {
          return {
            message: `a maximum resolution of  ${width}x${height}`,
            description: `a resolution of ${attributes.width}x${attributes.height}`
          }
        }
      }
      if(requirement.type === 'min_duration' && attributes.duration < requirement.value) {
        return {
          message: `a minimum duration of ${requirement.value} seconds`,
          description: `has a duration of ${attributes.duration}`
        }
      }
      if(requirement.type === 'max_duration' && attributes.duration > requirement.value) {
        return {
          message: `a maximum duration of ${requirement.value} seconds`,
          description: `a duration of ${attributes.duration}`
        }
      }
      if(requirement.type === 'min_size' && attributes.size < requirement.value) {
        return {
          message: `a minimum size of ${bytes(requirement.value)}`,
          description: `a size of ${bytes(attributes.size)}`
        }
      }
      if(requirement.type === 'max_size' && attributes.size > requirement.value) {
        return {
          message: `a maximum size of ${bytes(requirement.value)}`,
          description: `a size of ${bytes(attributes.size)}`
        }
      }
      return null
    }, null)
    this.setState({
      validations: validations.map(validation => ({
        ...validation,
        ...validation.id === file.id ? {
          status: error ? 'failed' : 'completed',
          error
        } : {}
      }))
    })
  }

}

export default Validating
