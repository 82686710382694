import ApplyAetheticProfile from './apply_aesthetic_profile'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ advisor }) => {

  const advisor_button = {
    label: advisor.team.title,
    className: 'link',
    route: `/truevail/admin/advisors/${advisor.id}`
  }

  const aesthetic_profile = advisor.aesthetic_profile ? {
    label: advisor.aesthetic_profile.title,
    className: 'link',
    route: `/truevail/admin/aesthetic_profiles/${advisor.aesthetic_profile.id}`
  } : null

  const details = {
    sections: [
      {
        title: 't(Branding)',
        items: [
          { label: 't(Advisor)', content: <Button { ...advisor_button } /> },
          { label: 't(Aesthetic Profile)', content: aesthetic_profile ? <Button { ...aesthetic_profile } /> : null },
          { label: 't(Applied At)', content: advisor.aesthetic_profile_applied_at, format: 'datetime' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } branding={ props.branding } />,
  tasks: {
    items: [
      { 
        label: 't(Apply Aesthetic Profile)', 
        svg: 'stamp',
        modal: <ApplyAetheticProfile advisor={ props.advisor } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
