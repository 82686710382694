import PropTypes from 'prop-types'
import Sidebar from './sidebar'
import Canvas from './canvas'
import React from 'react'

class SocialDesigner extends React.PureComponent {

  static propTypes = {
    cacheKey: PropTypes.string,
    config: PropTypes.object,
    editable: PropTypes.bool,
    title: PropTypes.string,
    version: PropTypes.object,
    versions: PropTypes.array,
    onChange: PropTypes.func,
    onSave: PropTypes.func
  }

  render() {
    const { editable } = this.props
    return (
      <div className="maha-social-designer-main">
        <div className="maha-social-designer-preview">
          <Canvas { ...this._getCanvas() } />
        </div>
        { editable &&
          <div className="maha-social-designer-form">
            <Sidebar { ...this._getSidebar() } />
          </div>      
        }
      </div>
    )
  }

  _getCanvas() {
    const { config } = this.props
    return {
      config
    }
  }

  _getSidebar() {
    const { cacheKey, config, title, version, versions, onChange, onSave } = this.props
    return {
      cacheKey,
      config,
      title,
      version,
      versions,
      onChange,
      onSave
    }
  }

}

export default SocialDesigner
