import ProgramForm from '@apps/crm/admin/components/programform'
import FormToken from '@apps/forms/admin/tokens/form'
import Page from '@admin/components/page'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Forms)',
  access: { rights: { $oneOf: ['forms:view_forms','forms:manage_forms'] } },
  collection: {
    endpoint: '/api/admin/forms/forms',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: FormToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : [],
      { label: 't(Responses)', key: 'responses_count', collapsing: true, align: 'right' },
      ...context.admin.hasRights({ allOf: ['finance:access_app'] }) ? [
        { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right', format: 'currency' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Form)', fields: [
            { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'square_check',
      title: 't(No Forms)',
      text: 't(You have not yet created any forms)',
      buttons: [
        { 
          label: 't(Create New Form)', 
          access: { rights: { $oneOf: ['forms:manage_forms'] } },
          modal: <ProgramForm form={ New } /> 
        }
      ]
    },
    entity: 'form',
    onClick: (record) => context.router.push(`/forms/forms/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Duplicate Form)',
        svg: 'copy',
        access: {
          programs: { $canEdit: record.program.id },
          rights: { $oneOf: ['forms:manage_forms'] }
        },
        modal: <Clone form={ record } />
      },
      {
        label: 't(Delete Form)',
        svg: 'trash',
        access: { 
          programs: { $canEdit: record.program.id },
          rights: { $oneOf: ['forms:manage_forms'] } 
        },
        confirm: 't(Are you sure you want to delete this form? You will loose access to all of the response and revenue information)',
        deletable: true,
        request: {
          endpoint: `/api/admin/forms/forms/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this form)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete this form)')
        }
      }
    ]
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Form)',
    access: { rights: { $oneOf: ['forms:manage_forms'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
