import InputTokens from '@admin/components/input_tokens'
import { CSSTransition } from 'react-transition-group'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class TagField extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    locale: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    filter: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    focused: false,
    tag: '',
    tags: null,
    suggestions: null
  }

  _handleBlured = _.debounce(this._handleBlured.bind(this), 150, { trailing: true })
  _handleChange = this._handleChange.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSuggest = this._handleSuggest.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { disabled } = this.props
    const { focused, suggestions, tags } = this.state
    if(!tags) return null
    return (
      <div className={ this._getClass() }>
        { tags.length > 0 &&
          <div className="maha-tagfield-field">
            <div className="maha-tagfield-tags">
              { tags.length > 0 &&
                <InputTokens { ...this._getInputTokens() } />
              }
            </div>
            { !disabled &&
              <Button { ...this._getClear() } />
            }
          </div>
        }
        <CSSTransition in={ (focused && suggestions !== null) } classNames="expanded" timeout={ 150 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-tagfield-input-options">
            { suggestions &&
              <>
                { suggestions.map((tag) => (
                  <div className="maha-tagfield-input-option" key={`tag_${tag.id}`} onClick={ this._handleChoose.bind(this, tag)}>
                    { tag.text }
                  </div>
                ))}
                { suggestions.length === 0 &&
                  <div className="maha-tagfield-input-options-empty">
                    No matching tags
                  </div>          
                }
              </>
            }
          </div>
        </CSSTransition>
        <div className="maha-tagfield-input">
          <Input { ...this._getInput() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { endpoint, value } = this.props
    const { tags, tag } = this.state
    if(endpoint && tag !== prevState.tag) {
      this._handleSuggest()
    }
    if(!_.isEqual(tags, prevState.tags)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused, suggestions } = this.state
    const classes = ['maha-input','maha-tagfield']
    if(focused && suggestions) classes.push('suggesting')
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-tagfield-clear',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused, tag } = this.state
    return {
      type: 'text',
      disabled,
      placeholder: !focused ? this._getPlaceholder() : null,
      id,
      tabIndex,
      value: tag,
      onBlur: this._handleBlured,
      onKeyDown: this._handleKeyDown,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getInputTokens() {
    const { tags} = this.state
    return {
      tokens: tags.map(tag => ({
        value: tag,
        text: tag
      })),
      textKey: 'text',
      onRemove: this._handleRemove
    }
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Separate) ${entity} t(with a comma)`)
    if(label && label.length > 0) return t(`t(Separate) ${label} t(with a comma)`)
    return ''
  }

  _getRemove(index) {
    return {
      svg: 'x',
      className: 'maha-tagfield-tag-action',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleAdd(tag) {
    const { tags } = this.state
    this.setState({
      suggestions: null,
      tags: [
        ...tags.filter(existing => {
          return existing !== tag
        }),
        tag
      ],
      tag: ''
    })
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { tags } = this.state
    const value = tags.length > 0 ? tags : []
    this.props.onChange(value)
  }

  _handleChoose(tag) {
    this._handleAdd(tag.text)
  }

  _handleClear() {
    this.setState({
      tags: [],
      tag: ''
    })
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyDown(e) {
    const { tag } = this.state
    if(tag.length > 0 && _.includes([13,188], e.which)) {
      e.preventDefault()
      this._handleAdd(tag)
    }
  }

  _handleRemove(tag, i) {
    this.setState({
      tags: [
        ...this.state.tags.filter((tag, index) => {
          return index !== i
        })
      ]
    })
  }

  _handleSuggest() {
    const { endpoint, filter } = this.props
    const { tag, tags } = this.state
    if(tag.length === 0) return this.setState({ suggestions: null })
    this.context.network.request({
      endpoint,
      method: 'GET',
      filter: { 
        q: tag,
        text: {
          $nin: tags
        },
        ...filter || {}
      },
      onSuccess: ({ data }) => {
        this.setState({
          suggestions: data
        })    
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to load tags)')
    })
  }

  _handleSet(value) {
    const tags = value || []
    this.setState({ tags })
  }

  _handleUpdate(tag) {
    this.setState({ tag: tag.toLowerCase() })
  }

}

export default TagField
