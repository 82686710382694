import { useProviderContext } from '@admin/components/provider'
import { useNetworkContext } from '@admin/components/network'
import { useRouterContext } from '@admin/components/router'
import { usePromptContext } from '@admin/components/prompt'
import { useTasksContext } from '@admin/components/tasks'
import { useFlashContext } from '@admin/components/flash'
import { useAdminContext } from '@admin/components/admin'
import { useModalContext } from '@admin/components/modal'
import { useMahaContext } from '@admin/components/maha'
import { useHostContext } from '@admin/components/host'
import useContainer from '@admin/hooks/use_container'
import Message from '@admin/components/message'
import Loader from '@admin/components/loader'
import React from 'react'

const getFailure = () => ({
  svg: 'triangle_alert',
  title: 't(Unable to load remote data)',
  color: 'yellow',
  button: {
    label: 't(Try again)',
    handler: () => {}
  }
})

const getForbidden = () => ({
  svg: 'triangle_alert',
  title: 't(You do not have permission to access this content)'
})

const Container = (mapResources, mapProps) => (...params) => {

  const Component = params[0]
  const LoaderComponent = params[1] || Loader

  return (props) => {

    const adminContext = useAdminContext()
    const flashContext = useFlashContext()
    const hostContext = useHostContext()
    const mahaContext = useMahaContext()
    const modalContext = useModalContext()
    const networkContext = useNetworkContext()
    const promptContext = usePromptContext()
    const providerContext = useProviderContext()
    const routerContext = useRouterContext()
    const tasksContext = useTasksContext()

    const context = {
      admin: adminContext?.admin,
      alert: promptContext?.alert,
      confirm: promptContext?.confirm,
      flash: flashContext?.flash,
      host: hostContext?.host,
      maha: mahaContext?.maha,
      modal: modalContext?.modal,
      network: networkContext?.network,
      prompt: promptContext?.prompt,
      provider: providerContext?.provider,
      router: routerContext?.router,
      tasks: tasksContext?.tasks
    }

    const resources = mapResources ? mapResources(props, context) : null

    const container = useContainer({
      display_name: 'container',
      resources: resources && Object.keys(resources).length > 0 ? resources : null
    })

    if(container.status === 'pending') return null
    if(container.status === 'loading') return <LoaderComponent />
    if(container.status === 'failure') return <Message { ...getFailure() } />

    const containerProps = {
      cacheKey: container.status,
      ...props,
      ...container.values
    }

    const mergedProps = {
      ...containerProps,
      ...mapProps ? mapProps(containerProps, context, container.values) : {}
    }

    return <Component { ...mergedProps } />

  }

}

export default Container