import ModalPanel from '@admin/components/modal_panel'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Sound extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    onDone: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    selected: null
  }

  sounds = [
    { value: 'ding', text: 't(Ding)' },
    { value: 'boing', text: 't(Boing)' },
    { value: 'drop', text: 't(Drop)' },
    { value: 'tada', text: 't(Tada)' },
    { value: 'plink', text: 't(Plink)' },
    { value: 'wow', text: 't(Wow)' },
    { value: 'here_you_go', text: 't(Here you go)' },
    { value: 'hi', text: 't(Hi)' },
    { value: 'knock_brush', text: 't(Knock Brush)' },
    { value: 'whoah', text: 't(Whoah!)' },
    { value: 'yoink', text: 't(Yoink)' }
  ]

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    const { selected } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-options">
          { this.sounds.map((sound, index) => (
            <div className="maha-option" key={ `sound_${index}` } onClick={ this._handleChoose.bind(this, index)}>
              <div className="maha-option-icon" onClick={ this._handleClick.bind(this, index)}>
                <Icon icon="volume-up" />
              </div>
              <div className="maha-option-label">
                { sound.text }
                { index === selected &&
                  <Icon svg="check" />
                }
              </div>
            </div>
          ))}
        </div>
      </ModalPanel>
    )
  }

  componentDidMount(){
    const { defaultValue } = this.props
    const selected = _.findIndex(this.sounds, { value: defaultValue })
    this.setState({ selected })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    if(selected !== prevState.selected && prevState.selected !== null) {
      this._handleDone()
    }
  }

  _getPanel() {
    return {
      title: 't(Choose Sound)',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleChoose(index) {
    this._handlePlay(index)
    this.setState({
      selected: index
    })
  }

  _handleDone() {
    const { selected } = this.state
    const sound = this.sounds[selected]
    this.props.onDone(sound.value)
    this.context.form.pop()
  }

  _handleClick(index, e) {
    e.stopPropagation()
    this._handlePlay(index)
  }

  _handlePlay(index) {
    const sound = this.sounds[index]
    const audio = new Audio(`/audio/${sound.value}.mp3`)
    audio.play()
  }

}

export default Sound
