import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Library extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleFetch = this._handleFetch.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { social_campaigns } = this.state
    return {
      defaultValue: social_campaigns,
      endpoint: '/api/admin/truevail/library/social_campaigns',
      entity: 'social campaign',
      format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ true }/>
      ),
      multiple: false,
      screenshot: (entity) => `truevail_social_campaign_variants/${entity.variants[0].code}`,
      showHeader: false,
      onDone: this._handleFetch
    }
  }

  _handleFetch(social_campaign) {
    this.context.network.request({
      endpoint: `/api/admin/truevail/library/social_campaigns/${social_campaign.id}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this._handleDone(data)
      }
    })
  }

  _handleDone(social_campaign) {
    this.props.onNext({
      truevail_social_campaign_id: social_campaign.id,
      title: social_campaign.title,
      type: social_campaign.type,
      truevail_templates: social_campaign.variants.map(variant => ({
        truevail_social_campaign_variant_id: variant.id,
        service: variant.service,
        config: variant.config
      }))
    })
  }

}

export default Library
