import SourceToken from '@admin/tokens/source'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const networks = this._getSources()
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Title)', 
              name: 'title', 
              type: 'textfield', 
              defaultValue: formdata.title,
              required: true 
            },
            { 
              label: 't(Networks)', 
              name: 'sources', 
              type: 'checkboxgroup', 
              entity: 'network', 
              options: networks,
              valueKey: 'source_name', 
              textKey: 'name', 
              format: (source) => <SourceToken source={ source } />, 
              defaultValue: networks.map(n => n.source_name), 
              required: true 
            },
            { 
              label: 't(Voice)', 
              type: 'segment', 
              fields: [
                { 
                  label: 'Perspective', 
                  type: 'dropdown', 
                  name: 'perspective', 
                  options: [
                    { value: 'all', text: 't(All Perspectives)' },
                    { value: 'first-person singular', text: 't(First Person Singular)' },
                    { value: 'first-person plural', text: 't(First Person Plural)' }
                  ], 
                  defaultValue: 'all', 
                  required: true 
                },
                { 
                  label: 'Language', 
                  type: 'dropdown', 
                  name: 'language',
                  options: [
                    { value: 'all', text: 't(All Languages)' },
                    { value: 'american english', text: 't(English) (t(American))' },
                    { value: 'canadian english', text: 't(English) (t(Canadian))' },
                    { value: 'french', text: 't(French)' },
                    { value: 'german', text: 't(German)' },
                    { value: 'italian', text: 't(Italian)' }
                  ], 
                  defaultValue: 'all', 
                  required: true 
                }
              ], 
              required: true 
            },
            { label: 't(Design URL)', name: 'design_url', type: 'urlfield' }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { features } = this.context.admin
    const { type } = this.props.formdata
    return [
      ..._.includes(['photo','video','story','reel'], type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      ..._.includes(['photo','video','link','story','reel'], type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ...features.linkedin === true && _.includes(['photo','video','link'], type) ? [
        { name: 'LinkedIn', source_name: 'linkedin' }
      ] : [],
      ...features.youtube === true && _.includes(['reel','video'], type) ? [
        { name: 'Youtube', source_name: 'youtube' }
      ] : [],
      ...features.tiktok === true && _.includes(['reel'], type) ? [
        { name: 'TikTok', source_name: 'tiktok' }
      ] : []
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onNext(social_campaign)
  }

}

export default Details
