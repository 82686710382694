import AssetViewer from '@admin/components/asset_viewer'
import Attachment from '@admin/components/attachment'
import Timestamp from '@admin/components/timestamp'
import Reactions from '@admin/components/reactions'
import Reaction from '@admin/components/reaction'
import RichText from '@admin/components/richtext'
import Gallery from '@admin/components/gallery'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import QuotedComment from './quoted_comment'
import Link from '@admin/components/link'
import PropTypes from 'prop-types'
import React from 'react'

class Comment extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.object,
    attachments: PropTypes.array,
    created_at: PropTypes.string,
    entity: PropTypes.string,
    files: PropTypes.array,
    id: PropTypes.number,
    images: PropTypes.array,
    link: PropTypes.object,
    media: PropTypes.array,
    quoted_comment: PropTypes.object,
    reactions: PropTypes.array,
    text: PropTypes.string,
    uid: PropTypes.string,
    updated_at: PropTypes.string,
    user: PropTypes.object,
    onDelete: PropTypes.func,
    onQuote: PropTypes.func
  }

  _handleDelete = this._handleDelete.bind(this)
  _handleHide = this._handleHide.bind(this)
  _handleQuote = this._handleQuote.bind(this)
  _handleShow = this._handleShow.bind(this)

  render() {
    const { created_at, files, images, link, media, quoted_comment, user } = this.props
    const { admin } = this.props.context
    return (
      <div { ...this._getComment() }>
        <div className="maha-comment-avatar">
          <Avatar user={ user } />
        </div>
        <div className="maha-comment-content">
          <div className="maha-comment-author">
            <div className="maha-comment-name">{ user.full_name }</div>
            <div className="maha-comment-timestamp">
              <Timestamp time={ created_at } />
            </div>
          </div>
          <div className="maha-comment-extras">
            { quoted_comment && <QuotedComment comment={ quoted_comment } /> }
            <div className="maha-comment-text">
              <RichText { ...this._getRichtext() } />
            </div>
            { link && <Link link={ link } /> }
            { images.length > 0 && <Gallery { ...this._getGallery() } /> }
            { media.length > 0 &&
              <div className="maha-medias">
                { media.map((video, index) => (
                  <div className="maha-media" key={`video_${index}`} onClick={ this._handleClick.bind(this, video.asset) }>
                    <AssetViewer asset={ video.asset } />
                    <AssetToken { ...video.asset } />
                  </div>
                ))}
              </div>
            }
            { files.length > 0 &&
              <div className="maha-files">
                { files.map((file, index) => (
                  <div className="maha-files-file" key={`file_${index}`}>
                    <Attachment { ...file } />
                  </div>
                ))}
              </div>
            }
          </div>
          <Reactions { ...this._getReactions() } />
        </div>
        <div className="maha-comment-actions">
          <div className="maha-comment-action">
            <Button { ...this._getQuote() } />
          </div>
          <div className="maha-comment-action">
            <Reaction { ...this._getReaction() } />
          </div>
          { admin.user.id === user.id &&
            <div className="maha-comment-action">
              <Button { ...this._getDelete() } />
            </div>
          }
        </div>
      </div>
    )
  }

  _getComment() {
    return {
      className: 'maha-comment inline',
      onMouseEnter: this._handleShow,
      onMouseLeave: this._handleHide
    }
  }

  _getDelete() {
    return {
      svg: 'x',
      className: 'maha-comment-reply',
      title: 't(Delete this comment)',
      confirm: 't(Are you sure you want to delete this comment?)',
      handler: this._handleDelete
    }
  }

  _getGallery() {
    const { id, images } = this.props
    return {
      images,
      attachable_type: 'chat_messages',
      attachable_id: id
    }
  }

  _getQuote() {
    return {
      icon: 'arrow-circle-left',
      className: 'maha-comment-reply',
      title: 't(Reply to this comment)',
      handler: this._handleQuote
    }
  }

  _getRichtext() {
    const { attachments, text } = this.props
    return {
      text: text,
      attachments: attachments
    }
  }

  _getReaction() {
    const { id, reactions } = this.props
    return {
      id,
      reactions,
      table: 'maha_comments'
    }
  }

  _getReactions() {
    const { id, reactions } = this.props
    return {
      id,
      reactions,
      table: 'maha_comments'
    }
  }

  _handleDelete() {
    this.props.onDelete()
  }

  _handleHide() {
    this.setState({ show: false })
  }

  _handleQuote() {
    this.props.onQuote()
  }

  _handleShow() {
    this.setState({ show: true })
  }

}

export default Comment
