import WorkflowPublish from '@apps/truevail/admin/components/workflow_publish'
import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forms)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/workflows`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'display_name', sort: 'created_at', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow }/>
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'workflow',
      title: 't(No Workflow)',
      text: 't(You have not yet created any workflows for this advisor)',
      buttons: [
        { 
          label: 't(Publish Advisor Workflow)',
          modal: <WorkflowPublish advisor={ props.advisor } entity="admin" />
        }
      ]
    },
    entity: 'form',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/automation/workflows/${record.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Advisor Workflow)',
    modal: <WorkflowPublish advisor={ props.advisor } entity="admin" />
  }
})

export default Panel(null, mapPropsToPanel)
