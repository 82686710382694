import DeliverabilityRate from '@admin/tokens/deliverability_rate'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Domain)', key: 'domain.name', sort: 'domain', primary: true },
      { label: 't(Sent)', key: 'sent_count', collapsing: true, align: 'right' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="open_rate" />
      ) },
      { label: 't(Click)', key: 'click_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="click_rate" />
      ) },
      { label: 't(Bounce)', key: 'bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="bounce_rate" />
      ) },
      { label: 't(Soft Bounce)', key: 'soft_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="soft_bounce_rate" />
      ) },
      { label: 't(Hard Bounce)', key: 'hard_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="hard_bounce_rate" />
      ) },
      { label: 't(Complain)', key: 'complain_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="complain_rate" />
      ) },
      { label: 't(Unsubscribe)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate domain={ domain } metric="unsubscribe_rate" />
      ) }
    ],
    defaultQuery: { 
      ...props.params.segment_id ? { 
        segment_id: props.params.segment_id
      } : {},
      interval: props.params.interval === 'day' ? 'hour' : 'minute',
      group_by: 'domain',
      value: props.params.value
    },
    defaultSort: { key: 'domain', order: 'asc' },
    empty: {
      svg: 'mail',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'domain',
    onClick: (record) => {
      const parts = ['/admin/truevail/admin/sent']  
      if(props.params.segment_id) parts.push(`domain_segments/${record.domain_segment.id}`)
      else parts.push(`domains/${record.domain.id}`)
      if(props.params.interval === 'day') parts.push(`day/${props.params.value}`)
      if(props.params.interval === 'hour') parts.push(`hour/${props.params.value}`)
      context.router.push(parts.join('/'))
    }  
  }
})

export default Panel(null, mapPropsToPanel)
