import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const InputTokens = (props) => {

  const { format, textKey, tokens, onRemove } = props

  const getLabel = (token) => {
    if(textKey) return  <T text={ _.get(token, textKey) } />
    if(format) return format(token)
    return token
  }

  const getRemove = (token, index) => ({
    svg: 'x',
    type: 'custom',
    className: 'maha-input-token-action',
    handler: () => onRemove(token, index)
  })

  return (
    <div className="maha-input-tokens">
      { tokens.map((token, index) => (
        <div className="maha-input-token" key={ `chosen_${index}` }>
          <div className="maha-input-token-label">
            { getLabel(token) }
          </div>
          { onRemove &&
            <Button { ...getRemove(token, index) } />
          }
        </div>
      )) }
    </div>
  )

}

InputTokens.propTypes = {
  format: PropTypes.func,
  tokens: PropTypes.array,
  textKey: PropTypes.string,
  onRemove: PropTypes.func
}

export default InputTokens