import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Scrollbar from '@admin/components/scrollbar'
import { Item, SortItem } from './item'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

class List extends React.Component {

  static propTypes = {
    actions: PropTypes.func,
    className: PropTypes.string,
    empty: PropTypes.any,
    format: PropTypes.any,
    header: PropTypes.string,
    itemClass: PropTypes.func,
    itemDisabled: PropTypes.func,
    items: PropTypes.array,
    itemsRef: PropTypes.func,
    listRef: PropTypes.func,
    maxHeight: PropTypes.string,
    scrollable: PropTypes.bool,
    scrollOnAdd: PropTypes.bool,
    transition: PropTypes.object,
    onClick: PropTypes.func,
    onMove: PropTypes.func
  }

  static defaultProps = {
    className: 'bordered',
    scrollable: false,
    scrollOnAdd: false
  }

  list = null
  items = null

  render() {
    const { empty, header, items, onMove } = this.props
    const ItemComponent = this._getItemComponent()
    const TransitionGroupComponent = this._getTransitionGroupComponent()
    const CSSTransitionComponent = this._getCSSTransitionComponent()
    return (
      <div { ...this._getList() }>
        { header &&
          <div className="maha-list-header">
            <T text={ header } />
          </div>
        }
        { items.length > 0 &&
          <Scrollbar { ...this._getItems()}>
            <TransitionGroupComponent { ...this._getTransitionGroup() } >
              { items.map((item, index) => (
                <CSSTransitionComponent { ...this._getCSSTransition() } key={`item_${index}`}>
                  { onMove ?
                    <div className="maha-list-item-container">
                      <ItemComponent { ...this._getItem(item, index) } />
                    </div> :
                    <ItemComponent { ...this._getItem(item, index) } />
                  }
                </CSSTransitionComponent>
              ))}
            </TransitionGroupComponent>
          </Scrollbar>
        }
        { (items.length === 0 && empty) &&
          <div className="maha-list-empty">
            { empty }
          </div>
        }
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props
    if(items.length > prevProps.items.length) {
      this._handleScrollOnAdd()
    }
  }

  _getCSSTransition() {
    const { transition } = this.props
    return transition || {}
  }

  _getCSSTransitionComponent() {
    const { transition } = this.props
    return transition ? CSSTransition : Fragment
  }

  _getItem(item, index) {
    const { actions, format, itemClass, itemDisabled, onClick, onMove } = this.props
    return {
      actions,
      format,
      index,
      item,
      itemClass,
      itemDisabled,
      onClick,
      onMove
    }
  }

  _getItemComponent() {
    const { onMove } = this.props
    return onMove ? SortItem : Item
  }

  _getItems() {
    const { itemsRef, maxHeight } = this.props
    return {
      className: 'maha-list-items',
      ...maxHeight ? { maxHeight, hideScroll: false } : {},
      ref: node => {
        if(itemsRef) itemsRef(node)
        this.items = node
      }
    }
  }

  _getList() {
    const { listRef } = this.props
    return {
      className: this._getListClass(),
      ref: node => {
        if(listRef) listRef(node)
        this.list = node
      }
    }
  }

  _getListClass() {
    const { className, scrollable, onClick } = this.props
    const classes = ['maha-list', className]
    if(onClick) classes.push('clickable')
    if(scrollable) classes.push('scrollable')
    return classes.join(' ')
  }

  _getTransitionGroup() {
    const { transition } = this.props
    return transition ? {
      component: null
    } : {}
  }

  _getTransitionGroupComponent() {
    const { transition } = this.props
    return transition ? TransitionGroup : Fragment
  }

  _handleScrollOnAdd() {
    const { scrollOnAdd, scrollable } = this.props
    if(!scrollable || !scrollOnAdd) return
    this.list.scrollTop = this.list.scrollHeight
  }

}

export default List
