import EmailViewer from '@admin/components/email_viewer/email'
import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

class IncomingEmail extends React.Component {

  static propTypes = {
    email: PropTypes.object,
    show_interactions: PropTypes.bool
  }

  render() {
    return <EmailViewer { ...this._getEmailViewer() } />
  }

  _getEmailViewer() {
    const { email, show_interactions } = this.props
    return {
      email,
      show_interactions
    }
  }
}

const mapResources = (props, context) => ({
  email: `/api/admin/incoming_emails/${props.uuid}`
})

export default Container(mapResources)(IncomingEmail)
