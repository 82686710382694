import ModalPanel from '@admin/components/modal_panel'
import Loader from '@admin/components/loader'
import Stack from '@admin/components/stack'
import Steps from '@admin/components/steps'
import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    cancelSvg: PropTypes.string,
    errors: PropTypes.array,
    formdata: PropTypes.object,
    formatData: PropTypes.func,
    message: PropTypes.string,
    props: PropTypes.object,
    saveText: PropTypes.string,
    showSteps: PropTypes.bool,
    status: PropTypes.string,
    step: PropTypes.number,
    steps: PropTypes.array,
    title: PropTypes.string,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func,
    onSetStep: PropTypes.func,
    onSuccess: PropTypes.func,
    onUpdateData: PropTypes.func
  }

  stackRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    const { message, status, steps, showSteps  } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        { _.includes(['loading'], status) ? 
          <Loader /> :
          <div className="multiform">
            { showSteps && steps.length > 1 &&
              <div className="multiform-header">
                <Steps { ...this._getSteps() } />
              </div>
            }
            { message &&
              <div className="maha-form-message">
                { message }
              </div>      
            }
            <div className="multiform-body">
              <Stack { ...this._getStack() } />
            </div>
          </div>
        }
      </ModalPanel>
    )
  }

  componentDidMount() {
    this.props.onSetStep(0)
  }

  componentDidUpdate(prevProps) {
    const { step, steps } = this.props
    if(step > prevProps.step ) {
      this._handlePush(steps[step].component, this._getStep.bind(this))
    } else if(step < prevProps.step ) {
      this._handlePop()
    }
  }

  _getFailure() {
    return {
      svg: 'triangle_alert',
      title: 't(Submission Failure)',
      text: 't(Unable to submit this form)'
    }
  }

  _getPanel() {
    const { cancelSvg, title } = this.props
    return {
      title,
      leftItems: [
        {
          ...cancelSvg ? {
            svg: cancelSvg 
          } : {
            label: 't(Cancel)'
          },
          handler: this._handleCancel 
        }
      ]
    }
  }

  _getStack() {
    return {
      display_name: 'multiform',
      ref: this.stackRef
    }
  }

  _getStep() {
    const { formdata, props, saveText, onSetStep } = this.props
    return {
      formdata,
      props,
      saveText,
      onBack: this._handleBack,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onNext: this._handleNext,
      onSave: this._handleSave,
      onSetStep
    }
  }

  _getSteps() {
    const { step, steps } = this.props
    return {
      completable: false,
      steps: steps.map(step => {
        return step.label
      }),
      current: step
    }
  }

  _handleBack() {
    const { step } = this.props
    this.props.onSetStep(step - 1)
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(data) {
    const { step, formdata } = this.props
    this.props.onUpdateData({
      ...formdata,
      ...data
    }, step)
  }

  _handleNext(data) {
    const { step, formdata, onNext } = this.props
    if(onNext) onNext({
      ...formdata,
      ...data
    })
    this.props.onUpdateData({
      ...formdata,
      ...data
    }, step + 1)
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSave(data) {
    const { formdata, formatData } = this.props
    const body = formatData({
      ...formdata,
      ...data
    })
    this.props.onSave(body)
  }

}

export default Main
