import Stack from '@admin/components/stack'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import Design from './design'
import React from 'react'

class Designs extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  state = {
    variants: []
  }

  stackRef = React.createRef()

  render() {
    return <Stack { ...this._getStack()} />
  }

  componentDidMount() {
    this._handleDesign(0)
  }

  _getDefaultValue(profile, config) {
    const { formdata } = this.props
    const { type, truevail_templates, canva_template } = formdata
    const truevail_template = truevail_templates?.find(variant => {
      return variant.service === profile.service.source
    }) || truevail_templates?.[0] || null
    return {
      truevail_social_campaign_variant_id: truevail_template?.truevail_social_campaign_variant_id || null,
      config: {
        entity: {
          id: getCode(5),
          entity: profile.service.source,
          content: {
            ...truevail_template?.config?.entity?.content || {},
            ...canva_template?.entity?.content || {},
            ...config?.entity?.content || {},
            type,
            profile: {
              profile_id: profile.id,
              profile
            }  
          }
        }
      }
    }
  }

  _getStack() {
    return {
      display_name: 'social_design',
      ref: this.stackRef
    }
  }

  _handleDone() {
    const { variants } = this.state
    this.props.onNext({ variants })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleDesign(index, config) {
    const { formdata, onBack } = this.props
    const { profiles } = formdata    
    const profile = profiles[index]
    const defaultValue = this._getDefaultValue(profile, config)
    this._handlePush(Design, {
      formdata,
      profile,
      defaultValue: defaultValue.config,
      onBack,
      onNext: this._handleNext.bind(this, index, defaultValue.truevail_social_campaign_variant_id)
    })
  }

  _handleNext(index, truevail_social_campaign_variant_id, config) {
    const { variants } = this.state
    const { formdata } = this.props
    const { profiles } = formdata
    this.setState({
      variants: [
        ...variants,
        {
          profile_id: profiles[index].id,
          ...truevail_social_campaign_variant_id ? { truevail_social_campaign_variant_id } : {},
          config
        }
      ]
    }, () => {
      if(profiles.length === index + 1) return this._handleDone()
      this._handleDesign(index + 1, config)
    })
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Designs

