import SocialDesigner from '@admin/components/social_designer'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.object,
    formdata: PropTypes.object,
    profile: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNext: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <SocialDesigner { ...this._getSocialDesigner() } />
      </ModalPanel>
    )
  }

  _getSocialDesigner() {
    const { defaultValue } = this.props
    return {
      defaultValue,
      onChange: this._handleUpdate
    }
  }

  _getPanel() {
    return {
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSubmit() {
    const { config } = this.state
    this.props.onNext(config)
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Design
