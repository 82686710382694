import FormPublish from '@apps/truevail/admin/components/form_publish'
import LibraryAdd from '@apps/truevail/admin/components/library_add'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ form }) => {

  const details = {
    audits: `truevail_forms/${form.id}`,
    comments: `truevail_forms/${form.id}`,
    sections: [
      {
        title: 't(Form Details)',
        items: [
          { label: 't(Title)', content: form.title }
        ]
      },
      {
        title: 't(Library Details)',
        items: [
          { label: 't(Folders)', content: form.folders.length > 0 ? form.folders.map(folder => folder.title).join(', ') : null },
          { label: 't(Tags)', content: form.tags.length > 0 ? <TagsToken tags={ form.tags } /> : null }
        ]
      }
    ]
  }

  if(form.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This form was deleted)' }
  } else if(form.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This form is not published to the library)' }
  } else if(form.status === 'published') {
    details.alert = { color: 'green', message: 't(This form is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } />,
  tasks: {
    items: [
      {
        label: 't(Edit Form)',
        svg: 'square_pen',
        modal: <Edit form={ props.form } />
      },
      {
        label: 't(Duplicate Form)',
        svg: 'copy',
        modal: <Clone form={ props.form } />
      },
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: props.form.status === 'draft',
        modal: <LibraryAdd scope="admin" entity="forms" record={ props.form } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: props.form.status === 'published',
        confirm: 't(Are you sure you want to unpublish this form?)',
        request: {
          endpoint: `/api/admin/truevail/agency/forms/${props.form.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished this form)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish this form)')
        }
      },
      {
        label: 't(Publish Advisor Form)',
        svg: 'user_round',
        modal: <FormPublish form_id={ props.form.id } entity="agency" />
      },
      {
        label: 't(Delete Form)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this form?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/forms/${props.form.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted form)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete form)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
