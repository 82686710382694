import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    products: PropTypes.array,
    onDone: PropTypes.func
  }

  state = {
    line_item: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Line Item)',
      cancelSvg: 'chevron_left',
      saveText: 't(Done)',
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Project)', name: 'project_id', type: 'lookup',  endpoint: '/api/admin/finance/memberships', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
            { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken },
            { label: 't(Description)', name: 'description', required: true, type: 'textfield' },
            { label: 't(Quantity)', name: 'quantity', required: true, type: 'numberfield', placeholder: '0.00' },
            { label: 't(Unit Price)', name: 'price', required: true, type: 'moneyfield', placeholder: '0.00' },
            { label: 't(Tax Rate)', name: 'tax_rate', required: true, type: 'ratefield', placeholder: '0.000', defaultValue: 0.000 },
            { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 'Is this product tax deductable?', defaultValue: false }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleSuccess(line_item) {
    this.props.onDone(line_item)
    this.context.form.pop()
  }

}

export default New
