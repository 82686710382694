const badges = [
  // { 
  //   title: 't(Truevail Library)', 
  //   weight: 10, 
  //   svg: 'book_open', 
  //   route: '/truevail/library', 
  //   visible: ['desktop','tablet'], 
  //   tooltip: 't(Truevail Content Library)'
  // },
  { 
    title: 't(Truevail Admin)', 
    weight: 10, 
    access: {
      rights: { $allOf: ['truevail:access_admin'] }
    },
    svg: 'plane', 
    route: '/truevail/truevail/admin', 
    visible: ['desktop','tablet'], 
    tooltip: 't(Truevail Admin)'
  }
]

export default badges
