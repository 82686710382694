import Form from '@admin/components/form'
import * as options from '../variables'
import PropTypes from 'prop-types'
import React from 'react'

class Security extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleReset = this._handleReset.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: this._getDefault(this.props.config)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate(null, config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      onCancel: this._handleDone,
      onChange: this._handleChange,
      showHeader: false,
      sections: [
        {
          label: 't(Form)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Button Text)', name: 'body.button_text', type: 'textfield', defaultValue: config.body.button_text },
            { name: 'security.captcha', type: 'checkbox', prompt: 'Include CAPTCHA?', defaultValue: config.security.captcha }
          ]
        },
        {
          label: 't(Confirmation Page)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 'Once Form is Submitted', name: 'confirmation.strategy', type: 'radiogroup', options: options.strategies, defaultValue: config.confirmation.strategy },
            ...config.confirmation.strategy === 'message' ? [
              { label: 't(Message)', name: 'confirmation.message', type: 'htmlfield', defaultValue: config.confirmation.message } 
            ] : [
              { label: 't(URL)', name: 'confirmation.redirect', type: 'textfield', placeholder: 'http://', defaultValue: config.confirmation.redirect }
            ]
          ]
        },
        {
          label: 't(Limits)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 'Max Responses', name: 'limits.max_responses', type: 'numberfield', defaultValue: config.limits.max_responses },
            { type: 'fields', fields: [
              { label: 't(Start Date)', name: 'limits.start_date', type: 'datefield', defaultValue: config.limits.start_date },
              { label: 't(End Date)', name: 'limits.end_date', type: 'datefield', defaultValue: config.limits.end_date }
            ] },
            { label: 'Once Closed', name: 'limits.strategy', type: 'radiogroup', options: options.strategies, defaultValue: config.limits.strategy },
            ...config.limits.strategy === 'message' ? [
              { label: 't(Message)', name: 'limits.message', type: 'htmlfield', defaultValue: config.limits.message } 
            ] : [
              { label: 't(URL)', name: 'limits.redirect', type: 'textfield', placeholder: 'http://', defaultValue: config.limits.redirect }
            ]
          ]
        }
      ]
    }
  }

  _getDefault(config) {
    return {
      ...config || {},
      confirmation: {
        strategy: 'message',
        message: '<p>Thank you for your submission</p>',
        redirect: '',
        ...config.confirmation || {}
      },
      limits: {
        max_responses: null,
        start_date: null,
        end_date: null,
        strategy: 'message',
        message: null,
        redirect: '',
        ...config.limits || {}
      },
      body: {
        button_text: 'Submit',
        ...config.body || {}
      },
      security: {
        captcha: true,
        ...config.security || {}
      }
    }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config || {},
        confirmation: {
          ...this.state.config.confirmation || {},
          ...config.confirmation || {}
        },
        limits: {
          ...this.state.config.limits || {},
          ...config.limits || {}
        },
        body: {
          ...this.state.config.body || {},
          ...config.body || {}
        },
        security: {
          ...this.state.config.security || {},
          ...config.security || {}
        }
      }
    })
  }

  _handleDone() {
    this.props.onPop()
  }

  _handleReset() {
    this.setState({
      config: this._getDefault()
    })
  }

}

export default Security
