import ModalPanel from '@admin/components/modal_panel'
import ProfileToken from '@admin/tokens/profile'
import Message from '@admin/components/message'
import List from '@admin/components/list'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Overview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    profiles: PropTypes.array,
    onDone: PropTypes.func,
    onReconnect: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)
  _handleReconnect = this._handleReconnect.bind(this)

  render() {
    const { profiles } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        { profiles.length > 0 ?
          <div className="maha-profile-reconnect">
            <div className="maha-profile-reconnect-intro">
              <T text="t(One or more of your social profiles require attention. Please address the following issues:)" />
            </div>
            <List { ...this._getList() } />
          </div> :
          <Message { ...this._getSuccess() } />          
        }
      </ModalPanel>
    )
  }

  _getList() {
    const { profiles } = this.props
    return {
      items: profiles,
      format: (profile) => <ProfileToken alert={ true } profile={ profile } />,
      onClick: this._handleReconnect
    }
  }

  _getPanel() {
    return {
      title: 't(Reconnect Profiles)',
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _getSuccess() {
    return {
      title: 't(All Profiles Reconnected)',
      text: 't(You have successfully reconnected all of your profiles)',
      svg: 'check',
      color: 'green',
      animation: 'tada',
      button: {
        label: 't(Finish)',
        handler: this._handleDone
      }
    }
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleReconnect(profile) {
    this.props.onReconnect(profile)
  }

}

export default Overview