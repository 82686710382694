import VendorToken from '@apps/finance/admin/tokens/vendor'
import Panel from '@admin/components/panel'
import Merge from '../vendors/merge'
import Edit from '../vendors/edit'
import New from '../vendors/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Vendors)',
  access: { rights: { $oneOf: ['finance:view_vendors','finance:manage_vendors'] } },
  collection: {
    endpoint: '/api/admin/finance/vendors',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true, format: VendorToken },
      { label: 't(Items)', key: 'items_count', collapsing: true, align: 'right'  }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Name)', key: 'name' },
      { label: 'Items', key: 'items_count' }
    ],
    onClick: (record) => context.router.push(`/finance/vendors/${record.id}`),
    recordTasks: (record) => [
      {
        label: 'Merge Vendor',
        access: { rights: { $oneOf: ['finance:manage_vendors'] } },
        modal: <Merge id={ record.id } />
      }, {
        label: 'Edit Vendor',
        access: { rights: { $oneOf: ['finance:manage_vendors'] } },
        modal: <Edit integration={ props.app.settings.integration } id={ record.id } />
      }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      icon: 'percent',
      title: 't(No Vendors)',
      text: 't(You have not yet created any vendors)',
      buttons: [
        { 
          label: 't(Create Vendor)', 
          access: { rights: { $oneOf: ['finance:manage_vendors'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'vendor'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Vendor)', 
    access: { rights: { $oneOf: ['finance:manage_vendors'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
