import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Send from '../send'
import Edit from '../edit'
import React from 'react'

const Overview = ({ sms_campaign }, { admin }) => {

  const program = {
    label: sms_campaign.program.title,
    className: 'link',
    route: `/team/programs/${sms_campaign.program.id}`
  }

  const details = {
    audits: `campaigns_sms_campaigns/${sms_campaign.id}`,
    comments: `campaigns_sms_campaigns/${sms_campaign.id}`,
    sections: [{
      title: 't(SMS Campaign Details)',
      items: [
        { label: 't(Title)', content: sms_campaign.title },
        ...admin.team.has_programs ? [
          { label: 't(Program)', content: <Button { ...program } /> }
        ] : []
      ]
    }]
  }

  if(sms_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This sms campaign is in draft mode)' }
  } else if(sms_campaign.status === 'active') {
    details.alert = { color: 'green', message: 't(This sms campaign is active)' }
  } else if(sms_campaign.status === 'inactive') {
    details.alert = { color: 'red', message: 't(This sms campaign is inactive)' }
  } else if(sms_campaign.status === 'retired') {
    details.alert = { color: 'red', message: 't(This sms campaign is retired)' }
  } else if(sms_campaign.status === 'scheduled') {
    details.alert = { color: 'teal', message: 't(This sms campaign is scheduled)' }
  } else if(sms_campaign.status === 'posting') {
    details.alert = { color: 'yellow', message: 't(This sms campaign is in the process of being sent)' }
  } else if(sms_campaign.status === 'sent') {
    details.alert = { color: 'green', message: 't(This sms campaign was sent)' }
  }

  if(sms_campaign.event) {
    const event = {
      label: sms_campaign.event.title,
      className: 'link',
      route: `/events/events/${sms_campaign.event.id}`
    }
    details.sections[0].items.push({ label: 't(Event)', content: <Button { ...event } /> })
  }

  if(sms_campaign.form) {
    const form = {
      label: sms_campaign.form.title,
      className: 'link',
      route: `/forms/forms/${sms_campaign.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(sms_campaign.pipeline) {
    const pipeline = {
      label: sms_campaign.pipeline.title,
      className: 'link',
      route: `/sales/pipelines/${sms_campaign.pipeline.id}`
    }
    details.sections[0].items.push({ label: 't(Pipeline)', content: <Button { ...pipeline } /> })
  }

  if(sms_campaign.service) {
    const service = {
      label: sms_campaign.service.title,
      className: 'link',
      route: `/subscriptions/services/${sms_campaign.service.id}`
    }
    details.sections[0].items.push({ label: 't(Service)', content: <Button { ...service } /> })
  }

  if(sms_campaign.store) {
    const store = {
      label: sms_campaign.store.title,
      className: 'link',
      route: `/stores/stores/${sms_campaign.store.id}`
    }
    details.sections[0].items.push({ label: 't(Store)', content: <Button { ...store } /> })
  }

  if(sms_campaign.direction === 'inbound') {
    details.sections[0].items.push({ label: 't(Trigger Term)', content: sms_campaign.term })
  }

  if(sms_campaign.direction === 'outbound') {
    details.sections[0].items.push({ label: 't(Purpose)', content: sms_campaign.purpose })
  }

  details.sections[0].items.push({ label: 't(Phone Number)', content: sms_campaign.phone_number.formatted })

  if(sms_campaign.status === 'scheduled') {
    details.sections[0].items.push({ label: 't(Send At)', content: sms_campaign.send_at, format: 'datetime' })
  }

  if(sms_campaign.status === 'sent') {
    details.sections[0].items.push({ label: 't(Sent At)', content: sms_campaign.sent_at, format: 'datetime' })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  sms_campaign: PropTypes.object
}

const mapPropsToPanel = ({ sms_campaign }, context) => ({
  title: 't(Overview)',
  panel: <Overview sms_campaign={ sms_campaign } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Campaign)', 
        svg: 'square_pen',
        show: sms_campaign.status === 'draft', 
        modal: <Edit sms_campaign={ sms_campaign } /> 
      },
      { 
        label: 'Send/Schedule Campaign',
        svg: 'clock',
        show: sms_campaign.direction === 'outbound' && sms_campaign.status === 'draft',
        modal: <Send sms_campaign={ sms_campaign } /> 
      },
      {
        label: 't(Unschedule Campaign)',
        svg: 'clock',
        show: sms_campaign.direction === 'outbound' && sms_campaign.status === 'scheduled',
        confirm: 't(Are you sure you want to unschedule this campaign?)',
        request: {
          endpoint: `/api/admin/campaigns/sms/${sms_campaign.id}/unschedule`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to unschedule campaign)')
        }
      },
      {
        label: 't(Deactivate Campaign)',
        svg: 'x',
        show: sms_campaign.direction === 'inbound' && sms_campaign.status === 'active',
        request: {
          endpoint: `/api/admin/campaigns/sms/${sms_campaign.id}/activate`,
          method: 'PATCH',
          confirm: 't(Are you sure you want to deactivate this campaign?)',
          body: {
            status: 'inactive'
          }
        }
      },
      {
        label: 't(Activate Campaign)',
        svg: 'check',
        show: sms_campaign.direction === 'inbound' && _.includes(['draft','inactive'], sms_campaign.status),
        request: {
          endpoint: `/api/admin/campaigns/sms/${sms_campaign.id}/activate`,
          method: 'PATCH',
          body: {
            status: 'active'
          }
        }
      },
      {
        label: 't(Delete Campaign)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
        deletable: true,
        request: {
          endpoint: `/api/admin/campaigns/sms/${sms_campaign.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted SMS campaign)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete SMS campaign)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
