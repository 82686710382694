import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Device extends React.Component {

  static contextTypes = {
    uploader: PropTypes.object
  }

  static propTypes = {
    multiple: PropTypes.bool,
    files: PropTypes.array,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleBrowse = this._handleBrowse.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    const { multiple } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-device">
          <div className="maha-attachments-device-body">
            <div className="maha-attachments-device-drop">
              <div className="maha-attachments-device-text">
                <p>Drop { multiple ? 'Files' : 'File' } Here</p>
                <span>or</span>
                <Button { ...this._getChoose() }/>
              </div>
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getChoose() {
    const { multiple } = this.props
    return {
      label: multiple ? 't(Choose Files)' : 't(Choose File)',
      color: 'blue',
      handler: this._handleBrowse
    }
  }

  _getPanel() {
    const { files } = this.props
    return {
      title: 't(Choose File(s))',
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: files.length > 0 ? [
        { label: 't(Next)', handler: this._handleNext }
      ] : []
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleBrowse() {
    this.context.uploader.browse()
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Device
